import PropTypes from 'prop-types'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'

const ItemListButton = ({ image, name, onClick, type, rarity = false, countItem }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className="w-[72px] h-[120px] overflow-hidden flex flex-col items-center relative"
    >
      <ItemThumbnail
        image={image}
        className="w-[72px] h-[80px] border-menu-dark-blue rounded-sm"
        imgClassName={type === 'standard' ? 'w-full' : 'w-[90%]'}
        rarity={rarity}
      />
      <div className="absolute top-[2px] right-[4px] w-[18px] h-[18px] leading-[18px] font-hiragino rounded-full bg-white text-xs	">
        {countItem}
      </div>
      <div className="w-full font-vldLineGR text-sm text-center line-clamp-2">{name}</div>
    </button>
  )
}

ItemListButton.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
  rarity: PropTypes.bool,
  countItem: PropTypes.countItem
}

export default ItemListButton
