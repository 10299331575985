// @ts-nocheck
import PropTypes from 'prop-types'
import React from 'react'
import ChevronDoubleDown from 'src/components/common/symbols/ChevronDoubleDown'
import ExchangeButton from 'src/pages/main/shop/components/ShopHeartTab/ExchangeButton'
import ShopExchangeConfirmModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeConfirmModal'
import ShopExchangeInsufficientModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeInsufficientModal'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'

import DiamondRed from 'src/assets/img/common/diamond-red.png'
import Life from 'src/assets/img/common/life.png'

const ShopExchangeHeart = ({ heartExchangeConfig }) => {
  const { user } = useAuthState()
  const currentRedDiamonds = user?.red_amount
  const { setModalContent, setSelectedHeartExchange } = useShopContext()

  const numberOfRedDiamonds = parseInt(heartExchangeConfig?.number_of_red_diamonds)
  const numberOfHeartsExchanged = parseInt(heartExchangeConfig?.number_of_hearts_exchanged)

  const onSelect = () => {
    const isAllowExchange = currentRedDiamonds >= numberOfRedDiamonds
    setSelectedHeartExchange(heartExchangeConfig)

    setModalContent(
      isAllowExchange ? <ShopExchangeConfirmModal /> : <ShopExchangeInsufficientModal />
    )
  }
  // common/

  return (
    <div className="flex flex-col items-center justify-center w-full h-full font-hiragino">
      <div className="flex items-center justify-center w-full my-2">
        <img src={DiamondRed} className="w-6 mr-2" />
        <span className="text-xs">
          ダイヤ <span className="text-md">{numberOfRedDiamonds}</span>個
        </span>
      </div>
      <ChevronDoubleDown />
      <div className="flex items-center justify-center w-full mb-4">
        <img src={Life} className="w-8 mr-2" />
        <span className="text-sm">
          ハート <span className="text-base">{numberOfHeartsExchanged}</span>個
        </span>
      </div>
      <ExchangeButton onClick={onSelect} />
    </div>
  )
}

ShopExchangeHeart.propTypes = {
  heartExchangeConfig: PropTypes.object.isRequired
}

export default ShopExchangeHeart
