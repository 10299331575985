import PropTypes from 'prop-types'
import { Listbox, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom'
import { MENU_PATHS } from 'src/routes/menu'
import SectionHeader from 'src/components/menu/SectionHeader'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnKakunin from 'src/assets/img/menu/btn_kakunin.png'
import ChevronDownBlue from 'src/assets/img/common/chevron_down_blue.svg'

const InquiryForm = ({
  userId,
  onConfirm,
  categories,
  selectedCategory,
  message,
  setSelectedCategory,
  setMessage,
  showHistory,
  setFiles,
  setFile1,
  setFile2,
  setFile3,
  file1,
  file2,
  file3
}) => {
  const fileSelectedHandler = (e, index) => {
    switch (index) {
      case 0:
        return handleUploadFile(e, setFile1, index)
      case 1:
        return handleUploadFile(e, setFile2, index)
      case 2:
        return handleUploadFile(e, setFile3, index)
    }
  }

  const handleUploadFile = (e, setFile, index) => {
    const selectedFiles = e.target.files[0]
    if (selectedFiles?.size > 3 * 1024 * 1024) {
      alert('ファイルサイズが大きすぎます。3MB 未満のファイルを選択してください。')
      e.target.value = ''
    } else {
      const allowedExtensions = ['jpeg', 'png', 'mp4', 'wav', 'heic', 'gif', 'mov', 'h.264', 'jpg']
      const fileName = selectedFiles?.name.toLowerCase()
      const fileExtension = fileName?.split('.').pop()

      if (allowedExtensions.includes(fileExtension)) {
        if (index === 0) {
          const readerFile1 = new FileReader()
          readerFile1.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile1.readAsDataURL(selectedFiles)
          setFiles(prev => ({
            ...prev,
            file1: selectedFiles
          }))
        } else if (index === 1) {
          const readerFile2 = new FileReader()
          readerFile2.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile2.readAsDataURL(selectedFiles)
          setFiles(prev => ({
            ...prev,
            file2: selectedFiles
          }))
        } else {
          const readerFile3 = new FileReader()
          readerFile3.onload = (e) => {
            const imageURL = e.target.result
            setFile(imageURL)
          }
          readerFile3.readAsDataURL(selectedFiles)
          setFiles(prev => ({
            ...prev,
            file3: selectedFiles
          }))
        }
      }
    }
  }

  const getFileStatus = (file) => {
    if (file) {
      return <p>ファイルが選択されました。</p>
    } else {
      return <p>ファイルが選択されていません。</p>
    }
  }

  return (
    <div className="pb-4">
      <div className="w-full p-4 bg-white rounded text-xs text-left leading-6">
        <p>
          お問合せの前に、必ず「
          <Link to={MENU_PATHS.HelpPage} className="text-menu-dark-blue">
            よくある質問
          </Link>
          」をご参照ください。原則として「よくある質問」に記載されております。また、不具合に関する報告は先に、｢現在確認されている不具合に関するご案内｣をご確認下さい。問題が解決しない場合には、下記フォームよりお問合せください。
        </p>
        <p>
          運営からの返答は「
          <button onClick={showHistory} className="text-menu-dark-blue">
            お問合せ履歴と返信
          </button>
          」より閲覧していただけます。（返答に時間がかかることもございますのであらかじめご了承ください。）
        </p>
      </div>
      <div className="bg-white p-6 mt-4 w-full rounded flex flex-col items-center">
        <SectionHeader title="ユーザーID" />
        <span className="mt-2 mb-6 w-full text-left">{userId || '------'}</span>
        <SectionHeader title="カテゴリー" className="w-full" />
        <div className="relative w-full z-10">
          <Listbox value={selectedCategory} onChange={setSelectedCategory}>
            {({ open }) => (
              <>
                <Listbox.Button
                  className={`bg-white w-full p-2 my-2 rounded border border-menu-dark-blue drop-shadow-md flex justify-between items-center text-sm  ${
                    !selectedCategory && 'text-gray-500'
                  }`}
                >
                  <>
                    <span className="truncate pr-2">
                      {selectedCategory?.name || 'お問合せ内容のカテゴリを選択してください。'}
                    </span>
                    <img src={ChevronDownBlue} className={`${open && 'transform rotate-180'}`} />
                  </>
                </Listbox.Button>
                <Transition
                  show={open}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="bg-white w-full my-2 rounded border border-menu-dark-blue drop-shadow-md text-sm text-left absolute z-10">
                    {categories.map((cat, i) => (
                      <Listbox.Option
                        key={cat.id}
                        value={cat}
                        className={`p-2 ${
                          i !== categories.length - 1 && 'border-b border-menu-dark-blue'
                        }`}
                      >
                        {cat.name}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </>
            )}
          </Listbox>
        </div>

        <SectionHeader title="お問合せ内容" className="mt-6 w-full" />
        <textarea
          className="bg-white w-full p-2 my-2 rounded border border-menu-dark-blue drop-shadow-md truncate flex justify-between items-center"
          rows={6}
          onChange={(e) => {
            setMessage(e.target.value)
          }}
          value={message}
          placeholder="お問合せ内容をご入力ください。"
        />
        <SectionHeader title='写真ファイルの添付' className="w-full mt-2" addStyle= '0'/>
        <p className='text-left w-full text-[13px] ml-[50px]'>（合計3枚まで/写真1点につき3MBまで）</p>
        <div className="w-full text-left ml-4">
          {[file1, file2, file3].map((item, index) => (
            <div className="mt-4 flex items-center gap-2" key={index}>
              <label className="px-4 py-1 bg-[#C7C7C7] text-[12px]" htmlFor={`file${index}`}>
                参照....
              </label>
              <input
                type="file"
                id={`file${index}`}
                accept="*/*"
                onChange={(e) => fileSelectedHandler(e, index)}
                style={{ display: 'none' }}
              />
              <span className="text-[12px] text-[#9c9898]">
                {getFileStatus(item)}
              </span>
            </div>
          ))}
        </div>
        <StandardizedButton
          className="w-40 mt-4"
          disabled={!selectedCategory || !message}
          onClick={() => {
            onConfirm(selectedCategory.id, message)
          }}
          img={BtnKakunin}
          alt="確認する"
        />
      </div>
    </div>
  )
}

InquiryForm.propTypes = {
  setSelectedCategory: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  showHistory: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object,
  message: PropTypes.string,
  userId: PropTypes.number,
  setFile1: PropTypes.func,
  setFile2: PropTypes.func,
  setFile3: PropTypes.func,
  file1: PropTypes.string,
  file2: PropTypes.string,
  file3: PropTypes.string,
  setFiles: PropTypes.object
}

export default InquiryForm
