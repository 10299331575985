import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getRewardInfo = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Ranking.RewardInfo)
    return res.data
  } catch (error) {
    return {}
  }
}

export default getRewardInfo
