import React, { useEffect, useState } from 'react'
import iconLiveTime from '../assets/icon_live_time.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { useDuringContext } from '../pages/DuringDelivery/_Provider'
import { useCharacterLiveContext } from '../pages/CharacterSelection/_Provider'
import { formattedCountDownTime } from 'src/lib/date'

const LiveTime = ({ time, livestreamId, className, onComplete }) => {
  const [isVisible, setIsVisible] = useState(new Date(time) > new Date())
  const [displayTime, setDisplayTime] = useState(time)
  const { liveItemsSocket } = useDuringContext()
  const { characterLiveSocket } = useCharacterLiveContext()
  const handleComplete = () => {
    setIsVisible(false)
    onComplete()
  }

  useEffect(() => {
    if (liveItemsSocket.watchableTime === undefined || liveItemsSocket.watchableTime === '') return

    setDisplayTime(liveItemsSocket.watchableTime)
  }, [displayTime, liveItemsSocket.watchableTime])

  useEffect(() => {
    if (characterLiveSocket.watchableTime === undefined || characterLiveSocket.watchableTime === '' || characterLiveSocket.livestreamId !== livestreamId) {
      return
    }

    setDisplayTime(characterLiveSocket.watchableTime)
  }, [characterLiveSocket.livestreamId, characterLiveSocket.watchableTime])

  return (
    <>
      { isVisible && (<div className={clsx('absolute w-[fit-content]', className)}>
        <img src={iconLiveTime} className="w-[88px] h-[42px]" />
        <span className="absolute bottom-[-1px] text-[10px] left-[55%] translate-x-[-50%] text-white font-hiragino">
          <Countdown
            date={new Date(displayTime)}
            onComplete={() => handleComplete()}
            renderer={({ hours, minutes, seconds }) => {
              return <>{formattedCountDownTime(hours, minutes, seconds)}</>
            }}
          />
        </span>
      </div>)}
    </>
  )
}

LiveTime.propTypes = {
  time: PropTypes.string,
  livestreamId: PropTypes.any,
  className: PropTypes.string,
  onComplete: PropTypes.func
}

export { LiveTime }
