import NotificationSetting from 'src/models/NotificationSetting'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getNotificationSettings = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Notification.List)
    if (res.data.success === false) {
      return {
        characterSetting: [],
        globalSetting: {}
      }
    } else {
      const settings = []
      for (const s of res.data.characters) {
        const setting = new NotificationSetting(
          s.id,
          s.name,
          s.is_line_follow,
          s.accept,
          s.is_notification,
          s.navigator
        )
        settings.push(setting)
      }
      return {
        characterSetting: settings,
        globalSetting: {
          isNotification: res.data?.global_setting?.is_notification
        }
      }
    }
  } catch (error) {
    return {
      characterSetting: [],
      globalSetting: {}
    }
  }
}

export default getNotificationSettings
