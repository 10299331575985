import { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { countCharacters } from 'src/lib/validation'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import { ErrorMessage } from 'src/components/ErrorMessage'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnHenkou from 'src/assets/img/common/btn_henkou.png'

const UserNameEditBox = ({ userName, handleSave, handleClose }) => {
  const [editUserName, setEditUserName] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    handleNameChange(userName)
  }, [userName])

  const handleNameChange = useCallback(
    (value) => {
      if (countCharacters(value) <= 16) {
        setEditUserName(value)
        setErrors({})
      } else {
        setErrors({ ...errors, name: ['全角8文字以内/半角16文字以内で入力してください。'] })
      }
    },
    [setEditUserName, setErrors, editUserName]
  )

  return (
    <div className="bg-popup-sm bg-fill bg-no-repeat rounded-md">
      <ModalCloseButton handleClose={handleClose} />
      <div className="py-8 px-4 w-full flex flex-col justify-center items-center z-10">
        <TitleHeader title="名前変更" />
        <span className="text-sm text-center w-full">彼に呼ばれたい名前を入力しよう！</span>
        <div className="w-5/6  flex flex-col items-start">
          <input
            type="text"
            name="username"
            id="username"
            aria-label="username"
            className="mt-4 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
            placeholder="朝日空"
            value={editUserName || ''}
            onChange={(e) => handleNameChange(e.target.value)}
          />
          <ErrorMessage errors={errors} field="name" />
          <span className="text-sm mt-2 w-full">全角8文字以内/半角16文字以内</span>
        </div>
        <StandardizedButton
          disabled={!editUserName}
          onClick={() => handleSave(editUserName)}
          img={BtnHenkou}
          alt="変更"
        />
      </div>
    </div>
  )
}

UserNameEditBox.propTypes = {
  userName: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default UserNameEditBox
