/* eslint-disable quotes */
import PropTypes from 'prop-types'
import { useState } from 'react'
import { Tab } from '@headlessui/react'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import StandardizedButton from 'src/components/common/StandardizedButton'
import InquiryPreview from 'src/pages/menu/InquiryPreview'
import NotificationIcon from 'src/components/common/NotificationIcon'
import InquiryForm from 'src/pages/menu/InquiryForm'
import InquiryHistory from 'src/pages/menu/InquiryHistory'
import Inquiry from 'src/models/Inquiry'

import TabHistoryOn from 'src/assets/img/menu/tab_history_on_large.png'
import TabHistoryOff from 'src/assets/img/menu/tab_history_off_large.png'
import TabFormOn from 'src/assets/img/menu/tab_form_on_large.png'
import TabFormOff from 'src/assets/img/menu/tab_form_off_large.png'
import BtnBack from 'src/assets/img/common/btn_back.png'
import { CATEGORIES } from 'src/constants/common'
import TipsModal from 'src/components/common/TipsModal'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'

const InquiryPage = ({
  userId,
  sendInquiry,
  hasNotifications,
  setSelectedIndex,
  isLoading,
  inquiries,
  selectedIndex
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [message, setMessage] = useState('')
  const [showPreview, setShowPreview] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const { adminMessage } = useUserContactListChannel()
  const [file1, setFile1] = useState('')
  const [file2, setFile2] = useState('')
  const [file3, setFile3] = useState('')
  const [files, setFiles] = useState({
    file1: '',
    file2: '',
    file3: ''
  })

  return (
    <>
      <MenuBar title="inquiry" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex flex-col pt-menu-header-offset" overflow={true}>
          {isSubmitted ? (
            <>
              <div className="w-full px-10 py-14 bg-white rounded">
                <div className="font-bold text-lg mb-4">お問合せありがとうございます。</div>
                <div className="leading-6 whitespace-pre-line">
                  {`お問合せ内容の返信は\n「`}
                  <button
                    onClick={() => {
                      setSelectedIndex(1)
                      setIsSubmitted(0)
                    }}
                    className="text-menu-dark-blue"
                  >
                    お問合せ履歴と返信
                  </button>
                  {`」より\nご覧いただけます。`}
                </div>
              </div>
              <StandardizedButton
                className="w-32 mx-auto mt-4"
                disabled={false}
                onClick={() => {
                  setIsSubmitted(0)
                }}
                img={BtnBack}
                alt="戻る"
              />
            </>
          ) : showPreview && message && selectedCategory ? (
            <InquiryPreview
              onSubmit={() => {
                sendInquiry(selectedCategory, message, files)
                setIsSubmitted(true)
                setSelectedCategory(null)
                setMessage('')
                setFile1('')
                setFile2('')
                setFile3('')
              }}
              onEdit={() => setShowPreview(false)}
              selectedCategory={selectedCategory}
              message={message}
              userId={userId}
              file1={file1}
              file2={file2}
              file3={file3}
            />
          ) : (
            <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
              <Tab.List className="flex justify-between">
                <Tab>
                  {({ selected }) => (
                    <img src={selected ? TabFormOn : TabFormOff} alt="お問合せフォーム" />
                  )}
                </Tab>
                <Tab>
                  {({ selected }) => (
                    <div className="relative">
                      <img src={selected ? TabHistoryOn : TabHistoryOff} alt="お問合せ履歴と返信" />
                      {(hasNotifications || adminMessage?.is_new_message) && (
                        <NotificationIcon className="-top-2 -right-1" />
                      )}
                    </div>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels className="mt-4 h-full font-hiragino">
                <Tab.Panel>
                  <InquiryForm
                    onConfirm={() => setShowPreview(true)}
                    categories={CATEGORIES}
                    userId={userId}
                    setSelectedCategory={setSelectedCategory}
                    setMessage={setMessage}
                    selectedCategory={selectedCategory}
                    message={message}
                    showHistory={() => setSelectedIndex(1)}
                    setFile1={setFile1}
                    setFile2={setFile2}
                    setFile3={setFile3}
                    file1={file1}
                    file2={file2}
                    file3={file3}
                    setFiles={setFiles}
                  />
                </Tab.Panel>
                <Tab.Panel
                  className={
                    isLoading || !inquiries || inquiries.length === 0
                      ? 'h-full flex items-center'
                      : null
                  }
                >
                  <InquiryHistory isLoading={isLoading} inquiries={inquiries} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          )}
          <TipsModal type="inquiry" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

InquiryPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  sendInquiry: PropTypes.func.isRequired,
  hasNotifications: PropTypes.bool.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  userId: PropTypes.number,
  inquiries: PropTypes.arrayOf(PropTypes.instanceOf(Inquiry)),
  selectedIndex: PropTypes.number
}

export default InquiryPage
