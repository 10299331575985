/* eslint-disable react/prop-types */
/* eslint-disable no-undef */
import PropTypes from 'prop-types'

import Btn1 from 'src/assets/img/gacha/btn_1_update.png'
import Btn11 from 'src/assets/img/gacha/btn_11_update.png'
import BtnAboutReward from 'src/assets/img/gacha/btn_about_reward.png'
import { DRAW_TYPE } from 'src/models/GachaInfo'
import { genGachaTimeString } from 'src/lib/date'
import { useAuthState } from 'src/providers/AuthProviders'
import BtnRollOne from 'src/assets/img/gacha/comment_1.png'
import BtnRollEleven from 'src/assets/img/gacha/comment_2.png'
import BtnFree from 'src/assets/img/gacha/button_free.png'
import BtnFreeGray from 'src/assets/img/gacha/btn_free_gray.png'
import Btn1Roll from 'src/assets/img/gacha/button_1_roll.png'
import Btn11Roll from 'src/assets/img/gacha/button_11_roll.png'
import styled from 'styled-components'
const CapsuleSlide = ({
  image,
  startAt,
  endAt,
  showDrawModal,
  showDetailsModal,
  useCompass,
  gachasMode,
  setTicket,
  useSSGachaTicket,
  countTimeRoll,
  countTimeRollCurrent,
  name,
  rollFree,
  gachaDetail
}) => {
  const { user } = useAuthState()
  const dreamCompass = user.dream_compass
  const checkButonRoll = () => {
    if (!gachaDetail.use_compass) {
      return <></>
    }

    const isDisabled = !dreamCompass || gachaDetail.use_compass_user === false

    return (
      <button
        type="button"
        className={`relative mb-1 ${isDisabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!isDisabled) {
            showDrawModal(DRAW_TYPE.Free)
          }
        }}
      >
        <img src={isDisabled ? BtnFreeGray : BtnFree} alt="無料1回ガチャ" />
      </button>
    )
  }

  return (
    <div className="px-5 py-3">
      <ResponsiveSlide
        className="w-full h-[22rem] bg-light-pink rounded-lg relative text-hiragino drop-shadow-md"
        style={{
          backgroundImage: `url('${image}')`,
          backgroundSize: '100% 100%',
          objectFit: 'cover'
        }}
      >
        <div className="absolute bottom-0 p-2">
          <div
            className="flex gap-2"
            style={
              gachaDetail.use_compass
                ? {}
                : { display: 'flex', justifyContent: 'center', gap: '40px' }
            }
          >
            {checkButonRoll()}
            <button
              onClick={() => {
                showDrawModal(DRAW_TYPE.One)
                setTicket(useSSGachaTicket)
              }}
            >
              {gachaDetail.use_compass ? <img src={Btn1} /> : <img src={Btn1Roll} />}

              {countTimeRollCurrent?.one_time_roll > 0 && (
                <>
                  <img
                    style={{ width: '60px' }}
                    className={
                      gachaDetail.use_compass
                        ? 'absolute top-[-11px] left-[42%]'
                        : 'absolute top-[-14px] left-[12%]'
                    }
                    src={BtnRollOne}
                  />
                  <span
                    className={
                      gachaDetail.use_compass
                        ? 'absolute top-[-8px] left-[46%] text-[10px]'
                        : 'absolute top-[-11px] left-[17%] text-[10px]'
                    }
                  >
                    {countTimeRollCurrent?.one_time_roll}
                    回目
                  </span>
                </>
              )}
            </button>
            <button
              onClick={() => {
                showDrawModal(DRAW_TYPE.Eleven)
                setTicket(useSSGachaTicket)
              }}
            >
              {gachaDetail.use_compass ? <img src={Btn11} /> : <img src={Btn11Roll} />}

              {countTimeRollCurrent?.eleven_time_roll > 0 && (
                <>
                  <img
                    style={{ width: '60px' }}
                    className={
                      gachaDetail.use_compass
                        ? 'absolute top-[-11px] right-[6%]'
                        : 'absolute top-[-14px] right-[6%]'
                    }
                    src={BtnRollEleven}
                  />
                  <span
                    className={
                      gachaDetail.use_compass
                        ? 'absolute top-[-8px] right-[11%] text-[10px]'
                        : 'absolute top-[-11px] right-[11%] text-[10px]'
                    }
                  >
                    {countTimeRollCurrent?.eleven_time_roll}
                    回目
                  </span>
                </>
              )}
            </button>
          </div>
          <div className="flex mt-2 items-center justify-between">
            <div className="w-full h-[30px] mx-auto bg-white/70 rounded text-xs truncate flex items-center justify-center">
              {genGachaTimeString(startAt, endAt)}
            </div>

            <button type="button" onClick={showDetailsModal} className="h-[30px] ml-2">
              <img src={BtnAboutReward} alt="提供割合について" />
            </button>
          </div>
        </div>
      </ResponsiveSlide>
    </div>
  )
}

CapsuleSlide.propTypes = {
  image: PropTypes.string,
  startAt: PropTypes.string.isRequired,
  endAt: PropTypes.string.isRequired,
  gachasMode: PropTypes.array.isRequired,
  showDrawModal: PropTypes.func.isRequired,
  showDetailsModal: PropTypes.func.isRequired,
  useCompass: PropTypes.bool.isRequired,
  gachaDetail: PropTypes.object
}

const ResponsiveSlide = styled.div`
  @media (max-width: 375px) {
    height: 16.5rem;
  }
  @media (max-width: 414px) {
    height: 16.5rem;
  }
`

export default CapsuleSlide
