import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { Tab } from '@headlessui/react'
import Item, { ITEM_TYPE } from 'src/models/Item'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import ItemListButton from 'src/components/menu/ItemListButton'
import NoContent from 'src/components/layouts/NoContent'
import { Modal } from 'src/components/common/Modal'
import ItemDetailsModal from 'src/pages/menu/ItemDetailsModal'

import TabItemOn from 'src/assets/img/menu/tab_item_on.png'
import TabItemOff from 'src/assets/img/menu/tab_item_off.png'
import TabSpecialItemOn from 'src/assets/img/menu/tab_specialitem_on.png'
import TabSpecialItemOff from 'src/assets/img/menu/tab_specialitem_off.png'

export const ItemPage = ({ itemsHistory, isLoading, itemSpecial }) => {
  const [showModal, setShowModal] = useState(false)
  const [showModalSpecial, setShowModalSpecial] = useState(false)
  const [modalItem, setModalItem] = useState(null)
  const [modalItemSpecial, setModalItemSpecial] = useState(null)
  const [specialItems, setSpecialItems] = useState([])

  const handleModal = (item) => {
    setModalItem(item)
    setShowModal(true)
  }
  const handleModalSpecial = (item) => {
    setModalItemSpecial(item)
    setShowModalSpecial(true)
  }

  const handleClose = () => {
    setModalItem(null)
    setShowModal(false)
    setShowModalSpecial(false)
  }

  useEffect(() => {
    if (!itemSpecial || itemSpecial.length === 0) {
      setSpecialItems([])
    } else {
      setSpecialItems(
        itemSpecial.filter(
          (el) =>
            (el.type === ITEM_TYPE.Special && (el.itemKind === 'gacha_ticket' || el.itemKind === 'ssr_ticket')) || (el.type === ITEM_TYPE.Payment && el.itemKind === 'platinum_passport')
        )
      )
    }
  }, [itemSpecial])

  return (
    <>
      <MenuBar title="item" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex flex-col">
          <Tab.Group>
            <Tab.List className="flex justify-between mx-2">
              <Tab>
                {({ selected }) => (
                  <img
                    src={selected ? TabItemOn : TabItemOff}
                    className="w-[644px] h-[39px]"
                    alt="アイテム"
                  />
                )}
              </Tab>
              <Tab>
                {({ selected }) => (
                  <img
                    src={selected ? TabSpecialItemOn : TabSpecialItemOff}
                    className="w-[644px] h-[39px]"
                    alt="特殊アイテム"
                  />
                )}
              </Tab>
            </Tab.List>
            <Tab.Panels className="mt-4 h-full overflow-y-scroll">
              <Tab.Panel
                className={
                  itemsHistory.length > 0 ? 'grid grid-cols-4 gap-2' : 'flex h-full items-center'
                }
              >
                {itemsHistory.length > 0 ? (
                  itemsHistory.map((item) => (
                    <ItemListButton
                      countItem={item.count}
                      key={item.id}
                      image={item?.image?.url}
                      name={item.name}
                      onClick={() => {
                        handleModal(item)
                      }}
                      type="standard"
                      rarity
                    />
                  ))
                ) : (
                  <NoContent
                    message={
                      isLoading ? 'ただいま、読み込み中です' : 'お持ちの\nアイテムがありません'
                    }
                  />
                )}
              </Tab.Panel>
              <Tab.Panel
                className={
                  specialItems.length > 0 ? 'grid grid-cols-4 gap-2' : 'flex h-full items-center'
                }
              >
                {specialItems.length > 0 ? (
                  specialItems.map(
                    (item) =>
                      (item.amount > 0 || item.itemKind === 'platinum_passport') && (
                        <ItemListButton
                          key={item.id}
                          image={item.image.url}
                          name={item.name}
                          onClick={() => {
                            handleModalSpecial(item)
                          }}
                          rarity
                          countItem={item.itemKind === 'platinum_passport' ? 1 : item.amount}
                        />
                      )
                  )
                ) : (
                  <NoContent
                    message={
                      isLoading ? 'ただいま、読み込み中です' : 'お持ちの\nアイテムがありません'
                    }
                  />
                )}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <Modal
            open={showModal}
            onClose={handleClose}
            className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
          >
            {modalItem ? <ItemDetailsModal item={modalItem} handleClose={handleClose} /> : null}
          </Modal>

          <Modal
            open={showModalSpecial}
            onClose={handleClose}
            className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
          >
            {modalItemSpecial ? (
              <ItemDetailsModal item={modalItemSpecial} handleClose={handleClose} />
            ) : null}
          </Modal>
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

ItemPage.propTypes = {
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)),
  isLoading: PropTypes.bool.isRequired,
  itemsHistory: PropTypes.array,
  itemSpecial: PropTypes.array
}
