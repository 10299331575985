export default class Story {
  constructor(
    id,
    type,
    isBlackout,
    blackoutColor,
    address,
    characterName,
    description,
    characterVoiceUrl,
    isCharacterVoiceRepeat,
    backgroundImage,
    bgmUrl,
    isBgmAudioRepeat,
    isBgmStop,
    beforeBgmSetting,
    seUrl,
    isSeAudioRepeat,
    timeToDisplay,
    timeToChange,
    timeToLeave,
    characterImageThumbnail,
    characterImageWidth,
    characterImageHeight,
    characterImageWidthWhenMove,
    characterImageHeightWhenMove,
    characterImageHorizontalPosition,
    characterImageVerticalPosition
  ) {
    this.id = id
    this.type = type
    this.isBlackout = isBlackout
    this.blackoutColor = blackoutColor
    this.address = address
    this.characterName = characterName
    this.description = description
    this.character_voice_url = characterVoiceUrl
    this.isCharacterVoiceRepeat = isCharacterVoiceRepeat
    this.backgroundImage = backgroundImage
    this.bgmUrl = bgmUrl
    this.isBgmAudioRepeat = isBgmAudioRepeat
    this.isBgmStop = isBgmStop
    this.beforeBgmSetting = beforeBgmSetting
    this.seUrl = seUrl
    this.isSeAudioRepeat = isSeAudioRepeat
    this.timeToDisplay = timeToDisplay
    this.timeToChange = timeToChange
    this.timeToLeave = timeToLeave
    this.characterImageThumbnail = characterImageThumbnail
    this.characterImageWidth = characterImageWidth
    this.characterImageHeight = characterImageHeight
    this.characterImageWidthWhenMove = characterImageWidthWhenMove
    this.characterImageHeightWhenMove = characterImageHeightWhenMove
    this.characterImageHorizontalPosition = characterImageHorizontalPosition
    this.characterImageVerticalPosition = characterImageVerticalPosition
  }
}
