import styled from 'styled-components'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'
import MessageContainer from 'src/components/message/MessageContainer'

import BGPage from 'src/assets/img/message/bg-common.png'

export const MessagePage = () => {
  return (
    <Wrapper>
      <Header>
        <MenuBar title="message" />
      </Header>
      <MainLayout className="h-fit" classNameHeader="top-[50px] xs:px-4 px-0 py-8" active="message">
        <MessageContainer />
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[110px] z-50'
})``

const Wrapper = styled.div.attrs({
  className: 'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto bg-center bg-no-repeat bg-cover'
})`
  background-image: url(${BGPage});
`
