import EffGray from 'src/assets/img/mypage/effect/eff-gray.png'
import EffLight from 'src/assets/img/mypage/effect/eff-light.png'

const BackGroundEffect = () => {
  return (
    <>
      <div className="absolute -right-[60px] top-[40px] z-20">
        <img src={EffGray} alt="" />
      </div>
      <div className="absolute top-0 left-0 bottom-0 z-20">
        <img src={EffLight} alt="" />
      </div>
    </>
  )
}

export default BackGroundEffect
