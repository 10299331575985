// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate, useParams } from 'react-router-dom'
import { useConversation } from 'src/pages/main/Message/hooks/useConversation'
import { Header } from 'src/components/message/chat/Header'
import { SlideButton } from 'src/components/common/SlideButton'
import LevelProgress from 'src/components/common/LevelProgress'
import { ItemChatReceived } from 'src/components/message/ChatBox/MessageReceived'
import { ItemChat } from 'src/components/message/ChatBox/Item'
import { Input } from '../../../components/message/ChatBox/Input'
import { BtnShowChatBox } from 'src/components/message/BtnShowChatBox'
import { isNavigator } from 'src/lib/character'
import { Modal } from 'src/components/common/Modal'
import TipsModal from 'src/components/common/TipsModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'
import { getConversationItem } from 'src/services/get/getChat'

import BtnErase from 'src/assets/img/message/detail/eraser-btn.png'
import ExpandChat from 'src/assets/img/message/detail/expand-chat.png'
import ZoomoutChat from 'src/assets/img/message/detail/zoom-out-chat.png'
import GrowthBG from 'src/assets/img/mypage/growth/growth-button-bg.png'
import GrowthBtn from 'src/assets/img/message/detail/growth-btn.png'
import GrowthBtnExpend from 'src/assets/img/mypage/growth/growth-btn-expend.png'
import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'
import BackBtn from 'src/assets/img/mini_game/btn/btn_back.png'
import PlayBtn from 'src/assets/img/mini_game/btn/btn_play.png'
import MiniGameTitle from 'src/assets/img/mini_game/title/tittle_minigame.png'
import ModalBg from 'src/assets/img/mini_game/daishi.png'
import MiniGameIcon from 'src/assets/img/mini_game/btn/btn_game.png'
import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import BtnClose from 'src/assets/img/common/btn-close.png'

import { MAIN_PATHS } from 'src/routes/main'
import { postIsCheckStory } from 'src/services/post/postBackGround'
import { useLoadingMore } from 'src/services/hooks/useLoadingMore'
import LoadingIcon from 'src/components/layouts/LoadingIcon'

export const MessageDetailPage = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [isExpand, setIsExpand] = useState(false)
  const [sizeInput, setSizeInput] = useState(0)
  const [isOpenGameModal, setIsOpenGameModal] = useState(false)
  const { user } = useAuthState()
  const lastScrollHeight = useRef(0)
  const roomChatRef = useRef()
  const { data: conversationData, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage, refetch, addNewMessageToCache, updateMessageToCache } = useConversation()
  const { characterId } = useParams()
  const navigate = useNavigate()
  const [character, setCharacter] = useState({})
  const [loveLvSettings, setLoveLvSettings] = useState({})
  const [bgImage, setBgImage] = useState('')
  const [messages, setMessages] = useState([])
  const [scroll, setScroll] = useState(true)
  const [multiLevel, setMultiLevel] = useState({})
  const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [isOpenGift, setIsOpenGift] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [conversationId, setConversationId] = useState()
  const [present, setPresent] = useState(null)
  const [checkText, setCheckText] = useState(0)

  const scrollToLast = () => {
    roomChatRef.current?.scrollTo({ top: roomChatRef.current?.scrollHeight - lastScrollHeight.current })
    lastScrollHeight.current = 0
  }

  useEffect(() => {
    if (!conversationData) return

    setMessages(conversationData.pages.map(page => page.messages).flat())
    const firstPage = conversationData.pages.find(page => page.first_page)
    if (firstPage) {
      setCharacter(firstPage.character)
      setConversationId(firstPage.conversation_id)
      setLoveLvSettings(firstPage.character_setting_level)
      setBgImage(firstPage.last_character_background_image_url)
    }
  }, [conversationData])

  useEffect(() => {
    conversationData && refetch()
  }, [])

  useLoadingMore({
    selector: '#start',
    skip: !hasNextPage,
    onLoadMore: () => {
      lastScrollHeight.current = (roomChatRef.current?.scrollHeight ?? 0)
      fetchNextPage()
    }
  })

  useEffect(() => {
    if (scroll) {
      scrollToLast()
    } else {
      setScroll(true)
    }
  }, [messages])

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    } else {
      setIsOpenGift(true)
    }
  }

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  const handleClose = useCallback(() => {
    setIsOpenGameModal(false)
  }, [isOpenGameModal])

  const changeBgImage = useCallback(
    (msg) => {
      if (!msg || !msg.is_heart_consumption || !msg.character_background_image_url) return
      setBgImage(msg.character_background_image_url)
    },
    [setBgImage]
  )

  const closeMultiLoveModal = useCallback(() => {
    setIsMultiLoveModalOpen(false)
    refetch()
  }, [setIsMultiLoveModalOpen])

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
      setIsOpenGift(true)
    }
  }

  const lastMsgIsOwner = useMemo(() => {
    const copyMsg = [...messages]
    return copyMsg?.reverse().find((message) => message)?.is_owner
  }, [messages])

  const handleUpdateMessage = (msg) => {
    const copyMessages = [...messages]
    const index = copyMessages.findIndex(
      (message) => msg.character_chat_id === message.character_chat_id
    )
    if (index >= 0) {
      copyMessages[index] = msg
      setMessages(copyMessages)
      updateMessageToCache(msg)
      changeBgImage(msg)
    }
  }

  useEffect(() => {
    const updateIsCheckStory = async () => {
      await postIsCheckStory({
        character_id: characterId,
        user_id: user.id,
        is_check_read: true
      })
    }

    updateIsCheckStory()
  }, [])

  const handleOpenGift = async (type, msg) => {
    setCheckText(type === 'character_cloth' ? 1 : 0)
    const giftId = type === 'character_cloth' ? msg.character_cloth_id : msg.setting_item_id
    const giftAmount = type === 'character_cloth' ? msg.number_of_avatar : msg.setting_item_amount
    if (
      (type === 'character_cloth' && !msg.is_received_cloth) ||
      (type === 'setting_item' && !msg.is_received_present)
    ) {
      const res = await getConversationItem(msg.character_chat_id, type, giftId, giftAmount)
      if (res) {
        if (res.characterLevel) {
          setCharacter({ ...character, level: res.characterLevel })
          setLoveLvSettings({ ...loveLvSettings, level: res.characterLevel })
        }
        if (!res.data.items) return
        setPresent(res.data.items)
        if (!res.characterLevels) return
        handleSetModalList(res.characterLevels)
        if (type === 'character_cloth') {
          msg.is_received_cloth = true
        } else if (type === 'setting_item') {
          msg.is_received_present = true
        }
      } else {
        console.log('プレゼントは受け取り済みです')
      }
    }
  }

  return (
    <Container chatBgImage={bgImage}>
      <div className="fixed w-screen sm:w-desktop-width top-0 mx-auto sm:left-1/2 sm:-translate-x-1/2">
        <Header back={MAIN_PATHS.MessagePage} />
      </div>
      {conversationData && !isNavigator(character) && !isExpand && (
        <SliderStatus>
          <div onClick={() => setIsOpen(!isOpen)} className="h-[40px] overflow-hidden">
            <img className="h-[100%]" src={isOpen ? GrowthBtnExpend : GrowthBtn} alt="growButton" />
          </div>
          <SlideButton visible={isOpen} className="w-[180px]">
            <div className="flex items-center justify-between h-[50px] overflow-hidden">
              <div className="btn h-[100%] w-[35%]">
                <img className="h-[100%]" src={GrowthBG} alt="growButton" />
              </div>
              <div className="status w-[65%] bg-sliderstatus px-3 pt-1 pb-2 bg-no-repeat bg-cover">
                <LevelProgress size="md" level={character?.level} />
              </div>
            </div>
          </SlideButton>
        </SliderStatus>
      )}
      {/* TODO: temporarily hide the minigame, it will be shown when the minigame is ready to release */}
      {false && conversationData && !isNavigator(character) && lastMsgIsOwner && (
        <img
          src={MiniGameIcon}
          alt="ミニゲームアイコン"
          className="absolute top-[25%] right-[5%] w-[48px] h-[48px] cursor-pointer"
          onClick={() => setIsOpenGameModal(true)}
        />
      )}

      <div className={hidden ? 'hidden' : 'block'}>
        <div
          className={'pt-[26px] absolute left-2 right-2 rounded-[14px] bg-white/40'}
          style={{ bottom: `${sizeInput}px` }}
        >
          <div className="absolute flex -top-4 right-2 ">
            <div
              className={isExpand ? 'hidden w-10 h-8' : 'block w-10 h-8' + ' cursor-pointer'}
              onClick={() => setHidden(true)}
            >
              <img className="w-full h-full" src={BtnErase} alt="erase" />
            </div>
            <div className="w-10 h-8 ml-1 cursor-pointer" onClick={() => setIsExpand(!isExpand)}>
              <img
                className="w-full h-full"
                src={isExpand ? ZoomoutChat : ExpandChat}
                alt="share"
              />
            </div>
          </div>
          <WrapperChatBox isExpand={isExpand} sizeInput={sizeInput} ref={roomChatRef}>
            {isFetching ? <LoadingIcon /> : <div id='start'/>}
            {messages.map((message, i) => (
              <div key={i}>
                {message?.is_owner ? (
                  <ItemChatReceived data={message} />
                ) : (
                  <ItemChat
                    msg={message}
                    loveLvSettings={loveLvSettings}
                    character={character}
                    changeBgImage={changeBgImage}
                    isScrollOpenTalk={() => setScroll(false)}
                    handleUpdateMessage={handleUpdateMessage}
                    setMultiLevel={setMultiLevel}
                    setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                    handleOpenGift={handleOpenGift}
                  />
                )}
                <br />
              </div>
            ))}
            <br />
          </WrapperChatBox>
        </div>
        <div className="fixed bottom-0 mx-auto z-10 w-screen sm:w-desktop-width">
          <Input
            senderId={user?.id}
            conversationId={conversationId}
            handleNewMessages={(msg) => {
              if (msg) {
                setMessages([...messages, msg])
                addNewMessageToCache(msg)
              }
            }}
            setSizeInput={setSizeInput}
            characterId={Number(characterId)}
            loveLvSettings={loveLvSettings}
            character={character}
            setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
            setMultiLevel={setMultiLevel}
          />
        </div>
      </div>
      <div className="absolute bottom-[11px] right-[16px]" onClick={() => setHidden(false)}>
        {hidden ? <BtnShowChatBox /> : null}
      </div>
      <Modal
        open={isOpenGameModal}
        onClose={handleClose}
        className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center"
      >
        <ModalContainer>
          <ModalHeader>
            <img src={MiniGameTitle} alt="minigame_title" className="" />
          </ModalHeader>
          <img
            src={MascotChara}
            alt="mascot_chara"
            className="block w-[56px] h-[48px] absolute right-6 top-[30%]"
          />
          <ModalBody>
            <p>彼からのお返事が届く間のみ、</p>
            <p>ミニゲームを遊んでハートを</p>
            <p>GETする事ができるョ！</p>
            <p className="mb-5">お返事が届いた時点で強制終了だコケッ！</p>
            <p>※ゲームは何回でも出来るけど</p>
            <p>ハートが貰えるのは1日</p>
            <p>（am5時〜am5時）の間で3回までだョ！</p>
          </ModalBody>
          <ModalFooter>
            <img
              src={BackBtn}
              onClick={() => setIsOpenGameModal(false)}
              alt="back_btn"
              className="w-[116px] mr-4"
            />
            <img
              src={PlayBtn}
              onClick={() => navigate(`/mini-game/${characterId}`)}
              alt="play_btn"
              className="w-[116px]"
            />
          </ModalFooter>
        </ModalContainer>
      </Modal>
      <MultiLoveEffectModal
        character={character}
        isMultiLoveModalOpen={isMultiLoveModalOpen}
        multiLevel={multiLevel}
        onClose={closeMultiLoveModal}
      />
      {currentModal && currentModal.id && (
        <GrowEffectModal
          character={currentModal}
          isGrowModalOpen={isGrowModalOpen}
          level={{ currentLevel: currentModal.current_level, nextLevel: currentModal.next_level }}
          onClose={closeGrowModal}
        />
      )}
      <Modal
        open={isOpenGift}
        onClose={() => setIsOpenGift(false)}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino text-[#333333]"
      >
        <div
          onClick={() => setIsOpenGift(false)}
          className="w-[50px] h-[44px] absolute -top-2 -right-2 z-50"
        >
          <img className="w-full h-full" src={BtnClose} alt="close" />
        </div>
        <GiftModalBody className="py-7">
          <ModalTitle>プレゼント受け取り</ModalTitle>
          <div className="h-[310px] overflow-y-scroll ">
            {present?.map((item, index) => (
              <div key={index}>
                <GiftBox>
                  {item?.gift_image && (
                    <div className="w-full h-full">
                      <img
                        className="aspect-square object-cover"
                        src={item?.gift_image}
                        alt="gift"
                      />
                    </div>
                  )}
                </GiftBox>
                <h5 className="font-vldLineGR mt-[3px] text-xl leading-[25px] text-center mb-4">
                  {item?.gift_name}
                </h5>
              </div>
            ))}
            {checkText === 1 ? (
              <></>
            ) : (
              <div className="font-light text-sm text-center leading-[21px] mt-2 mb-2">
                メニュー＞アイテムから確認できます。
              </div>
            )}
          </div>
        </GiftModalBody>
      </Modal>
      <TipsModal type="message_detail" isDetail={true} />
    </Container>
  )
}

const ModalContainer = styled.div.attrs({
  className: 'h-[400px] w-[320px] bg-fill bg-no-repeat'
})`
  background-image: url(${ModalBg});
`

const ModalHeader = styled.div.attrs({
  className: 'w-[85%] mx-auto mt-[44px]'
})``

const ModalBody = styled.div.attrs({
  className: 'mt-6 w-[72%] mx-auto text-center text-xs leading-4 font-light'
})``

const ModalFooter = styled.div.attrs({
  className: 'flex justify-center w-[85%] mx-auto mt-6 cursor-pointer'
})``

const Container = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative font-hiragino overflow-hidden bg-no-repeat bg-left-top bg-cover'
})`
  background-image: url(${(props) => props.chatBgImage || ''});
`

const SliderStatus = styled.div.attrs({
  className: 'absolute cursor-pointer flex items-center justify-center'
})`
  right: 0;
  top: 50px;
  height: fit-content;
`

const WrapperChatBox = styled.div.attrs({
  className: 'overflow-y-auto overflow-x-hidden'
})`
  height: ${(props) =>
    props.isExpand ? `calc(80vh - ${parseInt(props.sizeInput) + 'px'})` : '40vh'};
`

const GiftModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;

  @media (max-width: 410px) {
    font-size: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (max-width: 316px) {
    font-size: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

const GiftBox = styled.div.attrs({
  className: 'mx-auto my-6'
})`
  background-size: 100% 100%;
  width: 152px;
  height: 172px;
`
