import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import { Carousel } from 'react-responsive-carousel'
import clsx from 'clsx'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import CapsuleSlide from 'src/pages/main/capsule/CapsuleSlide'
import CapsuleCarouselControls from 'src/pages/main/capsule/CapsuleCarouselControls'
import { Modal } from 'src/components/common/Modal'
import CapsuleDetailsModal from 'src/pages/main/capsule/CapsuleDetailsModal'
import GachaInfo from 'src/models/GachaInfo'

import BGTitle from 'src/assets/img/common/bg-title.png'
import ArrowLeft from 'src/assets/img/gacha/left.png'
import ArrowRight from 'src/assets/img/gacha/right.png'
import getCounTimesroll from 'src/services/get/getCountTimeRoll'
import getGachaId from 'src/services/get/getGachaId'

const checkValueGachaMode = (item, index) => {
  const {
    lottery_count: lotteryCount,
    mode_group_key: modeGroupKey,
    is_pickup: isPickUp,
    lottery_from: lotteryFrom,
    lottery_to: lotteryTo
  } = item

  if (lotteryCount === 2) {
    if (modeGroupKey === '回以上毎回') {
      return isPickUp
        ? `1回ガチャ${lotteryFrom}回目以降は、毎回ピックアップのSSR ${lotteryTo}個以上確定`
        : `1回ガチャ${lotteryFrom}回目以降は、毎回SSR ${lotteryTo}個以上確定`
    } else if (modeGroupKey === '回目のみ') {
      return `1回ガチャ${lotteryFrom}回目は、SSR ${lotteryTo}個以上確定`
    }
  } else if (lotteryCount === 1) {
    if (modeGroupKey === '回以上毎回') {
      return isPickUp
        ? `11回ガチャ${lotteryFrom}回目以降は、毎回ピックアップのSSR ${lotteryTo}個以上確定`
        : `11回ガチャ${lotteryFrom}回目以降は、毎回SSR ${lotteryTo}個以上確定`
    } else if (modeGroupKey === '回目のみ') {
      return `11回ガチャ${lotteryFrom}回目は、${
        isPickUp ? 'ピックアップの' : ''
      }SSR ${lotteryTo}個以上確定`
    }
  }

  return ''
}

const CapsuleTab = ({
  gachas,
  showDrawModal,
  isShown,
  setTicket,
  setIdItem,
  countRoll,
  rollFree,
  gachaDetail,
  setGachaDetail,
  countTimeRollCurrent,
  setcountTimeRoll
}) => {
  const [slideIndex, setSlideIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    if (isShown) setSlideIndex(0)
  }, [isShown])

  useEffect(() => {
    const getIdFirst = async () => {
      const [countTimeRollRes, gachaIdRes] = await Promise.all([
        getCounTimesroll(gachas[0].id),
        getGachaId(gachas[0].id)
      ])

      setcountTimeRoll(countTimeRollRes.data)
      setGachaDetail(gachaIdRes.items)
    }
    getIdFirst()
  }, [])

  const handleIdItem = async (index) => {
    const currentSlide = gachas[index]
    setIdItem(currentSlide.id)
    const [countTimeRollRes, gachaIdRes] = await Promise.all([
      getCounTimesroll(currentSlide.id || gachas[0].id),
      getGachaId(currentSlide.id)
    ])

    setcountTimeRoll(countTimeRollRes.data)
    setGachaDetail(gachaIdRes.items)
  }

  return (
    <div className="relative px-2 py-4 overflow-y-scroll h-[600px]">
      <FadeTransition show={isShown} className="absolute top-0 left-0 right-0 h-[700px]">
        <Carousel
          swipeable={false}
          showIndicators={false}
          showStatus={false}
          emulateTouch={true}
          showArrows={true}
          showThumbs={false}
          renderArrowNext={(clickHandler, hasNext) =>
            hasNext && <CapsuleCarouselArrow isNext={true} onClick={clickHandler} />
          }
          renderArrowPrev={(clickHandler, hasPrev) =>
            hasPrev && <CapsuleCarouselArrow isNext={false} onClick={clickHandler} />
          }
          selectedItem={slideIndex}
          onChange={(index) => {
            setSlideIndex(index)
            handleIdItem(index)
          }}
        >
          {gachas.map((el, i) => {
            return (
              <CapsuleSlide
                name={el.name}
                countTimeRoll={countRoll}
                countTimeRollCurrent={countTimeRollCurrent}
                id={el.id}
                key={i}
                image={el.image}
                startAt={el.startAt}
                endAt={el.endAt}
                useCompass={el.useCompass}
                gachasMode={el.gachaModes}
                useSSGachaTicket={el.useSSGachaTicket}
                showDrawModal={showDrawModal}
                showDetailsModal={() => setShowModal(true)}
                setTicket={setTicket}
                rollFree={rollFree}
                gachaDetail={gachaDetail}
              />
            )
          })}
        </Carousel>
        <CapsuleCarouselControls
          length={gachas.length}
          selectedIndex={slideIndex}
          onClick={(value) => setSlideIndex(value)}
        />
        <div className="relative">
          <div
            className="px-10 py-1 absolute top-[-12px] left-[21px] z-[9]"
            style={{
              backgroundImage: `url('${BGTitle}')`,
              backgroundSize: '100% 100%'
            }}
          >
            <h3 className="font-vldLineGR text-white text-center font-normal leading-[126%]">
              回数報酬
            </h3>
          </div>
          <div className="h-20 mx-4 mt-4 py-4 px-5 bg-white rounded relative overflow-y-auto mb-[20px]">
            <div className="text-xs text-left font-hiragino">
              {gachas[slideIndex]?.gachaModes?.map((item, index) => (
                <ul key={index}>
                  <li>{checkValueGachaMode(item, index)}</li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </FadeTransition>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        className="sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
      >
        <CapsuleDetailsModal gachaDetail={gachaDetail} handleClose={() => setShowModal(false)} />
      </Modal>
    </div>
  )
}

CapsuleTab.propTypes = {
  isShown: PropTypes.bool.isRequired,
  showDrawModal: PropTypes.func.isRequired,
  gachas: PropTypes.arrayOf(PropTypes.instanceOf(GachaInfo)),
  setTicket: PropTypes.func,
  setIdItem: PropTypes.func,
  countRoll: PropTypes.number,
  rollFree: PropTypes.number,
  gachaDetail: PropTypes.object,
  setGachaDetail: PropTypes.func,
  countTimeRollCurrent: PropTypes.number,
  setcountTimeRoll: PropTypes.func
}

const CapsuleCarouselArrow = ({ isNext, onClick }) => {
  return (
    <button
      className={clsx(
        'absolute top-[40%] h-[80px] w-[100px] z-10',
        isNext ? '-right-[25px]' : '-left-[25px]'
      )}
      type="button"
      aria-label={`${isNext ? 'next' : 'previous'} slide / item`}
      onClick={onClick}
    >
      {<img src={isNext ? ArrowRight : ArrowLeft} style={{ width: 40 }} alt="next" />}
    </button>
  )
}

CapsuleCarouselArrow.propTypes = {
  isNext: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired
}

export default CapsuleTab
