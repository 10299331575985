import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

const FormAmazonPayStart = ({ data }) => {
  useEffect(() => {
    document.AmazonpayRedirectCall.submit()
  }, [])

  return (
    <form name="AmazonpayRedirectCall" action={data.url} method="POST">
      <noscript>
        <br />
        <br />
        <center>
          <h2>AmazonPayの決済画面へ遷移します。 </h2>
          <input type="submit" value="続行" />
        </center>
      </noscript>
      <input type="hidden" name="AccessID" value={data.AccessID} />
      <input type="hidden" name="Token" value={data.token} />
    </form>
  )
}

export default FormAmazonPayStart

FormAmazonPayStart.propTypes = {
  data: PropTypes.object
}
