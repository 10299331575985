import RakingItem from 'src/models/RakingItem'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getRankings = async (level, types, limit, characterId) => {
  const URL = level === 'love' ? API_ROUTES.Ranking.Love : API_ROUTES.Ranking.Grow
  try {
    const res = await axiosInstance.get(URL, {
      params: {
        ranking_type: types,
        limit,
        character_id: characterId
      }
    })
    if (!res.data) return []
    else {
      const ranking = level === 'love' ? res.data.love_rankings : res.data.grow_rankings
      for (const e of Object.keys(ranking)) {
        const items = []
        for (const o of ranking[e]) {
          const item = new RakingItem(
            o.user_id,
            o.name,
            o.birthday,
            o.email,
            o.gender,
            o['total_' + level + 's'],
            o.avatar
          )
          items.push(item)
        }
        ranking[e] = items
      }
      ranking.userRank = res.data.login_user_rank
      return ranking
    }
  } catch (error) {
    return null
  }
}

export const getRankingDetail = async (userId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Ranking.Detail + '/' + userId)
    if (!res.data) return []
    return res.data
  } catch (error) {
    return []
  }
}

export const getRankingD6 = async (types) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Ranking.d6, {
      params: {
        ranking_type: types
      }
    })
    if (!res.data) throw new Error()
    return res.data
  } catch (e) {
    console.log(e)
  }
}
