import PropTypes from 'prop-types'
import GoodFeeling from 'src/assets/img/in-progress/good-feeling.png'
import { Modal } from './Modal'
import ModalCloseButton from './ModalCloseButton'
import StandardizedButton from './StandardizedButton'

const ModalSuccess = ({ isOpen = false, text = '', img = GoodFeeling, handleClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <div className="bg-popup-sm bg-fill bg-no-repeat rounded-md">
        <ModalCloseButton handleClose={handleClose} />
        <div className="py-8 px-4 w-full flex flex-col justify-center items-center z-10">
          <img className="w-[115px] mb-3" src={img} alt="in-progress" />
          <span className="font-bold text-base text-[#333333]">{text}</span>
          <StandardizedButton
            title="閉じる"
            className="w-[120px] h-[60px] mt-4"
            onClick={handleClose}
          />
        </div>
      </div>
    </Modal>
  )
}

ModalSuccess.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
  img: PropTypes.string,
  handleClose: PropTypes.func.isRequired
}

export default ModalSuccess
