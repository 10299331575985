import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import Tabs from 'src/components/common/Tabs'
import useMultiAudio from 'src/components/common/UseMultiAudio'
import BGPage from 'src/assets/img/voicealbum/bg_common.png'
import PropTypes from 'prop-types'
import { getVoiceAlbum } from 'src/services/get/getRecommend'
import { useParams } from 'react-router-dom'

import TabHosyuOff from 'src/assets/img/voicealbum/tab_hosyu_off.png'
import TabHosyuOn from 'src/assets/img/voicealbum/tab_hosyu_on.png'
import TabVoiceLetterOff from 'src/assets/img/voicealbum/tab_voiceletter_off.png'
import TabVoiceLetterOn from 'src/assets/img/voicealbum/tab_voiceletter_on.png'
import BGVoice from 'src/assets/img/voicealbum/btn_voice.png'
import BGVoicePlay from 'src/assets/img/voicealbum/btn_voice_play.png'
import BGVoiceLetter from 'src/assets/img/voicealbum/btn_voiceletter.png'
import BGVoiceLetterPlay from 'src/assets/img/voicealbum/btn_voiceletter_play.png'
import BGTabContent from 'src/assets/img/voicealbum/bg_voice.png'

const characterNames = ['朝日空', '風早翔', '立花駿', '若槻蓮', '横浜湊']
const VoiceItem = ({ item = {}, type = 'voice', player, toggle, isPauseAll }) => {
  useEffect(() => {
    if (isPauseAll && player.playing) toggle()
  })

  const { name, subtitles } = item
  const playing = player?.playing

  const background =
    type === 'voice'
      ? playing
        ? BGVoicePlay
        : BGVoice
      : playing
        ? BGVoiceLetterPlay
        : BGVoiceLetter

  return (
    <VoiceItemWrapper type={type} background={background} onClick={toggle}>
      <p
        style={{
          textShadow: '0px 0px 90px #FFFFFF',
          display: '-webkit-box',
          WebkitLineClamp: 5,
          WebkitBoxOrient: 'vertical'
        }}
        className={`text-[6px] text-[#CB0056] font-SourceHanSans font-medium leading-[9px] ${
          type === 'voice' ? 'mt-[22px]' : 'mt-[27px]'
        } mb-5 mr-6 w-[49%] max-h-[45px] text-ellipsis overflow-y-hidden`}
      >
        {name || subtitles}
      </p>
    </VoiceItemWrapper>
  )
}

VoiceItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    text: PropTypes.string,
    voice_url: PropTypes.shape({
      url: PropTypes.string
    })
  }),
  type: PropTypes.string,
  player: PropTypes.object,
  toggle: PropTypes.func,
  isPauseAll: PropTypes.bool
}

const VoiceItemWrapper = styled.div.attrs({
  className: 'w-full cursor-pointer flex justify-end text-justify'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
  height: ${(props) => (props.type === 'voice' ? '86px' : '90px')};
`

export const VoiceAlbums = ({ voiceAlbums = [], type, isActive = false, isPauseAll }) => {
  const [players, toggle] = useMultiAudio(voiceAlbums.map((item) => item?.voice_url?.url || ''))

  return (
    <div className={`grid grid-cols-2 gap-x-5 gap-y-2.5 pr-2.5 ${!isActive && 'hidden'}`}>
      {voiceAlbums.map((item, idx) => (
        <VoiceItem
          key={idx}
          item={item}
          type={type}
          player={players[idx]}
          toggle={toggle(idx)}
          isPauseAll={isPauseAll}
        />
      ))}
    </div>
  )
}

VoiceAlbums.propTypes = {
  voiceAlbums: PropTypes.array,
  type: PropTypes.string,
  isActive: PropTypes.bool,
  isPauseAll: PropTypes.bool
}

export const RecommendVoiceAlbumPage = () => {
  const [voiceAlbums, setVoiceAlbums] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [activeTab, setActiveTab] = useState('voice')
  const [isPauseAll, setIsPauseAll] = useState(false)

  const { id, name } = useParams()

  const fetchData = async () => {
    const res = await getVoiceAlbum(id, activeTab)
    if (res && res.length) {
      setVoiceAlbums({
        ...voiceAlbums,
        [activeTab]: res
      })
    }
    setIsLoading(false)
  }

  useEffect(() => {
    fetchData()
  }, [activeTab])

  const renderTabs = (activeTab) => {
    return [
      {
        key: 'voice',
        label: <img src={activeTab === 'voice' ? TabHosyuOn : TabHosyuOff} alt="voice" />
      },
      {
        key: 'voice_letter',
        label: (
          <img
            src={activeTab === 'voice_letter' ? TabVoiceLetterOn : TabVoiceLetterOff}
            alt="voice_letter"
          />
        )
      }
    ]
  }

  const onChangeTab = (activeTab) => {
    setIsPauseAll(true)
    setTimeout(() => {
      setActiveTab(activeTab)
      setIsPauseAll(false)
    }, 100)
  }
  return (
    <Wrapper>
      <Header>
        <MenuBar title={`voice-album-${characterNames.includes(name) ? name : ''}`} />
      </Header>
      <MainLayout className="h-fit overflow-y-hidden" active="mypage" showHeader={false}>
        <div className="px-6 py-5 mb-[120px] h-fit">
          {isLoading ? (
            <Loading />
          ) : (
            <Tabs tabs={renderTabs(activeTab)} activeTab={activeTab} setTab={onChangeTab}>
              <TabContent>
                {!voiceAlbums[activeTab] || !voiceAlbums[activeTab].length ? (
                  <NoContent>
                    <p className="font-vldLineGR text-[#333333] text-xl px-11 leading-[30px]">
                      {activeTab === 'voice' ? (
                        <>
                          報酬ボイスが <br />
                          ありません
                        </>
                      ) : (
                        <>
                          ボイスレターが <br />
                          ありません
                        </>
                      )}
                    </p>
                  </NoContent>
                ) : (
                  <div className="h-full overflow-y-auto styled-scroll">
                    <VoiceAlbums
                      isActive={activeTab === 'voice'}
                      key={0}
                      voiceAlbums={voiceAlbums.voice}
                      type={'voice'}
                      isPauseAll={isPauseAll}
                    />
                    <VoiceAlbums
                      isActive={activeTab === 'voice_letter'}
                      key={1}
                      voiceAlbums={voiceAlbums.voice_letter}
                      type={'voice_letter'}
                      isPauseAll={isPauseAll}
                    />
                  </div>
                )}
              </TabContent>
            </Tabs>
          )}
        </div>
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden relative bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${BGPage});
`

const TabContent = styled.div.attrs({
  className: 'pl-5 pr-2 py-3 -mt-1.5 overflow-y-hidden'
})`
  background-image: url(${BGTabContent});
  background-size: 100% 100%;
  height: calc(100vh - 264px + 1.25rem);
`

const NoContent = styled.div.attrs({
  className: 'absolute w-full pb-20'
})`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
