import { useState, useEffect, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { TimeTabs } from 'src/components/ranking/TimeTabs'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { getRankingD6 } from 'src/services/ranking'
import { formatToDate, getFirstAndLastDate } from 'src/constants/date'
import { RANKING_CHARACTER_D6 } from 'src/constants/characters'
import { Tabs } from 'src/constants/common'

import BGPage from 'src/assets/img/ranking/bg-page.png'
import Effect1 from 'src/assets/img/ranking/bg-eff-1.png'
import Effect2 from 'src/assets/img/ranking/bg-eff-2.png'
import Effect3 from 'src/assets/img/ranking/bg-eff-3.png'
import BGGrid from 'src/assets/img/ranking/bg-grid.png'
import BGLoveHeader from 'src/assets/img/ranking/detail/detail-my-love-parameter-bg.png'
import BackIcon from 'src/assets/img/ranking/icon-back.png'
import HomeIcon from 'src/assets/img/ranking/icon-home.svg'
import BGMenu from 'src/assets/img/ranking/bg-menu.png'
import BGBackIcon from 'src/assets/img/ranking/bg-back-icon.png'
import BGHomeIcon from 'src/assets/img/ranking/bg-home-icon.png'
import BGLoveTitle from 'src/assets/img/ranking/detail/detail-top-love-parameter-bg.png'
import BGLovePage from 'src/assets/img/ranking/detail/detail-love-parameter-bg.png'
import BGFooter from 'src/assets/img/ranking/dx6/bg-footer.png'
import EffFooter from 'src/assets/img/ranking/dx6/bg-eff-footer.png'
import Signature from 'src/assets/img/ranking/dx6/signature.png'
import TipsModal from 'src/components/common/TipsModal'

const RankingPosition = ({ background, ranking }) => {
  return (
    <RankingPositionWrapper background={background}>
      <div className="absolute left-[52%] bottom-[4px] -translate-x-1/2 text-white font-black text-[7px] w-full">
        {ranking && `獲得LOVE度 ${ranking}`}
      </div>
    </RankingPositionWrapper>
  )
}

RankingPosition.propTypes = {
  ranking: PropTypes.number,
  background: PropTypes.string
}

export const RankingDx6Page = () => {
  const navigate = useNavigate()
  const [activeKey, setActiveKey] = useState('current_day')
  const [ranking, setRanking] = useState({})
  const [date, setDate] = useState(null)

  const onChangeTab = useCallback(
    (index) => {
      setActiveKey(Tabs[index].key)
      setDate(getFirstAndLastDate(Tabs[index].key))
    },
    [activeKey]
  )

  useEffect(() => {
    fetchData()
    setDate(getFirstAndLastDate(activeKey))
  }, [activeKey])

  const dataAvailable = useMemo(() => {
    return (rankObj) => {
      const extracted = {}
      for (const [key, value] of Object.entries(rankObj)) {
        if ('character_id' in value) {
          extracted[key] = value
        }
      }
      return extracted
    }
  }, [activeKey])

  const fetchData = useCallback(async () => {
    const res = await getRankingD6(activeKey)
    const scopeList = dataAvailable(res.rank)
    setRanking(scopeList)
  }, [activeKey])

  const handlerBackground = (ranking) => {
    return RANKING_CHARACTER_D6[ranking?.character_id].board[ranking?.rank].img
  }

  return (
    <Wrapper>
      <div className="sm:w-[400px] w-full fixed top-10 bottom-0">
        <img src={Effect1} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-18 bottom-0">
        <img src={Effect2} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-0 bottom-0">
        <img src={Effect3} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-0 bottom-0 z-0">
        <img src={BGGrid} alt="" />
      </div>
      <div className="h-[86px]">
        <div className="sm:w-[400px] w-full fixed top-0 left-0 sm:left-1/2 sm:-translate-x-1/2 content-center py-8 z-50 flex items-start justify-between">
          <MenuLeft>
            <div className="h-5 w-auto px-5 relative">
              <div
                onClick={() => navigate(-1)}
                className="h-full w-5 pr-[3px] pb-[3px] cursor-pointer"
                style={{
                  backgroundImage: `url(${BGBackIcon})`,
                  backgroundSize: '90% 90%',
                  backgroundPosition: 'right bottom',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <img className="h-full w-full object-contain" src={BackIcon} alt="" />
              </div>
            </div>
            <div className="bg-white w-[1px] h-6" />
            <div className="h-5 pl-4 pr-10 relative">
              <div
                className="h-full w-5 pr-[2px] pb-[1px] cursor-pointer"
                style={{
                  backgroundImage: `url(${BGHomeIcon})`,
                  backgroundSize: '80% 90%',
                  backgroundPosition: 'right bottom',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <Link to="/mypage">
                  <img className="h-full w-full object-cover" src={HomeIcon} alt="" />
                </Link>
              </div>
            </div>
          </MenuLeft>
          {/* <div className="w-44 h-auto">
            <img className='w-full h-full object-contain' src={SeeReward} alt="" />
          </div> */}
        </div>
      </div>
      <PageTitle background={BGLoveTitle}>Dx6ランキング - 詳細</PageTitle>
      <div className="w-full">
        <div className="px-5 pt-1 pb-3">
          <TimeTabs onChangeTab={onChangeTab} tabs={Tabs} gap={'gap-5'} />
        </div>
        <PageContent background={BGLovePage}>
          <div className="absolute -top-3 left-0 w-[60%] h-32">
            <img className="w-full h-full" src={Signature} alt="Signature" />
          </div>
          <div className="pt-2.5 relative">
            <RankingHeader className="ml-2 opacity-80" background={BGLoveHeader}>
              {date && (
                <div className="text-white text-xs text-left">{formatToDate(activeKey, date)}</div>
              )}
            </RankingHeader>
            {Object.keys(ranking).length !== 0 && (
              <div className="flex relative">
                {ranking.rank_4 && (
                  <div className="absolute top-0 -left-[20%] w-[70%] h-[72vh] z-0">
                    <img
                      className="h-full w-full object-cover"
                      src={RANKING_CHARACTER_D6[ranking.rank_4.character_id].image}
                    />
                  </div>
                )}
                {ranking.rank_2 && (
                  <div className="absolute top-0 -left-[2%] w-[70%] h-[72vh] z-[1]">
                    <img
                      className="h-full w-full object-cover"
                      src={RANKING_CHARACTER_D6[ranking.rank_2.character_id].image}
                    />
                  </div>
                )}
                <div className="absolute top-0 left-[15%] w-[70%] h-[72vh] z-[2]">
                  <img
                    className="h-full w-full object-cover"
                    src={RANKING_CHARACTER_D6[ranking.rank_1.character_id].image}
                  />
                </div>
                {ranking.rank_3 && (
                  <div className="absolute top-0 -right-[2%] w-[70%] h-[72vh] z-[1]">
                    <img
                      className="h-full w-full object-cover"
                      src={RANKING_CHARACTER_D6[ranking.rank_3.character_id].image}
                    />
                  </div>
                )}
                {ranking.rank_5 && (
                  <div className="absolute top-0 -right-[20%] w-[70%] h-[72vh] z-[0]">
                    <img
                      className="h-full w-full object-cover"
                      src={RANKING_CHARACTER_D6[ranking.rank_5.character_id].image}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        </PageContent>
      </div>
      {Object.keys(ranking).length !== 0 && (
        <div className="absolute bottom-10 left-0 z-50 w-full">
          <div className="flex justify-center items-center mb-2">
            <RankingPosition
              ranking={ranking.rank_1.total_love}
              background={() => handlerBackground(ranking.rank_1)}
            />
          </div>
          <div className="flex justify-center items-center mb-2 gap-16">
            {ranking.rank_2 && (
              <RankingPosition
                ranking={ranking.rank_2.total_love}
                background={() => handlerBackground(ranking.rank_2)}
              />
            )}
            {ranking.rank_3 ? (
              <RankingPosition
                ranking={ranking.rank_3.total_love}
                background={() => handlerBackground(ranking.rank_3)}
              />
            ) : (
              <RankingPosition />
            )}
          </div>
          <div className="flex justify-between items-center mb-2 px-2">
            {ranking.rank_4 && (
              <RankingPosition
                ranking={ranking.rank_4.total_love}
                background={() => handlerBackground(ranking.rank_4)}
              />
            )}
            {ranking.rank_5 ? (
              <RankingPosition
                ranking={ranking.rank_5.total_love}
                background={() => handlerBackground(ranking.rank_5)}
              />
            ) : (
              <RankingPosition />
            )}
          </div>
        </div>
      )}
      <Footer>
        <FooterEff />
      </Footer>
      <TipsModal type="dx6" />
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className:
    'sm:w-[400px] w-screen h-screen overflow-hidden mx-auto relative bg-fixed font-hiragino'
})`
  background-image: url(${BGPage});
  background-size: 100% 100%;
`

const MenuLeft = styled.div.attrs({
  className: 'flex items-center justify-between h-9'
})`
  background-image: url(${BGMenu});
  background-size: 100% 100%;
`

const PageTitle = styled.div.attrs({
  className: 'h-fixt w-full text-left text-white text-sm py-1 pl-4 mb-2'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const PageContent = styled.div.attrs({
  className: 'bg-fixed relative'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
  height: calc(100vh - 132px);
`

const RankingHeader = styled.div.attrs({
  className: 'relative px-2 py-1 pl-5 h-fix flex justify-start items-center'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const Footer = styled.div.attrs({
  className:
    'sm:w-[400px] w-full fixed bottom-0 left-0 sm:left-1/2 sm:-translate-x-1/2 content-center h-[400px] z-10'
})`
  background-image: url(${BGFooter});
  background-size: 110% 100%;
  mix-blend-mode: multiply;
  background-repeat: no-repeat;
  background-position: center;
`

const FooterEff = styled.div.attrs({
  className: 'w-full h-full z-10'
})`
  background-image: url(${EffFooter});
  background-size: 100% 40%;
  background-repeat: no-repeat;
  background-position: bottom;
`

const RankingPositionWrapper = styled.div.attrs({
  className: 'relative w-[5.5rem] h-12'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`
