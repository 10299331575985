import { useEffect, useState } from 'react'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { useAuthState } from 'src/providers/AuthProviders'
import getUnreadNews from 'src/services/get/getUnreadNews'

const useNewsHasNotifications = () => {
  const [hasNewsNotifications, setHasNewsNotifications] = useState(false)
  const { user } = useAuthState()

  useEffect(() => {
    const req = new CancelableAPIRequest(getUnreadNews, user?.id)
    req.invoke((resp) => {
      setHasNewsNotifications(resp)
    })

    return () => {
      req.cancel()
    }
  }, [])

  return {
    hasNewsNotifications
  }
}

export default useNewsHasNotifications
