import clsx from 'clsx'
import PropTypes from 'prop-types'

const CheckmarkCheckbox = ({ id, onChange, isChecked }) => {
  return (
    <div
      className={clsx(
        'rounded-full border border-menu-dark-blue w-4 h-4 top-2 right-1 absolute flex z-10 items-center justify-center',
        isChecked ? 'bg-menu-dark-blue' : 'bg-white'
      )}
    >
      <input
        type="checkbox"
        id={id}
        name={`${id}-checkbox`}
        onChange={onChange}
        checked={isChecked}
        className="checkbox"
      />
      <svg width="10" height="10" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.87964 6.80623L0.134671 4.06125C-0.0302419 3.89634 -0.0302419 3.62895 0.134671 3.46402L0.731884 2.86679C0.896797 2.70187 1.1642 2.70187 1.32911 2.86679L3.17826 4.71592L7.13892 0.755276C7.30384 0.590364 7.57124 0.590364 7.73615 0.755276L8.33337 1.35251C8.49828 1.51742 8.49828 1.78481 8.33337 1.94974L3.47687 6.80624C3.31195 6.97116 3.04456 6.97116 2.87964 6.80623Z"
          fill="white"
        />
      </svg>
    </div>
  )
}

CheckmarkCheckbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired
}

export default CheckmarkCheckbox
