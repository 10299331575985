import React from 'react'
import PropTypes from 'prop-types'
import closeLiveModal from '../assets/close_live_modal.png'
import clsx from 'clsx'
import { ModalTitle } from '../../../components/common/ModalWithBg'

const LiveModal = ({ open, onClose, title, children }) => {
  if (!open) return <></>

  return (
    <div className="h-screen w-full md:w-[400px] fixed bottom-0 left-[50%] translate-x-[-50%] z-[2000]" onClick={onClose}>
      <div className="bg-[#000000cc] w-full md:w-[400px] fixed bottom-0 left-[50%] translate-x-[-50%] z-[2000] rounded-t-[16px] pt-[10px]" onClick={(e) => e.stopPropagation()}>
        <div className="sticky top-0 px-[20px]">
          <div className='w-full text-right'>
            <img src={closeLiveModal} className="inline" onClick={onClose} />
          </div>

          {/* @ts-expect-error */}
          {title && <ModalTitle className="mb-0">{title}</ModalTitle>}
        </div>

        <div className={clsx('hidden-scroll overflow-y-auto px-[20px]', title ? 'max-h-[calc(calc(100vh-68px-64px-47px))]' : 'max-h-[calc(calc(100vh-68px-64px-16px))]')}>
          {children}
        </div>
      </div>
    </div>
  )
}

LiveModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export { LiveModal }
