import styled from 'styled-components'
import PropTypes from 'prop-types'
import ButtonCreate from 'src/components/dressup/ButtonCreate'

import BGItem from 'src/assets/img/dressup/bg_item_list.png'
import BtnCreate from 'src/assets/img/dressup/btn_create.png'
// import BtnCreate1 from '../../assets/img/dressup/btn_create_1.png'
import NotCreated from 'src/assets/img/dressup/not_created.png'
import DuringUpgrade from 'src/assets/img/dressup/during_upgrade.png'

const Item = ({ item, onClick }) => {
  const handleClick = () => {
    onClick && onClick(item)
  }

  const renderLabel = () => {
    if (item?.status === 'upgrading') {
      return (
        <div className='absolute top-1 -right-2 w-[120px] h-[18px] z-20'>
          <img className='w-full h-full' src={DuringUpgrade} alt='making' />
        </div>
      )
    } else if (item?.not_created || item?.status === 'not_created') {
      return (
        <div className='absolute top-1 -right-2 w-[120px] h-[18px] z-20'>
          <img className='w-full h-full' src={NotCreated} alt='not created' />
        </div>
      )
    }
    return null
  }

  return (<ItemWrapper>
    <div className='w-fit pt-1.5 pl-2 pr-1.5 text-[#8750D7] text-[8px] leading-[10px] font-vldLineGR'>
      LV.<span className='text-sm'>{item?.level < 10 ? '0' + item?.level : item?.level}</span>
    </div>
    <div className='h-full w-[73px]'>
      { item?.cloth_image?.url ? <img className='w-full h-full' src={item?.cloth_image?.url} alt={item.name} /> : null }
    </div>
    <div className='flex items-center gap-2 h-full px-2'>
      <ButtonWrapper className='pl-1.5 pr-2 h-[25px] w-[50%] mb-1' background={BtnCreate} />
      {/* <ButtonWrapper onClick={handleClick} className='px-2 h-[25px] w-[50%] mb-1' background={BtnCreate1} /> */}
      <ButtonCreate className='h-[25px] w-[50%] mb-1 pl-2.5' onClick={handleClick} diamond={item?.red_diamond} />
    </div>
    {renderLabel()}
    <div className='absolute bottom-2 right-[34px] font-vldLineGR text-sm text-[#8750D7]'>
      育て度
    </div>
  </ItemWrapper>)
}

Item.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

const ItemWrapper = styled.div.attrs({
  className: 'grid grid-cols-[50px_73px_auto] h-[82px] mb-1.5 relative'
})`
  background-image: url(${BGItem});
  background-size: 100% 100%;
  width: calc(100% - 0.5rem);
`

const ButtonWrapper = styled.div.attrs({
  className: 'w-fit text-[#fff] text-[8px] font-Inter font-light font-hiragino leading-[12px]'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const Detail = ({ items = [], onClick = () => {} }) => {
  return (
    <div className='max-h-[70vh] overflow-y-auto'>
      {items.map((item, index) => {
        return (
          <Item key={index} item={item} onClick={onClick} />
        )
      }
      )}
    </div>
  )
}

Detail.propTypes = {
  items: PropTypes.array,
  onClick: PropTypes.func
}

export default Detail
