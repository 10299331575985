import React from 'react'
import PropTypes from 'prop-types'
import TitleHourglassBlue from '../../assets/title_hourglass_blue.png'
import TitleHourglassRed from '../../assets/title_hourglass_red.png'
import TitleHourglassGold from '../../assets/title_hourglass_gold.png'
import TitleHourglassRainbow from '../../assets/title_hourglass_rainbow.png'
import TimeLoveBlue from '../../assets/time_love_blue.png'
import TimeLoveRed from '../../assets/time_love_red.png'
import TimeLoveGold from '../../assets/time_love_gold.png'
import TimeLoveRainbow from '../../assets/time_love_rainbow.png'
import DiamondBlue from '../../assets/diamond_blue.png'
import DiamondRed from '../../assets/diamond_red.png'
import DiamondGold from '../../assets/diamond_gold.png'
import DiamondRainbow from '../../assets/diamond_rainbow.png'

const HourglassItem = ({
  diamondAmount = 0,
  itemType,
  additionalTime = 0,
  additionalLove = 0,
  onClick,
  image = ''
}) => {
  const getImageSource = (itemType, imageType) => {
    const sources = {
      blue_time: {
        diamond: DiamondBlue,
        title: TitleHourglassBlue,
        timelove: TimeLoveBlue
      },
      red_time: {
        diamond: DiamondRed,
        title: TitleHourglassRed,
        timelove: TimeLoveRed
      },
      gold_time: {
        diamond: DiamondGold,
        title: TitleHourglassGold,
        timelove: TimeLoveGold
      },
      rainbow_time: {
        diamond: DiamondRainbow,
        title: TitleHourglassRainbow,
        timelove: TimeLoveRainbow
      }
    }
    return sources[itemType][imageType]
  }

  return (
    <button
      className="active:border-[#00FFD1] border-[3px] border-transparent p-2 rounded-[4px]"
      onClick={onClick}
    >
      {image ? (
        <img src={image} className="w-[108x] h-[144px] object-cover mx-auto" />
      ) : (
        <div className="w-[88px] h-[144px] bg-white mx-auto font-hiragino font-bold p-4 flex items-center justify-center">
          砂時計のイラスト挿入箇所
        </div>
      )}

      <img
        src={getImageSource(itemType, 'diamond')}
        className="mx-auto mt-4 w-[50px]"
      />

      <img
        src={getImageSource(itemType, 'title')}
        className="mx-auto mt-2 w-[130px] h-[78px]"
      />

      <img
        src={getImageSource(itemType, 'timelove')}
        className="mx-auto mt-3 w-[130px] h-[56px]"
      />
    </button>
  )
}

HourglassItem.propTypes = {
  diamondAmount: PropTypes.number,
  additionalTime: PropTypes.number,
  additionalLove: PropTypes.number,
  itemType: PropTypes.string,
  onClick: PropTypes.func,
  image: PropTypes.string
}

export { HourglassItem }
