import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { Modal } from 'src/components/common/Modal'
import PropTypes from 'prop-types'

import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import BtnClose from 'src/assets/img/common/btn-close.png'
import CancelBtn from 'src/assets/img/common/btn-cancel.png'
import BuyBtn from 'src/assets/img/common/btn-buy.png'

const MangaInfoModal = (props) => {
  const { isOpen, toggleModal } = props
  const navigate = useNavigate()

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      className="sm:w-[327px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <div
        onClick={toggleModal}
        className="w-[50px] h-[44px] absolute -top-4 -right-5 z-50 cursor-pointer"
      >
        <img className="w-full h-full" src={BtnClose} alt="close" />
      </div>
      <ModalBody>
        <ModalTitle>お知らせ</ModalTitle>
        <div className="pt-[2px] flex justify-center text-center">
          <p>
            続きを読むには、<span className="text-[#ff00ff]">「プラチナパスポート」</span>
            が必要です。ショップより、『プラチナパスポート』をご購入の上、再度お越し下さい。
            <br />
            <br />
            『プラチナパスポート』は1回購入するだけで、永久に、
            <span className="text-[#0000cd]">
              無料ハートの回復量が増加・全てのキャラクターの生い立ち漫画を最後まで読めるようになる
            </span>
            などの特典が多数！
          </p>
        </div>

        <div className="flex items-center justify-center space-x-6 mt-5">
          <button className="focus:outline-none w-[127px] h-[52px]" onClick={toggleModal}>
            <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
          </button>
          <button
            className="focus:outline-none w-[127px] h-[52px]"
            onClick={() => {
              toggleModal()
              navigate('/shop')
            }}
          >
            <img className="w-full h-full" src={BuyBtn} alt="Buy" />
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default MangaInfoModal

MangaInfoModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 pt-[38px] pb-[58px] relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-5'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;
`
