import PropTypes from 'prop-types'

import WingIcon from 'src/assets/img/common/icon.png'

const SectionHeader = ({ title, button, className = 'border-b w-full border-menu-dark-blue', isRequired = false, style, addStyle = '' }) => {
  return (
    <>
      <span className={`flex py-2 items-center ${className}`} style={{ textShadow: `${style}`, ...(addStyle ? { padding: `${addStyle}` } : {}) }}>
        <img src={WingIcon} className="mr-2" alt={title} />
        {title}
        {button}
        {isRequired === true &&
          <span className='ml-2 text-red-500'>*</span>
        }
      </span>
    </>
  )
}

SectionHeader.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.element,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  style: PropTypes.string,
  addStyle: PropTypes.string
}

export default SectionHeader
