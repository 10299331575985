import { useCallback, useEffect, useState } from 'react'
import { addRank } from 'src/lib/rank'
import { getRankings } from 'src/services/ranking'

export const useFetchRankingData = () => {
  const [ranking, setRanking] = useState({})
  const [activeIndex, setActiveIndex] = useState(1)

  useEffect(() => {
    fetchData()
  }, [activeIndex])

  const fetchData = useCallback(async () => {
    const loveRanking = await getRankings('love', 'top_five', 5, activeIndex)
    const growRanking = await getRankings('grow', 'top_five', 5, activeIndex)

    const loveRankingTopFive = addRank(loveRanking?.top_five)
    const love = loveRankingTopFive.map((item, idx) => {
      const _idx = idx + 1
      return {
        ...item,
        places: _idx,
        background: `no${_idx}.png`,
        character: `ranking_character_${activeIndex}.png`,
        backgroundPlaces: `character1-no${item.rank}.png`
      }
    })
    if (love.length < 5) {
      const offset = 5 - love.length
      for (let i = 0; i < offset; i++) {
        const curLen = love.length
        love.push({
          places: curLen + 1,
          background: `no${curLen + 1}.png`,
          character: '',
          backgroundPlaces: `character1-no${curLen + 1}.png`
        })
      }
    }

    const growRankingTopFive = addRank(growRanking?.top_five)
    const grow = growRankingTopFive.map((item, idx) => {
      const _idx = idx + 1
      return {
        ...item,
        places: _idx,
        background: `no${_idx}.png`,
        character: `ranking_character_${activeIndex}.png`,
        backgroundPlaces: `character1-no${item.rank}.png`
      }
    })
    if (grow.length < 5) {
      const offset = 5 - grow.length
      for (let i = 0; i < offset; i++) {
        const curLen = grow.length
        grow.push({
          places: curLen + 1,
          background: `no${curLen + 1}.png`,
          character: '',
          backgroundPlaces: `character1-no${curLen + 1}.png`
        })
      }
    }
    love.userRank = loveRanking?.userRank
    grow.userRank = growRanking?.userRank
    setRanking({ love, grow })
  }, [activeIndex])

  return {
    activeIndex,
    setActiveIndex,
    ranking,
    setRanking
  }
}
