import { useCallback, useEffect, useRef, useState } from 'react'
import getUserPresents from 'src/services/get/getUserPresents'
import putReceivePresent from 'src/services/put/putReceivePresent'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import PresentPage from 'src/pages/myPage/Present'
import { updateMultipleItemAmount, updateSingleItemAmount } from 'src/lib/present'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'

const PresentPageContainer = () => {
  const [presents, setPresents] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [step, setStep] = useState(0)
  const [currPage, setCurrPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const PRESENT_PAGE_TABS = { All: 0, Item: 1, Clothing: 2 }
  const [activeTab, setActiveTab] = useState(PRESENT_PAGE_TABS.All)
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const listRef = useRef(null)

  const fetchUserPresents = async (page = undefined) => {
    try {
      setIsLoading(true)
      let presentType = ''
      if (activeTab === PRESENT_PAGE_TABS.All) {
        presentType = 'all'
      } else if (activeTab === PRESENT_PAGE_TABS.Clothing) {
        presentType = 'Cloth'
      } else {
        presentType = 'Item'
      }
      const res = await getUserPresents(user?.id, page ?? currPage, presentType)
      if (res) {
        if (page) setCurrPage(page)
        setTotalPages(res.total_pages)
        setPresents(res.user_presents)
      }
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    } else {
      setStep(2)
    }
  }

  const handleReceiveAll = useCallback(
    async (presentType) => {
      const res = await putReceivePresent(user?.id, null, true, presentType, currPage)
      if (!res) return
      handleSetModalList(res)

      updateMultipleItemAmount(presents, updateUserItem)
      if (currPage === 1) {
        fetchUserPresents(1)
      } else {
        setCurrPage(1)
      }
    },
    [presents, currPage]
  )

  const handleReceiveOne = useCallback(
    async (presentId, presentExpiredAt) => {
      if (new Date(presentExpiredAt) < new Date()) return

      const res = await putReceivePresent(user?.id, presentId)
      if (!res) return
      handleSetModalList(res)

      updateSingleItemAmount({ presents, updateUserItem, presentId })

      fetchUserPresents()
    },
    [presents]
  )

  const updateUserItem = useCallback(
    (itemType, amount) =>
      authDispatch({
        type: authActionTypes.ADD_ITEM,
        payload: { itemType, amount }
      }),
    []
  )

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
      setStep(2)
    }
  }

  useEffect(() => {
    fetchUserPresents()
  }, [currPage, activeTab])

  useEffect(() => {
    setCurrPage(1)
    if (!listRef.current) return
    listRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [activeTab])

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  return (
    <>
      <PresentPage
        presents={presents}
        isLoading={isLoading}
        handleReceiveAll={handleReceiveAll}
        handleReceiveOne={handleReceiveOne}
        step={step}
        setStep={setStep}
        isLevelUp={modals.length > 0}
        totalPage={totalPages}
        currPage={currPage}
        setCurrPage={setCurrPage}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        listRef={listRef}
      />
      {currentModal && currentModal.id && (
        <GrowEffectModal
          character={currentModal}
          isGrowModalOpen={isGrowModalOpen}
          level={{ currentLevel: currentModal.current_level, nextLevel: currentModal.next_level }}
          onClose={closeGrowModal}
        />
      )}
    </>
  )
}

export default PresentPageContainer
