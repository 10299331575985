import React from 'react'
import { StillAlbumContainer } from 'src/containers/StillAlbumContainer'
import EventArchiveListContainer from 'src/pages/myPage/Event/EventArchiveListContainer'
import EventContainer from 'src/pages/myPage/Event/EventContainer'
import EventListContainer from 'src/pages/myPage/Event/EventListContainer'
import NewsListContainer from 'src/pages/myPage/News/NewsListContainer'
import NewsPageContainer from 'src/pages/myPage/News/NewsPageContainer'
import PresentPageContainer from 'src/pages/myPage/PresentPageContainer'
import { RankingDetailPage } from 'src/pages/myPage/Ranking/Detail'
import { RankingDx6Page } from 'src/pages/myPage/Ranking/Dx6'
import { RankingEventsPage } from 'src/pages/myPage/Ranking/Events'
import { RankingPage } from 'src/pages/myPage/Ranking/Ranking'
import { RankingRewardPage } from 'src/pages/myPage/Ranking/Reward'
import { RecommendDressUpPage } from 'src/pages/myPage/Recommend/DressUp'
import { RecommendMangaPage } from 'src/pages/myPage/Recommend/Manga'
import { RecommendProfilePage } from 'src/pages/myPage/Recommend/Profile'
import { RecommendPage } from 'src/pages/myPage/Recommend/Recommend'
import { RecommendSettingPage } from 'src/pages/myPage/Recommend/Setting'
import { RecommendVoiceAlbumPage } from 'src/pages/myPage/Recommend/VoiceAlbum'
import { SupportPage } from 'src/pages/myPage/Support'
import { TheaterPage } from 'src/pages/myPage/Theater'

const MYPAGE_PATHS = {
  RankingPage: '/ranking',
  RankingDx6Page: '/ranking/dx6',
  RankingDetailPage: '/ranking/detail/:type',
  RankingRewardPage: '/ranking/award/:id',
  RankingEventsPage: '/ranking/events',
  RecommendPage: '/recommend',
  RecommendDressUpPage: '/recommend/dressup/:id',
  RecommendMangaPage: '/recommend/manga/:id/:name',
  RecommendProfilePage: '/recommend/profile/:id/:name',
  RecommendSettingPage: '/recommend/setting',
  RecommendVoiceAlbumPage: '/recommend/voice-album/:id/:name',
  RecommendVoiceAlbumNonamePage: '/recommend/voice-album/:id',
  RecommendStillAlbumPage: '/recommend/still-album/:characterId/:name',
  RecommendStillAlbumDetailPage: '/recommend/still-album/detail/:characterId/:name/:id',
  EventListPage: '/events',
  EventArchiveListPage: '/events/past',
  NewsListPage: '/news',
  NewsPage: '/news/detail',
  PresentPage: '/present',
  TheaterPage: '/theater'
}
const MyPageRouter = [
  { path: MYPAGE_PATHS.RankingPage, element: <RankingPage /> },
  { path: MYPAGE_PATHS.RankingDx6Page, element: <RankingDx6Page /> },
  { path: MYPAGE_PATHS.RankingDetailPage, element: <RankingDetailPage /> },
  { path: MYPAGE_PATHS.RankingRewardPage, element: <RankingRewardPage /> },
  { path: MYPAGE_PATHS.RankingEventsPage, element: <RankingEventsPage /> },
  { path: MYPAGE_PATHS.RecommendPage, element: <RecommendPage /> },
  { path: MYPAGE_PATHS.RecommendDressUpPageRankingPage, element: <RecommendDressUpPage /> },
  { path: MYPAGE_PATHS.RecommendSettingPageRankingPage, element: <RecommendSettingPage /> },
  { path: MYPAGE_PATHS.RecommendStillAlbumPageRankingPage, element: <StillAlbumContainer /> },
  { path: MYPAGE_PATHS.RecommendStillAlbumDetailPageRankingPage, element: <StillAlbumContainer /> },
  { path: MYPAGE_PATHS.RecommendProfilePage, element: <RecommendProfilePage /> },
  { path: MYPAGE_PATHS.RecommendVoiceAlbumPageRankingPage, element: <RecommendVoiceAlbumPage /> },
  {
    path: MYPAGE_PATHS.RecommendVoiceAlbumNonamePage,
    element: <RecommendVoiceAlbumPage />
  },
  { path: MYPAGE_PATHS.RecommendMangaPage, element: <RecommendMangaPage /> },
  { path: MYPAGE_PATHS.EventListPage, element: <EventListContainer /> },
  { path: MYPAGE_PATHS.EventArchiveListPage, element: <EventArchiveListContainer /> },
  { path: `${MYPAGE_PATHS.EventListPage}/:id`, element: <EventContainer /> },
  { path: MYPAGE_PATHS.NewsListPage, element: <NewsListContainer /> },
  { path: `${MYPAGE_PATHS.NewsPage}/:id`, element: <NewsPageContainer /> },
  { path: MYPAGE_PATHS.PresentPage, element: <PresentPageContainer /> },
  {
    path: MYPAGE_PATHS.SupportPage, element: <SupportPage />
  },
  { path: MYPAGE_PATHS.TheaterPage, element: <TheaterPage /> }
]

export { MyPageRouter, MYPAGE_PATHS }
