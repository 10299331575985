import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { NEW_USER_PATHS } from 'src/routes/newUser'
import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { getConditions } from 'src/services/get/getConditions'
import { getAdTag } from 'src/services/get/getAdTag'
import InnerHTML from 'dangerously-set-html-content'

import check from 'src/assets/img/termofservice/check.png'
import AcceptBtn from 'src/assets/img/termofservice/btn-accept.png'
import BGCommon from 'src/assets/img/common/bg-common.png'

export const RegistTermOfServicePage = () => {
  const [accept, setAccept] = useState(false)
  const navigate = useNavigate()
  const [term, serTerm] = useState('')
  const [jsTag, serJsTag] = useState('')
  const [url] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('condition')
    const content = data.find((item) => item?.condition?.status === 'is_public')?.condition?.content
    serTerm(content)
  }

  const resultToAsp = async () => {
    const tagData = await getAdTag()
    if (!tagData) return
    if (tagData.fire_type === 'js_tag') {
      serJsTag(tagData.content)
    }
  }

  useEffect(() => {
    fetchCondition()
    resultToAsp()
  }, [])

  return (
    <MainLayout showHeader={false} showFooter={false}>
      <img src={url} style={{ width: 0, height: 0 }} />
      <div
        className={
          'overflow-y-auto w-screen sm:w-desktop-width p-6 h-screen fixed sm:left-1/2 sm:-translate-x-1/2 flex items-center bg-center bg-no-repeat bg-cover'
        }
        style={{ backgroundImage: `url('${BGCommon}')` }}
      >
        <LegaleseWrapper title="利用規約" content={term}>
          <div className="flex justify-center items-center mx-12 mt-5 p-2 bg-[#DAEBFF]">
            <div
              className={'text-center bg-white w-5 h-5 mr-4'}
              style={
                accept
                  ? {
                      backgroundImage: `url(${check})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '80% 80%',
                      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.25)'
                    }
                  : { boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.25)' }
              }
              onClick={() => setAccept(!accept)}
            />
            <div className="text-sm text-center font-hiragino leading-6 font-light ">
              利用規約に同意します
            </div>
          </div>
          <button
            disabled={!accept}
            className={`mt-7 w-[170px] ${accept ? '' : 'opacity-50'}`}
            onClick={() => navigate(NEW_USER_PATHS.OpeningPage)}
          >
            <img src={AcceptBtn} alt="Accept" />
          </button>
        </LegaleseWrapper>
      </div>
      {jsTag ? <InnerHTML html={jsTag} /> : null}
    </MainLayout>
  )
}
