export default class RakingItem {
  constructor(id, name, birthday, email, gender, totalReact, avatar) {
    this.id = id
    this.name = name
    this.birthday = birthday
    this.email = email
    this.gender = gender
    this.totalReact = totalReact
    this.avatar = avatar
  }
}
