export default class Item {
  constructor(
    id,
    name,
    image,
    description,
    expiredAt,
    itemKind,
    itemValue,
    rarity,
    increaseLove,
    maxAmount,
    type,
    amount
  ) {
    this.id = id
    this.name = name
    this.description = description
    this.image = image
    this.expiredAt = expiredAt
    this.itemKind = itemKind
    this.itemValue = itemValue
    this.rarity = rarity
    this.increaseLove = increaseLove
    this.maxAmount = maxAmount
    this.type = type
    this.amount = amount
  }
}

export const ITEM_RARITY = {
  R: 'r',
  SR: 'sr',
  SSR: 'ssr'
}

export const ITEM_TYPE = {
  Standard: 'SettingItem',
  Special: 'SpecialItem',
  Payment: 'PaymentItem'
}
