import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { liveStreamInfo } from '../../../services/get/getLiveSteamInfo'

const useLiveStreamInfos = (channelId) => {
  const query = useQuery({
    queryFn: () => liveStreamInfo(channelId),
    queryKey: 'live-stream-info',
    enabled: Boolean(channelId)
  })

  useEffect(() => () => query.remove(), [])

  return query
}

export { useLiveStreamInfos }
