import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const liveStreamInfo = async (id) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.LiveStreamInfo.replace(':id', id))

    return res?.data || null
  } catch {
    return null
  }
}
