import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import Inquiry from 'src/models/Inquiry'
import { MENU_PATHS } from 'src/routes/menu'
import { genDateString } from 'src/lib/date'
import NotificationIcon from 'src/components/common/NotificationIcon'

const InquiryHistory = ({ isLoading, inquiries }) => {
  const navigate = useNavigate()

  if (isLoading) return <Loading />

  if (!inquiries || inquiries.length === 0) return <NoContent message="お問合せ履歴はありません。" />

  return (
    <ul className="flex flex-col gap-2">
      {inquiries
        .slice(0)
        .reverse()
        .map((el) => (
          <li
            key={el.id}
            className="relative px-4 pt-4 pb-8 bg-menu-off-blue rounded-md w-full text-left text-sm border-4 border-menu-dark-blue drop-shadow-lg font-hiragino cursor-pointer"
            onClick={() => navigate(`${MENU_PATHS.InquiryPage}/${el.id}`)}
          >
            {el.adminResponse && (
              <>
                <span className="rounded bg-light-pink text-white text-center text-xs p-2 mr-2">
                返信あり
                </span>
                {!el.isReaded && (
                  <NotificationIcon className="-top-2 -right-1" />
                )}
              </>
            )}
            <span className="leading-7">{el.contactKinds}</span>
            <div className="text-[11px] text-right text-gray-500 absolute bottom-1 right-2">
              {genDateString(el.createdAt, true, '/')}
            </div>
          </li>
        ))}
    </ul>
  )
}

InquiryHistory.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  inquiries: PropTypes.arrayOf(PropTypes.instanceOf(Inquiry))
}

export default InquiryHistory
