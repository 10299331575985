import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getAdTag = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.AdTag)
    if (res.status === 200) {
      return res.data
    }
    return false
  } catch (error) {
    return false
  }
}
