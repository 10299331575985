import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'src/providers/AuthProviders'
import MenuCross from 'src/components/dressup/MenuCross'
import { getDateWaiting } from 'src/lib/date'
import registerCloth from 'src/services/post/registerCloth'
import menu from 'src/constants/dressup'
import { getCharacterCloths } from 'src/services/get/getCharacterCloths'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import CharacterDressup from 'src/components/dressup/Character'
import MenuVertical from 'src/components/dressup/MenuVertical'
import ButtonAbsolute from 'src/components/dressup/ButtonAbsolute'
import { SlideButton } from 'src/components/common/SlideButton'
import { Tab } from '@headlessui/react'
import Loading from 'src/components/layouts/Loading'
import ListDressup from 'src/components/dressup/List'
import GridDressup from 'src/components/dressup/Grid'
import { Modal } from 'src/components/common/Modal'
import Detail from 'src/components/dressup/Detail'

import BGPage from '../../../assets/img/dressup/bg-page.png'
import IconRefresh from '../../../assets/img/dressup/icon_refresh.png'
import IconSort from '../../../assets/img/dressup/icon_sort.png'
import BGLine from '../../../assets/img/dressup/bg-line.png'
import BGList from '../../../assets/img/dressup/bg-list.png'
import BGSort from '../../../assets/img/dressup/rarity_bg.png'
import RareLine from '../../../assets/img/dressup/rare-line.png'
import BGModal from '../../../assets/img/common/bg-modal-confirm.png'
import BGModalTitle from '../../../assets/img/common/bg-modal-title.png'
import CancelBtn from '../../../assets/img/common/btn-cancel.png'
import OKBtn from '../../../assets/img/dressup/btn_ok.png'
import BtnComplete from '../../../assets/img/dressup/completion_1.png'
import Navigator from '../../../assets/img/dressup/navigator.png'
import check from '../../../assets/img/termofservice/check.png'
import BtnClose from '../../../assets/img/common/btn-close.png'
import IconArrowDown from '../../../assets/img/dressup/icon_arrow_down.png'
// import CharacterCloth from '../../../assets/img/dressup/character_cloth.png'
import BtnCreate2 from '../../../assets/img/dressup/btn_creation_2.png'
import BtnDone1 from '../../../assets/img/dressup/btn_done_1.png'

const sort = [
  {
    key: 'newest',
    label: '入手日新しい順',
    labelButton: '入手順'
  },
  {
    key: 'oldest',
    label: '入手日古い順',
    labelButton: '入手順'
  },
  {
    key: 'rarity_desc',
    label: 'レア度高い順',
    labelButton: 'レア度'
  },
  {
    key: 'rarity_asc',
    label: 'レア度低い順',
    labelButton: 'レア度'
  }
]

export const RecommendDressUpPage = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [menuActive, setMenuActive] = useState('appearance1')
  const [activeTab, setActiveTab] = useState(0)
  const [menuCurrent, setMenuCurrent] = useState(null)
  const [viewType, setViewType] = useState('grid')
  const [isOpen, setIsOpen] = useState(false)
  const [modalKey, setModalKey] = useState('')
  const [accept, setAccept] = useState(false)
  const [category, setCategory] = useState('setup')
  const [characterCloths, setCharacterCloths] = useState({})
  const [mounted, setMounted] = useState(false)
  const [currCloth, setCurrCloth] = useState(null)
  const [dataDetail, setDataDetail] = useState({})
  const [currDetail, setCurrDetail] = useState(null)
  const [showMenuCross, setShowMenuCross] = useState(false)
  const [labelButtonSort, setLabelButtonSort] = useState('入手順')

  const { id } = useParams()

  const { user } = useAuthState()

  useEffect(() => {
    setMounted(true)
    fetchData()
  }, [])

  useEffect(() => {
    const viewType = ['atelier', 'wearhouse'].includes(category) ? 'list' : 'grid'
    setViewType(viewType)
    if (mounted) {
      setLabelButtonSort('入手順')
      fetchData(true)
    }
  }, [category])

  useEffect(() => {
    const _menuCurrent = menu.find((item) => item.key === menuActive)
    setMenuCurrent(_menuCurrent)
    setActiveTab(0)
    setCategory(menuActive === 'background' ? menuActive : _menuCurrent?.children[0]?.key)
  }, [menuActive])

  useEffect(() => {
    if (mounted) {
      setCategory(menuActive === 'background' ? menuActive : menuCurrent?.children[activeTab]?.key)
    }
  }, [activeTab])

  const onChangeSort = (key) => {
    fetchData(true, key)
    const _sort = sort.find((item) => item.key === key)
    setLabelButtonSort(_sort.labelButton)
  }

  const handleOk = () => {
    switch (modalKey) {
      case 'note':
        setModalKey('item-creation')
        if (accept) {
          localStorage.setItem('dressup-note-not-show', true)
        }
        break
      case 'item-creation':
        setModalKey('item-creation-confirm')
        break
      case 'item-creation-confirm':
        setModalKey('short-upgrade')
        break
      case 'short-upgrade':
        registerCloth(id, currCloth.id, user.id)
        setModalKey('')
        break
      case 'detail':
        setModalKey('')
        break
      case 'confirm':
        setModalKey('')
        break
      default:
        setModalKey('')
        break
    }
  }

  const fetchData = async (force = false, sortKey = 'newest') => {
    if (!category || (!force && characterCloths[category])) return
    setIsLoading(true)
    let _sort = {}
    switch (sortKey) {
      case 'newest':
        _sort = {
          sort_by: 'created_at',
          sort_type: 'desc'
        }
        break
      case 'oldest':
        _sort = {
          sort_by: 'created_at',
          sort_type: 'asc'
        }
        break
      case 'rarity_desc':
        _sort = {
          sort_by: 'rarity',
          sort_type: 'desc'
        }
        break
      case 'rarity_asc':
        _sort = {
          sort_by: 'rarity',
          sort_type: 'asc'
        }
        break
      default:
        break
    }
    const data = await getCharacterCloths(id, category, _sort)
    if (['atelier', 'wearhouse'].includes(category)) {
      const _dataDetail = {}
      data.forEach((item) => {
        if (_dataDetail[item.cloth_id]) {
          let _temp = _dataDetail[item.cloth_id]
          _temp = [..._temp, item]
          _dataDetail[item.cloth_id] = _temp
        } else {
          _dataDetail[item.cloth_id] = [item]
        }
      })
      Object.keys(_dataDetail).forEach((key) => {
        _dataDetail[key] = _dataDetail[key].sort((a, b) => {
          return a.level - b.level
        })
      })
      const _data = []
      Object.keys(_dataDetail).forEach((key) => {
        _data.push(_dataDetail[key][0])
      })
      _data.sort((a, b) => {
        switch (sortKey) {
          case 'newest':
            return new Date(b?.created_at) - new Date(a?.created_at)
          case 'oldest':
            return new Date(a?.created_at) - new Date(b?.created_at)
          case 'rarity_desc':
            return b?.rarity.localeCompare(a?.rarity)
          case 'rarity_asc':
            return a?.rarity.localeCompare(b?.rarity)
          default:
            return new Date(b?.created_at) - new Date(a?.created_at)
        }
      })
      setDataDetail({ ...dataDetail, [category]: _dataDetail })
      setCharacterCloths({ ...characterCloths, [category]: _data })
    } else {
      setCharacterCloths({ ...characterCloths, [category]: data })
    }
    setIsLoading(false)
  }

  const handleClickCreate = (item) => {
    setCurrCloth(item)
    const dateWaiting = getDateWaiting(item)
    const percent = Math.round(dateWaiting?.percent)
    if (percent >= 100) {
      if (localStorage.getItem('dressup-note-not-show')) {
        setModalKey('item-creation')
      } else {
        setModalKey('note')
      }
    } else {
      setModalKey('short-upgrade')
    }
  }

  const handleClickDetail = (item) => {
    setCurrDetail(item)
    setModalKey('detail')
  }

  return (
    <Wrapper>
      <div className="absolute z-10 w-full h-full">
        <img className="w-full h-full" src={BGLine} alt="BGLine" />
      </div>
      <Header>
        <MenuBar title="dressup" />
      </Header>
      <MainLayout
        className="relative z-20 h-fit"
        active="mypage"
        showHeader={false}
        showFooter={false}
      >
        <div
          className="relative"
          style={{
            height: 'calc(50vh - 80px)'
          }}
        >
          {
            <div>
              <CharacterDressup />
              <div className="absolute z-50 right-4">
                <MenuVertical active={menuActive} onChangeMenu={setMenuActive} />
              </div>
              <ButtonAbsolute
                onClick={() => setModalKey('confirm')}
                className="cursor-pointer p-2.5 flex gap-x-2 bottom-[68px] text-[10px] text-[#fff] font-Inter items-center"
                position="left"
              >
                全て外す <img className="w-3 h-3" src={IconRefresh} alt="refresh" />
              </ButtonAbsolute>
              <ButtonAbsolute
                className="py-3 px-2.5 flex gap-x-2 bottom-[56px] text-[8px] text-[#fff] font-Inter items-center"
                position="right"
              >
                マイページビュー
              </ButtonAbsolute>
              {/* <ButtonAbsolute className='py-2.5 px-4 flex gap-x-2 bottom-[20px] text-[10px] text-[#fff] font-Inter items-center' position='right'>
            <img className='w-3 h-3 mr-1' src={IconSort} alt='refresh' /> レア度
          </ButtonAbsolute> */}
              <ButtonAbsolute
                onClick={() => setIsOpen(!isOpen)}
                className="h-[36px] cursor-pointer py-2.5 px-4 flex gap-x-2 bottom-[20px] text-[10px] text-[#fff] font-Inter items-center z-20"
                position="right"
              >
                <img className="w-3 h-3 mr-1" src={IconSort} alt="Sort" /> {labelButtonSort}
              </ButtonAbsolute>
              <SlideButton
                visible={true}
                className="w-fit absolute bottom-[20px] right-0 h-[35px] z-10"
                classNameVisible="right-[74px]"
              >
                <div
                  className="flex items-center h-full overflow-hidden text-[8px] text-[#fff] leading-[10px] font-[400px] pl-6 pr-4"
                  style={{
                    backgroundImage: `url(${BGSort})`,
                    backgroundSize: '100% 100%'
                  }}
                >
                  {sort.map((el, i) => (
                    <div
                      onClick={() => onChangeSort(el?.key)}
                      className="flex items-center pl-2 cursor-pointer"
                      key={i}
                    >
                      {el.label}
                      {i < sort.length - 1 ? (
                        <img className="w-[1px] h-[18px] ml-2" src={RareLine} />
                      ) : null}
                    </div>
                  ))}
                </div>
              </SlideButton>
            </div>
          }
        </div>
        <div className="fixed bottom-0 sm:w-desktop-width w-screen mx-auto overflow-hidden z-[100] h-[50vh]">
          <Tab.Group key={category} selectedIndex={activeTab} onChange={setActiveTab}>
            <Tab.List className="flex justify-between w-full h-[60px]">
              {(menuCurrent?.children || []).map((el, index) => {
                return (
                  <Tab key={index}>
                    {({ selected }) => (
                      <img src={selected ? el.image.on : el.image.off} alt={el.title} />
                    )}
                  </Tab>
                )
              })}
            </Tab.List>
            <div
              className="relative"
              style={{
                height: 'calc(100% - 60px)',
                background: `${viewType === 'grid' ? '#fff' : `url(${BGList})`}`,
                overflowY: `${showMenuCross ? 'hidden' : 'auto'}`
              }}
            >
              {viewType !== 'grid' ? (
                <div
                  className="fixed bottom-0 z-0 w-full h-full mx-auto overflow-hidden sm:w-desktop-width"
                  style={{
                    height: 'calc(50vh - 60px)',
                    background: `url(${BGLine})`,
                    backgroundSize: '100% 100%',
                    backgroundAttachment: 'fixed'
                  }}
                />
              ) : null}
              {isLoading ? (
                <Loading />
              ) : viewType === 'list' ? (
                <ListDressup
                  openDetail={(item) => handleClickDetail(item)}
                  onClick={(item) => handleClickCreate(item)}
                  items={characterCloths[category] || []}
                />
              ) : (
                <GridDressup items={characterCloths[category] || []} col={4} gap="gap-2.5" />
              )}
              {showMenuCross ? (
                <div className="absolute top-0 bottom-0 left-0 right-0">
                  <MenuCross
                    onClose={() => setShowMenuCross(false)}
                    onClick={() => setShowMenuCross(false)}
                  />
                </div>
              ) : null}
            </div>
          </Tab.Group>
        </div>
      </MainLayout>
      <Modal
        open={modalKey === 'confirm'}
        onClose={() => setModalKey('')}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
      >
        <ModalBody>
          <ModalTitle className="mb-11">確認</ModalTitle>
          <h6 className="text-center text-base font-bold mb-[26px]">
            全てのアイテムを外しますか？
          </h6>
          <div className="flex items-center justify-center gap-6 mt-16">
            <button className="focus:outline-none w-[50%] h-12" onClick={() => setModalKey('')}>
              <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
            </button>
            <button className="focus:outline-none w-[50%] h-12" onClick={() => handleOk()}>
              <img className="w-full h-full" src={OKBtn} alt="Ok" />
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        open={modalKey === 'short-upgrade'}
        onClose={() => setModalKey('')}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino text-[#333333]"
      >
        <ModalBody className="pt-7 pb-[57px]">
          <ModalTitle className="mb-8 ml-3 mr-3">短縮アップグレード</ModalTitle>
          <h6 className="text-center text-base font-bold mb-2.5">今すぐ完成させますか？</h6>
          <div className="flex justify-between items-center gap-4 text-xs text-[#000000] font-light leading-[18px] border border-[#A1D2FF] rounded-[3px] bg-white">
            <div className="w-[45%] flex items-end justify-end py-1.5">必要数</div>
            <div className="w-[55%] flex items-center justify-between">
              ダイヤ{parseInt(currCloth?.red_diamond) ? currCloth?.red_diamond : 20}個
            </div>
          </div>
          <div className="flex items-center justify-center gap-6 mt-16">
            <button className="focus:outline-none w-[50%] h-12" onClick={() => setModalKey('')}>
              <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
            </button>
            <button className="focus:outline-none w-[50%] h-12" onClick={() => handleOk()}>
              <img className="w-full h-full" src={BtnComplete} alt="Ok" />
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        open={modalKey === 'note'}
        onClose={handleOk}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
      >
        <div
          onClick={handleOk}
          className="w-[50px] h-[44px] absolute -top-4 -right-5 z-50 cursor-pointer"
        >
          <img className="w-full h-full" src={BtnClose} alt="close" />
        </div>
        <ModalBody className="pl-4 pr-4 pt-7 pb-[30px]">
          <ModalTitle className="ml-8 mr-8 mb-[30px]">注意</ModalTitle>
          <div className="text-sm text-[#333333] font-light leading-[21px] text-center">
            <p className="mb-3">
              作成受付時間内に <br />
              「アイテム完成までの待ち時間」が <br />
              終わらないとアイテムが完成されないコケッ！
            </p>
            <p className="mb-3">
              ダイヤだけ消化されちゃうから、
              <br />
              作成する時は残り時間に注意するコケッ！
            </p>
            <p className="mb-3">
              アイテムまでの待ち時間は、
              <br />
              赤いダイヤで短縮することができるヨ！
            </p>
          </div>
          <div className="flex items-center justify-end">
            <img className="h-[84px] w-[84px]" src={Navigator} alt="Navigator" />
          </div>
          <div className="flex justify-center items-center mr-2.5 mt-1 p-2 bg-[#DAEBFF]">
            <div
              className={'text-center bg-white w-5 h-5 mr-4'}
              style={
                accept
                  ? {
                      backgroundImage: `url(${check})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '80% 80%',
                      boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.25)'
                    }
                  : { boxShadow: 'inset 0px 1px 2px rgba(0, 0, 0, 0.25)' }
              }
              onClick={() => setAccept(!accept)}
            />
            <div className="text-sm font-light leading-6 text-center font-hiragino">
              今後は表示しない
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        open={modalKey === 'item-creation'}
        onClose={() => setModalKey('')}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino text-[#333333]"
      >
        <ModalBody className="pt-7 pb-[37px]">
          <ModalTitle className="mr-3 ml-3 mb-[26px]">アイテム作成</ModalTitle>
          <h6 className="text-center text-base font-bold mb-2.5">
            {currCloth?.name} を作りますか？
          </h6>
          <div className="flex justify-between items-center gap-4 text-xs text-[#000000] font-light leading-[18px] border border-[#A1D2FF] rounded-[3px] bg-white">
            <div className="w-[45%] flex items-end justify-end py-1.5">必要数</div>
            <div className="w-[55%] flex items-center justify-between">
              ダイヤ{parseInt(currCloth?.red_diamond) ? currCloth?.red_diamond : 20}個
            </div>
          </div>
          <div className="flex items-center justify-center mt-3 mb-2">
            <img className="w-5 h-6" src={IconArrowDown} alt="arrow" />
          </div>
          <div className="font-light border border-[#A1D2FF] rounded-[3px] pt-2.5 pb-1.5 bg-white">
            <div className="flex gap-x-3">
              <div className="w-[45%]">
                <img
                  className="w-[65px] h-[65px] ml-auto"
                  src={currCloth?.cloth_image?.url}
                  alt="character"
                />
              </div>
              <div className="text-xs w-[55%]">
                <div>LV.{currCloth?.level < 10 ? '0' + currCloth?.level : currCloth?.level}</div>
                <div>
                  育て度 <span>＋500</span>
                </div>
              </div>
            </div>
            <div className="flex text-[10px] leading-[15px] gap-x-3">
              <div className="w-[45%] text-right">完成までの時間</div>
              <div className="w-[55%]">○日</div>
            </div>
            <div className="flex text-[10px] leading-[15px] gap-x-3">
              <div className="w-[45%] text-right">受付時間</div>
              <div className="w-[55%]">
                {/* 2022年12月31日 <br /> */}
                {(getDateWaiting(currCloth) || {})?.endformat} <br />
                23時59分まで
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center gap-6 mt-4">
            <button className="focus:outline-none w-[50%] h-12" onClick={() => setModalKey('')}>
              <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
            </button>
            <button className="focus:outline-none w-[50%] h-12" onClick={() => handleOk()}>
              <img className="w-full h-full" src={BtnCreate2} alt="Ok" />
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        open={modalKey === 'item-creation-confirm'}
        onClose={() => setModalKey('')}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center shadow-xl transform transition-all sm:max-w-lg font-hiragino font-light text-[#333333] text-sm"
      >
        <ModalBody className="pt-7 pb-[37px]">
          <ModalTitle className="mb-5 ml-3 mr-3">アイテム作成</ModalTitle>
          <div className="leading-[21px] mb-2.5">
            アバター完成までの待ち時間が、
            <br />
            作成受付時間をオーバーしてるコケ！
          </div>
          <div className="text-[#000000] text-xs leading-[18px] border border-[#A1D2FF] rounded-[3px] bg-white mb-4 pt-1.5 pb-2">
            <div>
              完成までの時間：<span>2日20時間</span>
            </div>
            <div>
              作成受付時間：<span>1日１時間</span>
            </div>
          </div>
          <div className="leading-[21px]">
            待ち時間を赤ダイヤで短縮しないと、
            <br />
            アバターが完成しないョ！
            <br />
            それでも作成するコケ？
          </div>
          <div className="flex items-center justify-end">
            <img className="h-[84px] w-[84px]" src={Navigator} alt="Navigator" />
          </div>
          <div className="flex items-center justify-center gap-6 mt-4">
            <button className="focus:outline-none w-[50%] h-12" onClick={() => setModalKey('')}>
              <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
            </button>
            <button className="focus:outline-none w-[50%] h-12" onClick={() => handleOk()}>
              <img className="w-full h-full" src={BtnCreate2} alt="Ok" />
            </button>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        open={modalKey === 'detail'}
        onClose={() => setModalKey('')}
        className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center shadow-xl transform transition-all sm:max-w-lg font-hiragino font-light text-[#333333] text-sm"
      >
        <ModalBody className="pt-[16px] pb-[16px] pr-3 pl-3">
          <ModalTitle className="mb-5 ml-3 mr-3">アイテム名</ModalTitle>
          <Detail
            items={(dataDetail[category] || {})[currDetail?.cloth_id] || []}
            onClick={(item) => handleClickCreate(item)}
          />
          <div className="flex items-center justify-center mt-4">
            <button className="focus:outline-none w-[50%] h-12" onClick={() => handleOk()}>
              <img className="w-full h-full" src={BtnDone1} alt="Ok" />
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className: 'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto dressup-page'
})`
  background-image: url(${BGPage});
  background-size: 100% 100%;
`

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 pt-[38px] pb-[58px] relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 whitespace-nowrap'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;
`
