import TitleHeader from '../common/TitleHeader'
import EventItem from './EventItem'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import NoContentImage from 'src/components/layouts/NoContentImage'
import styled from 'styled-components'
import Loading from 'src/components/layouts/Loading'
import BGPage from 'src/assets/img/register/bg-common.png'
import Pagination from 'src/components/common/Pagination'

const EventArchiveList = (props) => {
  const { events, totalPage = 0, currPage, setCurrPage, isLoading } = props
  const contentRef = useRef(null)

  useEffect(() => {
    if (!contentRef?.current) return

    contentRef.current.scrollTo(0, 0)
  }, [events])

  return (
    <Wrapper>
      <Header>
        <MenuBar title="event" />
      </Header>
      <MainLayout
        active="menu"
        showHeader={false}
        showFooter={false}
        className="h-fit mana-wrapper"
      >
        <div className="flex flex-col items-center gap-2 w-full p-4">
          <TitleHeader title="過去イベント" className="my-4" />
          {isLoading ? (
            <Loading />
          ) : !isLoading && events.length === 0 ? (
            <NoContentImage message="現在見れる過去の<br />イベントはありません" />
          ) : (
            <Content>
              <div className="h-full hidden-scroll overflow-y-auto">
                {events.map((event) => (
                  <EventItem key={event.id} event={event} />
                ))}
              </div>
              <Pagination totalPage={totalPage} currPage={currPage} setCurrPage={setCurrPage} />
            </Content>
          )}
        </div>
      </MainLayout>
    </Wrapper>
  )
}

export default EventArchiveList

EventArchiveList.propTypes = {
  events: PropTypes.array.isRequired,
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  isLoading: PropTypes.bool
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`

const Content = styled.div.attrs({
  className: 'w-full mx-auto relative'
})`
  height: calc(100vh - 250px);
`
