import React from 'react'
import { ModalWithBg } from '../../../components/common/ModalWithBg'
import PropTypes from 'prop-types'
import mascotChara from '../assets/mascot_chara.png'
import btnClose from '../assets/btn_close.png'
import iconReload from '../assets/btn_reload.png'

const ModalPassportRejoin = ({ open, onClose }) => {
  if (!open) return <></>

  return (
    <>
      <ModalWithBg title="入場制限" open={open} onClose={onClose}>
        <div className="space-y-4 pb-[20px] font-hiragino">
          <p className="text-center text-[12px] whitespace-pre-line">
            <span style={{ color: '#FF0000' }}>制限時間</span>が切れました。
          </p>
          <p className="text-center text-[12px] whitespace-pre-line">
            プラチナパスポートをお持ちでない為、
            <br />
            これ以上視聴することは出来ないコケ。
          </p>
          <p className="text-center text-[12px] whitespace-pre-line">
            ショップから「プラチナパスポート」を購入した場合は、再度参加が可能になりますコケッ！
          </p>
          <p className="text-center text-[12px] whitespace-pre-line" style={{ color: '#FF0000' }}>
            ※既に｢プラチナパスポート｣を購入された場合、
            <p>
              画面下部の｢
              <span
                style={{
                  backgroundImage: `url(${iconReload})`,
                  backgroundSize: 'cover',
                  fontSize: 14
                }}
              >
                &nbsp;&nbsp;&nbsp;
              </span>
              ｣マークを押下して画面を
            </p>
            <p>更新して下さいコケッ！</p>
          </p>
          <p className="text-right absolute right-10 z-0">
            <img className="inline w-[80px] h-[80px]" src={mascotChara} />
          </p>

          <div className="flex items-center justify-center relative">
            <img
              className="inline cursor-pointer w-[119px] h-[49px]"
              src={btnClose}
              onClick={onClose}
            />
          </div>
        </div>
      </ModalWithBg>
    </>
  )
}

ModalPassportRejoin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export { ModalPassportRejoin }
