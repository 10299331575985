import { useContext, useEffect, useState } from 'react'
import { ActionCableContext } from 'src/providers/ActionCableProvider'

const useUserContactListChannel = () => {
  const cable = useContext(ActionCableContext)
  const [adminMessage, setAdminMessage] = useState()

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create(
      { channel: 'UserContactChannel' },
      {
        received: (msg) => setAdminMessage(msg)
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [cable])

  return { adminMessage }
}

export default useUserContactListChannel
