import menu from 'src/constants/dressup'
import PropTypes from 'prop-types'

const MenuVertical = ({ active = 'appearance2', onChangeMenu }) => {
  const handleClick = (key) => {
    onChangeMenu && onChangeMenu(key)
  }

  return (
    <div>
      {menu.map((item, index) => {
        return (
          <div onClick={() => handleClick(item.key)} key={index} className={`cursor-pointer max-w-[80px] max-h-[45px] w-[15vw] h-[4.5vh] mb-2 ${active === item.key ? '-ml-2' : ''}`}>
            <img className='h-full v-full' src={item.image} alt="" />
          </div>
        )
      }
      )}
    </div>
  )
}

export default MenuVertical

MenuVertical.propTypes = {
  active: PropTypes.string,
  onChangeMenu: PropTypes.func
}
