import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getItemGacha = async (idGacha, timesroll, isFree, gachaType) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.Itemgacha.replace(':id', idGacha), {
      times_roll: timesroll,
      is_free: isFree,
      gacha_type: gachaType
    })
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export const getItemGachaGiftTicket = async (idGacha, timesroll, ticket, gachaType) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.Itemgacha.replace(':id', idGacha), {
      times_roll: timesroll,
      type_ticket: ticket,
      gacha_type: gachaType
    })
    return res.data
  } catch (error) {
    console.log(error)
  }
}
