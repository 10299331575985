import { useEffect, useState } from 'react'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { ItemPage } from 'src/pages/menu/Item'
import { useAuthState } from 'src/providers/AuthProviders'
import getItemRollUser from 'src/services/get/getItemRoll'
import _ from 'lodash'
import getUserItems from 'src/services/get/getUserItems'

const ItemPageContainer = () => {
  const [items, setItems] = useState([])
  const [itemSpecial, setItemSpecial] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuthState()

  useEffect(() => {
    const req = new CancelableAPIRequest(getUserItems, user?.id)

    req.invoke((res) => {
      setIsLoading(false)
      if (res.length !== 0) setItemSpecial([...res])
    })

    return () => {
      req.cancel()
    }
  }, [])

  useEffect(() => {
    const loadItemHistory = async () => {
      try {
        const response = await getItemRollUser(user.id)
        setItems((prevItem) => [...prevItem, ...response.data])
      } catch (error) {
        console.error(error)
      }
    }
    loadItemHistory()
  }, [!_.isEqual(items)])

  return <ItemPage itemsHistory={items} isLoading={isLoading} itemSpecial={itemSpecial} />
}

export default ItemPageContainer
