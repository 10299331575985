import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import SpeakerIcon from 'src/assets/img/message/detail/speaker-icon.png'
import GiftIcon from 'src/assets/img/message/detail/gift-icon.png'
import PauseIcon from 'src/assets/img/message/detail/pause-icon.png'
import { useAuthState } from 'src/providers/AuthProviders'
import useAudio from 'src/components/common/UseAudio'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'
import { formatDate } from 'src/lib/date'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'
import HTMLReactParser from 'html-react-parser'
import { CHARACTERS } from 'src/constants/characters'
import ModalPayShop from './ModalPayShop'
import { GiftDetail } from 'src/components/message/ChatBox/GiftDetail'

export const ItemChatRealTalk = ({
  msg,
  loveLvSettings,
  character,
  changeBgImage,
  isScrollOpenTalk,
  handleUpdateMessage,
  indexMes,
  handleOpenGift
}) => {
  const { advices } = msg
  const { user } = useAuthState()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const [canViewDetail, setCanViewDetail] = useState(false)
  const [isSpendHeartConfirm, setIsSpendHeartConfirm] = useState(true)
  const [playing, playAudio, pauseAudio] = useAudio(msg.character_voice)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)
  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, loveLvSettings?.open_chat_heart))
  }, [user, userHeart])

  useEffect(() => {
    const target = localStorage.getItem('isSpendHeartConfirmItem')
    if (target === null) return setIsSpendHeartConfirm(true)
    setIsSpendHeartConfirm(JSON.parse(target.toLowerCase()))
  }, [localStorage.getItem('isSpendHeartConfirmItem')])

  const closeSpendHeartModal = useCallback(() => {
    setIsOpenSpendHeartModal(false)
  }, [])

  const openSpendHeartModal = useCallback(() => {
    onSpendHeartConfirm()
  }, [isSpendHeartConfirm, canSpendHeartState, isScrollOpenTalk])

  useEffect(() => {
    openSpendHeartModal()
  }, [isSpendHeartConfirm, canSpendHeartState, isScrollOpenTalk])

  useEffect(() => {
    setCanViewDetail(msg.is_heart_consumption || character.navigator)
  }, [msg.is_heart_consumption, character])

  const onSpendHeartConfirm = useCallback(async () => {
    setIsOpenSpendHeartModal(false)
    localStorage.setItem('isSpendHeartConfirmItem', false)
    setCanViewDetail(true)
  }, [msg.character_chat_id, user?.id, loveLvSettings, handleUpdateMessage])

  const renderAvatar = () => {
    const characterAvt = CHARACTERS.find((item) => item.id === character.id)
    if (characterAvt) {
      return characterAvt.avatar
    }
  }

  const [openModal, setOpenModal] = useState(false)

  const handleClickModal = () => {
    setOpenModal(true)
  }

  const handleClose = () => {
    setOpenModal(false)
  }

  const handleCopy = (event) => {
    event.preventDefault()
  }

  return (
    <div className="flex justify-center">
      <div className="ml-3.5 flex h-full">
        <div className="w-[60px] h-[60px] rounded-[50%] overflow-hidden mt-[1rem] z-[10]">
          <img className="w-full h-full" src={renderAvatar()} alt="avatar" />
        </div>
      </div>
      <div className="flex mt-5">
        <DivRespon>
          <div
            className={
              'order-first rounded-lg relative bg-[#FFFFFF] max-w-[75%] w-[265px] h-full p-2 pl-3 pt-[15px] border-[0.5px] border-white ml-3.5 mt-2.5 z-10 break-words'
            }
          >
            {!user.platinum_passport && indexMes !== 0 && (
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50">
                <button
                  className="z-50 bg-[#FF787E] h-[30px] text-xs w-[150px] rounded-full opacity-100"
                  onClick={handleClickModal}
                >
                  メッセージを表示する
                </button>
              </div>
            )}
            <ChatItem />
            <div>
              <div className="absolute left-2.5 -top-2.5 grid grid-cols-3 gap-1.5">
                {(msg?.character_voice_id || msg?.character_voice) && (
                  <IconWrapper background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)">
                    <img src={SpeakerIcon} alt="speaker" />
                  </IconWrapper>
                )}
                {(msg?.setting_item_id || msg?.setting_item) && (
                  <IconWrapper background="linear-gradient(360deg, #F2709C 0%, #FF9472 100%)">
                    <img src={GiftIcon} alt="gift" />
                  </IconWrapper>
                )}
                {(msg?.character_cloth_id?.length > 0 || msg?.character_cloth?.length > 0) && (
                  <IconWrapper background="linear-gradient(360deg, #F2709C 0%, #FF9472 100%)">
                    <img src={GiftIcon} alt="avatar-gift" />
                  </IconWrapper>
                )}
              </div>
              <div
                className={`text-justify text-black text-xs font-light whitespace-pre-line ${
                  !canViewDetail && 'pb-3'
                } ${user.platinum_passport ? '' : 'blur-[4px]'}`}
                style={indexMes === 0 ? { filter: 'blur(0px)' } : {}}
                onCopy={user.platinum_passport ? null : handleCopy}
              >
                {HTMLReactParser(msg?.title)} {''}
                <span dangerouslySetInnerHTML={{ __html: msg?.description }} />
              </div>
              {canViewDetail && advices?.length ? (
                <div className="mt-2.5">
                  <div className="bg-[linear-gradient(90deg,#FBED96_0%,#ABECD6_100%)] -mr-[8.5px] -ml-[12.5px] text-left pl-3.5 py-1 text-[#325282] text-[10px] font-medium">
                    {advices[0].title}
                  </div>
                  <div className="text-[#FAED97] text-[10px] text-justify pt-[7px] leadding-[15px]">
                    {advices[0].content}
                  </div>
                </div>
              ) : null}
              <div className="gap-6 mx-2">
                {(((msg?.character_voice_id || msg?.character_voice) && user.platinum_passport) ||
                  ((msg?.character_voice_id || msg?.character_voice) && indexMes === 0)) && (
                  <div
                    style={{
                      background: playing
                        ? 'linear-gradient(90deg, #FFE259 0%, #FFA751 100%)'
                        : '#fff',
                      color: playing ? '#fff' : '#325282',
                      fontWeight: playing ? '600' : '500'
                    }}
                    className="relative rounded-[3px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] text-[9px] py-[3.2px] pl-5 pr-2 cursor-pointer mb-1 mt-2 h-5 flex items-center"
                  >
                    {playing ? (
                      <div onClick={pauseAudio}>
                        <IconWrapper
                          className="absolute top-0 -left-2"
                          background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                        >
                          <img src={PauseIcon} alt="speaker" />
                        </IconWrapper>
                        ボイス再生中
                      </div>
                    ) : (
                      <div onClick={playAudio}>
                        <IconWrapper
                          className="absolute top-0 -left-2"
                          background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                        >
                          <img src={SpeakerIcon} alt="speaker" />
                        </IconWrapper>
                        ボイスを聞く
                      </div>
                    )}
                  </div>
                )}
                {((msg?.setting_item_id && user.platinum_passport) ||
                  (msg?.setting_item_id && indexMes === 0)) && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_present}
                    giftType="setting_item"
                    handleOpenGift={handleOpenGift}
                  />
                )}
                {(((msg?.character_cloth_id?.length > 0 || msg?.character_cloth?.length > 0) &&
                  user.platinum_passport) ||
                  ((msg?.character_cloth_id?.length > 0 || msg?.character_cloth?.length > 0) &&
                    indexMes === 0)) && (
                  <GiftDetail
                    msg={msg}
                    isReceived={msg?.is_received_cloth}
                    giftType="character_cloth"
                    handleOpenGift={handleOpenGift}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="ml-2 text-[8px] font-light text-[#325282]">
            <span>{formatDate(new Date(msg?.created_at))}</span>
          </div>
          <SpendHeartModal
            isOpen={isOpenSpendHeartModal}
            closeModal={closeSpendHeartModal}
            onConfirm={onSpendHeartConfirm}
            userHeart={userHeart}
            spendHeartAmount={loveLvSettings?.open_chat_heart}
            canSpendHeart={canSpendHeartState}
            isOpenRealtimeTalk
            isScrollOpenTalk={isScrollOpenTalk}
          />

          {openModal && <ModalPayShop show={openModal} handleClose={handleClose} />}
        </DivRespon>
      </div>
    </div>
  )
}

ItemChatRealTalk.propTypes = {
  msg: PropTypes.object.isRequired,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  changeBgImage: PropTypes.func,
  isScrollOpenTalk: PropTypes.func,
  handleUpdateMessage: PropTypes.func,
  indexMes: PropTypes.number,
  handleOpenGift: PropTypes.func
}

const IconWrapper = styled.div.attrs({
  className:
    'flex items-center justify-center h-5 w-5 rounded-[50%] overflow-hidden border border-white p-[4px]'
})`
  background: ${(props) => props.background};
`
const ChatItem = styled.div`
  position: absolute;
  top: 18px;
  left: -18px;
  clip-path: polygon(100% 0, 0 49%, 100% 100%);
  width: 18px;
  height: 14px;
  background-color: #fff;
  z-index: 0;
  ::before {
    position: absolute;
    content: '';
    top: 0.5px;
    left: -0.5px;
    width: 20px;
    height: 13px;
    background: #fff;
    clip-path: polygon(100% 0, 0 49%, 100% 100%);
  }
`
const DivRespon = styled.div.attrs({
  className:
    'flex items-end w-full font-hiragino ml-2'
})`

  @media (max-width: 360px) {
    width: 85%;
  }
  @media (max-width: 320px) {
    width: 75%;
  }
`
