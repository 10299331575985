import Inquiry from 'src/models/Inquiry'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Fetches contact inquiries (お問い合わせ)
 *
 * @param {number} userId The ID representing the user whose data you wish to fetch
 * @param {number} inquiryId The ID representing the inquiry whose data you wish to fetch
 * @returns An array of inquiry data.
 */
const getUserInquiryDetail = async (userId, inquiryId) => {
  if (!userId || !inquiryId) return null

  try {
    const res = await axiosInstance.get(
      `${API_ROUTES.Users}/${userId}/${API_ROUTES.Inquiries}/${inquiryId}`
    )

    if (res.data.success === false) return null
    else {
      let contact = {}
      const c = res.data.user_contact

      const contactKind = typeof c.contact_kind === 'string' ? c.contact_kind : null
      contact = new Inquiry(
        c.id,
        c.attached_file,
        contactKind,
        c.content,
        c.admin_response,
        c.payment_amount,
        c.process_status,
        c.title,
        c.created_at,
        c.admin_response_content,
        c.is_readed,
        c.content_id,
        c.message_feedback,
        c.attached_file_two,
        c.attached_file_three
      )
      return contact
    }
  } catch (error) {
    return []
  }
}

export default getUserInquiryDetail
