import Asahi01 from 'src/assets/img/gacha/01_asahi.png'
import Minato02 from 'src/assets/img/gacha/02_minato.png'
import Wakatuki03 from 'src/assets/img/gacha/03_wakatuki.png'
import Kazehaya04 from 'src/assets/img/gacha/04_kazehaya.png'
import Tatibana05 from 'src/assets/img/gacha/05_tatibana.png'
// Khi chỉ có "R" trong 11 kết quả gacha liên tiếp
// Nếu kết quả gacha một lần bắn là "R"
export const gachaTurnR = [
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/01_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.18,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/01_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.18,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/01_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.18,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/01_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.18,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/01_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.18,
    img: Wakatuki03
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/05_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.02,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/05_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.02,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/05_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.02,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/05_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.02,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/05_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.02,
    img: Wakatuki03
  }
]

// Khi 1 hoặc nhiều "SR" được bao gồm trong 11 kết quả gacha liên tiếp và "SSR" không được bao gồm
export const gachaTurnSR = [
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/01_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.01,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/01_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.01,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/01_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.01,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/01_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.01,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/01_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%87%E3%83%95%E3%82%A9%E3%83%AB%E3%83%88.mp4',
    probability: 0.01,
    img: Wakatuki03
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/05_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.05,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/05_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.05,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/05_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.05,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/05_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.05,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/05_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%83%8E%E3%83%BC%E3%83%9E%E3%83%AB.mp4',
    probability: 0.05,
    img: Wakatuki03
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/07_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89.mp4',
    probability: 0.08,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/07_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89.mp4',
    probability: 0.08,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/07_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89.mp4',
    probability: 0.08,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/07_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89.mp4',
    probability: 0.08,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/07_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%83%83%E3%83%89.mp4',
    probability: 0.08,
    img: Wakatuki03
  },

  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/09_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E9%9B%86%E5%90%88.mp4',
    probability: 0.06,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/09_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E9%9B%86%E5%90%88.mp4',
    probability: 0.06,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/09_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E9%9B%86%E5%90%88.mp4',
    probability: 0.06,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/09_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E9%9B%86%E5%90%88.mp4',
    probability: 0.06,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/09_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%A1%E3%83%B3%E3%83%90%E3%83%BC%E9%9B%86%E5%90%88.mp4',
    probability: 0.06,
    img: Wakatuki03
  }
]

export const gachaTurnSSR = [
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/08_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%82%A4%E3%83%B3%E3%83%9C%E3%83%BC.mp4',
    probability: 0.1,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/08_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%82%A4%E3%83%B3%E3%83%9C%E3%83%BC.mp4',
    probability: 0.1,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/08_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%82%A4%E3%83%B3%E3%83%9C%E3%83%BC.mp4',
    probability: 0.1,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/08_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%82%A4%E3%83%B3%E3%83%9C%E3%83%BC.mp4',
    probability: 0.1,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/08_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AC%E3%83%A9%E3%82%B9%E3%83%AC%E3%82%A4%E3%83%B3%E3%83%9C%E3%83%BC.mp4',
    probability: 0.1,
    img: Wakatuki03
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/02_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E9%AD%9A%E7%BE%A4.mp4',
    probability: 0.05,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/02_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E9%AD%9A%E7%BE%A4.mp4',
    probability: 0.05,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/02_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E9%AD%9A%E7%BE%A4.mp4',
    probability: 0.05,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/02_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E9%AD%9A%E7%BE%A4.mp4',
    probability: 0.05,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/02_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E9%AD%9A%E7%BE%A4.mp4',
    probability: 0.05,
    img: Wakatuki03
  },

  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/03_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%95%E3%83%AA%E3%83%BC%E3%82%BA.mp4',
    probability: 0.04,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/03_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%95%E3%83%AA%E3%83%BC%E3%82%BA.mp4',
    probability: 0.04,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/03_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%95%E3%83%AA%E3%83%BC%E3%82%BA.mp4',
    probability: 0.04,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/03_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%95%E3%83%AA%E3%83%BC%E3%82%BA.mp4',
    probability: 0.04,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/03_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%83%95%E3%83%AA%E3%83%BC%E3%82%BA.mp4',
    probability: 0.04,
    img: Wakatuki03
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/06_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%82%B4%E3%83%BC%E3%83%AB%E3%83%89.mp4',
    probability: 0.01,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/06_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%82%B4%E3%83%BC%E3%83%AB%E3%83%89.mp4',
    probability: 0.01,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/06_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%82%B4%E3%83%BC%E3%83%AB%E3%83%89.mp4',
    probability: 0.01,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/06_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%82%B4%E3%83%BC%E3%83%AB%E3%83%89.mp4',
    probability: 0.01,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/06_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E7%BE%BD%E8%88%9E%E3%81%84%E3%82%B4%E3%83%BC%E3%83%AB%E3%83%89.mp4',
    probability: 0.01,
    img: Wakatuki03
  }
]

// Khi có 3 "SSR" trở lên trong 11 kết quả gacha liên tiếp
export const gachaTurnThreeResultSSR = [
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/01.%E6%9C%9D%E6%97%A5%E7%A9%BA/11_%E6%9C%9D%E6%97%A5%E7%A9%BA%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AB%E3%83%83%E3%83%88%E3%82%A4%E3%83%B3.mp4',
    probability: 0.2,
    img: Asahi01
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/04.%E9%A2%A8%E6%97%A9%E7%BF%94/11_%E9%A2%A8%E6%97%A9%E7%BF%94%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AB%E3%83%83%E3%83%88%E3%82%A4%E3%83%B3.mp4',
    probability: 0.2,
    img: Kazehaya04
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/02.%E6%A8%AA%E6%B5%9C%E6%B9%8A/11_%E6%A8%AA%E6%B5%9C%E6%B9%8A%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AB%E3%83%83%E3%83%88%E3%82%A4%E3%83%B3.mp4',
    probability: 0.2,
    img: Minato02
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/05.%E7%AB%8B%E8%8A%B1%E9%A7%BF/11_%E7%AB%8B%E8%8A%B1%E9%A7%BF%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AB%E3%83%83%E3%83%88%E3%82%A4%E3%83%B3.mp4',
    probability: 0.2,
    img: Tatibana05
  },
  {
    value:
      'https://idol-prince-assets.s3.ap-northeast-1.amazonaws.com/videos/03.%E8%8B%A5%E6%A7%BB%E8%93%AE/11_%E8%8B%A5%E6%A7%BB%E8%93%AE%E3%82%AC%E3%83%81%E3%83%A3_%E3%82%AB%E3%83%83%E3%83%88%E3%82%A4%E3%83%B3.mp4',
    probability: 0.2,
    img: Wakatuki03
  }
]
