import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getMainTenance = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Maintenance)
    if (!res) return
    return res.data
  } catch (e) {
    console.error(e)
  }
}

export default getMainTenance
