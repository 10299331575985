import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Fetches contact inquiries (お問い合わせ)
 *
 * @param {number} userId The ID representing the user whose data you wish to fetch
 * @param {number} inquiryId The ID representing the inquiry whose data you wish to fetch
 * @returns An array of inquiry data.
 */
const putUpdateNotificationInquiry = async (userId, inquiryId) => {
  if (!userId || !inquiryId) return false

  try {
    const res = await axiosInstance.put(
      `${API_ROUTES.Users}/${userId}/${API_ROUTES.Inquiries}/${inquiryId}/${API_ROUTES.ConfirmFeedback}`
    )

    if (res.data.success === false) return false
    return true
  } catch (error) {
    return false
  }
}

export default putUpdateNotificationInquiry
