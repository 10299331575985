import { useContext, useEffect, useState } from 'react'
import { ActionCableContext } from '../../providers/ActionCableProvider'
import { useAuthState } from '../../providers/AuthProviders'

const useCharacterListChannel = ({ liveSettingId }) => {
  const { user } = useAuthState()
  const cable = useContext(ActionCableContext)
  const [watchableTime, setWatchableTime] = useState('')
  const [extraWatchableTime, setExtraWatchableTime] = useState('')
  const [livestreamId, setLivestreamId] = useState('')
  const [loveData, setLoveData] = useState({})
  const [reFetch, setReFetch] = useState(false)

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create({ channel: 'CharacterListChannel', livestream_setting_id: liveSettingId }, {
      connected: () => console.log('CONNECTED'),
      received: (response) => {
        if (response.action === 'broadcast_normal_time') {
          const data = response.data
          if (data.started_extra_time) {
            if (user.id === data.user_id) {
              setLivestreamId(data.livestream_id)
              setExtraWatchableTime(data.extra_watchable_time)
            }
          } else {
            if (data.limitted_hourglass_item) {
              setExtraWatchableTime(data.extra_watchable_time)
            }
            setLivestreamId(data.livestream_id)
            setWatchableTime(data.watchable_time)
          }
        } else if (['broadcast_start_live', 'broadcast_complete_live'].includes(response.action)) {
          setReFetch(true)
        } else if (response.action === 'broadcast_love_point') {
          setLoveData(response.data)
        } else if (response.action === 'broadcast_black_list') {
          setReFetch(true)
        }
      }
    })

    return () => {
      channel.unsubscribe()
    }
  }, [cable])

  return { watchableTime, extraWatchableTime, livestreamId, reFetch, setReFetch, loveData }
}

export default useCharacterListChannel
