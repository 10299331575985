import PropTypes from 'prop-types'

import BGTitle from 'src/assets/img/common/bg-title.png'

const TitleHeader = ({ title, className = 'mb-[1.7rem]' }) => {
  return (
    <div
      className={`px-6 py-1.5 w-fit min-w-[200px] mx-auto ${className}`}
      style={{ backgroundImage: `url('${BGTitle}')`, backgroundSize: '100% 100%' }}
    >
      <h3 className="text-xl font-vldLineGR text-white text-center font-normal leading-[126%] truncate">
        {title}
      </h3>
    </div>
  )
}

TitleHeader.propTypes = {
  title: PropTypes.string.isRequired,
  className: PropTypes.string
}

export default TitleHeader
