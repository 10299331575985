import PropTypes from 'prop-types'

const sizeClass = {
  sm: {
    fontSizeTitle: 'text-[9px]',
    fontSizeLevel: 'text-[9px]',
    heightBar: 'h-[2px]'
  },
  md: {
    fontSizeTitle: 'text-[12px]',
    fontSizeLevel: 'text-[14px]',
    heightBar: 'h-[4px]'
  },
  lg: {
    fontSizeTitle: 'text-[12px]',
    fontSizeLevel: 'text-[14px]',
    paddingBottom: '2px',
    heightBar: 'h-[4px]'
  }
}

export const LoveLevel = ({ love, className, size = 'sm' }) => {
  const _size = sizeClass[size]
  return (<div className={`status ${className}`}>
    <div className={`flex items-center justify-between w-100 leading-[12px] pb-[${_size?.paddingBottom}]`}>
      <div className={`status-text text-[#b380d7] font-semibold ${_size.fontSizeTitle}`}>
        LOVE度
      </div>
      <div className={`level-text font-light ${_size.fontSizeTitle}`}>
        <small>lv.</small>
        <span className={`${_size.fontSizeLevel}`}>{
          love?.level
        }</span>
      </div>
    </div>
    <div className={`w-full bg-[#cda3eb] rounded-[50px] ${_size.heightBar}`}>
      <div className={`bg-[#7c3fa7] rounded-[50px] ${_size.heightBar}`} style={{ width: `${Math.round((love?.current_items / love?.total_items) * 100) > 100 ? 100 : Math.round((love?.current_items / love?.total_items) * 100)}%` }} />
    </div>
  </div>
  )
}

LoveLevel.propTypes = {
  love: PropTypes.shape({
    level: PropTypes.number.isRequired,
    total_items: PropTypes.number.isRequired,
    current_items: PropTypes.number.isRequired
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

export const GrowthLevel = ({ grow, className, size = 'sm' }) => {
  const _size = sizeClass[size]
  return (<div className={`status ${className}`}>
    <div className={`flex items-center justify-between leading-[12px] pb-[${_size?.paddingBottom}]`}>
      <div className={`status-text text-[#00335b] font-semibold ${_size.fontSizeTitle}`}>
        育て度
      </div>
      <div className={`level-text font-light ${_size.fontSizeTitle}`}>
        <small>lv.</small>
        <span className={`${_size.fontSizeLevel}`}>{
          grow?.level
        }</span>
      </div>
    </div>
    <div className={`w-full rounded-[50px] bg-[#86e0db] ${_size.heightBar}`}>
      <div className={`bg-[#105285] rounded-[50px] ${_size.heightBar}`} style={{ width: `${Math.round((grow?.current_items / grow?.total_items) * 100) > 100 ? 100 : Math.round((grow?.current_items / grow?.total_items) * 100)}%` }} />
    </div>
  </div>
  )
}

GrowthLevel.propTypes = {
  grow: PropTypes.shape({
    level: PropTypes.number.isRequired,
    total_items: PropTypes.number.isRequired,
    current_items: PropTypes.number.isRequired
  }),
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

const LevelProgress = ({ level, size = 'lg' }) => {
  return (
    <div className="font-hiragino status w-full">
      <LoveLevel size={size} love={level?.love} className='mb-1' />
      <GrowthLevel size={size} grow={level?.grow} />
    </div>
  )
}

LevelProgress.propTypes = {
  level: PropTypes.shape({
    love: PropTypes.shape({
      level: PropTypes.number.isRequired,
      total_items: PropTypes.number.isRequired,
      current_items: PropTypes.number.isRequired
    }).isRequired,
    grow: PropTypes.shape({
      level: PropTypes.number.isRequired,
      total_items: PropTypes.number.isRequired,
      current_items: PropTypes.number.isRequired
    }).isRequired
  }),
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

export default LevelProgress
