import React, { createContext, useState } from 'react'
import PropTypes from 'prop-types'
import actionCable from 'actioncable'
import { getAccessToken } from 'src/services/common/storage'

const CableApp = {}
const SOCKET_SERVER_URL = process.env.REACT_APP_SOCKET_SERVER_URL || ''

export const ActionCableContext = createContext()

const ActionCableProvider = ({ children }) => {
  const [token, setToken] = useState(getAccessToken() || '')
  const handleStorageChange = () => {
    setToken(window.localStorage.getItem('accessToken') || '')
  }

  window.addEventListener('storage', handleStorageChange)

  if (token) {
    CableApp.cable = actionCable.createConsumer(`${SOCKET_SERVER_URL}?token=${token.replace('"', '')}`)
    return <ActionCableContext.Provider value={CableApp.cable}>{children}</ActionCableContext.Provider>
  }

  return <div>{children}</div>
}

ActionCableProvider.propTypes = {
  children: PropTypes.object
}

export default ActionCableProvider
