import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import BtnTabOn from 'src/assets/img/common/btn_tab_on.png'
import BtnTabOff from 'src/assets/img/common/btn_tab_off.png'

const TabButton = ({ title }) => {
  const navigate = useNavigate()

  const inProgress = () => {
    if (title === '応援購入') {
      navigate('/in-progress')
    }
  }
  return (
    <Tab className="flex items-center justify-center w-full">
      {({ selected }) => (
        <>
          <div
            className="py-4 font-vldLineGR w-28"
            style={{
              backgroundImage: `url('${selected ? BtnTabOn : BtnTabOff}')`,
              backgroundSize: '100% 100%'
            }}
            onClick={inProgress}
          >
            <span className="truncate">{title}</span>
          </div>
        </>
      )}
    </Tab>
  )
}

TabButton.propTypes = { title: PropTypes.string.isRequired }

export default TabButton
