import React from 'react'
import CommonBg from '../assets/common_bg.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const LayoutBackground = ({ bg, customBg, isNoFooter }) => {
  return (
    <div>
      {customBg && (
        <img
          src={customBg}
          className={clsx(
            'absolute top-[120px] left-[50%] translate-x-[-50%] w-full md:w-[400px] object-cover object-top -z-[1]',
            isNoFooter ? 'h-[calc(100vh-120px)]' : 'h-[calc(100vh-220px)]'
          )}
        />
      )}
      {bg.includes('.mp4') ? (
        <div className='relative'>
          <video src={bg} poster={CommonBg} autoPlay loop muted playsInline={true} className='absolute w-full h-[100vh] object-cover'/>
        </div>
      ) : (
        <img
          src={bg || CommonBg}
          className="absolute top-0 left-[50%] translate-x-[-50%] w-full md:w-[400px] h-full -z-[0] object-cover"
        />
      )}
    </div>
  )
}

LayoutBackground.propTypes = {
  bg: PropTypes.any,
  customBg: PropTypes.any,
  isNoFooter: PropTypes.bool
}

export { LayoutBackground }
