import React, { useCallback } from 'react'
import ShopBlock from 'src/pages/main/shop/components/ShopBlock'

import ShopItem from 'src/pages/main/shop/components/ShopDiamondTab/ShopItem'
import ShopMethodSelectModal from 'src/pages/main/shop/components/ShopDiamondTab/ShopMethodSelectModal'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useShopQuery } from 'src/pages/main/shop/hooks/useShopQuery'
import { useAuthState } from 'src/providers/AuthProviders'

const ShopDiamondTab = () => {
  const { setSelectedItem, setModalContent } = useShopContext()
  const { user } = useAuthState()
  const { data: shops } = useShopQuery(user?.id)

  const handleSelect = useCallback(
    (item) => {
      setSelectedItem(item)
      setModalContent(<ShopMethodSelectModal description={item.explanatory_text} />)
    },
    [shops]
  )

  return (
    <div className="grid items-center justify-center w-full grid-cols-2 gap-2">
      {shops?.map((shop, i) => (
        <ShopBlock key={i}>
          <ShopItem handleSelect={() => handleSelect(shop)} shopDetails={shop} />
        </ShopBlock>
      ))}
    </div>
  )
}

export default ShopDiamondTab
