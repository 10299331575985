import PropTypes from 'prop-types'
import { CURRENCY_TYPE } from 'src/models/GachaInfo'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'

import BannerDiamond from 'src/assets/img/gacha/banner_diamond.png'
import BannerTicket from 'src/assets/img/gacha/banner_ticket.png'
import BannerSSRTicket from 'src/assets/img/gacha/banner_ssr_ticket.png'
import BannerDiamondFirst from 'src/assets/img/gacha/banner_diamond_1.png'
import BannerTicketFirst from 'src/assets/img/gacha/banner_ticket_1.png'
import BannerSSRTicketFirst from 'src/assets/img/gacha/banner_ssr_ticket_1.png'

const CapsuleCurrencySelect = ({
  headerText,
  setCurrency,
  handleClose,
  ticket,
  itemsTicketRoll
}) => {
  const checkText = '11回ガチャを引く'
  return (
    <div className="w-full px-4 pt-8 pb-16 bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title={headerText} className="mb-4" />
      <BannerButton
        image={headerText === checkText ? BannerDiamond : BannerDiamondFirst}
        onClick={() => setCurrency(CURRENCY_TYPE.Diamond)}
        headerText={headerText}
      />
      <BannerButton
        image={headerText === checkText ? BannerTicket : BannerTicketFirst}
        subtext={`ガチャチケット所有数${
          itemsTicketRoll.filter((el) => el.itemKind === 'gacha_ticket')[0]?.amount || 0
        }枚`}
        onClick={() => setCurrency(CURRENCY_TYPE.Ticket)}
        headerText={headerText}
      />
      {ticket && (
        <BannerButton
          image={headerText === checkText ? BannerSSRTicket : BannerSSRTicketFirst}
          subtext={`SSR確定ガチャチケット所有数${
            itemsTicketRoll.filter((el) => el.itemKind === 'ssr_ticket')[0]?.amount || 0
          }枚`}
          onClick={() => setCurrency(CURRENCY_TYPE.SSRTicket)}
          headerText={headerText}
        />
      )}
      <span className="w-60 font-vldLineGR text-[9px] text-center">※ガチャ演出の音量にご注意ください</span>
      <span className="w-60 font-vldLineGR text-[9px] text-center">マナーモードでも音が流れます</span>
    </div>
  )
}

CapsuleCurrencySelect.propTypes = {
  headerText: PropTypes.string.isRequired,
  setCurrency: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  ticket: PropTypes.bool.isRequired,
  itemsTicketRoll: PropTypes.array
}

const BannerButton = ({ image, onClick, subtext, headerText }) => {
  return (
    <>
      <button
        type="button"
        className={
          subtext !== undefined ? 'w-72 px-4' : 'w-72 px-4 mb-4'
        }
        onClick={onClick}
      >
        <img src={image} />
      </button>
      {subtext && <span className="w-60 font-vldLineGR text-xs text-right mb-4">{`※${subtext}`}</span>}
    </>
  )
}

BannerButton.propTypes = {
  image: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  subtext: PropTypes.string,
  headerText: PropTypes.string.isRequired
}

export default CapsuleCurrencySelect
