import { useQuery } from 'react-query'
import { getHeartExchangeConfigs } from 'src/services/get/getHeartExchangeConfigs'

const useHeartExchangeList = () => {
  return useQuery({
    queryFn: getHeartExchangeConfigs,
    queryKey: 'get-heart-exchange-configs'
  })
}

export { useHeartExchangeList }
