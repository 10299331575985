import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getUserInfo = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.UserInfo)
    if (res.data.success) {
      return res.data.items
    }
    return res.data.message
  } catch (error) {
    return false
  }
}

export default getUserInfo
