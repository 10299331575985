import PropTypes from 'prop-types'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import BtnShop from 'src/assets/img/gacha/btn_shop.png'
import { MAIN_PATHS } from 'src/routes/main'
import { Link } from 'react-router-dom'
import { Modal } from 'src/components/common/Modal'

const ModalPayShop = ({ show, handleClose }) => {
  const TRANSITION_CLASS_NAME =
    'fixed w-full top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 p-2 z-50'
  return (
    <Modal
      focusOutside={false}
      open={show}
      onClose={handleClose}
      className="sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
    >
      <FadeTransition show={show} className={TRANSITION_CLASS_NAME}>
        <div className="w-[85%] mx-10 px-4 pt-8 pb-10 bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center">
          <ModalCloseButton handleClose={handleClose} />
          <TitleHeader title="お知らせ" className="mb-4" />
          <p className="text-xs leading-loose text-center">
            リアルタイムトークで彼のトークを表示するには、
            <p>
              <span className="text-[#E2A0E7]"> 「プラチナパスポート」</span>{' '}
              <span>が必要です。</span>
            </p>
            <p>ショップより、『プラチナパスポート』をご購入 </p>
            <p>の上、再度お越し下さい。</p>
          </p>
          <p className="text-xs mt-5 leading-loose text-center">
            『プラチナパスポート』は1回購入するだけで、
            <p>
              永久に、<span className="text-[#503D8A]">リアルタイムトークが楽しめる・ </span>
            </p>
            <p className="text-[#503D8A]">
              無料ハートの回復量が増加・D×6全員の生い 立ち漫画を最後まで読める・LIVE配信に参加
              可能、
            </p>
            <p> などの特典が多数！ </p>
          </p>
          <Link className="w-40 mt-5" to={MAIN_PATHS.ShopPage}>
            <img src={BtnShop} />
          </Link>
        </div>
      </FadeTransition>
    </Modal>
  )
}

ModalPayShop.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func
}

export default ModalPayShop
