import ProfileVoiceAsahi from 'src/assets/voice/recommend/asahi_sora.wav'
import ProfileVoiceYokohama from 'src/assets/voice/recommend/yokohama_minato.wav'
import ProfileVoiceWakatuki from 'src/assets/voice/recommend/wakatuki_ren_update.wav'
import ProfileVoiceKazehaya from 'src/assets/voice/recommend/kazehaya_sho.wav'
import ProfileVoiceTatibana from 'src/assets/voice/recommend/tatibana_shun_update.wav'

export const profileVoice = (id) => {
  switch (id) {
    case 1:
      return ProfileVoiceAsahi
    case 2:
      return ProfileVoiceYokohama
    case 3:
      return ProfileVoiceWakatuki
    case 4:
      return ProfileVoiceKazehaya
    case 5:
      return ProfileVoiceTatibana
    default:
      return null
  }
}
