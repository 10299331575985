/* eslint-disable no-case-declarations */
import { authActionTypes } from 'src/providers/AuthProviders/actionTypes'
import { authInitState } from 'src/providers/AuthProviders/initState'
import { queryClient } from 'src/providers/QueryProvider'

const syncStateToLocalStorage = (state) => {
  localStorage.setItem('auth', JSON.stringify(state))
  console.log('[[AUTH STATE]]: ', state)
  return state
}

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case authActionTypes.ACTIVE_USER:
      return syncStateToLocalStorage({
        ...state,
        user: { ...state.user, ...payload, is_active: true }
      })
    case authActionTypes.LOGOUT_USER:
      queryClient.clear()
      localStorage.removeItem('accessToken')
      return syncStateToLocalStorage(authInitState)
    case authActionTypes.LOGIN_USER:
      localStorage.setItem('accessToken', JSON.stringify(payload.session_token))
      window.dispatchEvent(new Event('storage'))
      return syncStateToLocalStorage({
        ...state,
        user: payload,
        isLoggedIn: true
      })
    case authActionTypes.EXCHANGE_HEART_BY_RED_DIAMOND:
      return syncStateToLocalStorage({
        ...state,
        isLoggedIn: true,

        user: {
          ...state.user,
          possession_heart: parseInt(payload?.possession_heart) || 0,
          red_amount: parseInt(payload?.possession_red_diamond) || 0
        }
      })
    case authActionTypes.SPEND_HEART:
      const user = state?.user
      const heart = user?.possession_heart
      const heartRecovery = user?.possession_heart_recovery_or_maximum

      if (Number.isNaN(heart) || Number.isNaN(heartRecovery)) return state

      const spendHeartRecovery = payload >= heartRecovery ? heartRecovery : payload
      const spendHeart = payload >= heartRecovery ? payload - heartRecovery : 0

      return syncStateToLocalStorage({
        ...state,
        user: {
          ...(state?.user || {}),
          possession_heart: heart - spendHeart,
          possession_heart_recovery_or_maximum: heartRecovery - spendHeartRecovery
        }
      })
    case authActionTypes.ADD_ITEM:
      const possessionHeart = payload.itemType === 'heart' ? payload.amount : 0
      const blueAmount = payload.itemType === 'diamond' ? payload.amount : 0
      const redAmount = payload.itemType === 'diamond_red' ? payload.amount : 0
      return syncStateToLocalStorage({
        ...state,
        user: {
          ...state.user,
          possession_heart: state.user.possession_heart + possessionHeart,
          blue_amount: state.user.blue_amount + blueAmount,
          red_amount: state.user.red_amount + redAmount
        }
      })
    case authActionTypes.ADD_HEART:
      return syncStateToLocalStorage({
        ...state,
        user: {
          ...state.user,
          possession_heart: payload.possession_heart
        }
      })
    case authActionTypes.SPEND_DIAMOND_RED:
      return syncStateToLocalStorage({
        ...state,
        user: {
          ...state.user,
          red_amount: state.user.red_amount - payload
        }
      })
    case authActionTypes.SPEND_DIAMOND_BLUE:
      return syncStateToLocalStorage({
        ...state,
        user: {
          ...state.user,
          blue_amount: state.user.blue_amount <= payload ? 0 : state.user.blue_amount - payload,
          red_amount:
            state.user.blue_amount < payload
              ? state.user.blue_amount + state.user.red_amount - payload
              : state.user.red_amount
        }
      })
    case authActionTypes.SPEND_DIAMOND_RED_ADD_BLUE:
      return syncStateToLocalStorage({
        ...state,
        user: {
          ...state.user,
          red_amount: state.user.red_amount - payload,
          blue_amount: state.user.blue_amount < payload && 0
        }
      })
    default:
      return syncStateToLocalStorage(state)
  }
}

export { authReducer }
