import React, { useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import mascotChara from '../../assets/mascot_chara.png'
import ibg from 'src/assets/img/common/bar-bg.png'
import add from 'src/assets/img/common/add.png'
import diamonRed from '../../../../assets/img/common/diamond-red.png'
import btnCancel from '../../assets/btn_cancel.png'
import btnShop from '../../assets/btn_shop.png'

const LiveRedDiamond = ({ handleClick }) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const { user } = useAuthState()
  const navigate = useNavigate()

  return (
    <>
      <div className="relative z-0">
        <img src={ibg} className="w-[101px] h-4" />
        <img src={diamonRed} className="absolute w-6 -top-1 -left-1" />
        <img
          src={add}
          onClick={() => setConfirmModal(true)}
          className="absolute right-2 top-[2px] w-3 cursor-pointer z-10"
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 grid content-center text-sm text-center text-white">
          {user?.red_amount}
        </div>
      </div>

      <ModalWithBg
        title="ダイヤ購入"
        onClose={() => setConfirmModal(false)} open={confirmModal}>
        <div className="text-[12px] space-y-4">
          <div className="text-[12.7px]">
            <p>ショップページへ移動します。</p>
            <p>LIVE配信のページを離れても、<span className="text-[#fe05ff]">配信中の</span></p>
            <p className="text-[#fe05ff]">カウントダウンタイマーは一時停止しません。</p>
          </div>
          <div className="text-[12px]">
            <p className="whitespace-pre-line text-[#ff0000]">
              {'※Extraタイム中は特に注意が必要だコケッ！\n' +
                'ショップページから赤ダイヤをご購入し、\n' +
                'LIVE配信ページへ戻ってくる間にカウントダウン\n' +
                'タイマーの残り時間が0秒になった場合、\n' +
                '再度LIVE配信ページに入場する事が出来なくなるコケッ'}
            </p>
          </div>

          <p className="text-right">
            <img className="inline w-[80px] h-[80px]" src={mascotChara} />
          </p>

          <div className="grid grid-cols-2 justify-center gap-4">
            <img src={btnCancel} className="inline w-[119px] h-[49px]" onClick={() => setConfirmModal(false)} />
            <img src={btnShop} className="inline w-[119px] h-[49px]" onClick={() => navigate('/shop')} />
          </div>
        </div>
      </ModalWithBg>
    </>
  )
}

LiveRedDiamond.propTypes = {
  handleClick: PropTypes.func
}

export default LiveRedDiamond
