import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import mascotChara from '../../assets/mascot_chara.png'
import btnCancel from '../../assets/btn_cancel.png'
import btnShop from '../../assets/btn_shop.png'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'

const InsufficientDiamondModal = ({ open, onClose }) => {
  const navigate = useNavigate()

  return (
    <ModalWithBg title="ダイヤ不足" onClose={onClose} open={open}>
      <div className="text-[12px] space-y-4">
        <div className="text-[12.7px]">
          <p className="whitespace-pre-line">
            {'アイテムの交換に必要な赤ダイヤが\n' +
              '不足しています。\n' +
              'ショップページへ移動しますか？'
            }
          </p>
          <p>LIVE配信のページを離れても、<span className="text-[#fe05ff]">配信中の</span></p>
          <p className="text-[#fe05ff]">カウントダウンタイマーは一時停止しません。</p>
        </div>
        <div className="text-[12px]">
          <p className="whitespace-pre-line text-[#ff0000]">
            {'※Extraタイム中は特に注意が必要だコケッ！\n' +
              'ショップページから赤ダイヤをご購入し、\n' +
              'LIVE配信ページへ戻ってくる間にカウントダウン\n' +
              'タイマーの残り時間が0秒になった場合、\n' +
              '再度LIVE配信ページに入場する事が出来なくなるコケッ'}
          </p>
        </div>

        <p className="text-right">
          <img className="inline w-[80px] h-[80px]" src={mascotChara} />
        </p>

        <div className="grid grid-cols-2 justify-center gap-4">
          <img src={btnCancel} className="inline w-[119px] h-[49px]" onClick={onClose} />
          <img src={btnShop} className="inline w-[119px] h-[49px]" onClick={() => navigate('/shop')} />
        </div>
      </div>
    </ModalWithBg>
  )
}

InsufficientDiamondModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export { InsufficientDiamondModal }
