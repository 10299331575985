import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getHistoryManga = async (id) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Character.history.replace(':characterId', id))
    if (res.data.success) {
      return res.data
    }
    return []
  } catch (error) {
    return false
  }
}
