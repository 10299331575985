import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const postUserItemAnalytics = async (userId, payload) => {
  if (!userId) return false
  try {
    const res = await axiosInstance.post(`${API_ROUTES.Users}/${userId}${API_ROUTES.UserItemAnalytics}`, payload)
    if (!res.data.success) return false
    return true
  } catch (error) {
    return false
  }
}

export default postUserItemAnalytics
