// @ts-nocheck
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect, useMemo, useCallback } from 'react'
import styled from 'styled-components'
import { useAuthState } from 'src/providers/AuthProviders'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useConversation } from 'src/pages/main/Message/hooks/useConversation'
import { Header } from 'src/components/message/chat/Header'
import { SlideButton } from 'src/components/common/SlideButton'
import LevelProgress from 'src/components/common/LevelProgress'
import { BtnShowChatBox } from 'src/components/message/BtnShowChatBox'
import { isNavigator } from 'src/lib/character'
import { Modal } from 'src/components/common/Modal'
import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import BtnErase from 'src/assets/img/message/detail/eraser-btn.png'
import ExpandChat from 'src/assets/img/message/detail/expand-chat.png'
import ZoomoutChat from 'src/assets/img/message/detail/zoom-out-chat.png'
import GrowthBG from 'src/assets/img/mypage/growth/growth-button-bg.png'
import GrowthBtn from 'src/assets/img/message/detail/growth-btn.png'
import GrowthBtnExpend from 'src/assets/img/mypage/growth/growth-btn-expend.png'
import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'
import BackBtn from 'src/assets/img/mini_game/btn/btn_back.png'
import PlayBtn from 'src/assets/img/mini_game/btn/btn_play.png'
import MiniGameTitle from 'src/assets/img/mini_game/title/tittle_minigame.png'
import ModalBg from 'src/assets/img/mini_game/daishi.png'
import MiniGameIcon from 'src/assets/img/mini_game/btn/btn_game.png'
import BackGroundDefault from 'src/assets/img/common/bg_common.jpg'
import BtnClose from 'src/assets/img/common/btn-close.png'
import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'

import { MAIN_PATHS } from 'src/routes/main'
import { ItemChatRealTalk } from './components/ItemChatRealTalk'
import { ItemChatClient } from './components/ItemChatClient'

import { InputRealTalk } from './components/InputRealTalk'
import MenubarRealTalk from './components/MenubarRealTalk'
import { MYPAGE_CHARACTER_IMAGES } from 'src/constants/characters'
import StandardizedButton from 'src/components/common/StandardizedButton'
import BtnCheckBack from 'src/assets/img/realtalk/btn_check.png'
import BackGroundWattingOne from 'src/assets/img/realtalk/wating_back_1.png'
import BackGroundWattingTwo from 'src/assets/img/realtalk/wating_back_2.png'
import { postBackGround } from 'src/services/post/postBackGround'
import TipsModal from 'src/components/common/TipsModal'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'
import { useLoadingMore } from 'src/services/hooks/useLoadingMore'
import LoadingIcon from 'src/components/layouts/LoadingIcon'
import { getConversationItem } from 'src/services/get/getChat'

const RealTalkDetail = () => {
  const [isOpen, setIsOpen] = useState(false)
  const [hidden, setHidden] = useState(false)
  const [isExpand, setIsExpand] = useState(false)
  const [bgImage, setBgImage] = useState('')
  const [sizeInput, setSizeInput] = useState(0)
  const [isOpenGameModal, setIsOpenGameModal] = useState(false)
  const { user } = useAuthState()
  const roomChatRef = useRef()
  const lastScrollHeight = useRef(0)
  const {
    data: conversationData,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    refetch,
    addNewMessageToCache,
    updateMessageToCache
  } = useConversation('realtime_chat')
  const { characterId } = useParams()
  const navigate = useNavigate()
  const [character, setCharacter] = useState({})
  const [loveLvSettings, setLoveLvSettings] = useState({})
  const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
  const [multiLevel, setMultiLevel] = useState({})
  const [backgroundSetting, setBackgroundSetting] = useState({})
  const [present, setPresent] = useState(null)
  const [checkText, setCheckText] = useState(0)

  const [bgImagePreview, setBgImagePreview] = useState(bgImage)

  const [files, setFiles] = useState('')
  const [fileVideo, setFileVideo] = useState([])
  const [messages, setMessages] = useState([])
  const [indexBg, setIndexBg] = useState()
  const [scroll, setScroll] = useState(true)
  const [isOpenGift, setIsOpenGift] = useState(false)
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})
  const [conversationId, setConversationId] = useState()
  const [isDuplicate, setIsDuplicate] = useState(true)
  const [previousTestId, setPreviousTestId] = useState(null)
  const isMobileDevice = window.innerWidth <= 768

  const scrollToLast = () => {
    roomChatRef.current?.scrollTo({ top: roomChatRef.current?.scrollHeight - lastScrollHeight.current })
    lastScrollHeight.current = 0
    if (document.querySelector('#start')) {
      setIsDuplicate(false)
      // Handle additional state to avoid Intersection Observer calling the api twice on ios (safari)
    }
  }

  const location = useLocation()
  const currentURL = location.pathname
  localStorage.setItem('partName', currentURL)

  useEffect(() => {
    if (!conversationData) return

    setMessages(conversationData.pages.map(page => page.messages).flat())
    const firstPage = conversationData.pages.find(page => page.first_page)

    if (firstPage) {
      setCharacter(firstPage.character)
    }

    setConversationId(firstPage.conversation_id)

    setBgImage(
      conversationData?.background_type === 0 ? BackGroundDefault : backgroundSetting?.room
    )

    setLoveLvSettings(firstPage.character_setting_level)
  }, [conversationData])

  useEffect(() => {
    conversationData && refetch()
  }, [])

  useLoadingMore({
    selector: '#start',
    skip: !hasNextPage,
    onLoadMore: () => {
      if (isDuplicate) return
      setIsDuplicate(true)
      setTimeout(() => {
        fetchNextPage()
        lastScrollHeight.current = roomChatRef.current?.scrollHeight + 100 ?? 0
      })
    }
  })

  useEffect(() => {
    if (scroll && checkClickIcon) {
      scrollToLast()
    } else {
      setScroll(true)
    }
  }, [messages])

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  const handleClose = useCallback(() => {
    setIsOpenGameModal(false)
  }, [isOpenGameModal])

  const closeMultiLoveModal = useCallback(() => {
    setIsMultiLoveModalOpen(false)
    refetch()
  }, [setIsMultiLoveModalOpen])

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
      setIsOpenGift(true)
    }
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    } else {
      setIsOpenGift(true)
    }
  }

  const handleOpenGift = async (type, msg) => {
    setCheckText(type === 'character_cloth' ? 1 : 0)
    const giftId = type === 'character_cloth' ? msg.character_cloth_id : msg.setting_item_id
    const giftAmount = type === 'character_cloth' ? msg.number_of_avatar : msg.setting_item_amount
    if (
      (type === 'character_cloth' && !msg.is_received_cloth) ||
      (type === 'setting_item' && !msg.is_received_present)
    ) {
      const res = await getConversationItem(msg.character_chat_id, type, giftId, giftAmount)
      if (res) {
        if (res.characterLevel) {
          setCharacter({ ...character, level: res.characterLevel })
          setLoveLvSettings({ ...loveLvSettings, level: res.characterLevel })
        }
        if (!res.data.items) return
        setPresent(res.data.items)
        if (!res.characterLevels) return
        handleSetModalList(res.characterLevels)
        if (type === 'character_cloth') {
          msg.is_received_cloth = true
        } else if (type === 'setting_item') {
          msg.is_received_present = true
        }
      } else {
        console.log('プレゼントは受け取り済みです')
      }
    }
  }

  const lastMsgIsOwner = useMemo(() => {
    const copyMsg = [...messages]
    return copyMsg?.reverse().find((message) => message)?.is_owner
  }, [messages])

  const handleUpdateMessage = (msg) => {
    const copyMessages = [...messages]
    const index = copyMessages.findIndex(
      (message) => msg.character_chat_id === message.character_chat_id
    )
    if (index >= 0) {
      copyMessages[index] = msg
      setMessages(copyMessages)
      updateMessageToCache(msg)
    }
  }

  useEffect(() => {
    setBackgroundSetting(MYPAGE_CHARACTER_IMAGES[characterId])
  }, [characterId])

  const [checkClickIcon, setCheckClickIcon] = useState(true)
  const [checkClickIconSetting, setCheckClickIconSetting] = useState(false)
  const [checkClickIconBg, setCheckClickIconBg] = useState(true)
  const [isClickIconBg, setIsClickIconBg] = useState(false)
  const [checkTitle, setCheckTitle] = useState('background_setting')
  const [isPreview, setIspreview] = useState(false)

  const backgroundDef = {
    image: '',
    room: BackGroundDefault
  }

  const listBackGround = [backgroundDef, backgroundSetting]
  const [checkCharacter, setCheckCharacter] = useState('')

  const handleBackGround = (item, index) => {
    setIndexBg(index)
    setBgImagePreview(item.room)
    setCheckTitle('background_setting_preview')
    setCheckClickIcon(false)
    setIsClickIconBg(false)
    setIspreview(true)
    setCheckCharacter(item.room)
  }

  useEffect(() => {
    if (!files) {
      setCheckClickIcon(true)
    } else {
      setCheckClickIcon(false)
    }
  }, [files])

  return (
    <>
      {isPreview ? (
        <Container chatBgImage={bgImagePreview}>
          {checkClickIconSetting && (
            <MenubarRealTalk
              title={checkTitle}
              setCheckClickIcon={setCheckClickIcon}
              setCheckClickIconBg={setCheckClickIconBg}
              setIsClickIconBg={setIsClickIconBg}
              setCheckTitle={setCheckTitle}
              setCheckClickIconSetting={setCheckClickIconSetting}
              setIspreview={setIspreview}
              handleBackGroundBack={() => {
                setBgImage(BackGroundDefault)
              }}
            />
          )}
          {checkClickIcon && (
            <>
              <div className="fixed w-screen sm:w-desktop-width top-0 mx-auto sm:left-1/2 sm:-translate-x-1/2 z-50">
                <Header back={MAIN_PATHS.RealTalkPage} />
              </div>
              {conversationData && !isNavigator(character) && lastMsgIsOwner && (
                <img
                  src={MiniGameIcon}
                  alt="ミニゲームアイコン"
                  className="absolute top-[19%] right-[3%] w-[48px] h-[48px] cursor-pointer z-50"
                  onClick={() => setIsOpenGameModal(true)}
                />
              )}
              {conversationData && !isNavigator(character) && !isExpand && (
                <SliderStatus>
                  <div onClick={() => setIsOpen(!isOpen)} className="h-[40px] overflow-hidden">
                    <img
                      className="h-[100%]"
                      src={isOpen ? GrowthBtnExpend : GrowthBtn}
                      alt="growButton"
                    />
                  </div>
                  <SlideButton visible={isOpen} className="w-[180px]">
                    <div className="flex items-center justify-between h-[50px] overflow-hidden">
                      <div className="btn h-[100%] w-[35%]">
                        <img className="h-[100%]" src={GrowthBG} alt="growButton" />
                      </div>
                      <div className="status w-[65%] bg-sliderstatus px-3 pt-1 pb-2 bg-no-repeat bg-cover">
                        <LevelProgress size="md" level={character?.level} />
                      </div>
                    </div>
                  </SlideButton>
                </SliderStatus>
              )}
            </>
          )}
          {checkClickIconBg && (
            <div className={'pt-[26px] absolute'} style={{ bottom: `${sizeInput}px` }}>
              <WrapperChatBox isExpand={isExpand} sizeInput={sizeInput} ref={roomChatRef}>
                <>
                  {messages.map((message, i) => (
                    <div key={message.message_id || message.character_chat_id}>
                      {message?.is_owner ? (
                        <ItemChatClient data={message} />
                      ) : (
                        <ItemChatRealTalk
                          msg={message}
                          loveLvSettings={loveLvSettings}
                          character={character}
                          // changeBgImage={changeBgImage}
                          isScrollOpenTalk={() => setScroll(false)}
                          handleUpdateMessage={handleUpdateMessage}
                          indexMes={i}
                          handleOpenGift={handleOpenGift}
                        />
                      )}
                      <br />
                    </div>
                  ))}
                </>
                <br />
              </WrapperChatBox>
            </div>
          )}
          {isPreview && (
            <div className={'pt-[26px] absolute z-1000'} style={{ bottom: `${sizeInput}px` }}>
              <WrapperChatBox isExpand={isExpand} sizeInput={sizeInput} ref={roomChatRef}>
                <>
                  {messages.map((message, i) => (
                    <div key={message.message_id || message.character_chat_id}>
                      {message?.is_owner ? (
                        <ItemChatClient data={message} />
                      ) : (
                        <ItemChatRealTalk
                          msg={message}
                          loveLvSettings={loveLvSettings}
                          character={character}
                          // changeBgImage={changeBgImage}
                          isScrollOpenTalk={() => setScroll(false)}
                          handleUpdateMessage={handleUpdateMessage}
                          indexMes={i}
                          handleOpenGift={handleOpenGift}
                        />
                      )}
                      <br />
                    </div>
                  ))}
                  {checkCharacter !== BackGroundDefault && (
                    <CharacterImage>
                      <img
                        className="w-[327px] h-[860px]"
                        src={backgroundSetting.image}
                        alt="キャラクター画像"
                      />
                    </CharacterImage>
                  )}
                </>
                <br />
              </WrapperChatBox>
              <div className="fixed bottom-0 mx-auto z-10 w-screen sm:w-desktop-width bg-[#DAEBFF]">
                <div className="flex w-full justify-around mt-5 mb-[30px]">
                  <StandardizedButton
                    className="w-28"
                    img={BtnCancel}
                    alt="キャンセル"
                    onClick={() => {
                      setIspreview(false)
                      setIsClickIconBg(true)
                      setCheckClickIcon(false)
                      setCheckTitle('background_setting')
                      setBgImage(conversationData?.background_type)
                    }}
                  />
                  <StandardizedButton
                    className="w-28"
                    img={BtnConfirm}
                    alt="決定"
                    onClick={async () => {
                      setCheckTitle('background_setting')
                      setCheckClickIconSetting(false)
                      setIspreview(false)
                      setCheckClickIcon(true)
                      setCheckClickIconBg(true)
                      await postBackGround({
                        character_id: characterId,
                        user_id: user.id,
                        background_type: indexBg
                      })
                      refetch()
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="absolute bottom-[11px] right-[16px]" onClick={() => setHidden(false)}>
            {hidden ? <BtnShowChatBox /> : null}
          </div>
          <Modal
            open={isOpenGameModal}
            onClose={handleClose}
            className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center"
          >
            <ModalContainer>
              <ModalHeader>
                <img src={MiniGameTitle} alt="minigame_title" className="" />
              </ModalHeader>
              <img
                src={MascotChara}
                alt="mascot_chara"
                className="block w-[56px] h-[48px] absolute right-6 top-[30%]"
              />
              <ModalBody>
                <p>彼からのお返事が届く間のみ、</p>
                <p>ミニゲームを遊んでハートを</p>
                <p>GETする事ができるョ！</p>
                <p className="mb-5">お返事が届いた時点で強制終了だコケッ！</p>
                <p>※ゲームは何回でも出来るけど</p>
                <p>ハートが貰えるのは1日</p>
                <p>（am5時〜am5時）の間で3回までだョ！</p>
              </ModalBody>
              <ModalFooter>
                <img
                  src={BackBtn}
                  onClick={() => setIsOpenGameModal(false)}
                  alt="back_btn"
                  className="w-[116px] mr-4"
                />
                <img
                  src={PlayBtn}
                  onClick={() => navigate(`/mini-game/${characterId}`)}
                  alt="play_btn"
                  className="w-[116px]"
                />
              </ModalFooter>
            </ModalContainer>
          </Modal>
          <MultiLoveEffectModal
            character={character}
            isMultiLoveModalOpen={isMultiLoveModalOpen}
            multiLevel={multiLevel}
            onClose={closeMultiLoveModal}
          />
          {currentModal && currentModal.id && (
            <GrowEffectModal
              character={currentModal}
              isGrowModalOpen={isGrowModalOpen}
              level={{
                currentLevel: currentModal.current_level,
                nextLevel: currentModal.next_level
              }}
              onClose={closeGrowModal}
            />
          )}
          <TipsModal type="realtalk" isDetail={true} />
        </Container>
      ) : (
        <Container
          chatBgImage={
            conversationData?.background_type === 1 ? backgroundSetting?.room : BackGroundDefault
          }
          isMobileDevice={isMobileDevice}
        >
          {checkClickIconSetting && (
            <MenubarRealTalk
              title={checkTitle}
              setCheckClickIcon={setCheckClickIcon}
              setCheckClickIconBg={setCheckClickIconBg}
              setIsClickIconBg={setIsClickIconBg}
              setCheckTitle={setCheckTitle}
              setCheckClickIconSetting={setCheckClickIconSetting}
              setIspreview={setIspreview}
            />
          )}
          {checkClickIcon && (
            <>
              <div className="fixed w-screen sm:w-desktop-width top-0 mx-auto sm:left-1/2 sm:-translate-x-1/2 z-50">
                <Header back={MAIN_PATHS.RealTalkPage} />
              </div>
              {conversationData && !isNavigator(character) && lastMsgIsOwner && (
                <img
                  src={MiniGameIcon}
                  alt="ミニゲームアイコン"
                  className="absolute top-[19%] right-[3%] w-[48px] h-[48px] cursor-pointer z-50"
                  onClick={() => setIsOpenGameModal(true)}
                />
              )}
              {conversationData && !isNavigator(character) && !isExpand && (
                <SliderStatus>
                  <div onClick={() => setIsOpen(!isOpen)} className="h-[40px] overflow-hidden">
                    <img
                      className="h-[100%]"
                      src={isOpen ? GrowthBtnExpend : GrowthBtn}
                      alt="growButton"
                    />
                  </div>
                  <SlideButton visible={isOpen} className="w-[180px]">
                    <div className="flex items-center justify-between h-[50px] overflow-hidden">
                      <div className="btn h-[100%] w-[35%]">
                        <img className="h-[100%]" src={GrowthBG} alt="growButton" />
                      </div>
                      <div className="status w-[65%] bg-sliderstatus px-3 pt-1 pb-2 bg-no-repeat bg-cover">
                        <LevelProgress size="md" level={character?.level} />
                      </div>
                    </div>
                  </SlideButton>
                </SliderStatus>
              )}
            </>
          )}
          {isClickIconBg && (
            <div className="absolute mt-20 overflow-auto h-[650px]">
              <div className="grid grid-rows-3 grid-cols-3 overflow-y-scroll">
                {listBackGround.map((item, index) => (
                  <>
                    <div
                      className="relative overflow-hidden w-full"
                      onClick={() => handleBackGround(item, index)}
                    >
                      <img src={item.room} className="h-full w-full" />
                      {index === 1 && (
                        <img
                          src={item.image}
                          className="absolute w-[120px] top-[18%] left-[-7%] object-cover h-auto"
                        />
                      )}
                      {conversationData?.background_type === index && (
                        <img
                          src={BtnCheckBack}
                          className="absolute left-[80%] top-[10px] w-[20px]"
                        />
                      )}
                    </div>
                  </>
                ))}
                <div>
                  <img className="h-full w-full" src={BackGroundWattingTwo} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingOne} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingTwo} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingOne} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingTwo} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingOne} />
                </div>
                <div>
                  <img className="h-full w-full" src={BackGroundWattingTwo} />
                </div>
              </div>
            </div>
          )}

          {checkClickIconBg && (
            <div className={'pt-[26px] absolute z-100'} style={{ bottom: `${sizeInput}px` }}>
              <WrapperChatBox isExpand={isExpand} sizeInput={sizeInput} ref={roomChatRef}>
                <>
                  {isFetching ? <LoadingIcon /> : <div id="start" />}
                  {messages.map((message, i) => (
                    <div key={message.message_id || message.character_chat_id}>
                      {message?.is_owner ? (
                        <ItemChatClient data={message} />
                      ) : (
                        <ItemChatRealTalk
                          msg={message}
                          loveLvSettings={loveLvSettings}
                          character={character}
                          isScrollOpenTalk={() => setScroll(false)}
                          handleUpdateMessage={handleUpdateMessage}
                          indexMes={i}
                          handleOpenGift={handleOpenGift}
                        />
                      )}
                      <br />
                    </div>
                  ))}
                  {conversationData?.background_type === 1 && (
                    <CharacterImageBg>
                      <img
                        className="w-[327px]"
                        src={backgroundSetting.image}
                        alt="キャラクター画像"
                      />
                    </CharacterImageBg>
                  )}
                </>
                <br />
              </WrapperChatBox>
              <div className="fixed bottom-0 mx-auto z-10 w-screen sm:w-desktop-width">
                <InputRealTalk
                  setCheckClickIcon={setCheckClickIcon}
                  setCheckClickIconBg={setCheckClickIconBg}
                  setIsClickIconBg={setIsClickIconBg}
                  setCheckClickIconSetting={setCheckClickIconSetting}
                  senderId={user?.id}
                  conversationId={conversationId}
                  handleNewMessages={(msg, checkStatus) => {
                    const idCurrent = msg?.item?.item_id
                    if (
                      (msg && msg.description && msg.description !== '') ||
                      (msg && msg.attachment_url && msg.attachment_url !== '') ||
                      (msg && msg.item && idCurrent !== previousTestId)
                    ) {
                      setMessages([...messages, msg])
                      addNewMessageToCache(msg)
                    }
                    if (checkStatus === 'messageItem') {
                      setPreviousTestId(idCurrent)
                    }
                  }}
                  setSizeInput={setSizeInput}
                  characterId={Number(characterId)}
                  loveLvSettings={loveLvSettings}
                  character={character}
                  setFiles={setFiles}
                  files={files}
                  setFileVideo={setFileVideo}
                  fileVideo={fileVideo}
                  setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                  setMultiLevel={setMultiLevel}
                />
              </div>
            </div>
          )}
          <div className="absolute bottom-[11px] right-[16px]" onClick={() => setHidden(false)}>
            {hidden ? <BtnShowChatBox /> : null}
          </div>
          <Modal
            open={isOpenGameModal}
            onClose={handleClose}
            className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center"
          >
            <ModalContainer>
              <ModalHeader>
                <img src={MiniGameTitle} alt="minigame_title" className="" />
              </ModalHeader>
              <img
                src={MascotChara}
                alt="mascot_chara"
                className="block w-[56px] h-[48px] absolute right-6 top-[30%]"
              />
              <ModalBody>
                <p>彼からのお返事が届く間のみ、</p>
                <p>ミニゲームを遊んでハートを</p>
                <p>GETする事ができるョ！</p>
                <p className="mb-5">お返事が届いた時点で強制終了だコケッ！</p>
                <p>※ゲームは何回でも出来るけど</p>
                <p>ハートが貰えるのは1日</p>
                <p>(am5時～翌日am4時59分) の24時間の間で3回までだョ！</p>
              </ModalBody>
              <ModalFooter>
                <img
                  src={BackBtn}
                  onClick={() => setIsOpenGameModal(false)}
                  alt="back_btn"
                  className="w-[116px] mr-4"
                />
                <img
                  src={PlayBtn}
                  onClick={() => navigate(`/mini-game/${characterId}`)}
                  alt="play_btn"
                  className="w-[116px]"
                />
              </ModalFooter>
            </ModalContainer>
          </Modal>
          <MultiLoveEffectModal
            character={character}
            isMultiLoveModalOpen={isMultiLoveModalOpen}
            multiLevel={multiLevel}
            onClose={closeMultiLoveModal}
          />
          {currentModal && currentModal.id && (
            <GrowEffectModal
              character={currentModal}
              isGrowModalOpen={isGrowModalOpen}
              level={{
                currentLevel: currentModal.current_level,
                nextLevel: currentModal.next_level
              }}
              onClose={closeGrowModal}
            />
          )}
          <Modal
            open={isOpenGift}
            onClose={() => setIsOpenGift(false)}
            className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino text-[#333333]"
          >
            <div
              onClick={() => setIsOpenGift(false)}
              className="w-[50px] h-[44px] absolute -top-2 -right-2 z-50"
            >
              <img className="w-full h-full" src={BtnClose} alt="close" />
            </div>
            <GiftModalBody className="py-7">
              <ModalTitle>プレゼント受け取り</ModalTitle>
              <div className="h-[310px] overflow-y-scroll ">
                {present?.map((item, index) => (
                  <div key={index}>
                    <GiftBox>
                      {item && item?.gift_image && (
                        <div className="w-full h-full">
                          <img
                            className="aspect-square object-cover"
                            src={item?.gift_image}
                            alt="gift"
                          />
                        </div>
                      )}
                    </GiftBox>
                    <h5 className="font-vldLineGR mt-[3px] text-xl leading-[25px] text-center mb-4">
                      {item?.gift_name}
                    </h5>
                  </div>
                ))}
                {checkText === 1 ? (
                  <></>
                ) : (
                  <div className="font-light text-sm text-center leading-[21px] mt-2 mb-2">
                    メニュー＞アイテムから確認できます。
                  </div>
                )}
              </div>
            </GiftModalBody>
          </Modal>
          <TipsModal type="realtalk" isDetail={true} />
        </Container>
      )}
    </>
  )
}

const ModalContainer = styled.div.attrs({
  className: 'h-[400px] w-[320px] bg-fill bg-no-repeat'
})`
  background-image: url(${ModalBg});
`

const ModalHeader = styled.div.attrs({
  className: 'w-[85%] mx-auto mt-[44px]'
})``

const ModalBody = styled.div.attrs({
  className: 'mt-6 w-[72%] mx-auto text-center text-xs leading-4 font-light'
})``

const ModalFooter = styled.div.attrs({
  className: 'flex justify-center w-[85%] mx-auto mt-6 cursor-pointer'
})``

const Container = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-[100svh] mx-auto relative font-hiragino overflow-hidden bg-no-repeat bg-left-top bg-cover'
})`
  background-image: url(${(props) => props.chatBgImage || ''});
  position: ${(props) => props.isMobileDevice ? 'fixed' : ''};
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
`

const SliderStatus = styled.div.attrs({
  className: 'absolute cursor-pointer flex items-center justify-center z-50'
})`
  right: 0;
  top: 50px;
  height: fit-content;
`

const WrapperChatBox = styled.div.attrs({
  className: 'overflow-y-auto overflow-x-hidden'
})`
  height: ${(props) =>
    props.isExpand ? `calc(80svh - ${parseInt(props.sizeInput) + 'px'})` : '80svh'};
`

const CharacterImage = styled.div.attrs({
  className: 'absolute right-[10%] -bottom-[8%] h-full w-full'
})`
  @media (max-width: 350px) {
    left: -15%;
  }
`

const CharacterImageBg = styled.div.attrs({
  className: 'fixed top-[10%] right-[10%] h-full w-full z-0'
})`
  @media (max-width: 350px) {
    left: -15%;
  }
  @media (min-width: 464px) {
    position: absolute;
  }
`

const GiftModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;

  @media (max-width: 410px) {
    font-size: 1rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }

  @media (max-width: 316px) {
    font-size: 0.75rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
`

const GiftBox = styled.div.attrs({
  className: 'mx-auto my-6'
})`
  background-size: 100% 100%;
  width: 152px;
  height: 172px;
`
export default RealTalkDetail
