import BtnShowAll from '../../assets/img/message/btn-show-all-chat.png'
import styled from 'styled-components'
export const BtnShowChatBox = () => {
  return (
    <Wapper>
      <div>メッセージを再表示</div>
    </Wapper>
  )
}
const Wapper = styled.div.attrs({
  className:
    'flex justify-start w-full text-white leading-[28px] px-3.5 pt-0.5 pb-[5px] font-vldLineGR text-sm cursor-pointer'
})`
  background-image: url(${BtnShowAll});
  background-size: 100% 100%;
`
