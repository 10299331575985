import React from 'react'
import PropTypes from 'prop-types'

import ReturnBtn from '../assets/btn_return.png'
import { useNavigate } from 'react-router-dom'

const LayoutHeader = ({ isDuringDelivery, rightContent, botContent }) => {
  const navigate = useNavigate()
  const handleClick = () => {
    navigate(-1)
  }

  return (
    <div className="fixed top-0 w-full md:w-[400px] z-[2]">
      <div className="flex items-start justify-between w-full">
        <img src={ReturnBtn} className="mt-[8px]" onClick={() => handleClick()} />
        {rightContent}
      </div>

      {botContent}
    </div>
  )
}

LayoutHeader.propTypes = {
  isDuringDelivery: PropTypes.bool,
  rightContent: PropTypes.node,
  botContent: PropTypes.node
}
export { LayoutHeader }
