import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import getRankingEvent from 'src/services/get/getRankingEvent'
import getRankingEventDetails from 'src/services/get/getRankingEventDetails'
import getRewardInfo from 'src/services/get/getRewardInfo'

export const useRankingRewardEvents = () => {
  return useQuery({
    queryFn: getRankingEvent,
    queryKey: 'use-ranking-events'
  })
}

export const useRankingRewardEventDetails = () => {
  const { id } = useParams()
  return useQuery({
    queryFn: () => getRankingEventDetails(id),
    queryKey: ['use-ranking-event-details', id]
  })
}

export const useRankingRewardInfo = () => {
  return useQuery({
    queryFn: () => getRewardInfo(),
    queryKey: 'use-ranking-reward-info'
  })
}
