import { useContext, useEffect, useState } from 'react'
import { ActionCableContext } from 'src/providers/ActionCableProvider'

const useNewsListChannel = () => {
  const cable = useContext(ActionCableContext)
  const [newsMessage, setNewsMessage] = useState()

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create(
      { channel: 'NewsListChannel' },
      {
        received: (msg) => setNewsMessage(msg)
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [cable])

  return { newsMessage }
}

export default useNewsListChannel
