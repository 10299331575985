import moment from 'moment'

export const formatToDate = (activeKey, date) => {
  if (!date) return
  switch (activeKey) {
    case 'current_day':
      return `${date.year}年${date.month}月${date.date}日`
    case 'so_far':
      return `〜 ${date.year}年${date.month}月${date.date}日まで`
    default:
      return `${date.first.year}年${date.first.month}月${date.first.date}日から${date.last.month}月${date.last.date}日まで`
  }
}

export const getFirstAndLastDate = (type) => {
  if (type === 'current_month') {
    const firstDayOfMonth = moment().startOf('month')
    const lastDayOfMonth = moment().endOf('month')

    return {
      first: {
        date: firstDayOfMonth.date(),
        month: firstDayOfMonth.month() + 1,
        year: firstDayOfMonth.year()
      },
      last: {
        date: lastDayOfMonth.date(),
        month: lastDayOfMonth.month() + 1,
        year: lastDayOfMonth.year()
      }
    }
  } else if (type === 'previous_month') {
    const firstDayOfPreviousMonth = moment().subtract(1, 'months').startOf('month')
    const lastDayOfPreviousMonth = moment().subtract(1, 'months').endOf('month')

    return {
      first: {
        date: firstDayOfPreviousMonth.date(),
        month: firstDayOfPreviousMonth.month() + 1,
        year: firstDayOfPreviousMonth.year()
      },
      last: {
        date: lastDayOfPreviousMonth.date(),
        month: lastDayOfPreviousMonth.month() + 1,
        year: lastDayOfPreviousMonth.year()
      }
    }
  } else if (type === 'current_week') {
    const firstDayOfWeek = moment().startOf('isoWeek').isoWeekday(1)
    const lastDayOfWeek = moment().endOf('isoWeek').isoWeekday(7)

    return {
      first: {
        date: firstDayOfWeek.date(),
        month: firstDayOfWeek.month() + 1,
        year: firstDayOfWeek.year()
      },
      last: {
        date: lastDayOfWeek.date(),
        month: lastDayOfWeek.month() + 1,
        year: lastDayOfWeek.year()
      }
    }
  } else {
    return {
      date: moment().date(),
      month: moment().month() + 1,
      year: moment().year()
    }
  }
}
