import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { GrowthLevel, LoveLevel } from 'src/components/common/LevelProgress'
import { CHARACTERS } from 'src/constants/characters'
import { isNavigator } from 'src/lib/character'
import { removeNewlineCode } from 'src/lib/string'
import HTMLReactParser from 'html-react-parser'

import BGUnread from 'src/assets/img/message/bg-mess-count.png'
import AvatarDefault from 'src/assets/img/message/avatar-6.png'

const backgrounds = [
  'linear-gradient(180deg, #FF9899 0%, #FFD8AA 100%)',
  'linear-gradient(180deg, #D7D0FF 0%, #9883F3 100%)',
  'linear-gradient(0deg, #F4E8C9 0%, #FFEF60 100%)',
  'linear-gradient(0deg, #CDFFB1 0%, #A9FFE5 102.5%)',
  'linear-gradient(180deg, #FFA5B1 0%, #FFDDE1 100%)'
]

export const MessageItem = ({ data, idx }) => {
  const navigate = useNavigate()
  const { message, time, unreadTotal, character, level } = data
  const { name, avatar } = character

  const renderAvatar = () => {
    if (avatar) {
      return avatar
    }
    const character = CHARACTERS.find((item) => item.name === name)
    if (character) {
      return character.avatar
    } else {
      return AvatarDefault
    }
  }

  return (
    <div
      onClick={() => navigate(`/message/detail/${data?.character?.id}`)}
      className="cursor-pointer grid grid-cols-[84px_auto_48px] border-b border-[#A1D2FF] py-3 items-center font-hiragino text-[#333333]"
    >
      <div className="ml-3.5 flex items-end h-full">
        <div
          className="w-[60px] h-[60px] rounded-[50%] overflow-hidden"
          style={{
            background: backgrounds[idx] || 'transparent'
          }}
        >
          <img className="w-full h-full" src={renderAvatar()} alt="avatar" />
        </div>
      </div>
      <div>
        <div className="font-vldLineGR text-left text-base font-normal leading-[22px]">{name}</div>
        {!isNavigator(character) && (
          <div className="flex w-full ">
            <LoveLevel className="mr-3 w-[33%]" love={level?.love} />
            <GrowthLevel className="w-[33%]" grow={level?.grow} />
          </div>
        )}
        <div
          className="text-justify text-[9px] font-light leading-3 text-ellipsis overflow-hidden min-h-[24px] mt-1"
          style={{
            WebkitLineClamp: '2',
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical'
          }}
        >
          {HTMLReactParser(removeNewlineCode(message))}
        </div>
      </div>
      <div className="h-full text-[9px] pl-3 pr-2 pt-3 flex flex-col justify-between">
        <div className="leading-[14px] font-light">{time}</div>
        {unreadTotal ? (
          <Unread>
            {unreadTotal >= 100 ? (
              <span>
                99<sup className="-top-[0.65em] right-[0.15em] text-[10px] font-black">+</sup>
              </span>
            ) : (
              unreadTotal
            )}
          </Unread>
        ) : null}
      </div>
    </div>
  )
}

MessageItem.propTypes = {
  data: PropTypes.shape({
    channel_id: PropTypes.string,
    message: PropTypes.string,
    time: PropTypes.string,
    unreadTotal: PropTypes.number,
    character: PropTypes.shape({
      id: PropTypes.number,
      avatar: PropTypes.string,
      name: PropTypes.string,
      love_level: PropTypes.number,
      grow_level: PropTypes.number
    }),
    level: PropTypes.shape({
      love: PropTypes.shape({
        level: PropTypes.number.isRequired,
        total_items: PropTypes.number.isRequired,
        current_items: PropTypes.number.isRequired
      }).isRequired,
      grow: PropTypes.shape({
        level: PropTypes.number.isRequired,
        total_items: PropTypes.number.isRequired,
        current_items: PropTypes.number.isRequired
      }).isRequired
    })
  }),
  idx: PropTypes.number
}

const Unread = styled.div.attrs({
  className: 'text-white font-semibold leading-[14px] px-2 pb-[2px] pt-[9px] text-center'
})`
  background-image: url(${BGUnread});
  background-size: 100% 100%;
`
