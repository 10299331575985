import PropTypes from 'prop-types'
import ChickenLoader from 'src/assets/img/chicken-loader.png'
import styled, { keyframes } from 'styled-components'

const Loading = ({ type = 'processBar' }) => {
  return (
    <>
      {
        type === 'spinner' ? (
          <Spinner>
            {Array(12)
              .fill(null)
              .map((_, i) => (
                <Circle key={i} total={12} />
              ))}
            <TextSpin>&ensp;Loading...</TextSpin>
          </Spinner>
        ) : (
          <LoadingBack className='max-w-[212px]'>
            <div className="w-9/12 pt-2.5">
              <TextBar>読み込み中...</TextBar>
              <LoadingFront />
            </div>
            <div className="w-3/12 pt-1.5">
              <img src={ChickenLoader} className="w-12 rounded-full" />
            </div>
          </LoadingBack>
        )
      }
    </>
  )
}

export default Loading

Loading.propTypes = {
  type: PropTypes.oneOf(['spinner', 'processBar'])
}

const TextSpin = styled.p.attrs({
  className: 'text-sm font-light text-white absolute top-[54%]'
})``

const pulse = keyframes`
  0% {
    background: #fff8f9;
  }
  9% {
    background: #fff0f3;
  }
  18% {
    background: #ffe9ed;
  }
  27% {
    background: #ffe1e7;
  }
  36% {
    background: #ffd2db;
  }
  45% {
    background: #ffcbd5;
  }
  54% {
    background: #ffc4cf;
  }
  63% {
    background: #ffbcc9;
  }
  72% {
    background: #ffb4c3;
  }
  81% {
    background: #ffadbd;
  }
  90% {
    background: #ffa6b7;
  }
  100% {
    background: #ff9eb1;
  }
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`

const Circle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  animation: ${pulse} 1s ease-in-out infinite;
  animation-duration: 1.2s;
  position: absolute;
  top: 48%;
  &:nth-child(1) {
    animation-delay: 0s;
    transform: rotate(30deg) translate(-220%, -50%);
  }
  &:nth-child(2) {
    animation-delay: 0.1s;
    transform: rotate(60deg) translate(-220%, -50%);
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
    transform: rotate(90deg) translate(-220%, -50%);
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
    transform: rotate(120deg) translate(-220%, -50%);
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
    transform: rotate(150deg) translate(-220%, -50%);
  }
  &:nth-child(6) {
    animation-delay: 0.5s;
    transform: rotate(180deg) translate(-220%, -50%);
  }
  &:nth-child(7) {
    animation-delay: 0.6s;
    transform: rotate(210deg) translate(-220%, -50%);
  }
  &:nth-child(8) {
    animation-delay: 0.7s;
    transform: rotate(240deg) translate(-220%, -50%);
  }
  &:nth-child(9) {
    animation-delay: 0.8s;
    transform: rotate(270deg) translate(-220%, -50%);
  }
  &:nth-child(10) {
    animation-delay: 0.9s;
    transform: rotate(300deg) translate(-220%, -50%);
  }
  &:nth-child(11) {
    animation-delay: 1s;
    transform: rotate(330deg) translate(-220%, -50%);
  }
  &:nth-child(12) {
    animation-delay: 1.1s;
    transform: rotate(360deg) translate(-220%, -50%);
  }
`
const bar = keyframes`
  0% { transform: translateX(0%); }
  45% { transform: translateX(294%); }
  50% { transform: translateX(294%); }
  98% { transform: translateX(0%); }
  100% { transform: translateX(0%); }
`
const TextBar = styled.div.attrs({
  className: 'text-xs text-left ml-2 text-black font-[VDL-LineGR]'
})``

const LoadingBack = styled.div`
  display: flex;
  position: absolute;
  bottom: 20%;
  left: 0;
  width: 53%;
  height: 58px;
  background-color: white;
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
`

const LoadingFront = styled.div`
  display: block;
  width: 90%;
  height: 10px;
  background-image: linear-gradient(90deg, #ffadd4, #b5b5b5);
  position: relative;
  top: 5px;
  left: 6px;
  bottom: 20%;
  border-radius: 10px;
  transition: 0.5s ease-in-out;
  overflow: hidden;
  z-index: 9999;
  
  ::before {
    position: absolute;
    content: '';
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    left: -48%;
    width: 40%;
    height: 10px;
    background-color: #e31787;
    animation: ${bar} 3s ease-out infinite;
    border-radius: 50px;
  }
`
