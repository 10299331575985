import React, { useCallback } from 'react'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import ShopPaymentMethodPicker from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentMethodPicker'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import PropTypes from 'prop-types'

import BtnCareer from 'src/assets/img/shop/btn_career.png'
import BtnCredit from 'src/assets/img/shop/btn_credit.png'
import BtnPaypay from 'src/assets/img/shop/btn_paypay.png'
import BtnWebmoney from 'src/assets/img/shop/btn_webmoney.png'
import BtnAmazon from 'src/assets/img/shop/btn_amazon.png'
import BtnBitcash from 'src/assets/img/shop/btn_bitcash.png'
// TODO コメント箇所は今後実装する予定

const paymentMethodButtons = {
  career: BtnCareer,
  credit: BtnCredit,
  paypay: BtnPaypay,
  web_money: BtnWebmoney,
  amazon_pay: BtnAmazon,
  bitcash: BtnBitcash
  // amazon_pay: BtnAmazon
}

const ShopMethodSelectModal = (props) => {
  const { description } = props
  const { closeModal, selectedItem, setModalContent } = useShopContext()

  const handleSelect = useCallback(
    (methodName) => {
      if (!selectedItem?.listed_price) return

      // @ts-ignore
      setModalContent(<ShopPaymentMethodPicker method={methodName} description={description} />)
    },
    [selectedItem?.listed_price, description]
  )

  return (
    <div className="relative flex flex-col items-center justify-between w-full px-4 pt-8 pb-16 bg-no-repeat rounded-md bg-popup-l bg-fill font-hiragino">
      <ModalCloseButton handleClose={closeModal} />
      <TitleHeader title="支払い選択" />
      <div className="grid grid-cols-2 gap-2">
        {Object.keys(paymentMethodButtons).map((methodName) => (
          <button onClick={() => handleSelect(methodName)} key={methodName}>
            <img src={paymentMethodButtons[methodName]} />
          </button>
        ))}
      </div>
    </div>
  )
}

export default ShopMethodSelectModal

ShopMethodSelectModal.propTypes = {
  description: PropTypes.string
}
