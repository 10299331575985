import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getConditions = async (type) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Conditions, {
      params: {
        type
      }
    })
    if (res.status === 200) {
      return res.data.conditions
    }
    return []
  } catch (error) {
    return false
  }
}
