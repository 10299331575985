import Item from 'src/models/Item'
import axiosInstance from 'src/services/common/axiosInstance'

/**
 * Fetches the items in the inventory of the given player
 *
 * @param {number} id The ID representing the player whose inventory you wish to view
 * @returns An array of items the player currently possesses
 */
const getUserItems = async (id) => {
  if (!id) return []

  try {
    const res = await axiosInstance.get(`/users/${id}/items`)

    if (res.data.success === false) return []
    else {
      const items = []
      for (const i of res.data.items) {
        const item = new Item(
          i.id,
          i.name,
          i.image,
          i.description,
          i.expired_at,
          i.item_kind,
          i.item_value,
          i.rarity,
          i.increase_love,
          i.max_amount,
          i.type,
          i.amount
        )
        items.push(item)
      }
      return items
    }
  } catch (error) {
    return []
  }
}

export default getUserItems
