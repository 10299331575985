import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

const EventItem = (props) => {
  const { event } = props
  const getLinkTo = useMemo(
    () => (event.displayLink ? `/${event.link}` : `/events/${event.id}`),
    [event]
  )

  return (
    <Link to={getLinkTo}>
      <img className="w-full h-[88px] mb-4" src={event.image} alt={event.name} />
    </Link>
  )
}

EventItem.propTypes = {
  event: PropTypes.object.isRequired
}

export default EventItem
