import React from 'react'
import AccountPageContainer from 'src/pages/menu/AccountPageContainer'
import { HelpPage } from 'src/pages/menu/Help'
import InquiryDetailContainer from 'src/pages/menu/InquiryDetailContainer'
import InquiryPageContainer from 'src/pages/menu/InquiryPageContainer'
import ItemPageContainer from 'src/pages/menu/ItemPageContainer'
import { Menu } from 'src/pages/menu/Menu'
import MenuTermOfServicePage from 'src/pages/menu/MenuTermsOfService'
import { PrivacyPolicyPage } from 'src/pages/menu/PrivacyPolicy'
import { SpecifiedCommercialPage } from 'src/pages/menu/SpecifiedCommercial'
import UserProfilePageContainer from 'src/pages/menu/UserProfilePageContainer'

const MENU_PATHS = {
  AccountPage: '/account',
  HelpPage: '/help',
  InquiryPage: '/inquiry',
  InquiryDetailPage: '/inquiry/:id',
  ItemPage: '/item',
  PrivacyPolicyPage: '/privacy-policy',
  SpecifiedCommercialPage: '/specified-commercial',
  UserProfilePage: '/profile',
  Menu: '/menu',
  TermsOfServicePage: '/menu/terms-of-service'
}
const MenuRouter = [
  { path: MENU_PATHS.AccountPage, element: <AccountPageContainer /> },
  { path: MENU_PATHS.HelpPage, element: <HelpPage /> },
  { path: MENU_PATHS.InquiryPage, element: <InquiryPageContainer /> },
  { path: MENU_PATHS.InquiryDetailPage, element: <InquiryDetailContainer /> },
  { path: MENU_PATHS.ItemPage, element: <ItemPageContainer /> },
  { path: MENU_PATHS.PrivacyPolicyPage, element: <PrivacyPolicyPage /> },
  { path: MENU_PATHS.SpecifiedCommercialPage, element: <SpecifiedCommercialPage /> },
  { path: MENU_PATHS.UserProfilePage, element: <UserProfilePageContainer /> },
  { path: MENU_PATHS.Menu, element: <Menu /> },
  { path: MENU_PATHS.TermsOfServicePage, element: <MenuTermOfServicePage /> }
]

export { MenuRouter, MENU_PATHS }
