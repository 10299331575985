import axiosInstanceV2 from 'src/services/common/axiosInstanceV2'
import { API_ROUTES } from 'src/services/common/routes'

export const saveMessage = async (id, senderId, characterId, content, uuid) => {
  try {
    const res = await axiosInstanceV2.put(API_ROUTES.Chat.saveMessage.replace('{id}', id), {
      message: {
        sender_id: senderId,
        character_id: characterId,
        content,
        uuid: uuid
      }
    })
    const data = res.data
    return {
      currentLevel: data.current_level,
      nextLevel: data.next_level,
      mess: data.mess,
      uuid: data.uuid
    }
  } catch (error) {
    const payload = {
      sender_id: senderId,
      character_id: characterId,
      content,
      uuid: uuid
    }
    localStorage.setItem('payload', JSON.stringify(payload))
    return { item: false, level: false, mess: false, isOpenResend: error.status !== 500 }
  }
}

export const saveMessageItem = async (id, senderId, characterId, amount, idItem, uuid) => {
  try {
    const res = await axiosInstanceV2.put(API_ROUTES.Chat.saveMessage.replace('{id}', id), {
      message: {
        sender_id: senderId,
        character_id: characterId,
        type_send: 'item',
        amount: amount,
        item_id: idItem,
        uuid: uuid
      }
    })
    const data = res.data
    return {
      currentLevel: data.current_level,
      nextLevel: data.next_level,
      mess: data.mess,
      uuid: data.uuid
    }
  } catch (error) {
    const payload = {
      sender_id: senderId,
      character_id: characterId,
      type_send: 'item',
      amount: amount,
      item_id: idItem,
      uuid: uuid
    }
    localStorage.setItem('payload', JSON.stringify(payload))
    return { item: false, level: false, mess: false, isOpenResend: error.status !== 500 }
  }
}

export const saveMessageImg = async (id, formData, uuid, selectEvent, setitemitem) => {
  try {
    const res = await axiosInstanceV2.put(API_ROUTES.Chat.saveMessage.replace('{id}', id), formData, {
      params: {
        uuid: uuid
      }
    })
    const data = res.data
    return {
      currentLevel: data.current_level,
      nextLevel: data.next_level,
      mess: data.mess,
      uuid: data.uuid
    }
  } catch (error) {
    setitemitem(selectEvent)
    const payload = {
      uuid: uuid
    }
    localStorage.setItem('payload', JSON.stringify(payload))
    return { item: false, level: false, mess: false, isOpenResend: error.status !== 500 }
  }
}
