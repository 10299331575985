import React, { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { NEW_USER_PATHS } from 'src/routes/newUser'
import { DatePicker } from 'src/components/DatePicker'
import { ErrorMessage } from 'src/components/ErrorMessage'
import registerUser from 'src/services/post/registerUser'
import styled from 'styled-components'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { searchUserAgent } from 'src/constants/userAgent'
import { countCharacters } from 'src/lib/validation'

import BGTitle from 'src/assets/img/common/bg-title.png'
import BGCommon from 'src/assets/img/register/bg-common.png'
import BGSubmit from 'src/assets/img/register/btn_register.png'
import Icon from 'src/assets/img/register/wing.png'

export const RegistUserPage = () => {
  const { user } = useAuthState()
  const [name, setName] = useState(user.name)
  const [birthday, setBirthday] = useState(new Date())
  const [errors, setErrors] = useState({})
  const authDispatch = useAuthDispatch()
  const [isSubmitting, setIsSubmitting] = useState(true)

  const navigate = useNavigate()

  const handleSubmit = async () => {
    setIsSubmitting(false)
    if (errors && Object.keys(errors).length > 0 && !name) return
    const params = {
      name,
      birthday: birthday.toLocaleDateString('ja-JP'),
      is_active: true,
      device: searchUserAgent(),
      id_token: localStorage.getItem('idTokenRegister'),
      access_token: localStorage.getItem('accessTokenRegister')
    }
    try {
      const res = await registerUser(params)
      navigate(NEW_USER_PATHS.TermOfServicePage)
      authDispatch({
        type: authActionTypes.LOGIN_USER,
        payload: res.items
      })
      localStorage.removeItem('idTokenRegister')
      localStorage.removeItem('accessTokenRegister')
      authDispatch({
        type: authActionTypes.ACTIVE_USER,
        payload: params
      })
    } catch (error) {
      setIsSubmitting(true)
      setErrors(error?.details || {})
    }
  }

  const onChangeName = useCallback(
    (value) => {
      if (countCharacters(value) <= 16) {
        setName(value)
        setErrors({})
      } else {
        setErrors({ ...errors, name: ['全角8文字以内/半角16文字以内で記入してください。'] })
      }
    },
    [setName, setErrors]
  )

  return (
    <Wrapper>
      <div className="absolute w-11/12 bg-white top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 font-hiragino overflow-y-auto max-h-[95%]">
        <Title>
          <h3 className="text-xl font-vldLineGR text-white text-center font-normal leading-[25px]">
            プロフィール設定
          </h3>
        </Title>
        <form className="px-8">
          <div className="mb-7">
            <div className="flex items-start mb-[8px]">
              <img src={Icon} className="mr-[7px]" />
              <p className="text-normal leading-[21px] font-light">
                名前{' '}
                <span className="ml-1 text-xs leading-[18px] text-[#000000]">
                  (彼に呼ばれたい名前を入力しよう！)
                </span>
              </p>
            </div>
            <input
              className="text-normal placeholder-[#717171] border-[#A2D2FF] appearance-none border text-left font-light rounded w-full py-3 px-4 text-[#000000] leading-[21px] focus:outline-none focus:shadow-outline"
              id="username"
              value={name}
              onChange={(e) => onChangeName(e.target.value)}
              type="text"
              placeholder="名前を入力してください"
            />
            <ErrorMessage errors={errors} field="name" />
            <div className="text-left text-xs mt-2 font-light leading-[18px]">
              全角8文字以内/半角16文字以内
            </div>
          </div>
          <div className="mb-5">
            <div className="flex items-start mb-[8px]">
              <img src={Icon} className="mr-[7px]" />
              <p className="text-normal leading-[21px] font-light">生年月日</p>
            </div>
            <DatePicker initDate={birthday.toDateString()} handleChange={setBirthday} />
            <div className="mt-2 text-xs font-light text-left">
              <p className="text-[#333333] leading-[18px] mb-[#3px]">
                誕生日を登録しておくと、
                <br />
                彼から素敵なお祝いメッセージが届くかも？！
              </p>
              <p className="text-[#FF0000] leading-[18px]">※生年月日は後から変更できません。</p>
            </div>
          </div>
          <div
            className={` mx-auto w-[180px] cursor-pointer mb-40 ${
              name && !errors?.name ? '' : 'opacity-50'
            }`}
            style={{ cursor: isSubmitting ? 'auto' : 'wait' }}
            onClick={isSubmitting ? handleSubmit : undefined}
            disabled={!name}
          >
            <img src={BGSubmit} />
          </div>
        </form>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative bg-no-repeat bg-cover bg-center'
})`
  background-image: url('${BGCommon}');
`

const Title = styled.div.attrs({
  className: 'py-2 px-[1.9rem] w-fit mx-auto mt-6 mb-20'
})`
  background-image: url('${BGTitle}');
  background-size: 100% 100%;
`
