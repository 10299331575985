import PropTypes from 'prop-types'
import TitleHeader from 'src/components/common/TitleHeader'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import StandardizedButton from 'src/components/common/StandardizedButton'
import Item from 'src/models/Item'

import BtnAgain from 'src/assets/img/gacha/btn_again.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'

const CapsuleResultScreen = ({ handleClose, handleAgain, items }) => {
  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[368px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title="結果" className="mb-4" />
      {items.length === 1 ? (
        <>
          <ItemThumbnail
            image={items[0].image}
            className="w-[111px] h-[120px] border-menu-dark-blue"
            imgClassName="w-[90px]"
          />
          <span className="font-vldLineGR m-2">{items[0].name}</span>
        </>
      ) : (
        <ul className="grid grid-cols-4 gap-1 my-4">
          {items.map((el) => (
            <li className="flex flex-col" key={el.id}>
              <ItemThumbnail image={el.image} />
              <span className="font-vldLineGR pt-1 text-[9px] text-center line-clamp-2">
                {el.name}
              </span>
            </li>
          ))}
        </ul>
      )}
      <div className="flex w-4/5 justify-between">
        <StandardizedButton
          className="w-28"
          onClick={handleClose}
          img={BtnCancel}
          alt="キャンセル"
        />
        <StandardizedButton className="w-28" onClick={handleAgain} img={BtnAgain} alt="もう一度" />
      </div>
    </div>
  )
}

CapsuleResultScreen.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleAgain: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item))
}

export default CapsuleResultScreen
