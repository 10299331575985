// @ts-nocheck
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { Modal } from 'src/components/common/Modal'

import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import CancelBtn from 'src/assets/img/common/btn-cancel.png'
import OKBtn from 'src/assets/img/common/btn-ok.png'
import BuyBtn from 'src/assets/img/common/btn-buy.png'
import Life from 'src/assets/img/common/life.png'
import ArrowRight from 'src/assets/img/message/detail/arrow-right.png'
import BtnClose from 'src/assets/img/common/btn-close.png'
import { useEffect } from 'react'
import postUserItemAnalytics from 'src/services/post/postUserItemAnalytics'
import { useAuthState } from 'src/providers/AuthProviders'

export const SpendHeartModal = ({
  isOpen,
  closeModal,
  onConfirm,
  userHeart,
  canSpendHeart,
  spendHeartAmount,
  isNewConversation = false,
  isSendTalk = false,
  isOpenTalk = false,
  isSendRealtimeTalk = false,
  isOpenRealtimeTalk = false,
  isScrollOpenTalk
}) => {
  const navigate = useNavigate()
  const { user } = useAuthState()

  useEffect(() => {
    if (isOpen && !canSpendHeart) {
      postUserItemAnalytics(
        user.id,
        {
          is_user_show_modal_heart: true,
          ...(isSendTalk && { reason: 'send_talk' }),
          ...(isOpenTalk && { reason: 'open_talk' }),
          ...(isSendRealtimeTalk && { reason: 'send_realtime_talk' }),
          ...(isOpenRealtimeTalk && { reason: 'open_realtime_talk' })
        }
      )
    }
  }, [isOpen, canSpendHeart])

  return (
    <Modal
      open={isOpen}
      onClose={closeModal}
      className="sm:w-[340px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <div
        onClick={closeModal}
        className="w-[50px] h-[44px] absolute -top-4 -right-5 z-50 cursor-pointer"
      >
        <img className="w-full h-full" src={BtnClose} alt="close" />
      </div>
      <ModalBody>
        <ModalTitle>{canSpendHeart ? '確認' : 'アイテム不足'}</ModalTitle>
        <h6 className="text-center text-base font-bold mb-[26px]">
          {canSpendHeart && !isNewConversation && (
            <>トークの閲覧にはハートを{spendHeartAmount}個消費します。閲覧しますか？</>
          )}
          {canSpendHeart && isNewConversation && (
            <>トークの送信にはハートを{spendHeartAmount}個消費します。送信しますか？</>
          )}
          {!canSpendHeart && (
            <>
              ハートが不足しています。 <br />
              ショップでハートを購入しよう！
            </>
          )}
        </h6>
        <div className="mb-2 flex justify-between items-center px-6 border border-[#A1D2FF] rounded-[3px] py-1.5 text-xs text-[#000000] font-light leading-[18px]">
          <div className="w-[50%] text-center">必要数</div>
          <div className="w-[50%] text-center">ハート{spendHeartAmount}個</div>
        </div>
        <div className="flex justify-between items-center gap-3 text-xs text-[#000000] font-light leading-[18px]">
          <div className="w-[45%] bg-[#DAEBFF] text-center flex items-center justify-center rounded-[3px] py-1.5">
            <img className="w-4 h-4 mr-2" src={Life} alt="life" />
            所有数
          </div>
          <div className="w-[55%] flex items-center justify-between">
            <span className="w-[45%] text-center">{userHeart}個</span>
            <span className="w-[auto]">
              <img className="w-[9px] h-[10px] mx-1.5" src={ArrowRight} alt="arrow" />
            </span>
            <span className="w-[45%] text-center text-[#FF0000]">
              {canSpendHeart ? userHeart - spendHeartAmount : 0}個
            </span>
          </div>
        </div>
        {canSpendHeart ? (
          <div className="flex items-center justify-center gap-6 mt-16">
            <button className="focus:outline-none w-[50%] h-12" onClick={closeModal}>
              <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
            </button>
            <button className="focus:outline-none w-[50%] h-12" onClick={() => {
              onConfirm()
              if (isScrollOpenTalk) {
                isScrollOpenTalk()
              }
            }}>
              <img className="w-full h-full" src={OKBtn} alt="Ok" />
            </button>
          </div>
        ) : (
          <div className="flex items-center justify-center gap-6 mt-7">
            <button
              className="focus:outline-none w-[70%] h-12"
              onClick={() => {
                closeModal()
                navigate('/shop')
              }}
            >
              <img className="w-full h-full" src={BuyBtn} alt="Buy Item" />
            </button>
          </div>
        )}
      </ModalBody>
    </Modal>
  )
}

SpendHeartModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  userHeart: PropTypes.number.isRequired,
  canSpendHeart: PropTypes.bool.isRequired,
  spendHeartAmount: PropTypes.number,
  isNewConversation: PropTypes.bool,
  isSendTalk: PropTypes.bool,
  isOpenTalk: PropTypes.bool,
  isSendRealtimeTalk: PropTypes.bool,
  isOpenRealtimeTalk: PropTypes.bool,
  isScrollOpenTalk: PropTypes.func
}

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 pt-[38px] pb-[58px] relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-11'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;
`
