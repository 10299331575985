import { Transition } from '@headlessui/react'
import PropTypes from 'prop-types'

const FadeTransition = ({ show, children, className }) => {
  return (
    <Transition
      show={show}
      enter="ease-out duration-200"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      className={className}
    >
      {children}
    </Transition>
  )
}

FadeTransition.propTypes = {
  show: PropTypes.bool.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}

export default FadeTransition
