import IdolCharacter from 'src/models/Character'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getCharacters = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Character.filter)
    if (res.data.success) {
      const characters = []
      for (const i of res.data.items) {
        const character = new IdolCharacter(
          i.id,
          i.name,
          i.birthday,
          i.gender,
          i.playable,
          i.sub_name,
          i.created_at,
          i.updated_at,
          i.level,
          i.navigator
        )
        characters.push(character)
      }
      return characters
    } else return []
  } catch (error) {
    return []
  }
}

export default getCharacters
