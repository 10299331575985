import React from 'react'
import PropTypes from 'prop-types'
import diamondRed from '../../assets/diamond-red.png'
import _ from 'lodash'

const LoveItem = ({ item, onClick }) => {
  return (
    <button
      className="space-y-1 active:border-[#00FFD1] border-[3px] border-transparent p-2 rounded-[4px]"
      onClick={onClick}
    >
      <div className="w-[88px] mx-auto font-hiragino font-bold p-1 flex items-center justify-center">
        <img src={item.image.url} />
      </div>

      <div className="mx-auto font-bold text-sm text-white font-hiragino">{_.get(item, 'name')}</div>

      <div className="mt-4">
        <img src={diamondRed} className="inline mx-auto" />
        <span className="ml-1 text-white">x{_.get(item, 'number_diamond')}</span>
      </div>

      <div className="flex">
        <span className="w-1/2 flex-shrink-0 px-2 py-1 bg-black rounded-l-[12px] font-hiragino text-[10px] text-white font-[500">
          LOVE度
        </span>
        <span className="w-1/2 flex-shrink-0 px-2 py-1 bg-white rounded-r-[12px] font-hiragino text-[10px] font-[500] text-[#FF1D34]">
          ＋{_.get(item, 'extra_love')}
        </span>
      </div>
    </button>
  )
}

LoveItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

export { LoveItem }
