import PropTypes from 'prop-types'

const ShopPaymentItem = ({ shopDetails }) => {
  return (
    <div className="flex flex-col items-center">
      {shopDetails?.payment_items?.map((item, index) => {
        const firstName = shopDetails?.first_name
        const secondName = shopDetails?.second_name
        const longNameSettingStyle = (name) => {
          return name.length > 7 ? '-tracking-widest' : ''
        }

        switch (item?.item_kind) {
          case 'dream_compass':
            return (
              <div className="flex items-center" key={index}>
                {index === 0 && <img src={shopDetails.icon.url} className="w-20" />}

                {index === 0 && (
                  <div className="text-xs ml-1 flex-col items-center">
                    <p className={`w-full overflow-ellipsis ${longNameSettingStyle(firstName)}`}>
                      {firstName}
                      <br />
                      {secondName}
                    </p>
                  </div>
                )}
              </div>
            )
          case 'platinum_passport':
            return (
              <div className="flex items-center" key={index}>
                {index === 0 && <img src={shopDetails.icon.url} className="w-12" />}

                {index === 0 && (
                  <div className="text-xs ml-1 flex-col items-center">
                    <p className={`w-full overflow-ellipsis ${longNameSettingStyle(firstName)}`}>
                      {firstName}
                      <br />
                      {secondName}
                    </p>
                  </div>
                )}
              </div>
            )
          default:
            return (
              <div className="flex items-center" key={index}>
                {index === 0 && <img src={shopDetails.icon.url} className="w-10" />}

                {index === 0 && (
                  <div className="text-xs ml-1 flex-col items-center">
                    <p className={`w-full overflow-ellipsis ${longNameSettingStyle(firstName)}`}>
                      {firstName}
                      <br />
                      {secondName}
                    </p>
                  </div>
                )}
              </div>
            )
        }
      })}
    </div>
  )
}

ShopPaymentItem.propTypes = {
  shopDetails: PropTypes.object
}

export default ShopPaymentItem
