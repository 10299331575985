import PropTypes from 'prop-types'
import { CURRENCY_TYPE, DRAW_TYPE } from 'src/models/GachaInfo'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import CapsuleCurrencyChangeDisplay from 'src/pages/main/capsule/CapsuleCurrencyChangeDisplay'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import TitleKakunin from 'src/assets/img/gacha/title_kakunin.png'
import { useState } from 'react'

const CapsuleConfirmScreen = ({
  currencyType,
  currencyText,
  drawType,
  handleClose,
  handleCancel,
  handleConfirm,
  redCount,
  blueCount,
  ticketCount,
  ssrCount
}) => {
  const genConfirmText = () => {
    let d
    switch (drawType) {
      case DRAW_TYPE.Free:
        return '無料ガチャを1回引きますか？'
      case DRAW_TYPE.Eleven:
        d = '11'
        break
      case DRAW_TYPE.One:
      default:
        d = '1'
        break
    }

    const c = currencyText

    return `${c}で\nガチャを${d}回引きますか？`
  }

  const [checkModal, setCheckModal] = useState(true)

  return (
    <>
      {checkModal && (
        <div className="w-full px-4 pt-8 pb-16  min-h-[368px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
          <ModalCloseButton handleClose={handleClose} />
          <img src={TitleKakunin} alt="確認" className="w-64" />
          <div>
            <div className="font-bold text-center whitespace-pre-line text-lg">
              {genConfirmText()}
            </div>
            {currencyType === CURRENCY_TYPE.Diamond && drawType !== DRAW_TYPE.Free && (
              <div className="text-xs mt-2">※青ダイヤから優先的に消費されます</div>
            )}
          </div>
          {drawType !== DRAW_TYPE.Free && (
            <CapsuleCurrencyChangeDisplay
              currencyType={currencyType}
              currencyText={currencyText}
              redCount={redCount}
              blueCount={blueCount}
              ticketCount={ticketCount}
              ssrCount={ssrCount}
            />
          )}
          <div className="flex w-4/5 justify-between">
            <StandardizedButton
              className="w-28"
              img={BtnCancel}
              alt="キャンセル"
              onClick={drawType === DRAW_TYPE.Free ? handleClose : () => handleCancel()}
            />
            <StandardizedButton
              className="w-28"
              img={BtnConfirm}
              alt="決定"
              onClick={() => {
                handleConfirm(true)
                setCheckModal(false)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

CapsuleConfirmScreen.propTypes = {
  currencyType: PropTypes.number.isRequired,
  currencyText: PropTypes.string.isRequired,
  drawType: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired
}

export default CapsuleConfirmScreen
