import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useAuthState } from 'src/providers/AuthProviders'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import getOrderDetails from 'src/services/get/getOrderDetails'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import CurrencyChangeIndicator from 'src/components/common/CurrencyChangeIndicator'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnBack from 'src/assets/img/shop/btn_back.png'

const ShopPurchaseResultScreen = ({ isError }) => {
  const { user } = useAuthState()
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('order_id')
  const { closeModal } = useShopContext()

  const { data: order, isFetched } = useQuery({
    queryFn: () => {
      if (!orderId) return
      return getOrderDetails(orderId)
    },
    queryKey: ['orderDetails', orderId],
    staleTime: Infinity,
    enabled: Boolean(orderId) && !isError,
    refetchOnWindowFocus: false
  })

  const paymentAmount = order?.payment_amount

  const paymentStatus = useMemo(() => {
    switch (order?.status) {
      case 'PAYSUCCESS':
        return 'success'
      case 'ERROR':
      case 'EXPIRED':
      case 'INVALID':
      case 'PAYSTART':
        return 'error'
      default:
        return 'pending'
    }
  }, [order?.status])

  useEffect(() => {
    if (!isFetched || paymentStatus !== 'pending') return

    closeModal()
  }, [isFetched, paymentStatus])

  if (!isFetched && !isError) return <></>

  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[368px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={closeModal} />

      {!isError && paymentStatus === 'success' && (
        <>
          <TitleHeader title="購入完了" />
          <div className="text-lg font-bold text-center whitespace-pre-line">
            アイテムの購入が完了しました！
          </div>
          <div className="flex flex-col items-center justify-center h-32 my-4 text-xs text-center border-4 w-28 border-menu-dark-blue drop-shadow">
            <>
              <img src={order?.icon.url} className="w-16" />
              <span className="scale-90 tracking-tighter">{order?.first_name}</span>
              {order?.second_name && (
                <span className="scale-90 tracking-tighter mt-1">{order?.second_name}</span>
              )}
            </>
          </div>
          {paymentAmount && (
            <div className="w-3/4">
              <CurrencyChangeIndicator
                icon={'red'}
                from={user?.red_amount - (order?.red_diamond_amount || 0) || 0}
                to={user?.red_amount || 0}
                counter="個"
                hasChange={true}
              />
            </div>
          )}
        </>
      )}
      {(isError || paymentStatus === 'error') && (
        <>
          <TitleHeader title="決済失敗" />
          <div className="text-lg font-bold text-center whitespace-pre-line">
            {'決済に失敗しました。\nもう一度やり直してください。'}
          </div>
          <StandardizedButton
            img={BtnBack}
            alt="決済ページに戻る"
            onClick={closeModal}
            className="w-52"
          />
        </>
      )}
    </div>
  )
}

ShopPurchaseResultScreen.propTypes = {
  isError: PropTypes.bool
}

export default ShopPurchaseResultScreen
