import LegaleseWrapper from 'src/components/layouts/LegaleseWrapper'
import MenuBar from 'src/components/menu/MenuBar'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { useEffect, useState } from 'react'
import { getConditions } from 'src/services/get/getConditions'
import TipsModal from 'src/components/common/TipsModal'

export const MenuTermOfServicePage = () => {
  const [term, setTerm] = useState('')

  const fetchCondition = async () => {
    const data = await getConditions('condition')
    const content = data.find(item => item?.condition?.status === 'is_public')?.condition?.content
    setTerm(content)
  }

  useEffect(() => {
    fetchCondition()
  }, [])

  return (
    <>
      <MenuBar title="terms_of_service" />
      <MainLayout className="bg-menu-bg h-[100%]" active="menu" showHeader={false}>
        <MenuWrapper className="flex items-center">
          <LegaleseWrapper content={term} />
          <TipsModal type="menu" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

export default MenuTermOfServicePage
