import { useNavigate } from 'react-router-dom'
import MenuRanking from 'src/assets/img/mypage/side-menu/menu-ranking.png'

const RankingBanner = () => {
  const navigate = useNavigate()

  return (
    <div onClick={() => navigate('/ranking')} className="absolute xs:right-0 -right-4 top-0 w-[5.8rem]">
      <img
        src={MenuRanking}
        alt=""
        className="xs:w-[100%] w-[85%] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
      />
    </div>
  )
}

export default RankingBanner
