import PropTypes from 'prop-types'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import SectionHeader from 'src/components/menu/SectionHeader'
import clsx from 'clsx'

import BgLove from 'src/assets/img/gacha/bg_love.png'
import BgGachaCloth from 'src/assets/img/gacha/gacha_cloths.png'
import IconR from 'src/assets/img/gacha/R.png'
import IconSR from 'src/assets/img/gacha/SR.png'
import IconSSR from 'src/assets/img/gacha/SSR.png'
import TitleReward from 'src/assets/img/gacha/title_reward.png'
import { useEffect, useState } from 'react'

const getItemGacha = (gacha) => {
  let data = []

  switch (gacha.gacha_type) {
    case 'item':
      data = gacha.gacha_items
      break
    case 'avatar':
      data = gacha.gacha_character_cloths
      break
  }

  return data
}

const CapsuleDetailsModal = ({ handleClose, gachaDetail }) => {
  const [rarityR, setRarityR] = useState([])
  const [raritySR, setRaritySR] = useState([])
  const [raritySSR, setRaritySSR] = useState([])
  const data = getItemGacha(gachaDetail)

  useEffect(() => {
    if (!data) return
    const sortLove = (a, b) => {
      if (a.increase_love === 0 && b.increase_love !== 0) {
        return -1
      } else if (a.increase_love !== 0 && b.increase_love === 0) {
        return 1
      } else {
        return b.increase_love - a.increase_love
      }
    }
    const rarityR = data.filter((item) => item.rarity === 'r')
    const raritySR = data.filter((item) => item.rarity === 'sr')
    const raritySSR = data.filter((item) => item.rarity === 'ssr')
    setRarityR(rarityR.sort((a, b) => sortLove(a, b)))
    setRaritySR(raritySR.sort((a, b) => sortLove(a, b)))
    setRaritySSR(raritySSR.sort((a, b) => sortLove(a, b)))
  }, [])

  function processRarityInfo() {
    const rarityInfo = {}
    if (!data) return
    data.forEach((item) => {
      const { rarity, rate } = item

      if (!rarityInfo[rarity]) {
        rarityInfo[rarity] = {
          count: 0,
          totalRate: 0
        }
      }

      rarityInfo[rarity].count++
      rarityInfo[rarity].totalRate += rate
    })

    const resultArray = Object.keys(rarityInfo).map((rarity) => {
      const { count, totalRate } = rarityInfo[rarity]
      return { rarity, count, totalRate }
    })

    return resultArray
  }
  const resultGacha = processRarityInfo(gachaDetail)

  const ICONRARITY = {
    ssr: IconSSR,
    sr: IconSR,
    r: IconR
  }

  const ICONCLASSNAME = {
    ssr: 'w-10',
    sr: 'w-7',
    r: 'w-6'
  }

  const handleIconType = (item) => {
    return ICONRARITY[item] || null
  }

  const handleClassIcon = (item) => {
    return ICONCLASSNAME[item] || null
  }

  const allItems = raritySSR?.concat(raritySR, rarityR) || []

  const itemDetails = allItems
    .filter((item) => item.is_picup)
    .map((item, index) => (
      <ItemDetails
        key={index}
        image={item.item_image_url}
        name={item.name}
        rate={
          Number.isFinite(item.rate)
            ? item.rate.toFixed(4).padEnd(4, '0')
            : parseFloat(item.rate.toFixed(4))
        }
        loveAmount={item.increase_love}
        type={gachaDetail.gacha_type}
      />
    ))

  const itemDetailsNotIsPicUp = allItems.map((item, index) => {
    if (item.is_picup) {
      return null
    }
    return (
      <ItemDetails
        key={index}
        image={item.item_image_url}
        name={item.name}
        rate={
          Number.isFinite(item.rate)
            ? item.rate.toFixed(4).padEnd(4, '0')
            : parseFloat(item.rate.toFixed(4))
        }
        loveAmount={item.increase_love}
        type={gachaDetail.gacha_type}
      />
    )
  })

  return (
    <div className="w-full h-[550px] p-1 m-4 bg-popup-xl bg-fill bg-no-repeat rounded-md relative">
      <ModalCloseButton handleClose={handleClose} />
      <div className="h-full py-5 px-3 flex flex-col items-center overflow-y-scroll">
        <img className="w-2/3 mb-4" src={TitleReward} />
        <table className="text-sm w-[80%] mb-4 font-hiragino">
          <thead>
            <tr className="bg-menu-light-blue text-center p-1">
              <th className="p-1 font-normal">レア度</th>
              <th className="p-1 border-x border-menu-dark-blue font-normal">提供数</th>
              <th className="p-1 font-normal">提供割合</th>
            </tr>
          </thead>
          <tbody>
            {resultGacha.reverse().map((item, index) => (
              <tr key={index} className="bg-white text-center p-1 border-b border-menu-dark-blue">
                <td className="p-1 flex justify-center border-l border-menu-dark-blue">
                  <img src={handleIconType(item.rarity)} className={handleClassIcon(item.rarity)} />
                </td>
                <td className="p-1 border-x border-menu-dark-blue">{item.count}</td>
                <td className="p-1 border-r border-menu-dark-blue">
                  {parseFloat(item.totalRate.toFixed(4))}%
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <SectionHeader title="ピックアップ" className="w-full" />
        <div className="grid grid-cols-3 gap-2 w-full">{itemDetails}</div>
        <SectionHeader title="その他アイテム" className="w-full" />
        <div className="grid grid-cols-3 gap-2 w-full">{itemDetailsNotIsPicUp}</div>
      </div>
    </div>
  )
}

CapsuleDetailsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  gachaDetail: PropTypes.object.isRequired
}

const ItemDetails = ({ image, name, rate, loveAmount, type }) => {
  return (
    <div className="font-vldLineGR flex flex-col items-center">
      <ItemThumbnail
        image={image}
        className=""
        imgClassName="w-[70px] h-[70px]"
        loveAmount={loveAmount}
        rarity
        type={type}
      />
      <div className="font-vldLineGR pt-1 text-[9px] text-center line-clamp-2 text-ellipsis">
        {name}
      </div>
      <span className="truncate text-[7px]">{`出現率 ${rate}%`}</span>
    </div>
  )
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]',
  type
}) => {
  const getBackGroundType = () => {
    switch (type) {
      case 'avatar':
        return BgGachaCloth
      case 'item':
        return BgLove
    }
  }

  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount !== 0 && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${getBackGroundType()}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">{type === 'item' ? 'LOVE度' : '育て度 '}</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemDetails.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number.isRequired,
  loveAmount: PropTypes.number.isRequired,
  rarity: PropTypes.number,
  type: PropTypes.string.isRequired
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string,
  type: PropTypes.string.isRequired
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

export default CapsuleDetailsModal
