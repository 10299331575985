export default class NotificationSetting {
  constructor(id, name, isLineFollow, accept, isNotification, navigator) {
    this.id = id
    this.name = name
    this.isLineFollow = isLineFollow
    this.accept = accept
    this.isNotification = isNotification
    this.navigator = navigator
  }
}

// TODO: acceptはまだサーバーの返事に含めていません。
