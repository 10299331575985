import PropTypes from 'prop-types'
import HTMLReactParser from 'html-react-parser'

import MascotSorry from 'src/assets/img/character/mascot/sorry.png'

const NoContentImage = (props) => {
  const { message } = props

  return (
    <div className='absolute top-[50%] left-[50%] -translate-x-2/4 -translate-y-2/4 font-vldLineGR text-lg leading-7' >
      <img className='h-[115px] w-[115px] mx-auto mb-2' src={MascotSorry} />
      <p>{HTMLReactParser(message)}</p>
    </div>
  )
}

export default NoContentImage

NoContentImage.propTypes = { message: PropTypes.string }
