import { useNavigate } from 'react-router-dom'
import MenuKaregekijo from 'src/assets/img/mypage/side-menu/menu-karegekijo.png'

const HisTheaterBanner = () => {
  const navigate = useNavigate()

  return (
    <div
      onClick={
        // () => navigate('/theater')
        () => navigate('/in-progress')
      }
      className="absolute xs:right-0 -right-5 xs:top-0 -top-3 w-[7.4rem]"
    >
      <img
        src={MenuKaregekijo}
        alt=""
        className="xs:w-[100%] w-[85%] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
      />
    </div>
  )
}

export default HisTheaterBanner
