import { Disclosure } from '@headlessui/react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import { getFaqs } from 'src/services/get/getFaqs'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import HTMLReactParser from 'html-react-parser'
import { removeNewlineCode } from 'src/lib/string'

import ChevronDownBlue from 'src/assets/img/common/chevron_down_blue.svg'
import ChevronDown from 'src/assets/img/common/chevron_down.svg'
import TipsModal from 'src/components/common/TipsModal'
import { useState } from 'react'

export const HelpPage = () => {
  const { data: groups } = useQuery('groups', () => getFaqs())

  return (
    <>
      <MenuBar title="help" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper
          className="flex flex-col items-center gap-2 pt-menu-header-offset"
          overflow={true}
        >
          {groups?.filter((group) => group.faqs.length > 0)?.map((group) => (
            <>
              <GroupAccordion
                key={group.id}
                name={group.name}
                faqs={group.faqs}
              />
            </>

          )
          )}
          <TipsModal type="help" />
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

const HelpAccordion = ({ question, answer, hidden }) => {
  return (
    <div className={`p-4 bg-menu-off-blue rounded-md w-full text-left text-sm border-4 border-menu-dark-blue drop-shadow-lg font-hiragino ${hidden && 'hidden'}`}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`w-full text-left flex justify-between items-center ${
                open && 'border-b border-menu-dark-blue pb-2'
              }`}
            >
              <span className="pr-2">{HTMLReactParser(removeNewlineCode(question))}</span>
              <img src={ChevronDown} className={`${open && 'transform rotate-180'}`} />
            </Disclosure.Button>

            <Disclosure.Panel>
              <span className="py-2 block text-menu-dark-blue">A.</span>
              <p>{HTMLReactParser(removeNewlineCode(answer))}</p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}

const GroupAccordion = ({ name, faqs }) => {
  const [open, setOpen] = useState(false)
  return (
    <>
      <div className="p-4 bg-menu-off-blue rounded-md w-full text-left text-sm border-4 border-pink-300 drop-shadow-lg font-hiragino" onClick={() => setOpen(!open)}>
        <div
          className={'w-full text-left flex justify-between items-center'}
        >
          <span className="pr-2">{HTMLReactParser(removeNewlineCode(name))}</span>
          <img src={ChevronDownBlue} className={`${open && 'transform rotate-180'}`} />
        </div>
      </div>

      {faqs.map((faq) => (
        <HelpAccordion
          key={faq.id}
          question={faq.question}
          answer={faq.answer}
          hidden={!open}
        />
      ))}
    </>
  )
}

HelpAccordion.propTypes = {
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  hidden: PropTypes.bool.isRequired
}

GroupAccordion.propTypes = {
  name: PropTypes.string.isRequired,
  faqs: PropTypes.array.isRequired
}
