import axiosInstance from 'src/services/common/axiosInstance'

const patchUser = async (id, params) => {
  if (!id) {
    return ({
      success: false,
      error: {
        id: 400,
        message: 'ブランクID',
        details: { id: 'ブランクID' }
      }
    })
  }

  const res = await axiosInstance.patch(`/users/${id}`, { user: params })
  return res.data
}

export default patchUser
