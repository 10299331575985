import PropTypes from 'prop-types'

import HeaderMenu from 'src/assets/img/common/header_menu.png'
import BgSetting from 'src/assets/img/realtalk/bg_setting_back.png'
import BgSettingPreview from 'src/assets/img/realtalk/bg_setting.png'
import BtnReturn from 'src/assets/img/common/btn_return.png'

const MenubarRealTalk = ({
  title,
  showReturnButton = true,
  setCheckClickIcon,
  setCheckClickIconBg,
  setCheckTitle,
  setCheckClickIconSetting,
  setIspreview,
  handleBackGroundBack,
  setIsClickIconBg
}) => {
  const determineHeaderImage = () => {
    switch (title) {
      case 'background_setting':
        return BgSetting
      case 'background_setting_preview':
        return BgSettingPreview
      default:
        return HeaderMenu
    }
  }

  return (
    <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed left-1/2 -translate-x-1/2 z-[51]">
      {showReturnButton ? (
        <ReturnButton
          setCheckClickIcon={setCheckClickIcon}
          setCheckClickIconBg={setCheckClickIconBg}
          setCheckTitle={setCheckTitle}
          setCheckClickIconSetting={setCheckClickIconSetting}
          setIspreview={setIspreview}
          handleBackGroundBack={handleBackGroundBack}
          title={title}
          setIsClickIconBg={setIsClickIconBg}
        />
      ) : null}
      <img src={determineHeaderImage()} className="absolute right-0 w-2/5" alt={title} />
    </div>
  )
}

const ReturnButton = ({
  setCheckClickIcon,
  setCheckClickIconBg,
  setCheckTitle,
  setCheckClickIconSetting,
  setIspreview,
  handleBackGroundBack,
  title,
  setIsClickIconBg
}) => {
  const onClick = (e) => {
    setCheckClickIcon(true)
    setCheckClickIconBg(true)
    setCheckClickIconSetting(false)
    setIspreview(false)
    setIsClickIconBg(false)
  }

  const onClickTitle = () => {
    setIspreview(false)
    setCheckTitle('background_setting')
    setIsClickIconBg(true)
  }

  return (
    <button
      onClick={title === 'background_setting_preview' ? onClickTitle : onClick}
      className="absolute left-0 top-2"
    >
      <img src={BtnReturn} className="w-[64px]" alt="return button" />
    </button>
  )
}

ReturnButton.propTypes = {
  back: PropTypes.string,
  setCheckClickIcon: PropTypes.func,
  setCheckClickIconBg: PropTypes.func,
  setCheckTitle: PropTypes.func,
  setCheckClickIconSetting: PropTypes.func,
  setIspreview: PropTypes.func,
  handleBackGroundBack: PropTypes.func,
  title: PropTypes.string.isRequired,
  setIsClickIconBg: PropTypes.func
}

MenubarRealTalk.propTypes = {
  title: PropTypes.string.isRequired,
  showReturnButton: PropTypes.bool,
  setCheckClickIcon: PropTypes.func,
  setCheckClickIconBg: PropTypes.func,
  setCheckTitle: PropTypes.func,
  setCheckClickIconSetting: PropTypes.func,
  setIspreview: PropTypes.func,
  handleBackGroundBack: PropTypes.func,
  setIsClickIconBg: PropTypes.func
}

export default MenubarRealTalk
