export const searchUserAgent = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  if (userAgent.indexOf('android') > -1) {
    return 'Android'
  } else if (/iphone|ipad|ipod/.test(userAgent.toLowerCase())) {
    return 'iOS'
  } else {
    return 'others'
  }
}
