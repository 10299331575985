import PropTypes from 'prop-types'
import { formatDate } from 'src/lib/date'

export const ItemChatReceived = ({ data }) => {
  return (
    <div className="flex items-end w-full font-hiragino">
      <div
        className={
          'order-last rounded-lg relative bg-[#F8F8F8] max-w-[270px] w-[270px] p-2 pl-[9px] pr-[10px] pt-[5px] border-[0.5px] border-[#325282] z-10 break-words'
        }
      >
        <div className="chat-received-item" />
        <div className="text-justify text-[#325282] text-xs font-normal whitespace-pre-line">
          {data?.description || data?.content}
        </div>
      </div>
      <div className="pl-2 mr-2 text-[8px] font-light text-[#325282]">
        <span>{formatDate(new Date(data?.created_at))}</span>
      </div>
    </div>
  )
}

ItemChatReceived.propTypes = {
  data: PropTypes.object.isRequired,
  isMe: PropTypes.bool
}
