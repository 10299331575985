
import { useParams } from 'react-router-dom'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'
import { Profile } from 'src/components/myPage/profile/Profile'

export const RecommendProfilePage = () => {
  const { id, name } = useParams()
  return (
    <>
      <MenuBar title="profile" />
      <MainLayout active="mypage" showHeader={false} showFooter={true}>
        <Profile id={id} name={name} />
      </MainLayout>
    </>
  )
}
