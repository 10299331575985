import React, { useState, useCallback, useEffect } from 'react'
import { Tab } from '@headlessui/react'
import { useSearchParams } from 'react-router-dom'
import ShopPurchaseResultScreen from 'src/pages/main/shop/components/ShopDiamondTab/ShopPurchaseResultScreen'
import { ShopContextProvider } from 'src/pages/main/shop/contexts/ShopContext'
import { Modal } from 'src/components/common/Modal'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import TabButton from 'src/components/common/TabButton'
import ShopDiamondTab from 'src/pages/main/shop/components/ShopDiamondTab'
import ShopHeartTab from 'src/pages/main/shop/components/ShopHeartTab'
import TipsModal from 'src/components/common/TipsModal'

const ShopPage = () => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [modalContent, setModalContent] = useState(null)
  const [selectedItem, setSelectedItem] = useState()
  const closeModal = useCallback(() => setModalContent(null), [])
  const [selectedHeartExchange, setSelectedHeartExchange] = useState(null)
  const [searchParams] = useSearchParams()
  const orderId = searchParams.get('order_id')
  const targetScreen = searchParams.get('target_screen')

  // check for payment result screen
  useEffect(() => {
    // do nothing unleess PAYMENT_RESULT
    if (targetScreen !== 'PAYMENT_RESULT' || !orderId) return

    // @ts-ignore
    setModalContent(<ShopPurchaseResultScreen />)
  }, [targetScreen])

  return (
    <ShopContextProvider
      value={{
        modalContent,
        setModalContent,
        closeModal,
        setSelectedItem,
        selectedItem: selectedItem,
        selectedHeartExchange,
        setSelectedHeartExchange
      }}
    >
      {modalContent && (
        <Modal
          open={Boolean(modalContent)}
          onClose={closeModal}
          className="shop-modal first-line:sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center p-2"
        >
          {modalContent}
        </Modal>
      )}
      <MenuBar title="shop" />
      <MainLayout active="shop" classNameHeader="pt-menu-header-offset xs:px-4 px-0">
        <StandardPageWrapper className={'h-full flex flex-col'}>
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex w-full h-16 gap-1 xs:px-2 pt-4 items-around">
              <TabButton title="ダイヤ購入" />
              <TabButton title="ハート交換" />
              <TabButton title="応援購入" />
            </Tab.List>
            <Tab.Panels className="relative px-2 py-4 overflow-y-scroll">
              <Tab.Panel>
                <ShopDiamondTab />
              </Tab.Panel>
              <Tab.Panel>
                <ShopHeartTab />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </StandardPageWrapper>
      </MainLayout>
      <TipsModal type="exchange" />
    </ShopContextProvider>
  )
}

export default ShopPage
