import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { MAIN_PATHS } from 'src/routes/main'
import { MENU_PATHS } from 'src/routes/menu'
import NotificationIcon from 'src/components/common/NotificationIcon'

import iconMypage from 'src/assets/img/common/mypage.png'
import iconActiveMypage from 'src/assets/img/common/mypage-active.png'
import iconEvent from 'src/assets/img/common/event.png'
import iconActiveEvent from 'src/assets/img/common/event-active.png'
import iconMenu from 'src/assets/img/common/menu.png'
import iconActiveMenu from 'src/assets/img/common/menu-active.png'
import iconGacha from 'src/assets/img/common/gacha.png'
import iconActiveGacha from 'src/assets/img/common/gacha-active.png'
import iconMessage from 'src/assets/img/common/message.png'
import iconActiveMessage from 'src/assets/img/common/message-active.png'
import iconShop from 'src/assets/img/common/shop.png'
import iconActiveShop from 'src/assets/img/common/shop-active.png'
import bg from 'src/assets/img/common/bg.png'
import ibg from 'src/assets/img/common/tab-menu-active.png'
import useInquiriesHasNotifications from 'src/hooks/useInquiriesHasNotifications'
import styled from 'styled-components'
import { getChatList } from 'src/services/get/getChat'
import { useAuthState } from 'src/providers/AuthProviders'
import useConversationListChannel from 'src/services/common/useConversationListChannel'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import useNewsListChannel from 'src/services/common/useNewsListChannel'

export const Footer = ({ active }) => {
  const { hasNotifications } = useInquiriesHasNotifications()
  const navigate = useNavigate()
  const { user } = useAuthState()
  const [hasUnreadMessage, setHasUnreadMessage] = useState(false)
  const [hasUnreadMessageStory, setHasUnreadMessageStory] = useState(false)
  const { message } = useConversationListChannel()
  const { adminMessage } = useUserContactListChannel()
  const { hasNewsNotifications } = useNewsHasNotifications()
  const { newsMessage } = useNewsListChannel()

  const buttons = [
    {
      label: 'マイページ',
      icon: iconMypage,
      active: iconActiveMypage,
      cat: 'mypage',
      goto: MAIN_PATHS.MyPage
    },
    {
      label: 'ストーリー',
      icon: iconMessage,
      active: iconActiveMessage,
      cat: 'message',
      goto: MAIN_PATHS.MessagePage
    },
    {
      label: 'リアルタイム',
      icon: iconEvent,
      active: iconActiveEvent,
      cat: 'event',
      goto: MAIN_PATHS.RealTalkPage
      // goto: MAIN_PATHS.InProgress
    },
    {
      label: 'ショップ',
      icon: iconShop,
      active: iconActiveShop,
      cat: 'shop',
      goto: MAIN_PATHS.ShopPage
    },
    {
      label: 'ガチャ',
      icon: iconGacha,
      active: iconActiveGacha,
      cat: 'gacha',
      goto: MAIN_PATHS.CapsuleToyPage
    },
    {
      label: 'メニュー',
      icon: iconMenu,
      active: iconActiveMenu,
      cat: 'menu',
      goto: MENU_PATHS.Menu
    }
  ]

  useEffect(() => {
    (async () => {
      const channelList = await getChatList(user.id, 'realtime_chat')
      const hasUnread = (channelList || []).some(
        (data) => data.character.id !== 6 && data.unreadTotal > 0
      )
      if (hasUnread) setHasUnreadMessage(true)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const channelList = await getChatList(user.id)
      const hasUnread = (channelList || []).every(
        (data) => data.is_check_read === true
      )
      if (!hasUnread) setHasUnreadMessageStory(true)
    })()
  }, [])

  useEffect(() => {
    if (message?.conversation_id && message?.roomType === 'realtime' && message?.unreadTotal > 0) setHasUnreadMessage(true)
    if (message?.roomType === 'single' && message?.is_check_read === false) setHasUnreadMessageStory(true)
  }, [message])

  return (
    <div
      className="max-h-footer-offset fixed bottom-0 bg-cover bg-center grid grid-cols-6 z-50"
      style={{ backgroundImage: `url(${bg})`, width: 'inherit' }}
    >
      {buttons.map((v, i) => {
        return (
          <ButtonFooter
            key={i}
            className={`text-xs text-center bg-cover bg-center pt-5 pb-6 relative ${
              active === v.cat ? 'text-purple-400' : 'text-white'
            }`}
            onClick={() => navigate(v.goto)}
            style={{ backgroundImage: `url(${active === v.cat && ibg})` }}
          >
            <img className="w-10 mx-auto" src={active === v.cat ? v.active : v.icon} />
            <p className="font-bold font-vldLineGR text-[10px]">{v.label}</p>
            {v.cat === 'menu' &&
            (hasNotifications ||
              adminMessage?.is_new_message ||
              hasNewsNotifications ||
              newsMessage?.is_have_news) ? (
                  <NotificationIcon />
                ) : null}
            {v.cat === 'event' && hasUnreadMessage && <NotificationIcon />}
            {v.cat === 'message' && hasUnreadMessageStory && <NotificationIcon />}
          </ButtonFooter>
        )
      })}
    </div>
  )
}

const ButtonFooter = styled.div`
  @media (max-width: 395px) {
    padding-bottom: 1rem !important;
    p {
      font-size: 8px !important;
    }
  }
`

Footer.propTypes = {
  active: PropTypes.string
}
