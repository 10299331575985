import PropTypes from 'prop-types'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import Present from 'src/models/Present'
import styled from 'styled-components'
import { calculateDifference } from 'src/lib/date'
import { getItemImage } from 'src/lib/present'

import BtnUketoru from 'src/assets/img/menu/btn_uketoru.png'

const PresentItem = ({ present, handleReceive, isNormalItem }) => {
  return (
    <li className="w-full my-2 p-1 rounded bg-gradient-to-b from-light-pink via-white to-light-pink font-hiragino">
      <Content>
        <ItemThumbnail
          image={getItemImage(present)}
          className="w-[69px] h-[69px] shrink-0	border-pale-blue rounded-sm"
          imgClassName={
            isNormalItem(present.presentType)
              ? 'w-full h-full'
              : 'w-[90%]'
          }
          rarity={isNormalItem(present.presentType)}
        />
        <div className="text-left flex flex-col items-start">
          <div className="text-sm">
            {present.title} x {present.amount}
          </div>
          <PresentText>{present.boxMessage}</PresentText>
        </div>
        <div className="text-[10px] w-full shrink-0 flex flex-col items-center justify-center">
          <div className="mb-1 scale-90 whitespace-nowrap">
            {calculateDifference(present.expiredAt)}
          </div>
          <button type="button" className="w-20" onClick={handleReceive}>
            <img src={BtnUketoru} alt="受け取る" />
          </button>
        </div>
      </Content>
    </li>
  )
}

PresentItem.propTypes = {
  present: PropTypes.instanceOf(Present).isRequired,
  handleReceive: PropTypes.func.isRequired,
  isNormalItem: PropTypes.func.isRequired
}

export default PresentItem

const Content = styled.div.attrs({
  className: 'bg-white px-3 py-4 rounded w-full'
})`
  display: grid;
  grid-template-columns: 1fr 4fr 3fr;
  gap: 10px;
`

const PresentText = styled.p.attrs({
  className: 'leading-tight'
})`
  font-size: 10px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
`
