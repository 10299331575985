import PropTypes from 'prop-types'
import notify from 'src/assets/img/common/notify.png'

const NotificationIcon = ({ className = 'top-2 right-0', style }) => {
  return <img src={notify} className={`w-[35px] absolute ${className}`} style={style} />
}

NotificationIcon.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
}

export default NotificationIcon
