import { useState, useEffect } from 'react'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import MenuItem from 'src/components/menu/MenuItem'
import Loading from 'src/components/layouts/Loading'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import SectionHeader from 'src/components/menu/SectionHeader'
import { MENU_PATHS } from 'src/routes/menu'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { NEW_USER_PATHS } from 'src/routes/newUser'
import BGPage from 'src/assets/img/register/bg-common.png'

import BtnMenuContact from 'src/assets/img/menu/btn_menu_contact.png'
import BtnMenuSide from 'src/assets/img/menu/btn_menu_side.png'
import BtnMenuHelp from 'src/assets/img/menu/btn_menu_help.png'
import BtnMenuProduction from 'src/assets/img/menu/btn_menu_production.png'
import BtnMenuHP from 'src/assets/img/menu/btn_menu_HP.png'
import BtnMenuBlack from 'src/assets/img/menu/logo-black-1.png'
import BtnMenuInsta from 'src/assets/img/menu/Instagram.png'
import BtnMenuYoutube from 'src/assets/img/menu/yt_logo.png'
import BtnMenuTiktok from 'src/assets/img/menu/tiktok-logo.png'
import BtnMenuIdName from 'src/assets/img/menu/btn_menu_idname.png'
import BtnMenuItem from 'src/assets/img/menu/btn_menu_item.png'
import BtnMenuNews from 'src/assets/img/menu/btn_menu_news.png'
import BtnMenuEvent from 'src/assets/img/menu/btn_menu_event.png'
import BtnMenuPresent from 'src/assets/img/menu/btn_menu_present.png'
import BtnMenuOpening from 'src/assets/img/menu/btn_menu_opening.png'
import BtnMenuPrivacyPolicy from 'src/assets/img/menu/btn_menu_privacypolicy.png'
import BtnMenuRegulation from 'src/assets/img/menu/btn_menu_regulation.png'
import BtnMenuSettings from 'src/assets/img/menu/btn_menu_settings.png'
import BtnMenuTermsOfService from 'src/assets/img/menu/btn_menu_termsofservice.png'
import useInquiriesHasNotifications from 'src/hooks/useInquiriesHasNotifications'
import { useFetchFirstStory } from '../newUser/hooks/useFetchFirstStory'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import useNewsListChannel from 'src/services/common/useNewsListChannel'
import BtnMenuLive from 'src/assets/img/menu/btn_menu_LIVE.png'
import { getLiveActiveLink } from '../../services/get/getLiveActiveLink'

export const Menu = () => {
  const { hasNotifications } = useInquiriesHasNotifications()
  const { adminMessage } = useUserContactListChannel()
  const { hasNewsNotifications } = useNewsHasNotifications()
  const { newsMessage } = useNewsListChannel()
  const [liveScheduleId, setLiveScheduleId] = useState()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getLiveActiveLink().then((data) => {
      setIsLoading(true)
      setLiveScheduleId(data.event.id)
    })
  }, [])

  useFetchFirstStory()
  const HeaderStyles =
    'font-vldLineGR border-b border-menu-lilac text-menu-lilac text-lg leading-4 my-4'
  const Style = '2px 2px #FFFF'

  return (
    <>
      {isLoading
        ? <>
            <MenuBar title="menu" showReturnButton={true} />
            <MainLayout className="bg-menu-bg" active="menu" showHeader={false}>
              <MenuWrapper className="flex flex-col mt-8 pb-[40px]" overflow={true}>
                <SectionHeader title="情報" className={HeaderStyles} style={Style}/>
                <MenuSection>
                  <MenuItem
                    src={BtnMenuNews}
                    label="お知らせ"
                    to={MYPAGE_PATHS.NewsListPage}
                    showNotice={hasNewsNotifications || newsMessage?.is_have_news}
                  />
                  <MenuItem src={BtnMenuEvent} label="イベント" to={MYPAGE_PATHS.EventListPage} />
                  <MenuItem src={BtnMenuLive} label="LIVE配信スケジュール" to={`${MYPAGE_PATHS.EventListPage}/${liveScheduleId}`} />
                  <MenuItem src={BtnMenuPresent} label="プレセントBOX" to={MYPAGE_PATHS.PresentPage} />
                  <MenuItem src={BtnMenuItem} label="アイテム" to={MENU_PATHS.ItemPage} />
                </MenuSection>
                <SectionHeader title="ストーリー" className={HeaderStyles} style={Style}/>
                <MenuSection>
                  <MenuItem
                    src={BtnMenuOpening}
                    label="オープニングストーリー"
                    to={NEW_USER_PATHS.OpeningStoryPage}
                    from={MENU_PATHS.Menu}
                    isOpeningStory
                  />
                  <MenuItem src={BtnMenuSide} label="サイドストーリーVol1" to={`${MYPAGE_PATHS.EventListPage}/106`} />
                </MenuSection>
                <SectionHeader title="各種設定" className={HeaderStyles} style={Style}/>
                <MenuSection>
                  <MenuItem src={BtnMenuSettings} label="通知設定" to={MENU_PATHS.AccountPage} />
                  <MenuItem
                    src={BtnMenuIdName}
                    label="ID確認・名前変更"
                    to={MENU_PATHS.UserProfilePage}
                  />
                </MenuSection>
                <SectionHeader title="各種表記" className={HeaderStyles} style={Style}/>
                <MenuSection>
                  <MenuItem
                    src={BtnMenuTermsOfService}
                    label="利用規約"
                    to={MENU_PATHS.TermsOfServicePage}
                  />
                  <MenuItem
                    src={BtnMenuPrivacyPolicy}
                    label="プライバシーポリシー"
                    to={MENU_PATHS.PrivacyPolicyPage}
                  />
                  <MenuItem
                    src={BtnMenuRegulation}
                    label="特定商取引法"
                    to={MENU_PATHS.SpecifiedCommercialPage}
                  />
                </MenuSection>
                <SectionHeader title="運営・お問い合わせ" className={HeaderStyles} style={Style}/>
                <MenuSection>
                  <MenuItem src={BtnMenuHelp} label="ヘルプ・よくある質問" to={MENU_PATHS.HelpPage} />
                  <MenuItem
                    src={BtnMenuContact}
                    label="お問い合わせ"
                    to={MENU_PATHS.InquiryPage}
                    showNotice={hasNotifications || adminMessage?.is_new_message}
                  />
                </MenuSection>
                <SectionHeader title="ホームページ・SNS" className={HeaderStyles} style={Style}/>
                <div className='flex justify-between'>
                  <ButtonSocial alt='アイドルプリンスプロダクション' href='https://ipri-official.jp' srcImg={BtnMenuProduction} style='w-[48%]' />
                  <ButtonSocial alt='アイドルプリンス' href='https://idol-p.jp' srcImg={BtnMenuHP} style='w-[48%]' />
                </div>
                <div className='flex justify-between mt-4'>
                  <ButtonSocial alt='Logo X' href='https://twitter.com/ido1prince' srcImg={BtnMenuBlack} style='w-[15%]' />
                  <ButtonSocial
                    alt='Youtube'
                    href='https://www.youtube.com/@idolprinceproduction/videos'
                    srcImg={BtnMenuYoutube}
                    style='w-[15%] flex items-center'
                    scale='scale-[1.6]' />
                  <ButtonSocial alt='Logo Instagram' href='https://www.instagram.com/idol.prince' srcImg={BtnMenuInsta} style='w-[15%]' />
                  <ButtonSocial alt='Logo TikTok' href='https://www.tiktok.com/@idol.prince' srcImg={BtnMenuTiktok} style='w-[15%]' />
                </div>
              </MenuWrapper>
            </MainLayout>
          </> : (
            <Wrapper>
              <Loading />
            </Wrapper>
          )}
    </>
  )
}

const ButtonSocial = ({ srcImg, style, href, alt, scale }) => {
  return <div className={`${style} cursor-pointer`} onClick={() => { window.open(href, '_blank') }}>
    <img src={srcImg} alt={alt} className={scale} />
  </div>
}

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`
ButtonSocial.propTypes = {
  href: PropTypes.string,
  style: PropTypes.string,
  srcImg: PropTypes.string,
  alt: PropTypes.string,
  scale: PropTypes.string
}

const MenuSection = ({ children, className }) => {
  return <div className={`grid grid-cols-2 gap-4 items-center ${className}`}>{children}</div>
}

MenuSection.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}
