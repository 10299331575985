/* eslint-disable no-unused-vars */
import clsx from 'clsx'
import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import ShopPaymentItem from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentItem'
import PurchaseButton from 'src/pages/main/shop/components/ShopDiamondTab/PurchaseButton'
import ReverseButton from 'src/components/common/ReverseButton'

import BtnQuestion from 'src/assets/img/shop/btn_question.png'

const ShopItem = ({ handleSelect, shopDetails }) => {
  const [isReverse, setIsReverse] = useState(false)
  const { user } = useAuthState()

  const includeItemDreamCompass = useMemo(
    () => shopDetails.payment_items.some((item) => item.item_kind === 'dream_compass'),
    [shopDetails]
  )

  return (
    <div className='relative h-[170px]'>
      {isReverse || (
        <div className="h-full">
          <div className="flex flex-col items-center justify-between h-full space-y-4 font-hiragino relative">
            <div className="flex items-center text-center w-full space-x-1 whitespace-nowrap">
              {Number.isInteger(shopDetails?.purchase_limit) && (
                <div className="py-1 text-xs text-white rounded font-vldLineGR bg-vibrant-pink w-[70px]">
                  {shopDetails?.purchase_limit}回限り
                </div>
              )}
              {user.dream_compass && includeItemDreamCompass && (
                <div className="text-[10px] w-[70px] font-vldLineGR rounded py-1 bg-white border border-menu-dark-blue">
                  残り<span className="text-xs">{user.dream_compass_remaining_days}</span>日
                </div>
              )}
            </div>

            {shopDetails.explanatory_text && (
              <button onClick={() => setIsReverse(true)} className="absolute -top-4 right-0">
                <img src={BtnQuestion} className="w-7" />
              </button>
            )}
            <ShopPaymentItem shopDetails={shopDetails} />
            <PurchaseButton
              cost={shopDetails?.listed_price}
              onClick={handleSelect}
              disabled={user.dream_compass && includeItemDreamCompass}
            />
          </div>
        </div>
      )}

      {isReverse && <ReverseButton shopDetails={shopDetails} setIsReverse={setIsReverse} />}
    </div>
  )
}

ShopItem.propTypes = {
  handleSelect: PropTypes.func.isRequired,
  shopDetails: PropTypes.object
}

export default ShopItem
