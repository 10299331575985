export default class IdolCharacter {
  constructor(id, name, birthday, gender, playable, subName, createdAt, updatedAt, level, navigator) {
    this.id = id
    this.name = name
    this.birthday = birthday
    this.gender = gender
    this.playable = playable
    this.subName = subName
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.level = level
    this.navigator = navigator
  }
}
