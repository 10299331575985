import React, { useState } from 'react'
import waitingTimeBanner from '../assets/waiting_baner.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { formattedCountDownTime } from 'src/lib/date'

const WaitingTime = ({ time, className }) => {
  const timeWaiting = new Date(time).getTime()
  const timeCurrent = new Date().getTime()
  const [isVisible, setIsVisible] = useState(timeWaiting > timeCurrent && (timeWaiting - timeCurrent) <= 5 * 60 * 1000)

  return (
    <>
      { isVisible && (
        <div className={clsx('absolute w-[fit-content]', className)}>
          <img src={waitingTimeBanner} className="w-[155px] h-[44px]" />
          <span className="absolute bottom-[0px] text-[10px] left-[70%] translate-x-[-50%] text-white font-hiragino">
            <Countdown
              date={new Date(time)}
              onComplete={() => setIsVisible(false)}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </div>)
      }
    </>
  )
}

WaitingTime.propTypes = {
  time: PropTypes.string,
  className: PropTypes.string
}

export { WaitingTime }
