/* eslint-disable no-unused-vars */
import styled from 'styled-components'
import { CharacterItem } from 'src/components/ranking/CharacterItem'
import { RankingItem } from 'src/components/ranking/RankingItem'
import { TimeTabs } from 'src/components/ranking/TimeTabs'
import { Link, useNavigate } from 'react-router-dom'
import { rankingCharacters } from 'src/constants/characters'
import { CharacterDetail } from 'src/components/ranking/CharacterDetail'
import { Modal } from 'src/components/common/Modal'
import { useFetchRankingDetailData } from './hooks/useFetchRankingDetailData'

import BGPage from 'src/assets/img/ranking/bg-page.png'
import Effect1 from 'src/assets/img/ranking/bg-eff-1.png'
import Effect2 from 'src/assets/img/ranking/bg-eff-2.png'
import Effect3 from 'src/assets/img/ranking/bg-eff-3.png'
import BGGrid from 'src/assets/img/ranking/bg-grid.png'
import BackIcon from 'src/assets/img/ranking/icon-back.png'
import HomeIcon from 'src/assets/img/ranking/icon-home.svg'
import BGMenu from 'src/assets/img/ranking/bg-menu.png'
import BGBackIcon from 'src/assets/img/ranking/bg-back-icon.png'
import BGHomeIcon from 'src/assets/img/ranking/bg-home-icon.png'
import ChartIcon from 'src/assets/img/ranking/detail/chart-icon.png'
import Signature from 'src/assets/img/ranking/tutorial/signature.png'
import BGTutorial from 'src/assets/img/ranking/tutorial/bg-modal.png'
import BGBtnClose from 'src/assets/img/ranking/tutorial/bg-btn.png'
import TipsModal from 'src/components/common/TipsModal'

export const RankingDetailPage = () => {
  const navigate = useNavigate()
  const {
    theme,
    activeIndex,
    setActiveIndex,
    onChangeTab,
    Tabs,
    ranking,
    openDetailModal,
    openDetail,
    activeTab,
    rankingDetail
  } = useFetchRankingDetailData()

  const currentDate = new Date()
  const targetDate = new Date(2023, 6, 1)

  return (
    <div className="relative">
      <div className="h-[264px]">
        <Header className="sm:w-[400px] w-full h-[264px] fixed top-0 left-0 sm:left-1/2 sm:-translate-x-1/2 content-center pt-8 z-[100]">
          <div className="flex items-start justify-between">
            <MenuLeft>
              <div className="h-5 w-auto px-5 relative">
                <div
                  onClick={() => navigate(-1)}
                  className="h-full w-5 pr-[3px] pb-[3px] cursor-pointer"
                  style={{
                    backgroundImage: `url(${BGBackIcon})`,
                    backgroundSize: '90% 90%',
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                  <img className="h-full w-full object-contain" src={BackIcon} alt="" />
                </div>
              </div>
              <div className="bg-white w-[1px] h-6" />
              <div className="h-5 pl-4 pr-10 relative">
                <div
                  className="h-full w-5 pr-[2px] pb-[1px] cursor-pointer"
                  style={{
                    backgroundImage: `url(${BGHomeIcon})`,
                    backgroundSize: '80% 90%',
                    backgroundPosition: 'right bottom',
                    backgroundRepeat: 'no-repeat'
                  }}
                >
                  <Link to="/mypage">
                    <img className="h-full w-full object-cover" src={HomeIcon} alt="" />
                  </Link>
                </div>
              </div>
            </MenuLeft>
          </div>
          <PageTitle background={theme?.bgTitle}>{theme?.title}</PageTitle>
          <div className="relative">
            <div className="grid grid-cols-5 gap-1 px-2 pb-2">
              {rankingCharacters.map((character) => (
                <CharacterItem
                  key={character.id}
                  character={character}
                  isActive={character.id === activeIndex}
                  onClick={() => setActiveIndex(character.id)}
                />
              ))}
            </div>
          </div>
          <div className="px-2 pt-1 pb-4">
            <TimeTabs onChangeTab={onChangeTab} tabs={Tabs} />
          </div>
        </Header>
      </div>
      <div className="absolute top-0 lef-0 right-0 bottom-0">
        <div className="sm:w-[400px] w-full fixed top-10 bottom-0">
          <img src={Effect1} alt="" />
        </div>
        <div className="sm:w-[400px] w-full fixed top-18 bottom-0">
          <img src={Effect2} alt="" />
        </div>
        <div className="sm:w-[400px] w-full fixed top-0 bottom-0">
          <img src={Effect3} alt="" />
        </div>
        <div className="sm:w-[400px] w-full fixed top-0 bottom-0 z-0">
          <img src={BGGrid} alt="" />
        </div>
      </div>
      <Wrapper>
        <div className="pl-2 fixed left-0 top-[276px] z-[100] sm:w-[400px] w-full sm:left-1/2 sm:-translate-x-1/2">
          <RankingHeader background={theme?.bgHeader}>
            <img
              style={{
                filter:
                  'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(353deg) brightness(110%) contrast(101%)'
              }}
              className="w-3 h-4 object-contain"
              src={ChartIcon}
            />
            {theme?.rankingHeader}
          </RankingHeader>
        </div>
        <PageContent className="sm:w-[400px] py-8 min-h-[500px]" background={theme?.bgPage}>
          <div className="px-2 pt-2.5">
            {(currentDate > targetDate || activeTab !== 'previous_month') && <div className="grid grid-cols-5 gap-1.5 py-2.5">
              {(ranking[activeTab] || []).map((item, index) => (
                <RankingItem key={index} ranking={item} onClick={() => openDetailModal(item.id)} />
              ))}
            </div>
            }
          </div>
        </PageContent>
        <Modal
          open={openDetail}
          onClose={openDetailModal}
          className="sm:w-[380px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino max-h-[95%] overflow-y-auto"
        >
          <img
            className="absolute -top-3 -left-2.5 w-[45%] h-8 z-50"
            src={Signature}
            alt="signature"
          />
          <ModalTutorial className="px-6">
            <CharacterDetail character={rankingDetail} id={activeIndex} />
            <BtnTutorialClose
              className="mt-1 mb-4 text-base font-bold text-[#606060] cursor-pointer"
              onClick={openDetailModal}
            >
              完了
            </BtnTutorialClose>
          </ModalTutorial>
        </Modal>
        <TipsModal type="ranking_detail" />
      </Wrapper>
    </div>
  )
}

const Header = styled.div.attrs({
  className: 'sm:w-[400px] w-screen mx-auto'
})`
  background-image: url(${BGPage});
  background-size: cover;
`

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto bg-fixed font-hiragino'
})`
  background-image: url(${BGPage});
  background-size: 100% 100%;
`

const MenuLeft = styled.div.attrs({
  className: 'flex items-center justify-between h-9'
})`
  background-image: url(${BGMenu});
  background-size: 100% 100%;
`

const PageTitle = styled.div.attrs({
  className: 'h-fix w-full text-left text-white text-sm py-1 pl-4 my-3'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const PageContent = styled.div.attrs({
  className: 'sm:w-[400px] w-full overflow-y-auto -z-[1] bg-fixed'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const RankingHeader = styled.div.attrs({
  className: 'py-1 pl-5 h-fix flex justify-start items-center'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const ModalTutorial = styled.div.attrs({
  className: 'w-full h-fit px-8 py-7 relative'
})`
  background-image: url(${BGTutorial});
  background-size: 100% 102%;
  background-repeat: no-repeat;
`
const BtnTutorialClose = styled.div.attrs({
  className: 'mx-auto w-32 py-1.5 text-center'
})`
  background-image: url(${BGBtnClose});
  background-size: 100% 100%;
`
