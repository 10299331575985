export const userPossessionHeart = (user) => {
  if (!user) return
  const possessionHeart = user.possession_heart
  const recoveryHeart = user.possession_heart_recovery_or_maximum
  return possessionHeart + recoveryHeart
}

export const canSpendHeart = (userHeart, msgHeart) => {
  return userHeart >= msgHeart
}
