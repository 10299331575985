import styled from 'styled-components'
import PropTypes from 'prop-types'

import DiamondRed from 'src/assets/img/dressup/diamond-red.png'
import BGBtnCreate from 'src/assets/img/dressup/btn_create_2.png'
import BGUpgrade from 'src/assets/img/dressup/upgrade.png'

const ButtonCreate = ({ onClick, diamond = 20, notCreated = false, className = 'w-[92px] h-[24px] pl-4' }) => {
  return (
    <ButtonWrapper notCreated={notCreated} className={className} onClick={onClick}>
      <div className='flex justify-start items-center w-full h-full py-1.5'>
        <img className='w-3 h-3' src={DiamondRed} alt="" />
        <span className='-ml-[2px] text-[5px] font-semibold text-white mt-2'>x{parseInt(diamond) ? diamond : 20}</span>
      </div>
    </ButtonWrapper>
  )
}

ButtonCreate.propTypes = {
  onClick: PropTypes.func.isRequired,
  diamond: PropTypes.number,
  className: PropTypes.string,
  notCreated: PropTypes.bool
}

const ButtonWrapper = styled.div.attrs({
  className: 'cursor-pointer'
})`
  background-image: url(${props => props.notCreated ? BGUpgrade : BGBtnCreate});
  background-size: 100% 100%;
`

export default ButtonCreate
