import Story from 'src/models/Story'
import axiosInstance from 'src/services/common/axiosInstance'

const getFirstStories = async () => {
  try {
    const res = await axiosInstance.get('/stories/first_stories')
    const items = []

    for (const i of res.data.stories) {
      const item = new Story(
        i.id,
        i.type,
        i.is_blackout,
        i.blackout_color,
        i.address,
        i.character_name,
        i.description,
        i.character_voice_url,
        i.character_voice_repeat,
        i.background_image,
        i.bgm_url,
        i.bgm_audio_repeat,
        i.is_bgm_stop,
        i.before_bgm_setting,
        i.se_url,
        i.se_audio_repeat,
        i.time_to_display,
        i.type === 'Blackout' ? i.time_to_blackout : i.time_to_change,
        i.type === 'CancelBlackout' ? i.time_until_morning : i.time_to_leave,
        i.character_image_thumbnail,
        i.character_image_width,
        i.character_image_height,
        i.character_image_width_when_move,
        i.character_image_height_when_move,
        i.character_image_horizontal_position,
        i.character_image_vertical_position,
        i.character_image_horizontal_position_when_move,
        i.character_image_vertical_position_when_move
      )
      items.push(item)
    }
    return items
  } catch (error) {
    return []
  }
}

export default getFirstStories
