import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const loginByLine = async (accessToken, idToken) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.LoginByLine, {
      params: {
        id_token: idToken,
        access_token: accessToken,
        code_manager_id: localStorage.getItem('adcd'),
        xuid: localStorage.getItem('xuid')
      }
    })
    localStorage.removeItem('adcd')
    if (res.data.success) {
      return res.data.items
    }
    return res.data.message
  } catch (error) {
    return false
  }
}

export default loginByLine
