import clsx from 'clsx'
import PropTypes from 'prop-types'
import React from 'react'

const ShopBlock = ({ children, isPink = false }) => {
  return (
    <div
      className={clsx(
        'rounded w-full p-1 m-auto h-full',
        isPink ? 'shop-bg-border-pink' : 'shop-bg-border'
      )}
    >
      <div className="p-2 bg-white">{children}</div>
    </div>
  )
}

ShopBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isPink: PropTypes.bool
}

export default ShopBlock
