import React from 'react'
import { Navigate } from 'react-router-dom'
import { LoginPage } from 'src/pages/newUser/LoginPage'
import { LoginLinePage } from 'src/pages/newUser/LoginLine'
import { LiffPage } from 'src/pages/newUser/Liff'
import { OpeningPage } from 'src/pages/newUser/OpeningPage'
import { OpeningStoryPage } from 'src/pages/newUser/OpeningStory'
import { RegistTermOfServicePage } from 'src/pages/newUser/RegistTermOfService'
import { RegistUserPage } from 'src/pages/newUser/RegistUser'
import { MyPage } from 'src/pages/main/MyPage'

const NEW_USER_PATHS = {
  TermOfServicePage: '/term-of-service',
  RegistUserPage: '/regist-user',
  LoginPage: '/login',
  LoginLinePage: '/login-line',
  LiffPage: '/liff',
  OpeningPage: '/opening',
  OpeningStoryPage: '/opening-story',
  MyPage: '/mypage'
}

const LoginRouter = [
  { path: NEW_USER_PATHS.LoginPage, element: <LoginPage /> },
  { path: '*', element: <Navigate to={NEW_USER_PATHS.LoginPage} /> }
]

const LoginLineRouter = [
  { path: NEW_USER_PATHS.LoginLinePage, element: <LoginLinePage /> },
  { path: '*', element: <Navigate to={NEW_USER_PATHS.LoginLinePage} /> }
]

const LiffRouter = [
  { path: NEW_USER_PATHS.LiffPage, element: <LiffPage /> },
  { path: '*', element: <Navigate to={NEW_USER_PATHS.LiffPage} /> }
]

const NewUserRouter = [
  { path: NEW_USER_PATHS.RegistUserPage, element: <RegistUserPage /> },
  { path: '*', element: <Navigate to={NEW_USER_PATHS.RegistUserPage} /> }
]

const TermRouter = [
  { path: NEW_USER_PATHS.TermOfServicePage, element: <RegistTermOfServicePage /> },
  { path: NEW_USER_PATHS.OpeningPage, element: <OpeningPage /> },
  { path: NEW_USER_PATHS.OpeningStoryPage, element: <OpeningStoryPage /> }
]

const MaintenanceRouter = [
  { path: NEW_USER_PATHS.MyPage, element: <MyPage /> },
  { path: '*', element: <Navigate to={NEW_USER_PATHS.MyPage} /> }
]

export { LoginRouter, LoginLineRouter, LiffRouter, TermRouter, NewUserRouter, NEW_USER_PATHS, MaintenanceRouter }
