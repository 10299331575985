import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { DRAW_TYPE, GACHA_TYPES } from 'src/models/GachaInfo'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import Loading from 'src/components/layouts/Loading'
import CapsuleTab from 'src/pages/main/capsule/CapsuleTab'
import CapsuleMixerTab from 'src/pages/main/capsule/CapsuleMixerTab'
import { Modal } from 'src/components/common/Modal'
import CapsuleDrawModal from 'src/pages/main/capsule/CapsuleDrawModal'
import Item, { ITEM_TYPE } from 'src/models/Item'
import TipsModal from 'src/components/common/TipsModal'
import GrowEffectModal from 'src/components/Effect/GrowEffectModal'

import BtnMixerOn from 'src/assets/img/gacha/btn_4_on.png'
import BtnKisekaeOn from 'src/assets/img/gacha/btn_1_on.png'
import BtnItemOn from 'src/assets/img/gacha/btn_2_on.png'
import BtnGekijyoOn from 'src/assets/img/gacha/btn_3_on.png'
import BtnMixerOff from 'src/assets/img/gacha/btn_4_off.png'
import BtnKisekaeOff from 'src/assets/img/gacha/btn_1_off.png'
import BtnItemOff from 'src/assets/img/gacha/btn_2_off.png'
import BtnGekijyoOff from 'src/assets/img/gacha/btn_3_off.png'
import Improgress from './components/Improgress'

const CapsuleToyPage = ({
  isLoading,
  drawGacha,
  drawMixerGacha,
  resultItems,
  itemsTicket,
  selectedIndex,
  setSelectedIndex,
  redCount,
  blueCount,
  ticketCount,
  ssrCount,
  gachas,
  setItemsUpdate,
  items
}) => {
  const [showDrawModal, setShowDrawModal] = useState(false)
  const [drawType, setDrawType] = useState(DRAW_TYPE.Free)
  const [mixerGacha, setMixerGacha] = useState(null)
  const [clothesGacha, setClothesGacha] = useState(null)
  const [itemsGacha, setItemsGacha] = useState(null)
  const [scenesGacha, setScenesGacha] = useState(null)
  const [isVideoPlaying, setIsVideoPlaying] = useState(true)
  const [ticket, setTicket] = useState(false)
  const [idItem, setIdItem] = useState(0)
  const [countRoll, setCountRoll] = useState({})
  const [rollFree, setRollFree] = useState([])
  const [countTimeRollCurrent, setcountTimeRoll] = useState({})
  const [gachaDetail, setGachaDetail] = useState([])
  const [regularTicket, setRegularTicket] = useState([])
  const [SSRTicket, setSSRTicket] = useState([])
  const [typeGacha, setTypeGacha] = useState('item')
  const [isGrowModalOpen, setIsGrowModalOpen] = useState(false)
  const [modals, setModals] = useState([])
  const [currentModal, setCurrentModal] = useState({})

  useEffect(() => {
    if (!gachas) return

    if (Array.isArray(gachas)) {
      const mixer = gachas.filter(
        (el) => el.gachaType === GACHA_TYPES.Mixer && el.status !== 'disable'
      )
      const clothes = gachas.filter(
        (el) => el.gachaType === GACHA_TYPES.Clothes && el.status !== 'disable'
      )
      const itemsGacha = gachas.filter(
        (el) => el.gachaType === GACHA_TYPES.Items && el.status !== 'disable'
      )
      const stills = gachas.filter(
        (el) => el.gachaType === GACHA_TYPES.Still && el.status !== 'disable'
      )

      setMixerGacha(mixer)
      setClothesGacha(clothes)
      setItemsGacha(itemsGacha)
      setIdItem(itemsGacha[0]?.id)
      setScenesGacha(stills)
    }
  }, [gachas])

  useEffect(() => {
    const filterTicketItems = itemsTicket?.filter(
      (el) => el.type === ITEM_TYPE.Special && el.itemKind === 'gacha_ticket'
    )

    const filterSSRItems = itemsTicket?.filter(
      (el) => el.type === ITEM_TYPE.Special && el.itemKind === 'ssr_ticket'
    )
    setRegularTicket(filterTicketItems)
    setSSRTicket(filterSSRItems)
  }, [itemsTicket])

  useEffect(() => {
    if (!currentModal) return
    currentModal.id && setIsGrowModalOpen(true)
  }, [currentModal])

  const openDrawModal = (type) => {
    setDrawType(type)
    setShowDrawModal(true)
  }

  const closeDrawModal = () => {
    setShowDrawModal(false)
  }

  const handleSetModalList = (res) => {
    const arr = []
    res.forEach((data) => {
      if (data.current_level < data.next_level) {
        arr.push(data)
      }
    })
    if (arr.length > 0) {
      setModals(arr)
      setCurrentModal(arr[0])
    }
  }

  const closeGrowModal = () => {
    if (modals && currentModal && currentModal.id !== modals[modals.length - 1].id) {
      const index = modals.findIndex((d) => d.id === currentModal.id)
      setIsGrowModalOpen(false)
      setTimeout(() => {
        setCurrentModal(modals[index + 1])
      }, 100)
    } else {
      setIsGrowModalOpen(false)
      setCurrentModal({})
    }
  }

  return (
    <>
      <MenuBar title="gacha" />
      <MainLayout active="gacha" classNameHeader="pt-menu-header-offset xs:px-4 px-0">
        <StandardPageWrapper
          className={clsx('h-full flex flex-col', isLoading && 'justify-center p-4')}
        >
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <Tab.Group
                selectedIndex={selectedIndex}
                onChange={(index) => {
                  setSelectedIndex(index)
                  if (index === 0) {
                    setIdItem(clothesGacha[0]?.id)
                    setTypeGacha('cloth')
                  }
                  if (index === 1) {
                    setIdItem(itemsGacha[0]?.id)
                    setTypeGacha('item')
                  }
                }}
              >
                <Tab.List className="h-16 pt-4 px-2 w-full flex gap-1 items-around">
                  <Tab>
                    {({ selected }) => (
                      <img src={selected ? BtnGekijyoOn : BtnGekijyoOff} alt="劇場ガチャ" />
                    )}
                  </Tab>
                  <Tab>
                    {({ selected }) => (
                      <img src={selected ? BtnItemOn : BtnItemOff} alt="アイテムガチャ" />
                    )}
                  </Tab>
                  <Tab>
                    {({ selected }) => (
                      <img src={selected ? BtnKisekaeOn : BtnKisekaeOff} alt="着せ替えガチャ" />
                    )}
                  </Tab>
                  <Tab>
                    {({ selected }) => (
                      <img src={selected ? BtnMixerOn : BtnMixerOff} alt="ミキサーガチャ" />
                    )}
                  </Tab>
                </Tab.List>

                {!gachas || gachas.length === 0 ? (
                  // <div className="flex justify-center h-full flex-col p-4">
                  //   <Improgress />
                  // </div>
                  <Loading />
                ) : (
                  <>
                    {clothesGacha?.length > 0 && selectedIndex === 0 ? (
                      <CapsuleTab
                        gachas={clothesGacha}
                        isShown={selectedIndex === 0}
                        showDrawModal={openDrawModal}
                        setTicket={setTicket}
                        setIdItem={setIdItem}
                        countRoll={countRoll}
                        setCountRoll={setCountRoll}
                        rollFree={rollFree}
                        setGachaDetail={setGachaDetail}
                        gachaDetail={gachaDetail}
                        countTimeRollCurrent={countTimeRollCurrent}
                        setcountTimeRoll={setcountTimeRoll}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 0}
                        className="absolute top-0 left-0 right-0 flex justify-center h-full flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )}
                    {itemsGacha?.length > 0 && selectedIndex === 1 ? (
                      <CapsuleTab
                        gachas={itemsGacha}
                        isShown={selectedIndex === 1}
                        showDrawModal={openDrawModal}
                        setTicket={setTicket}
                        setIdItem={setIdItem}
                        countRoll={countRoll}
                        setCountRoll={setCountRoll}
                        rollFree={rollFree}
                        setGachaDetail={setGachaDetail}
                        gachaDetail={gachaDetail}
                        countTimeRollCurrent={countTimeRollCurrent}
                        setcountTimeRoll={setcountTimeRoll}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 1}
                        className="absolute top-0 left-0 right-0 flex justify-center h-full flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                      // <Loading />
                    )}
                    {scenesGacha?.length > 0 && selectedIndex === 2 ? (
                      <CapsuleTab
                        gachas={scenesGacha}
                        isShown={selectedIndex === 2}
                        showDrawModal={openDrawModal}
                        setTicket={setTicket}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 2}
                        className="absolute top-0 left-0 right-0 flex justify-center h-full flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )}
                    {mixerGacha.length > 0 && selectedIndex === 3 ? (
                      <CapsuleMixerTab
                        isShown={selectedIndex === 3}
                        showDrawModal={openDrawModal}
                        startDate={mixerGacha.startDate}
                        endDate={mixerGacha.endDate}
                        image={mixerGacha.image}
                        items={itemsTicket}
                        drawMixerGacha={drawMixerGacha}
                        resultItem={resultItems?.[0]}
                      />
                    ) : (
                      <FadeTransition
                        show={selectedIndex === 3}
                        className="absolute top-0 left-0 right-0 flex justify-center h-full flex-col p-4 pointer-events-none"
                      >
                        <Improgress />
                      </FadeTransition>
                    )}
                  </>
                )}
              </Tab.Group>
              <Modal
                focusOutside={false}
                open={showDrawModal}
                onClose={isVideoPlaying ? () => false : closeDrawModal}
                isVideoPlaying={isVideoPlaying}
                className="sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
              >
                <CapsuleDrawModal
                  drawType={drawType}
                  drawGacha={drawGacha}
                  handleClose={closeDrawModal}
                  redCount={redCount}
                  blueCount={blueCount}
                  ticketCount={regularTicket[0]?.amount || 0}
                  ssrCount={SSRTicket[0]?.amount || 0}
                  resultItems={resultItems}
                  isVideoPlaying={isVideoPlaying}
                  setIsVideoPlaying={setIsVideoPlaying}
                  ticket={ticket}
                  idItem={idItem}
                  setCountRoll={setCountRoll}
                  setRollFree={setRollFree}
                  setGachaDetail={setGachaDetail}
                  setItemsTicketUpdate={setItemsUpdate}
                  itemsUpdate={itemsTicket}
                  itemsTicketRoll={items}
                  setcountTimeRoll={setcountTimeRoll}
                  typeGacha={typeGacha}
                  handleSetModalList={handleSetModalList}
                />
              </Modal>
              {currentModal && currentModal.id && (
                <GrowEffectModal
                  character={currentModal}
                  isGrowModalOpen={isGrowModalOpen}
                  level={{
                    currentLevel: currentModal.current_level,
                    nextLevel: currentModal.next_level
                  }}
                  onClose={closeGrowModal}
                />
              )}
              <TipsModal type="capsule" />
            </>
          )}
        </StandardPageWrapper>
      </MainLayout>
    </>
  )
}

CapsuleToyPage.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  drawGacha: PropTypes.func.isRequired,
  drawMixerGacha: PropTypes.func.isRequired,
  resultItems: PropTypes.arrayOf(PropTypes.instanceOf(Item)),
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  setSelectedIndex: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired,
  gachas: PropTypes.array,
  itemsTicket: PropTypes.array,
  setItemsUpdate: PropTypes.func
}

export default CapsuleToyPage
