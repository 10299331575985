import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const CheckForbiddenMessage = (content) => {
  return axiosInstance.post(API_ROUTES.Chat.checkForbiddenMessage, {
    message: {
      content
    }
  }).then(res => {
    const isForbidden = res.data.isForbidden

    return isForbidden
  }).catch(error => console.error(error))
}
