import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import btnLiveEnter from '../../assets/btn_live_enter.png'
import mascotChara from '../../assets/mascot_chara.png'
import btnCancel from '../../assets/btn_cancel.png'
import btnShop from '../../assets/btn_shop.png'
import PropTypes from 'prop-types'
import { VOICE_LIVE_PATH } from '../../../../routes/live'

const ConditionModal = ({ open, modalType, livestreamId, freeMinutes, setOpenModal }) => {
  const navigate = useNavigate()

  const textPassport =
    'プラチナパスポートをお持ちでないため、\n' +
    'LIVE配信に参加する事が出来ないコケェ…\n' +
    'ショップから「プラチナパスポート付ダイヤ」を\n' +
    'ご購入の上、お越しください。\n\n' +
    'プラチナパスポートを入手すると、\n' +
    '「LIVE配信」の他にも「交流会」に\n' +
    '参加出来たり、毎日のハートの回復量も\n' +
    '＋20ハート増量されるコケッ！'

  const textDreamCompass =
    '本日のLIVE配信入室条件は、\n' +
    '夢のコンパス有効期限内の\n' +
    'ユーザー様のみだコケッ！\n\n' +
    'ショップから夢のコンパスを購入すると、\n' +
    '配信LIVEに参加できるョ！'

  const getTitle = useMemo(() => {
    if (modalType === 'PLATINUM_PASSPORT' || modalType === 'DREAM_COMPASS') {
      return 'お知らせ'
    } else {
      return '注意事項'
    }
  }, [modalType])

  return (
    <ModalWithBg title={getTitle} open={open} onClose={() => setOpenModal(false)}>
      <div className="space-y-4">
        {modalType === 'FREE_PLATINUM_PASSPORT' ? (
          <>
            <p className="text-center text-[14px] whitespace-pre-line font-hiragino">
              LIVE配信に入場するコケッ！
            </p>
            <p className="text-center text-[14px] whitespace-pre-line font-hiragino">
              プラチナパスポートをお持ちでない為、
              <br/>
              <span className='text-[#FF1D34] text-[14px] font-hiragino'>
                視聴時間は入場から{freeMinutes}分間
                <br/>
              </span>
              のみとなりますコケッ！
            </p>
            <div className='flex justify-center'>
              <img
                className="inline cursor-pointer w-[144px] h-[46px]"
                src={btnLiveEnter}
                onClick={() => {
                  navigate(
                    VOICE_LIVE_PATH.DuringDelivery.replace(':channel_id', livestreamId)
                  )
                }}
              />
            </div>
            <p className='text-[14px]' style={{ marginBottom: 20 }}>※視聴時間を過ぎると強制退出になるョ！</p>
          </>
        ) : (
          <>
            <p className="text-center text-xs whitespace-pre-line">
              {modalType === 'PLATINUM_PASSPORT' ? textPassport : textDreamCompass}
            </p>

            <p className="text-right">
              <img className="inline w-[80px] h-[80px]" src={mascotChara} />
            </p>

            <div className="flex items-center justify-between">
              <img
                className="inline cursor-pointer w-[119px] h-[49px]"
                src={btnCancel}
                onClick={() => setOpenModal(false)}
              />
              <img
                className="inline cursor-pointer w-[119px] h-[49px]"
                src={btnShop}
                onClick={() => navigate('/shop')}
              />
            </div>
          </>
        )}
      </div>
    </ModalWithBg>
  )
}

ConditionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  modalType: PropTypes.string.isRequired,
  livestreamId: PropTypes.number,
  freeMinutes: PropTypes.number,
  setOpenModal: PropTypes.func
}

export default ConditionModal
