import PropTypes from 'prop-types'

const ReverseButton = (props) => {
  const { shopDetails, setIsReverse } = props

  return (
    <div className="h-full overflow-y-auto">
      <div className="w-full pb-1 text-sm whitespace-pre-line border-b font-vldLineGR border-menu-dark-blue">
        {`${shopDetails.first_name}\n購入特典`}
      </div>
      <div className="w-full mt-1 text-xs text-left whitespace-pre-line font-hiragino max-h-[144px] overflow-y-scroll">
        {shopDetails.explanatory_text}
      </div>
      <button className="absolute -bottom-1 -right-1" onClick={() => setIsReverse(false)}>
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.1382 4.86905C12.9944 3.72176 11.3921 3.00617 9.64328 3.00723H7.3515V2.65148V0.279358L1.45972 5.07023L7.3515 9.86158V7.41695V7.15855H9.64328C10.2587 7.15958 10.7972 7.40226 11.2028 7.80411C11.605 8.21005 11.8477 8.74823 11.8487 9.36392C11.8477 9.97961 11.6049 10.5178 11.2028 10.9232C10.7972 11.3256 10.2587 11.5683 9.64328 11.5693H0V15.7206H9.64328C11.3921 15.7217 12.9944 15.0061 14.1382 13.8588C15.285 12.7151 16.0011 11.1127 16 9.36392C16.001 7.61514 15.285 6.01276 14.1382 4.86905Z"
            fill="#A1D2FF"
          />
        </svg>
      </button>
    </div>
  )
}

export default ReverseButton

ReverseButton.propTypes = {
  shopDetails: PropTypes.object,
  setIsReverse: PropTypes.func.isRequired
}
