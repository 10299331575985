import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import BackroundPopupFrame from 'src/assets/img/in-progress/bg_popup_frame.png'
import ButtonOk from 'src/assets/img/common/btn_ok.png'
import GoodFeeling from 'src/assets/img/in-progress/good-feeling.png'

const PopUpProgress = () => {
  const [isPopup, setIsPopup] = useState(true)
  const navigate = useNavigate()

  return isPopup ? (
    <div className="absolute top-0 bottom-0 w-screen  sm:w-desktop-width bg-progress-gray flex justify-center ">
      <div className="max-w-[327px] max-h-[305px] w-[83%] mt-[45%] relative ">
        <img src={BackroundPopupFrame} alt="" />
        <div className="absolute top-0 flex items-center flex-col w-full pt-[21px]">
          <div>
            <img className="w-[115px] mb-3" src={GoodFeeling} alt="in-progress" />
          </div>
          <span className="font-bold text-base text-[#333333]">こちらは現在準備中です</span>
          <span className="font-bold text-base text-[#333333]">サービス開始までお待ちください</span>
          <div
            className="w-[117px] h-[48px] mt-[29px] cursor-pointer"
            onClick={() => setIsPopup(false)}
          >
            <button onClick={() => navigate(-1)}>
              <img src={ButtonOk} alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null
}

export default PopUpProgress
