import PropTypes from 'prop-types'
import React from 'react'

import BtnExchange from 'src/assets/img/shop/btn_exchange1.png'

const ExchangeButton = ({ onClick }) => {
  return (
    <button className="w-[136px]drop-shadow" onClick={onClick}>
      <img src={BtnExchange} />
    </button>
  )
}

ExchangeButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default ExchangeButton
