import PropTypes from 'prop-types'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import News from 'src/models/News'
import { genDateString } from 'src/lib/date'
import { removeNewlineCode } from 'src/lib/string'
import InnerHTML from 'dangerously-set-html-content'

const NewsPage = ({ news, isLoading }) => {
  return (
    <>
      <MenuBar title="news" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex flex-col items-center px-2 pb-4 py-0">
          {isLoading ? (
            <Loading />
          ) : !news ? (
            <NoContent />
          ) : (
            <div className="bg-white w-full h-full overflow-y-auto rounded py-3 px-2 text-left font-hiragino text-sm">
              <div className="styled-scroll max-h-full overflow-y-auto">
                <h1 className="font-bold mb-2">{news.title}</h1>
                <div className="mb-2">{genDateString(news.startDisplayDate)}</div>
                <div className="whitespace-pre-line">
                  {news.content && <InnerHTML html={removeNewlineCode(news.content)} />}
                </div>
              </div>
            </div>
          )}
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

NewsPage.propTypes = {
  news: PropTypes.instanceOf(News),
  isLoading: PropTypes.bool.isRequired
}

export default NewsPage
