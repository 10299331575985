import StillR from 'src/assets/img/stillalbum/still_R.png'
import StillSR from 'src/assets/img/stillalbum/still_SR.png'
import StillSSR from 'src/assets/img/stillalbum/still_SSR.png'
import DressupR from 'src/assets/img/dressup/r.png'
import DressupSR from 'src/assets/img/dressup/sr.png'
import DressupSSR from 'src/assets/img/dressup/ssr.png'

export const rarities = {
  r: StillR,
  sr: StillSR,
  ssr: StillSSR
}

export const raritiesHorizontal = {
  r: DressupR,
  sr: DressupSR,
  ssr: DressupSSR
}
