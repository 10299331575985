import styled from 'styled-components'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Notification from 'src/assets/img/mypage/side-menu/menu-news.png'
import MenuPresent from 'src/assets/img/mypage/side-menu/menu-present.png'
import NotificationIcon from '../common/NotificationIcon'
import useNewsHasNotifications from 'src/hooks/useNewsHasNotifications'
import useNewsListChannel from 'src/services/common/useNewsListChannel'

export const NotificationArea = ({ presents }) => {
  const { hasNewsNotifications } = useNewsHasNotifications()
  const { newsMessage } = useNewsListChannel()
  const navigate = useNavigate()
  const CLASSNAMEPRESENT = 'z-10 absolute w-[30px] top-[-20%] right-[-10px] transform skew-y-[15deg]'
  return (
    <NotificationContainer>
      <div onClick={() => navigate('/news')} className="relative">
        <img
          src={Notification}
          alt="notification"
          className="drop-shadow-[0_22px_70px_rgba(0,0,0,0.56)] xs:w-full w-[90%]"
        />
        {(hasNewsNotifications || newsMessage?.is_have_news) && (
          <NotificationIcon className="-top-3 -right-2 w-[30px] transform skew-y-[15deg]" />
        )}
      </div>
      <div onClick={() => navigate('/present')} className="relative">
        {presents?.length > 0 && (
          <NotificationIcon className={CLASSNAMEPRESENT} />
        )}
        <img
          src={MenuPresent}
          alt="MenuPresent"
          className="mt-2 xs:w-full w-[90%] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
        />
      </div>
    </NotificationContainer>
  )
}

NotificationArea.propTypes = {
  presents: PropTypes.array
}

const NotificationContainer = styled.div.attrs({
  className: 'cursor-pointer w-[70px] xs:h-[120px] h-[100px] z-30'
})`
  transform: skew(0, -15deg);
`
