import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const loginByEmail = async (email, password) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.LoginByEmail, { email, password })
    if (res.data.success) {
      return res.data.items
    }
    return res.data.message
  } catch (error) {
    return false
  }
}

export default loginByEmail
