import React from 'react'
import CurrencyChangeIndicator from 'src/components/common/CurrencyChangeIndicator'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'

const ShopExchangeCompleteModal = () => {
  const { user } = useAuthState()
  const { closeModal, selectedHeartExchange } = useShopContext()

  const {
    number_of_red_diamonds: numberOfRedDiamonds,
    number_of_hearts_exchanged: numberOfHeartsExchanged
  } = selectedHeartExchange

  const currentRedDiamonds = user?.red_amount
  const currentPossessionHeart = user.possession_heart

  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[340px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={closeModal} />
      <TitleHeader title="交換完了" />
      <div className="text-lg font-bold text-center whitespace-pre-line">
        {`ダイヤ${numberOfRedDiamonds}個を\nハート${numberOfHeartsExchanged}個に交換しました。`}
      </div>
      <CurrencyChangeIndicator
        icon="red"
        from={currentRedDiamonds + numberOfRedDiamonds}
        to={currentRedDiamonds}
        counter="個"
        hasChange={true}
      />
      <CurrencyChangeIndicator
        icon="life"
        from={currentPossessionHeart - numberOfHeartsExchanged}
        to={currentPossessionHeart}
        counter="個"
        hasChange={true}
      />
    </div>
  )
}

export default ShopExchangeCompleteModal
