import GachaInfo from 'src/models/GachaInfo'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getGachaInfo = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Gachas)

    if (res.data) {
      const gachas = []
      for (const i of res.data.gachas) {
        const gacha = new GachaInfo(
          i.id,
          i.name,
          i.content,
          i.amount,
          i.start_at,
          i.end_at,
          i.gacha_cover,
          i.gacha_type,
          i.high_priority_order,
          i.lottery_count,
          i.payment_limit,
          i.sales_type,
          i.status,
          i.use_compass,
          i.use_ss_gacha_ticket,
          i.event_id,
          i.payment_item_id,
          i.image,
          i.gacha_modes
        )
        gachas.push(gacha)
      }
      return gachas
    } else return []
  } catch (error) {
    return error
  }
}

export default getGachaInfo

/**
 *
 */
