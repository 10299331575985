import styled, { keyframes } from 'styled-components'
import Crystal from 'src/assets/img/effect/common/crystal.png'
import Star from 'src/assets/img/effect/common/star.png'
import BgWhiteMist from 'src/assets/img/effect/common/white_mist.png'
import Heart from 'src/assets/img/effect/love_level/heart.png'
import LoveEffect from 'src/assets/img/effect/love_level/love_effect.png'
import LoveUp from 'src/assets/img/effect/love_level/love_up.png'
import Love from 'src/assets/img/effect/love_level/love.png'
import RibbonPink from 'src/assets/img/effect/love_level/ribbon-pink.png'
import BtnClosePink from 'src/assets/img/common/btn_close_pink.png'
import { Modal } from 'src/components/common/Modal'
import PropTypes from 'prop-types'
import Note from 'src/assets/img/effect/love_level/love_note.png'

const MultiLoveEffectModal = ({ character, isMultiLoveModalOpen, multiLevel, onClose }) => {
  return (
    <Modal
      open={isMultiLoveModalOpen}
      onClose={() => null}
      className="w-full absolute top-[45%] left-1/2 -translate-x-2/4 -translate-y-2/4 transform transition-all max-w-[400px]"
    >
      <ModalBody>
        <ModalContainer>
          <ModalTitle>
            <img src={Love} className="w-[161px]" />
            <img src={LoveUp} className="w-[59px]" />
          </ModalTitle>
          <ModalContent>
            <StarImage src={Crystal} top={23} left={7} delay={0} alt="crystal" />
            <StarImage src={Star} top={18} left={14} delay={2} alt="star" />
            <StarImage src={Crystal} top={18} right={14} delay={1} alt="crystal" />
            <StarImage src={Star} top={23} right={7} delay={4} alt="star" />
            <img
              src={LoveEffect}
              className="absolute top-[20%] left-[6%] w-[343px] z-10"
              alt="love-effect"
            />
            <HeartIcon>
              <img className="w-full" src={Heart} alt="heart" />
            </HeartIcon>
            <div className="absolute w-[322px] bottom-[8%] left-[9%] overflow-hidden">
              <RibbonPinkIcon>
                <img src={RibbonPink} className="w-full" alt="ribbon-pink" />
              </RibbonPinkIcon>
              <p className="text-xl text-white z-10 absolute top-[50%] left-[40%]">
                {character?.name}
              </p>
            </div>
            <img src={Note} className="w-[240px] absolute -bottom-[10%]" />
            {character.level && (
              <div className="font-normal font-weight-400 text-shadow-lg text-[#FF9ECD] text-lg">
                <BeforeLevel
                  length={String(multiLevel.currentLevel).length}
                >{`Lv${multiLevel.currentLevel || ''}`}</BeforeLevel>
                <ArrowLevel length={String(multiLevel.currentLevel).length}>→</ArrowLevel>
                <CurrentLevel
                  length={String(multiLevel.nextLevel).length}
                >{`Lv${multiLevel.nextLevel || ''}`}</CurrentLevel>
              </div>
            )}
            <StarImage src={Crystal} bottom={0} left={9} delay={0} alt="crystal" />
            <StarImage src={Star} bottom={3} left={15} delay={1} alt="star" />
            <StarImage src={Crystal} bottom={0} right={9} delay={2} alt="crystal" />
            <StarImage src={Star} bottom={3} right={15} delay={4} alt="star" />
          </ModalContent>
        </ModalContainer>
        <img
          src={BtnClosePink}
          className="w-28 z-50 mx-auto absolute -bottom-[30%] left-[35%] cursor-pointer"
          alt="next"
          onClick={onClose}
        />
      </ModalBody>
    </Modal>
  )
}

export default MultiLoveEffectModal

MultiLoveEffectModal.propTypes = {
  character: PropTypes.object,
  isMultiLoveModalOpen: PropTypes.bool.isRequired,
  multiLevel: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
}

const shine = keyframes`
  100% {
    left: 125%;
  }
`

const star = keyframes`
  0% {opacity:0;}
  5% {opacity:1;}
  10% {opacity:0;}
  55% {opacity:0;}
  60% {opacity:1;}
  95% {opacity:0;}
  100% {opacity:1;}
`

const heart = keyframes`
  20%{height: 60%;}
  35%{height: 100%;}
  40%{height: 70%;}
  90%{height: 20%;}
  100% {left: 125%;}
`

const ribbon = keyframes`
  0% {top: 0;}
  15% {top: 10%;}
  20%{height: 60%; top: 40%;}
  40%{height: 60%; top: 30%;}
  42%{height: 60%; top: 15%;}
  100% {left: 125%;}
`

const StarImage = styled.img.attrs({
  className: 'absolute'
})`
  top: ${(props) => `${props.top}%`};
  bottom: ${(props) => `${props.bottom}%`};
  left: ${(props) => `${props.left}%`};
  right: ${(props) => `${props.right}%`};
  animation-name: ${star};
  animation-duration: 7s;
  animation-delay: ${(props) => `${props.delay}s`};
  animation-iteration-count: infinite;
`

const HeartIcon = styled.span.attrs({
  className: 'absolute top-[38%] left-[29%] z-20 inline-block w-[171px] overflow-hidden'
})`
  &:before {
    content: '';
    position: absolute;
    top: 0%;
    left: -15%;
    width: 10%;
    height: 95%;
    z-index: 50;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
    animation: ${heart};
    animation-delay: 1s;
    animation-duration: 1.2s;
  }
`

const RibbonPinkIcon = styled.span.attrs({
  className: 'z-0 relative'
})`
  &:before {
    content: '';
    position: absolute;
    top: 0%;
    left: -10%;
    width: 5%;
    height: 10%;
    z-index: 50;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
    animation: ${ribbon};
    animation-delay: 1s;
    animation-duration: 1.2s;
  }
`

const BeforeLevel = styled.div.attrs({
  className: 'z-30 absolute top-[53%] font-bold'
})`
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  left: ${(props) => (props.length === 1 ? '37%' : '35%')};
`

const ArrowLevel = styled.div.attrs({
  className: 'z-30 absolute top-[52%] font-bold'
})`
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  left: ${(props) => (props.length === 1 ? '45%' : '46%')};
`

const CurrentLevel = styled.div.attrs({
  className: 'z-30 absolute font-bold'
})`
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  font-size: ${(props) => (props.length === 1 ? '34px' : '26px')};
  top: 52%;
  left: ${(props) => (props.length === 1 ? '50%' : '51.5%')};
`

const ModalBody = styled.div.attrs({
  className: 'relative'
})``

const ModalContainer = styled.div.attrs({
  className: 'relative'
})`
  background-image: url(${BgWhiteMist});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className: 'flex absolute top-[11%] left-[22%] z-50 overflow-x-hidden'
})`
  &:before {
    content: '';
    position: absolute;
    top: 10%;
    left: -15%;
    width: 10%;
    height: 80%;
    z-index: 50;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);
    animation: ${shine};
    animation-delay: 1s;
    animation-duration: 1.2s;
  }
`

const ModalContent = styled.div.attrs({
  className: 'h-[350px] relative flex justify-center'
})``
