import React from 'react'
import PropTypes from 'prop-types'
import InfoBox from 'src/components/common/InfoBox'

const ShopPaymentConditions = ({ method }) => {
  switch (method) {
    case 'credit':
      return (
        <InfoBox title="不正利用防止について" className="mt-4">
          <p>
            ご利用のクレジットカード会社で不正を検知した場合、決済が一時中断されクレジットカード会社からご本人確認をさせていただく場合が御座います。
            カード情報入力画面で電話番号の入力にお間違いないようご確認ください。また本人確認のご連絡があった際はご協力頂けますようお願い申し上げます。
          </p>
        </InfoBox>
      )
    // case 'web_money':
    //   return (
    //     <InfoBox title="WebMoneyのご利用方法" className="mt-4">
    //       <p>
    //         コンビニやイオン、薬局などで販売されているWebMoneyギフトカードをご購入頂き、カードに記載されているプリペイド番号をご入力頂くだけで簡単に決済が完了します。ギフトカード販売店は
    //         <a
    //           href="https://www.webmoney.jp/variation/gift.html"
    //           target="_blank"
    //           rel="noopener nofollow noreferrer"
    //           className="underline text-blue-600 cursor-pointer font-semibold"
    //         >
    //           こちら
    //         </a>
    //         インターネット上でもWebMoneyのご購入が可能ですので、詳しくは
    //         <a
    //           href="https://www.webmoney.jp/guide/buy.html"
    //           target="_blank"
    //           rel="noopener nofollow noreferrer"
    //           className="underline text-blue-600 cursor-pointer font-semibold"
    //         >
    //           こちら
    //         </a>
    //         をご確認下さい。
    //       </p>
    //     </InfoBox>
    //   )
    // case 'amazon_pay':
    //   return (
    //     <InfoBox title="Amazon Payのご利用方法" className="mt-4">
    //       <p>
    //         ｢amazon pay｣ボタンを押して頂くと、Amazonのサインイン画面に移行します。
    //         AmazonのID・パスワードをご入力頂き、サインインを行ってください。
    //         <br />
    //         Amazonにご登録済みのクレジットカード(複数のクレジットカードを登録している場合は決済を行うクレジットカードを選択してください)、または、Amazonギフト券の残高からお支払い頂けます。
    //       </p>
    //     </InfoBox>
    //   )
    case 'paypay':
      return (
        <InfoBox title="Paypayのご利用方法" className="mt-4">
          <p>
            ｢決済へ進む｣ボタンを押して頂くと、自動でPaypayのアプリが立ち上がります。(事前にPaypayアプリがスマートフォンにインストールされている場合のみ)
            ｢支払う｣ボタンをクリックすると支払いが完了致します。
            <br />
            <br />
            ※Paypayのアプリをお持ちでない場合、ログイン画面が表示されますので登録済みの電話番号とパスワードでログインしてください。(事前にPaypayへの登録が必要となります)
          </p>
        </InfoBox>
      )
    case 'bitcash':
      return (
        <InfoBox title="ビットキャッシュのご利用方法" className="mt-4">
          <p>※商品の性質上、購入後の返品、キャンセルはできませんのであらかじめご了承ください。</p>
          <p className="mt-2 text-warn-red">
            交換権付きのダイヤを購入された場合、所持アイテム付与されますので、そちらよりご確認くださいませ。
          </p>
          <p className="mt-2">
            利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る利用方法の説明が入る。
          </p>
        </InfoBox>
      )
    default:
      return null
  }
}

ShopPaymentConditions.propTypes = {
  method: PropTypes.string.isRequired
}

export default ShopPaymentConditions
