/* eslint-disable no-prototype-builtins */
import PropTypes from 'prop-types'
import { useState, useEffect } from 'react'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import { CURRENCY_TYPE, DRAW_TYPE } from 'src/models/GachaInfo'
import Item from 'src/models/Item'
import CapsuleConfirmScreen from 'src/pages/main/capsule/CapsuleConfirmScreen'
import CapsuleCurrencySelect from 'src/pages/main/capsule/CapsuleCurrencySelect'
import CapsuleInsufficientScreen from 'src/pages/main/capsule/CapsuleInsufficientScreen'
import VideoCharacterGacha from './components/VideoCharacterGacha'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import {
  gachaTurnR,
  gachaTurnSR,
  gachaTurnSSR,
  gachaTurnThreeResultSSR
} from './components/video_character'
import { getItemGachaGiftTicket, getItemGacha } from 'src/services/get/getItemGacha'
import getCounTimesroll from 'src/services/get/getCountTimeRoll'
import getGachaId from 'src/services/get/getGachaId'
import getUserItems from 'src/services/get/getUserItems'
import CapsuleModalSoldOut from './CapsuleModalSoldOut'
const CapsuleDrawModal = ({
  drawGacha,
  drawType,
  handleClose,
  redCount,
  blueCount,
  ticketCount,
  ssrCount,
  setIsVideoPlaying,
  isVideoPlaying,
  ticket,
  idItem,
  setCountRoll,
  setGachaDetail,
  setItemsTicketUpdate,
  itemsTicketRoll,
  setcountTimeRoll,
  typeGacha,
  handleSetModalList
}) => {
  const MODAL_STEP = { CurrencySelect: 0, Confirm: 1, Insuffcient: 2, Result: 3 }

  const { user } = useAuthState()
  const [step, setStep] = useState(null)
  const [playVideo, setPlayVideo] = useState(false)
  const [currencyType, setCurrencyType] = useState(CURRENCY_TYPE.Diamond)
  const authDispatch = useAuthDispatch()
  const [itemGacha, setItemGacha] = useState([])
  const [items, setItems] = useState([])
  const [showModalSoldOut, setShowModalSoldOut] = useState(false)
  const [characterLevels, setCharacterLevels] = useState([])
  const [videoEnded, setVideoEnded] = useState(false)

  const IS_FREE = true
  const [randomValue, setRandomValue] = useState({})

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await itemGacha
        setItems(response.items)
        if (response.character_levels) setCharacterLevels(response.character_levels)
        const rarityCountMap = {}
        response?.items.forEach((item) => {
          if (rarityCountMap[item.rarity]) {
            rarityCountMap[item.rarity] += 1
          } else {
            rarityCountMap[item.rarity] = 1
          }
        })

        const random = Math.random().toFixed(2)
        let cumulativeProbability = 0
        let chosenValue = null
        const keyToCheck = 'ssr'

        if (
          (rarityCountMap?.r === 11 || rarityCountMap?.r === 1) &&
          rarityCountMap[keyToCheck] === undefined
        ) {
          for (const item of gachaTurnR) {
            cumulativeProbability += item.probability
            if (!chosenValue && random <= cumulativeProbability) {
              chosenValue = {
                video: item.value,
                img: item.img
              }
              break
            }
          }
        } else if (rarityCountMap?.sr >= 1 && rarityCountMap[keyToCheck] === undefined) {
          for (const item of gachaTurnSR) {
            cumulativeProbability += item.probability
            if (!chosenValue && random <= cumulativeProbability) {
              chosenValue = {
                video: item.value,
                img: item.img
              }
              break
            }
          }
        } else if (rarityCountMap?.ssr >= 3) {
          for (const item of gachaTurnThreeResultSSR) {
            cumulativeProbability += item.probability
            if (!chosenValue && random <= cumulativeProbability) {
              chosenValue = {
                video: item.value,
                img: item.img
              }
              break
            }
          }
        } else if (rarityCountMap?.ssr === 1 || rarityCountMap?.ssr === 2) {
          for (const item of gachaTurnSSR) {
            cumulativeProbability += item.probability
            if (!chosenValue && random <= cumulativeProbability) {
              chosenValue = {
                video: item.value,
                img: item.img
              }
              break
            }
          }
        }

        if (chosenValue) {
          setRandomValue(chosenValue)
        }
      } catch (error) {
        console.error('Error fetching items:', error)
      }
    }

    fetchData()
  }, [itemGacha])

  const diamond1red = 5
  const diamond1blue = 5
  const diamond11blue = 50
  const diamond11red = 50

  const TRANSITION_CLASS_NAME =
    'fixed w-full top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 p-2'

  useEffect(() => {
    if (drawType === DRAW_TYPE.Free) setStep(MODAL_STEP.Confirm)
    else setStep(MODAL_STEP.CurrencySelect)
  }, [])

  useEffect(() => {
    if (videoEnded) {
      handleSetModalList(characterLevels)
    }
  }, [videoEnded])

  const genCurrencyText = () => {
    switch (currencyType) {
      case CURRENCY_TYPE.Diamond:
        return drawType === DRAW_TYPE.Eleven ? 'ダイヤ50個' : 'ダイヤ5個'
      case CURRENCY_TYPE.Ticket:
        return drawType === DRAW_TYPE.Eleven ? 'ガチャチケット10枚' : 'ガチャチケット1枚'
      case CURRENCY_TYPE.SSRTicket:
        return drawType === DRAW_TYPE.Eleven
          ? 'SSR確定ガチャチケット10枚'
          : 'SSR確定ガチャチケット1枚'
    }
  }

  const calcSufficientCurrency = (currency) => {
    switch (currency) {
      case CURRENCY_TYPE.SSRTicket:
        if (drawType === DRAW_TYPE.Eleven) {
          return ssrCount - 11 >= 0
        }
        return ssrCount - 1 >= 0
      case CURRENCY_TYPE.Ticket:
        if (drawType === DRAW_TYPE.Eleven) {
          return ticketCount - 11 >= 0
        }
        return ticketCount - 1 >= 0
      case CURRENCY_TYPE.Diamond:
        if (drawType === DRAW_TYPE.Eleven) {
          if (blueCount >= 50) {
            return blueCount - 50 >= 0
          } else {
            if (redCount > 0) {
              return redCount + blueCount - 50 >= 0
            }
          }
        } else {
          if (blueCount >= 5) {
            return blueCount - 5 >= 0
          } else {
            if (redCount > 0) {
              return redCount + blueCount - 5 >= 0
            }
          }
        }
        return
      default:
        return redCount + blueCount - 50 >= 0
    }
  }

  const handleSetCurrency = (currency) => {
    setCurrencyType(currency)
    if (calcSufficientCurrency(currency)) setStep(MODAL_STEP.Confirm)
    else setStep(MODAL_STEP.Insuffcient)
  }

  const handleConfirm = () => {
    setcountTimeRoll({})
    setRandomValue({})
    if (ticketCount < 1 && genCurrencyText() === 'ガチャチケット1枚') {
      setShowModalSoldOut(true)
    } else if (ticketCount < 10 && genCurrencyText() === 'ガチャチケット10枚') {
      setShowModalSoldOut(true)
    } else if (ssrCount < 1 && genCurrencyText() === 'SSR確定ガチャチケット1枚') {
      setShowModalSoldOut(true)
    } else if (ssrCount < 10 && genCurrencyText() === 'SSR確定ガチャチケット10枚') {
      setShowModalSoldOut(true)
    } else if (user?.red_amount + user.blue_amount <= 4 && genCurrencyText() === 'ダイヤ5個') {
      setShowModalSoldOut(true)
    } else if (user?.red_amount + user.blue_amount <= 49 && genCurrencyText() === 'ダイヤ50個') {
      setShowModalSoldOut(true)
    } else {
      setItems([])
      setItemsTicketUpdate([])
      if (
        drawType === DRAW_TYPE.Eleven &&
        genCurrencyText() !== 'ガチャチケット10枚' &&
        genCurrencyText() !== 'SSR確定ガチャチケット10枚'
      ) {
        if (blueCount >= 50) {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_BLUE,
            payload: diamond11blue
          })
        } else if (blueCount < 50) {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_BLUE,
            payload: diamond11blue
          })
        } else {
          if (redCount > 0) {
            authDispatch({
              type: authActionTypes.SPEND_DIAMOND_RED_ADD_BLUE,
              payload: diamond11red - blueCount
            })
          }
        }
      }
      if (
        drawType === DRAW_TYPE.One &&
        genCurrencyText() !== 'ガチャチケット1枚' &&
        genCurrencyText() !== 'SSR確定ガチャチケット1枚'
      ) {
        if (blueCount >= 5) {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_BLUE,
            payload: diamond1blue
          })
        } else if (blueCount > 0 && blueCount < 5) {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_BLUE,
            payload: diamond1blue
          })
        } else {
          if (redCount > 0) {
            authDispatch({
              type: authActionTypes.SPEND_DIAMOND_RED_ADD_BLUE,
              payload: diamond1red - blueCount
            })
          }
        }
      }

      if (drawType === DRAW_TYPE.Eleven) {
        if (genCurrencyText() === 'ガチャチケット10枚') {
          setItemGacha(getItemGachaGiftTicket(idItem, 11, 'gacha_ticket', typeGacha))
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
        } else if (genCurrencyText() === 'SSR確定ガチャチケット10枚') {
          setItemGacha(getItemGachaGiftTicket(idItem, 11, 'ssr_ticket', typeGacha))
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
        } else {
          setItemGacha(getItemGacha(idItem, 11, 'normal', typeGacha))
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
        }
      }

      if (drawType === DRAW_TYPE.One) {
        if (genCurrencyText() === 'ガチャチケット1枚') {
          setItemGacha(getItemGachaGiftTicket(idItem, 1, 'gacha_ticket', typeGacha))
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
        } else if (genCurrencyText() === 'SSR確定ガチャチケット1枚') {
          setItemGacha(getItemGachaGiftTicket(idItem, 1, 'ssr_ticket', typeGacha))
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
        } else {
          setItemGacha(getItemGacha(idItem, 1, false, typeGacha))
          setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
          setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
        }
      }
      setPlayVideo(true)
      setIsVideoPlaying(true)
      setStep(MODAL_STEP.Result)
    }
    if (drawType === DRAW_TYPE.Free) {
      setRandomValue({})
      setShowModalSoldOut(false)
      setItemGacha(getItemGacha(idItem, 1, IS_FREE, typeGacha))
      setTimeout(() => getGachaId(idItem).then((res) => setGachaDetail(res.items)), 1000)
      setPlayVideo(true)
      setIsVideoPlaying(true)
      setStep(MODAL_STEP.Result)
      setTimeout(() => getUserItems(user.id).then((res) => setItemsTicketUpdate(res)), 1000)
      setTimeout(() => getCounTimesroll(idItem).then((res) => setcountTimeRoll(res.data)), 1000)
    }
  }

  return (
    <>
      <FadeTransition show={step === MODAL_STEP.CurrencySelect} className={TRANSITION_CLASS_NAME}>
        <CapsuleCurrencySelect
          headerText={drawType === DRAW_TYPE.Eleven ? '11回ガチャを引く' : '1回ガチャを引く'}
          setCurrency={handleSetCurrency}
          handleClose={handleClose}
          ticket={ticket}
          itemsTicketRoll={itemsTicketRoll}
        />
      </FadeTransition>
      <FadeTransition show={step === MODAL_STEP.Confirm} className={TRANSITION_CLASS_NAME}>
        <CapsuleConfirmScreen
          drawGacha={drawGacha}
          handleClose={handleClose}
          handleConfirm={handleConfirm}
          handleCancel={() => setStep(MODAL_STEP.CurrencySelect)}
          drawType={drawType}
          currencyType={currencyType}
          currencyText={genCurrencyText()}
          redCount={redCount}
          blueCount={blueCount}
          ticketCount={ticketCount}
          ssrCount={ssrCount}
          playVideo={playVideo}
        />
      </FadeTransition>
      <FadeTransition show={step === MODAL_STEP.Insuffcient} className={TRANSITION_CLASS_NAME}>
        <CapsuleInsufficientScreen
          handleRechoose={() => setStep(MODAL_STEP.CurrencySelect)}
          handleClose={handleClose}
          currencyType={currencyType}
          currencyText={genCurrencyText()}
          redCount={redCount}
          blueCount={blueCount}
          ticketCount={ticketCount}
          ssrCount={ssrCount}
          handleConfirm={handleConfirm}
          drawType={drawType}
          typeGacha={typeGacha || ''}
        />
      </FadeTransition>
      {playVideo && (
        <VideoCharacterGacha
          show={step === MODAL_STEP.Result}
          className={TRANSITION_CLASS_NAME}
          handleClose={handleClose}
          handleAgain={() => setStep(MODAL_STEP.CurrencySelect)}
          isVideoPlaying={isVideoPlaying}
          setIsVideoPlaying={setIsVideoPlaying}
          randomValue={randomValue}
          drawType={drawType}
          items={items}
          handleConfirm={handleConfirm}
          videoEnded={videoEnded}
          setVideoEnded={setVideoEnded}
        />
      )}
      {/* <FadeTransition show={step === MODAL_STEP.Result} className={TRANSITION_CLASS_NAME}>
        <CapsuleResultScreen
          handleClose={handleClose}
          handleAgain={() => setStep(MODAL_STEP.CurrencySelect)}
          items={resultItems}
        />
      </FadeTransition> */}
      {showModalSoldOut && (
        <FadeTransition show={true} className={TRANSITION_CLASS_NAME}>
          <CapsuleModalSoldOut
            handleClose={handleClose}
            currencyType={currencyType}
            currencyText={genCurrencyText()}
          />
        </FadeTransition>
      )}
    </>
  )
}

CapsuleDrawModal.propTypes = {
  drawType: PropTypes.number.isRequired,
  drawGacha: PropTypes.func.isRequired,
  resultItems: PropTypes.arrayOf(PropTypes.instanceOf(Item)),
  handleClose: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired,
  setIsVideoPlaying: PropTypes.func.isRequired,
  isVideoPlaying: PropTypes.bool,
  scenesGacha: PropTypes.array,
  ticket: PropTypes.bool,
  idItem: PropTypes.number,
  setCountRoll: PropTypes.func.isRequired,
  setRollFree: PropTypes.func.isRequired,
  setGachaDetail: PropTypes.func.isRequired,
  itemsCard: PropTypes.array,
  setItemsTicketUpdate: PropTypes.func,
  itemsUpdate: PropTypes.array,
  itemsTicketRoll: PropTypes.array,
  setcountTimeRoll: PropTypes.func,
  typeGacha: PropTypes.string,
  handleSetModalList: PropTypes.func
}

export default CapsuleDrawModal
