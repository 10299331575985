export default class News {
  constructor(
    id,
    conditionForDisplayUser,
    content,
    displayLocation,
    displaySetting,
    endDisplayDate,
    index,
    isHidden,
    name,
    newType,
    startDisplayDate,
    title,
    createdAt,
    updatedAt,
    unreadUserIds
  ) {
    this.id = id
    this.conditionForDisplayUser = conditionForDisplayUser
    this.content = content
    this.displayLocation = displayLocation
    this.displaySetting = displaySetting
    this.endDisplayDate = endDisplayDate
    this.index = index
    this.isHidden = isHidden
    this.name = name
    this.newType = newType
    this.startDisplayDate = startDisplayDate
    this.title = title
    this.createdAt = createdAt
    this.updatedAt = updatedAt
    this.unreadUserIds = unreadUserIds
  }
}
