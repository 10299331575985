import { useState, useEffect } from 'react'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { useAuthState } from 'src/providers/AuthProviders'
import NewsListPage from 'src/pages/myPage/News/NewsList'
import useNewsListChannel from 'src/services/common/useNewsListChannel'
import getNewsPerPage from 'src/services/get/getNewsPerPage'

const NewsListContainer = () => {
  const [newsList, setNewsList] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const { user } = useAuthState()
  const { newsMessage } = useNewsListChannel()
  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    if (!user) return
    setIsLoading(true)
    const req = new CancelableAPIRequest(getNewsPerPage, user.id, currPage)

    req.invoke((res) => {
      setNewsList(res)
      setIsLoading(false)
    })

    return () => {
      req.cancel()
    }
  }, [user, newsMessage, currPage])

  useEffect(() => {
    const currPageStore = localStorage.getItem('current_page')
    if (!currPageStore) return
    setCurrPage(Number(currPageStore))
  }, [])

  return (
    <NewsListPage
      newsList={newsList?.items}
      isLoading={isLoading}
      userId={user?.id}
      totalPage={newsList?.total_pages}
      currPage={currPage}
      setCurrPage={setCurrPage}
    />
  )
}

export default NewsListContainer
