import Appearance1 from 'src/assets/img/dressup/appearance_1.png'
import Appearance2 from 'src/assets/img/dressup/appearance_2.png'
import D6 from 'src/assets/img/dressup/D6.png'
import LargeCategory from 'src/assets/img/dressup/large_category.png'
import Background from 'src/assets/img/dressup/background.png'
import SetupOn from 'src/assets/img/dressup/on_setup.png'
import SetupOff from 'src/assets/img/dressup/off_setup.png'
import InnerOn from 'src/assets/img/dressup/on_inner.png'
import InnerOff from 'src/assets/img/dressup/off_inner.png'
import OuterOn from 'src/assets/img/dressup/on_outer.png'
import OuterOff from 'src/assets/img/dressup/off_outer.png'
import BottomOn from 'src/assets/img/dressup/on_bottoms.png'
import BottomOff from 'src/assets/img/dressup/off_bottoms.png'
import ShoesOn from 'src/assets/img/dressup/on_shose.png'
import ShoesOff from 'src/assets/img/dressup/off_shose.png'
import ExpressionOn from 'src/assets/img/dressup/on_expression.png'
import ExpressionOff from 'src/assets/img/dressup/off_expression.png'
import HeadOn from 'src/assets/img/dressup/on_head.png'
import HeadOff from 'src/assets/img/dressup/off_head.png'
import EarOn from 'src/assets/img/dressup/on_ear.png'
import EarOff from 'src/assets/img/dressup/off_ear.png'
import NeckOn from 'src/assets/img/dressup/on_neck.png'
import NeckOff from 'src/assets/img/dressup/off_neck.png'
import HandOn from 'src/assets/img/dressup/on_hand.png'
import HandOff from 'src/assets/img/dressup/off_hand.png'
import SoraOn from 'src/assets/img/dressup/on_sora.png'
import SoraOff from 'src/assets/img/dressup/off_sora.png'
import MinatoOn from 'src/assets/img/dressup/on_minato.png'
import MinatoOff from 'src/assets/img/dressup/off_minato.png'
import RenOn from 'src/assets/img/dressup/on_ren.png'
import RenOff from 'src/assets/img/dressup/off_ren.png'
import SyoOn from 'src/assets/img/dressup/on_syo.png'
import SyoOff from 'src/assets/img/dressup/off_syo.png'
import SyunOn from 'src/assets/img/dressup/on_syun.png'
import SyunOff from 'src/assets/img/dressup/off_syun.png'
import TapestryOn from 'src/assets/img/dressup/on_small_tapestry.png'
import TapestryOff from 'src/assets/img/dressup/off_small_tapestry.png'
import SpeechOn from 'src/assets/img/dressup/on_small_speech.png'
import SpeechOff from 'src/assets/img/dressup/off_small_speech.png'
import DallOn from 'src/assets/img/dressup/on_small_dall.png'
import DallOff from 'src/assets/img/dressup/off_small_dall.png'
import AtelierOn from 'src/assets/img/dressup/on_small_atelier.png'
import AtelierOff from 'src/assets/img/dressup/off_small_atelier.png'
import WearHouseOn from 'src/assets/img/dressup/on_small_wearhouse.png'
import WearHouseOff from 'src/assets/img/dressup/off_small_wearhouse.png'

const menu = [
  {
    key: 'appearance1',
    image: Appearance1,
    children: [
      {
        key: 'setup',
        image: {
          on: SetupOn,
          off: SetupOff
        }
      },
      {
        key: 'inner',
        image: {
          on: InnerOn,
          off: InnerOff
        }
      },
      {
        key: 'outer',
        image: {
          on: OuterOn,
          off: OuterOff
        }
      },
      {
        key: 'bottom',
        image: {
          on: BottomOn,
          off: BottomOff
        }
      },
      {
        key: 'shoes',
        image: {
          on: ShoesOn,
          off: ShoesOff
        }
      }
    ]
  },
  {
    key: 'appearance2',
    image: Appearance2,
    children: [
      {
        key: 'expression',
        image: {
          on: ExpressionOn,
          off: ExpressionOff
        }
      },
      {
        key: 'head',
        image: {
          on: HeadOn,
          off: HeadOff
        }
      },
      {
        key: 'ear',
        image: {
          on: EarOn,
          off: EarOff
        }
      },
      {
        key: 'neck',
        image: {
          on: NeckOn,
          off: NeckOff
        }
      },
      {
        key: 'arm',
        image: {
          on: HandOn,
          off: HandOff
        }
      }
    ]
  },
  {
    key: 'd6',
    image: D6,
    children: [
      {
        key: 'sora',
        image: {
          on: SoraOn,
          off: SoraOff
        }
      },
      {
        key: 'minato',
        image: {
          on: MinatoOn,
          off: MinatoOff
        }
      },
      {
        key: 'ren',
        image: {
          on: RenOn,
          off: RenOff
        }
      },
      {
        key: 'syo',
        image: {
          on: SyoOn,
          off: SyoOff
        }
      },
      {
        key: 'syun',
        image: {
          on: SyunOn,
          off: SyunOff
        }
      }
    ]
  },
  {
    key: 'largeCategory',
    image: LargeCategory,
    children: [
      {
        key: 'tapestry',
        image: {
          on: TapestryOn,
          off: TapestryOff
        }
      },
      {
        key: 'speech',
        image: {
          on: SpeechOn,
          off: SpeechOff
        }
      },
      {
        key: 'dall',
        image: {
          on: DallOn,
          off: DallOff
        }
      },
      {
        key: 'atelier',
        image: {
          on: AtelierOn,
          off: AtelierOff
        }
      },
      {
        key: 'wearhouse',
        image: {
          on: WearHouseOn,
          off: WearHouseOff
        }
      }
    ]
  },
  {
    key: 'background',
    image: Background,
    children: []
  }
]

export default menu
