import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const getChatList = async (userId, roomType = 'single_chat') => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Chat.getChatList, {
      params: {
        user_id: userId,
        room_type: roomType
      }
    })
    if (res.data) {
      return res.data.channels
    }
    return []
  } catch (error) {
    return false
  }
}

export const getChat = async (characterId, userId, chatId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Chat.getChat, {
      params: {
        user_id: userId,
        character_id: characterId,
        chat_id: chatId
      }
    })
    if (res.data.success) {
      return res.data.items
    }
    return res.data.message
  } catch (error) {
    return false
  }
}

export const getChannelInfo = async (params) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Chat.getChannelInfo, {
      params
    })
    if (res.data) {
      return res.data
    }
    return null
  } catch (error) {
    return false
  }
}

export const spendHeart = async (chatId, userId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Chat.spendHeart, {
      params: {
        user_id: userId,
        character_chat_id: chatId
      }
    })
    if (!res.data) return { data: false }
    const data = res.data
    return {
      data: { title: data.title, description: data.description },
      currentLevel: data.current_level,
      nextLevel: data.next_level
    }
  } catch (error) {
    console.error(error)
    return { data: false }
  }
}

export const getConversationItem = async (characterChatId, type, giftId, giftAmount) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Chat.getConversationItem, {
      params: {
        character_chat_id: characterChatId,
        type: type,
        gift_id: giftId,
        gift_amount: giftAmount
      }
    })
    if (res.data.error) {
      return false
    } else {
      const data = res.data
      return {
        data: data,
        characterLevel: res.data.character_level,
        characterLevels: res.data.character_levels
      }
    }
  } catch (error) {
    return false
  }
}
