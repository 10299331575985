import PropTypes from 'prop-types'
import clsx from 'clsx'
import { Header } from 'src/components/layouts/Header'
import { Footer } from 'src/components/layouts/Footer'

export const MainLayout = ({
  children,
  active,
  className,
  classNameHeader,
  style,
  showHeader = true,
  showFooter = true
}) => {
  return (
    <div className={clsx('sm:w-desktop-width w-screen h-screen m-h-screen mx-auto', className)} style={style}>
      {showHeader ? <Header className={classNameHeader} /> : null}
      <main>{children}</main>
      {showFooter ? <Footer active={active} /> : null}
    </div>
  )
}

MainLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  active: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  classNameHeader: PropTypes.string,
  showHeader: PropTypes.bool,
  showFooter: PropTypes.bool
}
