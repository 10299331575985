import React from 'react'
import PropTypes from 'prop-types'
import StandardizedButton from 'src/components/common/StandardizedButton'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import TitleHeader from 'src/components/common/TitleHeader'
import BtnAgain from 'src/assets/img/gacha/btn_again.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import _ from 'lodash'
import { DRAW_TYPE } from 'src/models/GachaInfo'
import ReactPlayer from 'react-player'

const VideoCharacterGacha = ({
  handleClose,
  handleAgain,
  isVideoPlaying,
  setIsVideoPlaying,
  randomValue,
  itemsGacha,
  drawType,
  items,
  handleConfirm,
  videoEnded,
  setVideoEnded
}) => {
  const handleVideoEnd = () => {
    setIsVideoPlaying(false)
    setVideoEnded(true)
  }

  return (
    <>
      {!_.isEmpty(randomValue) && (
        <div className="w-[392px]">
          {isVideoPlaying && (
            <ReactPlayer
              width="100%"
              height="100%"
              playing={true}
              onEnded={handleVideoEnd}
              url={randomValue?.video}
            />
          )}
          {videoEnded && (
            <div
              className="w-full px-4 pt-8 pb-16 bg-popup-md bg-fill bg-no-repeat relative flex flex-col items-center justify-between font-hiragino"
              style={{
                backgroundImage: `url('${randomValue?.img}')`,
                height: '640px'
              }}
            >
              <TitleHeader title="結果" className="mb-4" />
              {items?.length === 1 ? (
                <div className="text-center">
                  <ItemThumbnail
                    image={items[0].item_image_url}
                    className=""
                    imgClassName="w-[90px]"
                    rarity={items[0].rarity}
                  />
                  <span className="font-vldLineGR m-2 text-black">{items[0].name}</span>
                </div>
              ) : (
                <ul className="grid grid-cols-4 gap-1 my-4">
                  {items?.map((el) => (
                    <li className="flex flex-col" key={el.id}>
                      <ItemThumbnail
                        image={el.item_image_url}
                        rarity={el.rarity}
                        className=""
                        imgClassName="w-[70px]"
                      />
                      <span className="font-vldLineGR pt-1 text-[9px] text-center line-clamp-2 text-black">
                        {el.name}
                      </span>
                    </li>
                  ))}
                </ul>
              )}
              <div
                className={
                  drawType !== DRAW_TYPE.Free
                    ? 'flex w-4/5 justify-between'
                    : 'flex w-4/5 justify-center'
                }
              >
                <StandardizedButton
                  className="w-28"
                  onClick={handleClose}
                  img={BtnCancel}
                  alt="キャンセル"
                />
                {drawType !== DRAW_TYPE.Free ? (
                  <StandardizedButton
                    className="w-28"
                    onClick={() => {
                      setVideoEnded(false)
                      handleConfirm()
                    }}
                    img={BtnAgain}
                    alt="もう一度"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

VideoCharacterGacha.propTypes = {
  // resultItems: PropTypes.arrayOf(PropTypes.instanceOf(Item)),
  handleClose: PropTypes.func.isRequired,
  handleAgain: PropTypes.func.isRequired,
  isVideoPlaying: PropTypes.bool,
  setIsVideoPlaying: PropTypes.func.isRequired,
  randomValue: PropTypes.object.isRequired,
  itemsGacha: PropTypes.object,
  drawType: PropTypes.number,
  items: PropTypes.array,
  handleConfirm: PropTypes.func,
  videoEnded: PropTypes.bool,
  setVideoEnded: PropTypes.func
}

export default VideoCharacterGacha
