import React, { useState } from 'react'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import { LiveModal } from '../../components/LiveModal'
import TitleHourglassModal from '../../assets/title_hourglass.png'
import btnCancel from '../../assets/btn_cancel.png'
import SendHourglass from '../../assets/send_hourglass.png'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import { HourglassItem } from './_HourglassItem'
import HourGlassItemAddition from '../../assets/hourglass_item_addition.png'
import { useDuringContext } from './_Provider'
import _ from 'lodash'
import { postLiveTime } from '../../../../services/post/postLiveTime'
import { CantSendItemModal } from './_CantSendItemModal'
import { InsufficientDiamondModal } from './_InsufficientDiamondModal'
import { ModalPassport } from '../../components/ModalPassport'
import PropTypes from 'prop-types'

const HourglassModal = ({ isExtraTime, setIsMultiLoveModalOpen, setMultiLevel, normalPassportModal }) => {
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()
  const [open, setOpen] = useState(false)
  const [openModalPassport, setOpenModalPassport] = useState(false)
  const onClose = () => {
    document.getElementById('chicken-icon').className = 'relative z-[9]'
    setOpen(false)
  }
  const [sendModal, setSendModal] = useState(false)
  const onCloseSendModal = () => {
    setSendModal(false)
    onClose()
  }
  const [cantSendModal, setCantSendModal] = useState(false)
  const [selected, setSelected] = useState({})
  const { liveItemsSocket, liveInfoQuery, channelId } = useDuringContext()
  const liveExtraTimes = _.get(liveInfoQuery, 'data.live_extra_times') || []
  const [insufficientDiamond, setInsufficientDiamond] = useState(false)

  const openLiveModal = () => {
    document.getElementById('chicken-icon').className = 'relative z-[8]'
    setOpen(true)
  }
  const clickTimeItem = (item) => {
    if (normalPassportModal) {
      if (liveItemsSocket.limittedHourglass) {
        setCantSendModal(true)
      } else {
        setSendModal(true)
        setSelected(item)
      }
      setOpen(false)
    } else {
      setOpenModalPassport(true)
    }
  }
  const sendExtraTime = async () => {
    if (user.red_amount < _.get(selected, 'number_diamond')) {
      onCloseSendModal()
      setInsufficientDiamond(true)
    } else {
      try {
        onCloseSendModal()
        const { item, flag, currentLevel, nextLevel } = await postLiveTime({
          livestream_id: channelId,
          live_item_id: _.get(selected, 'id'),
          amount: 1
        })
        if (flag) {
          setCantSendModal(true)
        } else {
          authDispatch({
            type: authActionTypes.SPEND_DIAMOND_RED,
            payload: _.get(selected, 'number_diamond')
          })
          if (!item && (!currentLevel || !nextLevel)) return
          setMultiLevel({
            currentLevel: currentLevel,
            nextLevel: nextLevel
          })
          setIsMultiLoveModalOpen(true)
        }
      } catch {}
    }
  }

  const typeHourglass = () => {
    const itemType = _.get(selected, 'item_type')
    return itemType === 'blue_time' ? '青'
      : itemType === 'red_time' ? '赤'
        : itemType === 'gold_time' ? '金'
          : '虹'
  }

  return (
    <div className="relative z-[9]" id="hourglass-icon">
      <img src={HourGlassItemAddition} className="w-[54px] h-[57px]" onClick={() => openLiveModal()} />
      <LiveModal open={open} onClose={onClose}>
        <img src={TitleHourglassModal} className="mx-auto w-[298px] h-[84px]" />

        <div className="grid grid-cols-2 gap-x-8 gap-y-4">
          {liveExtraTimes.slice().map((extra, index) => (
            <HourglassItem
              onClick={() => clickTimeItem(extra)}
              itemType={_.get(extra, 'item_type')}
              diamondAmount={_.get(extra, 'number_diamond')}
              additionalTime={_.get(extra, 'extra_time')}
              additionalLove={_.get(extra, 'extra_love')}
              image={_.get(extra, 'image')}
              key={index}
            />
          ))}
        </div>
      </LiveModal>

      <ModalWithBg title="確認" open={sendModal} onClose={() => setSendModal(false)}>
        <div className="space-y-[24px]">
          <p className="font-bold text-center font-hiragino">
            赤ダイヤ{_.get(selected, 'number_diamond')}個と時間延長の砂(
            {typeHourglass()}
            )を交換して彼に送りますか？
          </p>

          {/* <label className="flex items-center justify-center bg-[#DAEBFF] h-[36px] w-full"> */}
          {/*  <input type="checkbox" className="mr-2 h-[20px] w-[20px]" /> */}
          {/*  次回より表示しない */}
          {/* </label> */}

          <div className="flex items-center justify-between">
            <img src={btnCancel} onClick={onCloseSendModal} className="w-[119px] h-[49px]" />
            <img src={SendHourglass} onClick={sendExtraTime} className="w-[119px] h-[49px]" />
          </div>
        </div>
      </ModalWithBg>

      <ModalPassport open={openModalPassport} modalType="live-extra-item" onClose={() => setOpenModalPassport(false)} />

      <CantSendItemModal
        open={cantSendModal}
        isExtraTime={isExtraTime}
        onClose={() => {
          setCantSendModal(false)
          onClose()
        }}
      />

      <InsufficientDiamondModal open={insufficientDiamond} onClose={() => setInsufficientDiamond(false)} />
    </div>
  )
}

HourglassModal.propTypes = {
  isExtraTime: PropTypes.bool,
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func,
  normalPassportModal: PropTypes.bool
}

export { HourglassModal }
