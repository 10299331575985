export default class IdolEvent {
  constructor(
    id,
    articleContentHtml,
    displayLink,
    displayOnTheEventPage,
    displayOnTheMyPage,
    displayPeriodSetting,
    startTime,
    endTime,
    image,
    index,
    link,
    name
  ) {
    this.id = id
    this.articleContentHtml = articleContentHtml
    this.displayLink = displayLink
    this.displayOnTheEventPage = displayOnTheEventPage
    this.displayOnTheMyPage = displayOnTheMyPage
    this.displayPeriodSetting = displayPeriodSetting
    this.startTime = startTime
    this.endTime = endTime
    this.image = image
    this.index = index
    this.link = link
    this.name = name
  }
}
