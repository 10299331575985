import PropTypes from 'prop-types'
import styled from 'styled-components'

export const CharacterItem = ({ character, isActive, onClick }) => {
  return (
    <Wrapper className={`${isActive ? '-mt-1' : 'grayscale-[0.5]'}`} background={character.background} onClick={() => onClick()}>
      <div className="character-item__image pb-[1px] px-[1px] h-[100%]">
        <div className='overflow-hidden  h-[100%]'>
          <img className='h-[200%]  w-full object-cover object-top ' src={require(`../../assets/img/ranking/${character.image}`)} alt={character.name} />
        </div>
      </div>
      <div className="absolute bottom-1 left-0 right-0">
        <img src={require(`../../assets/img/ranking/${isActive ? character.backgroundNameActive : character.backgroundName}`)} alt={character.name} />
      </div>
    </Wrapper>
  )
}

CharacterItem.propTypes = {
  character: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    background: PropTypes.string.isRequired,
    backgroundName: PropTypes.string.isRequired,
    backgroundNameActive: PropTypes.string.isRequired
  }).isRequired,
  isActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func
}

const Wrapper = styled.div.attrs({
  className: 'character-item relative h-[90px] cursor-pointer'
})`
  background-image: url(${props => require(`../../assets/img/ranking/${props.background}`)});
  background-size: 100% 85%;
  background-position: bottom;
  background-repeat: no-repeat;
`
