import { useEffect, useMemo } from 'react'
import { useRoutes, useSearchParams, useLocation } from 'react-router-dom'
import { useAuthState } from 'src/providers/AuthProviders'
import { useMaintenanceContext } from 'src/providers/MaintenanceProviders'
import { MainRouter, NavigateToMyPage } from 'src/routes/main'
import { MenuRouter } from 'src/routes/menu'
import { MyPageRouter } from 'src/routes/myPage'
import { LoginRouter, LoginLineRouter, LiffRouter, NewUserRouter, TermRouter, MaintenanceRouter, NEW_USER_PATHS } from 'src/routes/newUser'
import { VoiceLivePath } from './live'

const IndexRouter = () => {
  const { isLoggedIn, user } = useAuthState()
  const { maintenance } = useMaintenanceContext()
  const [searchParams] = useSearchParams()
  const isActive = user.is_active
  const location = useLocation()

  useEffect(() => {
    const codeManagerId = searchParams.get('code_manager_id')

    localStorage.setItem('code_manager_id', codeManagerId || '')
  }, [])

  const routes = useMemo(() => {
    if (!isLoggedIn && location.pathname === NEW_USER_PATHS.LoginPage) return LoginRouter
    if (location.pathname === NEW_USER_PATHS.LiffPage) return LiffRouter
    if (!isLoggedIn) return LoginLineRouter
    if (!isActive) return [...NewUserRouter, ...TermRouter]
    if (maintenance?.maint_mode) return MaintenanceRouter

    return [
      ...MainRouter,
      ...MenuRouter,
      ...MyPageRouter,
      ...TermRouter,
      ...VoiceLivePath,
      ...NavigateToMyPage
    ]
  }, [isLoggedIn, isActive, maintenance])

  return useRoutes(routes)
}

export { IndexRouter }
