import { useState, Fragment, useRef, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { MAIN_PATHS } from 'src/routes/main'
import { NEW_USER_PATHS } from 'src/routes/newUser'
import { Dialog, Transition } from '@headlessui/react'
import styled from 'styled-components'

import BGModal from 'src/assets/img/opening/bg-modal.png'
import BGModalTitle from 'src/assets/img/opening/bg-modal-title.png'
import TapToStart from 'src/assets/img/opening/start-btn.png'
import LiveBtn from 'src/assets/img/opening/live-btn.png'
import SkipBtn from 'src/assets/img/opening/btn-skip.png'
import CancelBtn from 'src/assets/img/common/btn-cancel.png'
import BgOpening from 'src/assets/img/opening/bg-opening.png'
import { postSkipOpeningStory } from 'src/services/post/postSkipOpeningStory'

export const OpeningPage = () => {
  const cancelButtonRef = useRef(null)
  const [isSkip, setIsSkip] = useState(false)
  const navigate = useNavigate()

  const redirect = useCallback((path) => navigate(path), [])

  return (
    <Wrapper>
      <div
        className="absolute cursor-pointer text-[11px] top-[64px] right-[11px] font-bold z-10"
        onClick={() => setIsSkip(true)}
      >
        <img src={LiveBtn} className="w-44" alt="オープニングストーリー一括スキップ" />
      </div>
      <div
        className="absolute bottom-[101px] tap-to-start text-center w-full"
        onClick={() => redirect(NEW_USER_PATHS.OpeningStoryPage)}
      >
        <div className="absolute w-full h-full left-0 bg-[#fff] blur-[35px]" />
        <div className="pt-[20px] pb-[36px] cursor-pointer">
          <img
            className="mx-auto object-cover w-64 drop-shadow-[0_4px_4px_rgba(0,0,0,0.25)]"
            src={TapToStart}
          />
        </div>
      </div>
      <Transition.Root show={isSkip} as={Fragment}>
        <Dialog
          as="div"
          className="sm:w-[400px] relative z-10"
          initialFocus={cancelButtonRef}
          onClose={setIsSkip}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="relative inset-0 h-screen w-screen">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="w-10/12 absolute top-[20%] left-1/2 -translate-x-2/4 -translate-y-2/4 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                  <ContentModal>
                    <div className="text-center text-[#333333] font-hiragino">
                      <Dialog.Title
                        as="h3"
                        className="text-lg leading-6 font-medium text-gray-900 pt-[38px] px-[50px]"
                      >
                        <img className="w-full" src={BGModalTitle} alt="opening page" />
                      </Dialog.Title>
                      <h6 className="mt-[26px] mb-3.5 mx-[4.2rem] text-base font-bold">
                        オープニングストーリーを 一括スキップしますか？
                      </h6>
                      <div className="mt-2">
                        <p className="text-sm font-light ml-8 mr-5 mb-1.5 flex items-center text-justify leading-[163%]">
                          オープニングストーリーを最後まで読むと、
                          チャット送信に必要なアイテムがもらえます。
                        </p>
                        <p className="text-xs font-light ml-7 mr-8 mb-[1.61rem] text-justify leading-[21px]">
                          ※オープニングストーリーは後から読み返すこともできますが、アイテムは今回限りとなります。
                          <br />
                          ※所要時間約10分
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center pb-[74px] justify-center">
                      <button
                        className="mr-3.5 focus:outline-none"
                        onClick={() => setIsSkip(false)}
                      >
                        <img className="w-[118px]" src={CancelBtn} alt="Cancel" />
                      </button>
                      <button
                        className="ml-3.5 focus:outline-none"
                        onClick={() => {
                          postSkipOpeningStory()
                          redirect(MAIN_PATHS.MyPage)
                        }}
                      >
                        <img className="w-[118px]" src={SkipBtn} alt="skip" />
                      </button>
                    </div>
                  </ContentModal>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </Wrapper>
  )
}

const ContentModal = styled.div.attrs({
  className: ''
})`
  background-image: url('${BGModal}');
  background-size: 100% 100%;
`

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative bg-no-repeat bg-left bg-cover'
})`
  background-image: url(${BgOpening});
`
