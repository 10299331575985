import React, { useCallback, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import styled from 'styled-components'
import postSaveAdcd from 'src/services/post/postSaveAdcd'
import Loading from 'src/components/layouts/Loading'
import BGCommon from 'src/assets/img/register/bg-common.png'
import { getParameterName } from 'src/services/get/getParameterName'

const REACT_APP_LINE_OFFICIAL_ACCOUNT_LINK = process.env.REACT_APP_LINE_OFFICIAL_ACCOUNT_LINK

export const LiffPage = () => {
  const [searchParams] = useSearchParams()
  const adcd = searchParams.get('adcd') || localStorage.getItem('adcd')
  useEffect(() => {
    SaveAdcd()
  }, [])

  const SaveAdcd = useCallback(() => {
    if (adcd) {
      getParameterName(adcd).then((data) => {
        const parameterName = data.parameter_name
        const sessionParams = searchParams.get(parameterName) || localStorage.getItem(parameterName)
        localStorage.setItem('adcd', adcd)
        const params = {
          access_token: sessionParams,
          adcd: adcd,
          session_params: sessionParams
        }
        postSaveAdcd(params).then(() => {
          // TODO: Temporary set for testing
          setTimeout(() => {
            window.location.href = REACT_APP_LINE_OFFICIAL_ACCOUNT_LINK
          }, adcd === 'fam_te_01' ? 3000 : 0)
        })
      }).catch((e) => console.error(e))
    }
  }, [])

  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative bg-center bg-cover bg-no-repeat'
})`
  background-image: url('${BGCommon}');
`
