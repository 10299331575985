import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import EventItem from 'src/components/Event/EventItem'
import NoContentImage from 'src/components/layouts/NoContentImage'
import { MYPAGE_PATHS } from 'src/routes/myPage'

import BtnViewPastEvents from 'src/assets/img/menu/btn_viewpastevents.png'

const Event = (props) => {
  const { events } = props
  return (
    <div>
      {events?.length ? (
        <div className="w-full text-center">
          {events.map((event) => (
            <EventItem key={event.id} event={event} />
          ))}
        </div>
      ) : (
        <NoContentImage message="現在イベントは<br/>開催されていません" />
      )}
      <Link
        to={{ pathname: MYPAGE_PATHS.EventArchiveListPage }}
        className="block w-[200px] my-4 mx-auto"
      >
        <img src={BtnViewPastEvents} alt="過去イベント" />
      </Link>
    </div>
  )
}

export default Event

Event.propTypes = {
  events: PropTypes.array
}
