import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Fetches contact inquiries (お問い合わせ)
 *
 * @param {number} characterId The ID representing the character whose data you wish to fetch
 * @returns An array of inquiry data.
 */
const putUpdateRecommendMan = async (characterId) => {
  if (!characterId) return false

  try {
    const res = await axiosInstance.put(
      `${API_ROUTES.Recommend.Characters}/${characterId}/${API_ROUTES.Recommend.RecommendUpdate}`
    )
    if (res.data.success === false) return false
    return res.data.items
  } catch (error) {
    return false
  }
}

export default putUpdateRecommendMan
