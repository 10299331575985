import styled from 'styled-components'
import { SlideButton } from 'src/components/common/SlideButton'
import LevelProgress from 'src/components/common/LevelProgress'
import PropTypes from 'prop-types'

import GrowthBG from 'src/assets/img/mypage/growth/growth-button-bg.png'
import GrowthBtn from 'src/assets/img/mypage/growth/growth-btn.png'
import GrowthBtnExpend from 'src/assets/img/mypage/growth/growth-btn-expend.png'

const ProgressSlideBar = (props) => {
  const { isOpen, setIsOpen, recommendedMan } = props

  return (
    <SliderStatus>
      <div onClick={() => setIsOpen(!isOpen)} className="h-[50px] overflow-hidden ">
        <img className="h-[100%]" src={isOpen ? GrowthBtnExpend : GrowthBtn} alt="growthButton" />
      </div>
      <SlideButton visible={isOpen} className="w-[180px]">
        <div className="flex items-center justify-between h-[50px] overflow-hidden">
          <div className="btn h-[100%] w-[35%]">
            <img className="h-[100%]" src={GrowthBG} alt="growthButton" />
          </div>
          <div className="status w-[65%] bg-sliderstatus px-3 pt-1 pb-2 bg-no-repeat bg-cover">
            {!!recommendedMan && <LevelProgress size="md" level={recommendedMan.level} />}
          </div>
        </div>
      </SlideButton>
    </SliderStatus>
  )
}

ProgressSlideBar.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  recommendedMan: PropTypes.object
}

export default ProgressSlideBar

const SliderStatus = styled.div.attrs({
  className: 'absolute cursor-pointer flex items-center justify-center bottom-[1vh] right-0 z-30'
})`
  height: fit-content;
`
