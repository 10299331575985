import PropTypes from 'prop-types'

import BtnClose from 'src/assets/img/opening-story/btn-close.png'

const ModalCloseButton = ({ handleClose }) => {
  return (
    <button
      className="absolute top-2 right-2 translate-x-1/2 -translate-y-1/2 z-20"
      onClick={handleClose}
    >
      <img src={BtnClose} alt="close" />
    </button>
  )
}

ModalCloseButton.propTypes = { handleClose: PropTypes.func.isRequired }

export default ModalCloseButton
