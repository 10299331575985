import { useMutation, useQuery } from 'react-query'
import { useEffect } from 'react'
import { updateRecommends } from 'src/lib/recommends'
import { queryClient } from 'src/providers/QueryProvider'
import { getRecommendMans } from 'src/services/get/getRecommend'
import putUpdateRecommendMan from 'src/services/put/putUpdateRecommendMan'

export const useGetRecommendsData = () => {
  const recommends = useQuery('recommends', getRecommendMans, {
    initialData: () => {
      return queryClient.getQueryData('recommends')
    }
  })

  useEffect(() => {
    if (recommends.data) {
      recommends.refetch()
    }
  }, [recommends.data])

  return recommends
}

export const useUpdateRecommendsMan = () => {
  const { data: recommends } = useQuery('recommends', getRecommendMans)

  const mutation = useMutation((value) => putUpdateRecommendMan(value), {
    onSuccess: (_, id) => queryClient.setQueryData('recommends', updateRecommends(recommends, id))
  })

  return mutation
}
