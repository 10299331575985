import PropTypes from 'prop-types'
import styled from 'styled-components'

import BGLoveHeader from 'src/assets/img/ranking/detail/detail-my-love-parameter-bg.png'
import BGTrainingHeader from 'src/assets/img/ranking/detail/detail-my-training-parameter-bg.png'
import BGLoveBottom from 'src/assets/img/ranking/detail/bg-love-bottom.png'
import BGTrainingBottom from 'src/assets/img/ranking/detail/bg-training-bottom.png'
import BGCharacter from 'src/assets/img/ranking/detail/bg-character.png'
import { RANKING_MODAL_CHARACTER_IMAGE } from 'src/constants/characters'

const Skill = ({ rank, type, idx }) => {
  return (
    <div className="relative border border-[#f3d999] rounded bg-[#fcfbfd]">
      <div className="w-[100%] mx-auto p-2 pb-8">
        <img style={{
          filter: type === 'love' ? 'brightness(0) saturate(100%) invert(28%) sepia(13%) saturate(7131%) hue-rotate(248deg) brightness(91%) contrast(90%)'
            : 'brightness(0) saturate(100%) invert(31%) sepia(88%) saturate(420%) hue-rotate(150deg) brightness(92%) contrast(92%)'
        }}
        className="w-full h-full"
        src={require(`../../assets/img/ranking/detail/skill${idx}.png`)} alt="" />
      </div>
      <SkillValue className="absolute bottom-0 w-full" background={type === 'love' ? BGLoveBottom : BGTrainingBottom}>
        <div className="py-[1px] flex justify-end items-center text-white text-xs">
          <div className="min-w-[20px] text-center text-sm font-bold font-AlibabaSansBlack">{rank || '--'}</div>
          <div className="px-1 leading-[18px]">位</div>
        </div>
      </SkillValue>
    </div>
  )
}

Skill.propTypes = {
  rank: PropTypes.number,
  type: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired
}

export const CharacterDetail = ({ character, id }) => {
  return (
    <div>
      <h3 className="text-center text-[#323232] font-bold">{character.user_name}さんのプロフィール</h3>
      <div>
        <div className='px-8 pt-2 pb-4'>
          <CharacterWrapper>
            <img src={RANKING_MODAL_CHARACTER_IMAGE[id]} alt={''} style={{
              clipPath: 'polygon(11% 0, 100% 0, 100% 30%, 100% 90%, 87% 100%, 0 100%, 0 10%)'
            }} />
          </CharacterWrapper>
        </div>
        <RankingHeader background={BGLoveHeader}>
          今月のLOVE度ランキング
        </RankingHeader>
        <div className="grid grid-cols-5 gap-2 pt-1.5 pb-3">
          {
            (character.love_rank || []).slice(0, 5).map((skill, index) =>
              <Skill key={index} rank={skill.rank} idx={index} type="love" />
            )
          }
        </div>
        <RankingHeader background={BGTrainingHeader}>
          今月の育て度ランキング
        </RankingHeader>
        <div className="grid grid-cols-5 gap-2 pt-1.5 pb-3">
          {
            (character.grow_rank || []).slice(0, 5).map((skill, index) => (
              <Skill key={index} rank={skill.rank} idx={index} type="training" />
            ))
          }
        </div>
      </div>
    </div>
  )
}

CharacterDetail.propTypes = {
  character: PropTypes.shape({
    image: PropTypes.string,
    grow_rank: PropTypes.array,
    love_rank: PropTypes.array,
    user_name: PropTypes.string
  }),
  id: PropTypes.number.isRequired
}

const RankingHeader = styled.div.attrs({
  className: 'relative py-1 pl-3 flex justify-start items-center text-xs text-white'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const CharacterWrapper = styled.div.attrs({
  className: 'p-2 relative'
})`
  background-image: url(${BGCharacter});
  background-size: 100% 100%;
`

const SkillValue = styled.div.attrs({
  className: 'text-center text-[#323232] font-bold'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`
