import { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import InquiryPage from 'src/pages/menu/Inquiry'
import { useAuthState } from 'src/providers/AuthProviders'
import useUserContactListChannel from 'src/services/common/useUserContactChannel'
import getUserInquiries from 'src/services/get/getUserInquiries'
import postUserInquiry from 'src/services/post/postUserInquiry'

const InquiryPageContainer = () => {
  const [inquiries, setInquiries] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [hasNotifications, setHasNotifications] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)

  const [searchParams, setSearchParams] = useSearchParams()
  const { adminMessage } = useUserContactListChannel()

  const { user } = useAuthState()
  useEffect(() => {
    updateIndex(searchParams.get('tab'))

    const req = new CancelableAPIRequest(getUserInquiries, user?.id)
    req.invoke((resp) => {
      setInquiries(resp)
      setIsLoading(false)

      if (resp.some((el) => !el.isReaded && el.adminResponse)) {
        setHasNotifications(true)
      }
    })

    return () => {
      req.cancel()
    }
  }, [adminMessage])

  const updateIndex = (value) => {
    value = parseInt(value)
    if (value !== 0 && value !== 1) return

    setSelectedIndex(value)
    setSearchParams({ tab: value }, { replace: true })
  }

  const sendInquiry = (categoryId, message, files) => {
    // TODO: この機能が動作している間、何らかのロード インジケータが表示されるはずですが、そのようなインジケータは設計上存在しませんでした。
    postUserInquiry(user?.id, message, categoryId, files).then(() => {
      getUserInquiries(user?.id).then((resp) => {
        setInquiries(resp)
      })
    })
  }

  return (
    <InquiryPage
      userId={user?.id}
      isLoading={isLoading}
      hasNotifications={hasNotifications}
      sendInquiry={sendInquiry}
      inquiries={inquiries}
      selectedIndex={selectedIndex}
      setSelectedIndex={updateIndex}
    />
  )
}

export default InquiryPageContainer
