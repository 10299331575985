import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import GiftIcon from '../../../assets/img/message/detail/gift-icon.png'

export const GiftDetail = ({ msg, isReceived, giftType, handleOpenGift }) => {
  const openGift = () => handleOpenGift(giftType, msg)

  return (
    <div
      onClick={isReceived ? null : openGift}
      className={`cursor-pointer relative rounded-[3px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] font-medium text-[9px] pl-3.5 pr-2 mb-1 mt-2 h-5 flex items-center ${
        isReceived ? 'bg-gray-300 text-[#333333]' : 'bg-white text-[#325282]'
      }`}
    >
      <IconWrapper
        className="absolute top-0 -left-2"
        background="linear-gradient(360deg, #F2709C 0%, #FF9472 100%)"
      >
        <img src={GiftIcon} alt="gift" />
      </IconWrapper>
      {isReceived ? 'プレゼント受け取り済' : 'プレゼントを受け取る'}
    </div>
  )
}

GiftDetail.propTypes = {
  msg: PropTypes.object.isRequired,
  isReceived: PropTypes.bool,
  giftType: PropTypes.string,
  handleOpenGift: PropTypes.func
}

const IconWrapper = styled.div.attrs({
  className:
    'flex items-center justify-center h-5 w-5 rounded-[50%] overflow-hidden border border-white p-[4px]'
})`
  background: ${(props) => props.background};
`
