import { useEffect, useState } from 'react'
import EventArchiveList from 'src/components/Event/EventArchiveList'
import getEvents from 'src/services/get/getEvents'

const EventArchiveListContainer = () => {
  window.scrollTo(0, 0)
  const [isLoading, setIsLoading] = useState(true)
  const [arChiveEvents, setArchiveEvents] = useState([])
  const [totalPages, setTotalPages] = useState(0)
  const [currPage, setCurrPage] = useState(1)

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)
      const res = await getEvents(currPage)
      if (res.events.length === 0) return
      setIsLoading(false)
      setArchiveEvents(res.events)
      setTotalPages(res.total_pages)
    })()
  }, [currPage])

  return (
    <EventArchiveList
      events={arChiveEvents}
      isLoading={isLoading}
      totalPage={totalPages}
      currPage={currPage}
      setCurrPage={setCurrPage}
    />
  )
}

export default EventArchiveListContainer
