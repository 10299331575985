import PropTypes from 'prop-types'
import { useState } from 'react'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import UserNameEditBox from 'src/components/menu/UserNameEditBox'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import { Link } from 'react-router-dom'
import { MENU_PATHS } from 'src/routes/menu'
import { Modal } from 'src/components/common/Modal'
import SectionHeader from 'src/components/menu/SectionHeader'
import EmailEditBox from 'src/components/menu/EmailEditBox'
import ModalSuccess from 'src/components/common/ModalSuccess'
import moment from 'moment'

const UserProfilePage = ({ user, handleSave, isLoading, apiErrors = {} }) => {
  const [showEditUserNameModal, setShowEditUserNameModal] = useState(false)
  const [showEditEmailModal, setShowEditEmailModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const genBirthdayString = () => {
    if (!user?.birthday) return 'xxxx年xx月xx日'
    return moment(user?.birthday).format('YYYY年MM月DD日')
  }

  return (
    <>
      <MenuBar title="user_profile" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex items-center">
          <div className="bg-white p-6 w-full rounded font-hiragino flex flex-col items-start overflow-y-auto" style={{ maxHeight: '100%' }}>
            <SectionHeader title="ユーザーID" />
            <span className="mt-2 mb-6 ml-8">{user?.id}</span>
            <SectionHeader
              title="名前"
              button={
                <button
                  className="text-xs border rounded-md p-[3px] border-menu-dark-blue right-0 ml-auto"
                  onClick={() => setShowEditUserNameModal(true)}
                >
                  名前を変更する
                </button>
              }
            />
            <span className="mt-2 mb-6 ml-8">{user?.name}</span>
            <SectionHeader title="生年月日" />
            <span className="mt-2 mb-4 ml-8">{genBirthdayString()}</span>
            <span className="text-xs text-left whitespace-pre-line">
              ※生年月日は変更できません。生年月日を変更したい場合、
              <Link to={MENU_PATHS.InquiryPage} className="text-menu-dark-blue">
                お問い合わせ
              </Link>
              までご連絡ください。
            </span>
            <SectionHeader
              title="メールアドレス"
              button={
                <button
                  className="text-xs border rounded-md p-[3px] border-menu-dark-blue right-0 ml-auto"
                  onClick={() => setShowEditEmailModal(true)}
                >
                  {user?.email ? 'アドレスを変更する' : 'アドレスを登録する'}
                </button>
              }
            />
            <span className="mt-2 mb-4 ml-8">{user?.email || '未設定'}</span>
            <span className="text-xs text-left whitespace-pre-line">
              {user?.email ? '※safariやchromeでログインする場合、コチラがログインIDとなります。' : '※メールアドレスを登録すると、safariやchromeでのログインが可能となります。'}
            </span>
            <SectionHeader
              title="パスワード"
              button={
                user?.email ? <button
                  className="text-xs border rounded-md p-[3px] border-menu-dark-blue right-0 ml-auto"
                  onClick={() => setShowEditEmailModal(true)}
                >
                  パスワードを変更する
                </button> : null
              }
            />
            {user?.email ? <>
              <span className="mt-2 mb-4 ml-8">********</span>
              <span className="text-xs text-left whitespace-pre-line">
                {'※パスワードの初期設定はランダム値です。\nパスワードの変更・またはお忘れの方はパスワード変更へお進みください。'}
              </span>
            </> : <span className="mt-2 mb-4 ml-8">未設定</span>}
          </div>
        </MenuWrapper>
        <Modal
          open={showEditUserNameModal}
          onClose={() => setShowEditUserNameModal(false)}
          className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
        >
          <UserNameEditBox
            userName={user?.name}
            handleSave={(value) => {
              setShowEditUserNameModal(false)
              handleSave({ name: value })
            }}
            handleClose={() => setShowEditUserNameModal(false)}
          />
        </Modal>
        <Modal
          open={showEditEmailModal}
          onClose={() => setShowEditEmailModal(false)}
          className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
        >
          <EmailEditBox
            emailAddress={user?.email}
            handleSave={ async (values) => {
              const success = await handleSave(values)
              setShowEditEmailModal(!success)
              setShowSuccessModal(success)
            }}
            handleClose={() => setShowEditEmailModal(false)}
            apiErrors={apiErrors}
          />
        </Modal>
        <ModalSuccess
          isOpen={showSuccessModal}
          text={'アドレスに確認メールを送りました！\nURLからログインしてくださいー!'}
          handleClose={() => setShowSuccessModal(false)}
        />
      </MainLayout>
      {isLoading && <Loading />}
    </>
  )
}

UserProfilePage.propTypes = {
  user: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  apiErrors: PropTypes.object
}

export default UserProfilePage
