import { useNavigate } from 'react-router-dom'
import MenuOshimen from 'src/assets/img/mypage/side-menu/menu-oshimen.png'

const RecommendedManBanner = () => {
  const navigate = useNavigate()

  return (
    <div onClick={() => navigate('/recommend')} className="absolute left-0 xs:top-0 -top-3 w-[7.4rem]">
      <img
        src={MenuOshimen}
        alt=""
        className="xs:w-[100%] w-[85%] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
      />
    </div>
  )
}

export default RecommendedManBanner
