import PropTypes from 'prop-types'

import ImprogressGacha from 'src/assets/img/gacha/Im_progress_gacha.png'

const Improgress = () => {
  return (
    <div className="bg-white/50 py-12 px-9 w-full text-2xl rounded-md flex mx-auto">
      <img src={ImprogressGacha} />
    </div>
  )
}

Improgress.propTypes = { message: PropTypes.string }

export default Improgress
