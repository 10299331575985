import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import EmailIcon from 'src/assets/img/message/detail/email-icon.png'
import EmailOpenedIcon from 'src/assets/img/message/detail/email-opened-icon.png'
import SpeakerIcon from 'src/assets/img/message/detail/speaker-icon.png'
import GiftIcon from 'src/assets/img/message/detail/gift-icon.png'
import PauseIcon from 'src/assets/img/message/detail/pause-icon.png'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import useAudio from 'src/components/common/UseAudio'
import { spendHeart } from 'src/services/get/getChat'
import { SpendHeartModal } from 'src/components/message/ChatBox/SpendHeartModal'
import { formatDate } from 'src/lib/date'
import { canSpendHeart, userPossessionHeart } from 'src/lib/message'
import HTMLReactParser from 'html-react-parser'
import { GiftDetail } from './GiftDetail'

export const ItemChat = ({
  msg,
  loveLvSettings,
  character,
  changeBgImage,
  isOpenTalk,
  isScrollOpenTalk,
  handleUpdateMessage,
  isOpenGift,
  setIsOpenGift,
  setMultiLevel,
  setIsMultiLoveModalOpen,
  handleOpenGift
}) => {
  const { advices } = msg
  const { user } = useAuthState()
  const authDispatch = useAuthDispatch()
  const [isOpenSpendHeartModal, setIsOpenSpendHeartModal] = useState(false)
  const [canViewDetail, setCanViewDetail] = useState(false)
  const [isSpendHeartConfirm, setIsSpendHeartConfirm] = useState(true)
  const [playing, playAudio, pauseAudio] = useAudio(msg.character_voice)
  const [userHeart, setUserHeart] = useState(0)
  const [canSpendHeartState, setCanSpendHearState] = useState(false)
  useEffect(() => {
    setUserHeart(userPossessionHeart(user))
    setCanSpendHearState(canSpendHeart(userHeart, loveLvSettings?.open_chat_heart))
  }, [user, userHeart])

  useEffect(() => {
    const target = localStorage.getItem('isSpendHeartConfirmItem')
    if (target === null) return setIsSpendHeartConfirm(true)
    setIsSpendHeartConfirm(JSON.parse(target.toLowerCase()))
  }, [localStorage.getItem('isSpendHeartConfirmItem')])

  const closeSpendHeartModal = useCallback(() => {
    setIsOpenSpendHeartModal(false)
  }, [])

  const openSpendHeartModal = useCallback(() => {
    if (!isSpendHeartConfirm && canSpendHeartState) {
      isScrollOpenTalk()
      return onSpendHeartConfirm()
    }
    setIsOpenSpendHeartModal(true)
  }, [isSpendHeartConfirm, canSpendHeartState, isScrollOpenTalk])

  useEffect(() => {
    setCanViewDetail(msg.is_heart_consumption || character.navigator)
  }, [msg.is_heart_consumption, character])

  const onSpendHeartConfirm = useCallback(async () => {
    try {
      const { data, currentLevel, nextLevel } = await spendHeart(msg.character_chat_id, user?.id)
      if (data) {
        handleUpdateMessage({
          ...msg,
          is_heart_consumption: true,
          description: data.description,
          title: data.title
        })
        setIsOpenSpendHeartModal(false)
        localStorage.setItem('isSpendHeartConfirmItem', false)
        setCanViewDetail(true)
        authDispatch({
          type: authActionTypes.SPEND_HEART,
          payload: loveLvSettings?.open_chat_heart
        })
        if (!currentLevel || !nextLevel) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      }
    } catch {}
  }, [
    msg.character_chat_id,
    user?.id,
    loveLvSettings,
    handleUpdateMessage,
    setMultiLevel,
    setIsMultiLoveModalOpen
  ])

  return (
    <div className="flex items-end w-full font-hiragino" onClick={() => changeBgImage(msg)}>
      <div
        className={
          'order-first rounded-lg relative bg-[#325282] max-w-[75%] w-[75%] h-auto p-2 pl-3 pt-[15px] border-[0.5px] border-white ml-3.5 mt-2.5 z-10 break-words'
        }
      >
        <div className="chat-item" />
        <div className="absolute left-2.5 -top-2.5 grid grid-cols-4 gap-1.5">
          {canViewDetail ? (
            <IconWrapper background="#fff">
              <img src={EmailOpenedIcon} alt="email" />
            </IconWrapper>
          ) : (
            <IconWrapper background="linear-gradient(0deg, #A8D1F7 0%, #88E4D4 100%)">
              <img src={EmailIcon} alt="email" />
            </IconWrapper>
          )}
          {(msg?.character_voice_id || msg?.character_voice) && (
            <IconWrapper background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)">
              <img src={SpeakerIcon} alt="speaker" />
            </IconWrapper>
          )}
          {(msg?.setting_item_id || msg?.setting_item) && (
            <IconWrapper background="linear-gradient(360deg, #F2709C 0%, #FF9472 100%)">
              <img src={GiftIcon} alt="gift" />
            </IconWrapper>
          )}
          {(msg?.character_cloth_id.length > 0 || msg?.character_cloth) && (
            <IconWrapper background="linear-gradient(360deg, #F2709C 0%, #FF9472 100%)">
              <img src={GiftIcon} alt="avatar-gift" />
            </IconWrapper>
          )}
        </div>
        <div
          className={`text-justify text-white text-xs font-light whitespace-pre-line ${
            !canViewDetail && 'pb-3'
          }`}
        >
          {HTMLReactParser(msg?.title)} {''}
          <span dangerouslySetInnerHTML={{ __html: msg?.description }} />
        </div>
        {canViewDetail && advices?.length ? (
          <div className="mt-2.5">
            <div className="bg-[linear-gradient(90deg,#FBED96_0%,#ABECD6_100%)] -mr-[8.5px] -ml-[12.5px] text-left pl-3.5 py-1 text-[#325282] text-[10px] font-medium">
              {advices[0].title}
            </div>
            <div className="text-[#FAED97] text-[10px] text-justify pt-[7px] leadding-[15px]">
              {advices[0].content}
            </div>
          </div>
        ) : null}
        {canViewDetail ? (
          <div className="flex-row w-[70%] mx-2">
            {(msg?.character_voice_id || msg?.character_voice) && (
              <div
                style={{
                  background: playing ? 'linear-gradient(90deg, #FFE259 0%, #FFA751 100%)' : '#fff',
                  color: playing ? '#fff' : '#325282',
                  fontWeight: playing ? '600' : '500'
                }}
                className="relative rounded-[3px] shadow-[0px_2px_6px_rgba(0,0,0,0.25)] text-[9px] py-[3.2px] pl-5 pr-2 cursor-pointer mb-1 mt-2 h-5 flex items-center"
              >
                {playing ? (
                  <div onClick={pauseAudio}>
                    <IconWrapper
                      className="absolute top-0 -left-2"
                      background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                    >
                      <img src={PauseIcon} alt="speaker" />
                    </IconWrapper>
                    ボイス再生中
                  </div>
                ) : (
                  <div onClick={playAudio}>
                    <IconWrapper
                      className="absolute top-0 -left-2"
                      background="linear-gradient(180deg, #FFE259 0%, #FFA751 107.5%)"
                    >
                      <img src={SpeakerIcon} alt="speaker" />
                    </IconWrapper>
                    ボイスを聞く
                  </div>
                )}
              </div>
            )}
            {msg?.setting_item_id && (
              <GiftDetail
                msg={msg}
                isReceived={msg?.is_received_present}
                giftType="setting_item"
                handleOpenGift={handleOpenGift}
              />
            )}
            {(msg?.character_cloth_id.length > 0 || msg?.character_cloth) && (
              <GiftDetail
                msg={msg}
                isReceived={msg?.is_received_cloth}
                giftType="character_cloth"
                handleOpenGift={handleOpenGift}
              />
            )}
          </div>
        ) : (
          <div
            onClick={openSpendHeartModal}
            className="cursor-pointer shadow-[0px_2px_6px_rgba(0,0,0,0.25)] absolute right-2 bottom-2 bg-white text-[9px] text-[#325282] font-medium leading-[11px] py-0.5 px-[12px] rounded-[3px]"
          >
            続きを読む
          </div>
        )}
      </div>
      <div className="ml-2 text-[8px] font-light text-[#325282]">
        <span>{formatDate(new Date(msg?.created_at))}</span>
      </div>
      <SpendHeartModal
        isOpen={isOpenSpendHeartModal}
        closeModal={closeSpendHeartModal}
        onConfirm={onSpendHeartConfirm}
        userHeart={userHeart}
        spendHeartAmount={loveLvSettings?.open_chat_heart}
        canSpendHeart={canSpendHeartState}
        isOpenTalk
        isScrollOpenTalk={isScrollOpenTalk}
      />
    </div>
  )
}

ItemChat.propTypes = {
  msg: PropTypes.object.isRequired,
  loveLvSettings: PropTypes.object,
  character: PropTypes.object,
  changeBgImage: PropTypes.func,
  isOpenTalk: PropTypes.func,
  isScrollOpenTalk: PropTypes.func,
  handleUpdateMessage: PropTypes.func,
  isOpenGift: PropTypes.bool,
  setIsOpenGift: PropTypes.func,
  setMultiLevel: PropTypes.func,
  setIsMultiLoveModalOpen: PropTypes.func,
  handleOpenGift: PropTypes.func
}

const IconWrapper = styled.div.attrs({
  className:
    'flex items-center justify-center h-5 w-5 rounded-[50%] overflow-hidden border border-white p-[4px]'
})`
  background: ${(props) => props.background};
`
