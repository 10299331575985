import React from 'react'
import CurrencyChangeIndicator from 'src/components/common/CurrencyChangeIndicator'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'

const ShopExchangeInsufficientModal = () => {
  const { user } = useAuthState()
  const { closeModal, selectedHeartExchange } = useShopContext()
  const diamondAmount = user?.red_amount

  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[340px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={closeModal} />
      <TitleHeader title="交換失敗" />
      <div className="text-lg font-bold text-center whitespace-pre-line">
        ダイヤが不足しています。
      </div>
      <div className="w-4/5 my-6">
        <div className="flex justify-center w-full p-2 mb-4 text-sm bg-white border rounded-md border-menu-dark-blue">
          <span className="mr-2">必要数</span>
          <span>ダイヤ{selectedHeartExchange?.number_of_red_diamonds - diamondAmount}個</span>
        </div>
        <CurrencyChangeIndicator
          icon="red"
          from={diamondAmount}
          to={0}
          counter="個"
          hasChange={true}
        />
      </div>
    </div>
  )
}

export default ShopExchangeInsufficientModal
