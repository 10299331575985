import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const postSkipOpeningStory = async () => {
  const res = await axiosInstance.post(API_ROUTES.Tracking.ActionAccessLine, { action_name: 'skip_open_story_at' })
  return res.data
}

const postReadOpeningStory = async () => {
  const res = await axiosInstance.post(API_ROUTES.Tracking.ActionAccessLine, { action_name: 'read_open_story_at' })
  return res.data
}

export { postSkipOpeningStory, postReadOpeningStory }
