import PropTypes from 'prop-types'
import SectionHeader from 'src/components/menu/SectionHeader'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnSyusei from 'src/assets/img/menu/btn_syusei.png'
import BtnSubmit from 'src/assets/img/menu/btn_submit.png'
import ReactPlayer from 'react-player'

const InquiryPreview = ({
  onSubmit,
  onEdit,
  selectedCategory,
  message,
  userId,
  file1,
  file2,
  file3
}) => {
  return (
    <div className="pb-4">
      <div className="w-full bg-white rounded text-left leading-6bg-white p-6 mt-4 flex flex-col">
        <div className="pb-4 text-center">
          以下の内容で問合せをします。 内容をご確認の上、送信してください。
        </div>
        <SectionHeader title="ユーザーID" />
        <span className="mt-2 mb-6 w-full text-left">{userId || '------'}</span>
        <SectionHeader title="カテゴリー" />
        <span className="mt-2 mb-6 w-full text-left">{selectedCategory.name}</span>
        <SectionHeader title="お問合せ内容" />
        <span className="mt-2 mb-6 w-full text-left styled-scroll max-h-44 overflow-y-auto whitespace-pre-line">
          <span className="pr-2">{message}</span>
        </span>
        <>
          {
            file1.includes('image') ? <img src={file1} className="w-2/5 h-2/5 object-cover my-2 m-auto" /> : <ReactPlayer height="100%" width="100%" controls url={file1} />
          }
          {
            file2.includes('image') ? <img src={file2} className="w-2/5 h-2/5 object-cover my-2 m-auto" /> : <ReactPlayer height="100%" width="100%" style={{ marginTop: file2 ? '10px' : '0px' }} controls url={file2} />
          }
          {
            file3.includes('image') ? <img src={file3} className="w-2/5 h-2/5 object-cover my-2 m-auto" /> : <ReactPlayer height="100%" width="100%" style={{ marginTop: file3 ? '10px' : '0px' }} controls url={file3} />
          }
        </>
        <div className="flex justify-around mt-2">
          <StandardizedButton
            className="w-32"
            disabled={false}
            onClick={onEdit}
            img={BtnSyusei}
            alt="修正する"
          />
          <StandardizedButton
            className="w-32"
            disabled={false}
            onClick={onSubmit}
            img={BtnSubmit}
            alt="送信"
          />
        </div>
      </div>
    </div>
  )
}

InquiryPreview.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectedCategory: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  file1: PropTypes.string.isRequired,
  file2: PropTypes.string.isRequired,
  file3: PropTypes.string.isRequired
}

export default InquiryPreview
