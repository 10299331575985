import React from 'react'
import { Footer } from '../../../../components/layouts/Footer'
import { Header } from '../../../../components/layouts/Header'
import { LayoutBackground } from '../../components/LayoutBackground'
import { LayoutHeader } from '../../components/LayoutHeader'
import { useParams } from 'react-router-dom'
import { CharacterLiveProvider } from '../../pages/CharacterSelection/_Provider'
import useCharacterListChannel from 'src/services/common/userCharacterListChannel'

// eslint-disable-next-line react/display-name
const withVoiceFullLayout = (Component, options) => () => {
  const { setting_id: settingId } = useParams()
  const characterLiveSocket = useCharacterListChannel({ liveSettingId: settingId })

  return (
    <>
      <CharacterLiveProvider value={{ characterLiveSocket }}>
        <div className="w-screen h-screen mx-auto md:w-desktop-width m-h-screen">
          <main>
            <LayoutBackground bg={options?.bg || ''} customBg={options?.customBg || ''} />
            <LayoutHeader
              rightContent={<img src={options.headerBg} className="mt-[8px] w-[148px] h-[70px]" />}
              botContent={<Header className="relative mt-[16px] px-[16px]" />}
            />

            <div className="hidden-scroll absolute top-[120px] w-full md:w-[400px] h-[calc(100%-220px)] pt-0 overflow-y-auto">
              <Component />
            </div>
          </main>
          <Footer />
        </div>
      </CharacterLiveProvider>
    </>
  )
}

export { withVoiceFullLayout }
