import { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import BGSelect from 'src/assets/img/ranking/detail/detail-time-select.png'
import BGWaiting from 'src/assets/img/ranking/detail/detail-time-waiting.png'

export const TabItem = ({ isActive, name, onClick }) => {
  return (
    <Wrapper
      className={`${isActive && 'font-bold'}`}
      background={isActive ? BGSelect : BGWaiting}
      onClick={() => {
        onClick && onClick()
      }}
    >
      <div>{name}</div>
    </Wrapper>
  )
}

TabItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

const Wrapper = styled.div.attrs({
  className: 'relative py-1 cursor-pointer'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

export const TimeTabs = ({ tabs, onChangeTab, gap = 'gap-1.5' }) => {
  const [activeTab, setActiveTab] = useState(0)
  const onClick = (index) => {
    setActiveTab(index)
    onChangeTab && onChangeTab(index)
  }
  const className = `grid font-hiragino text-sm text-[#532698] ${
    'grid-cols-' + tabs.length + ' ' + gap
  }`
  return (
    <div className={className}>
      {tabs.map((item, index) => (
        <TabItem
          key={index}
          isActive={activeTab === index}
          name={item.name}
          onClick={() => onClick(index)}
        />
      ))}
    </div>
  )
}

TimeTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChangeTab: PropTypes.func,
  gap: PropTypes.string
}
