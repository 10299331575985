import styled from 'styled-components'

import BGCharacter from 'src/assets/img/dressup/bg-character.png'
import Character from 'src/assets/img/dressup/character.png'

const CharacterDressup = () => {
  return (
    <Wrapper>
      <div className="absolute w-[50%] z-50">
        <img src={Character} alt="" />
      </div>
    </Wrapper>
  )
}

export default CharacterDressup

const Wrapper = styled.div.attrs({
  className: 'fixed top-0 sm:w-desktop-width w-screen mx-auto overflow-hidden'
})`
  background-image: url(${BGCharacter});
  background-size: 100% 100%;
  height: calc(50vh + 60px);
`
