import PropTypes from 'prop-types'
import { useEffect, useMemo, useState } from 'react'
import { getListYear, MIN_YEAR, MonthNames } from 'src/lib/date'

import arrowDown from 'src/assets/img/common/arrow-down.png'
export function DatePicker({
  handleChange,
  showMonthName = false,
  initDate = null,
  showDate = true
}) {
  const currentDate = useMemo(() => new Date(initDate), [initDate])
  const [year, setYear] = useState(currentDate.getFullYear() - 30)
  const [month, setMonth] = useState(0)
  const [date, setDate] = useState(1)
  const [dateList, setDateList] = useState()
  const yearList = getListYear(MIN_YEAR, new Date().getFullYear())
  const handleChangeDate = (e) => {
    setDate(e.target.value)
  }

  const handleChangeMonth = (e) => {
    const selectedMonth = e.target.value
    setMonth(selectedMonth)
  }

  const handleChangeYear = (e) => {
    setYear(e.target.value)
  }

  const adjustDate = (m) => {
    const dList = []
    const days = new Date(year, parseInt(m) + 1, 0).getDate()
    for (let i = 1; i <= days; i++) {
      dList.push(i)
    }
    setDateList(dList)
    if (days < parseInt(date)) setDate(days)
  }

  useEffect(() => {
    adjustDate(month)
  }, [year, month])

  useEffect(() => {
    handleChange(new Date(year, month, date))
  }, [date, year, month])

  return (
    <div className="flex w-full justify-center">
      <div className="auto-cols-auto pr-4 flex items-end">
        <div className="w-full relative">
          <select
            className="text-center text-[15px] font-light leading-[22px] text-[#000000] block appearance-none w-full border border-[#A2D2FF] py-3 px-2 pr-6 rounded focus:outline-none focus:bg-white"
            id="grid-state"
            value={year}
            onChange={handleChangeYear}
          >
            {yearList.map((y) => {
              return (
                <option key={y} value={y}>
                  {y}
                </option>
              )
            })}
          </select>
          <div className="cursor-pointer rounded-r bg-[#A1D2FF] pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 border-l-[1px] border-[#A2D2FF] text-gray-700">
            <img src={arrowDown} />
          </div>
        </div>
        <label
          className="font-light block uppercase tracking-wide text-[##333333] text-xs pl-[4px]"
          htmlFor="grid-state"
        >
          年
        </label>
      </div>
      <div className="auto-cols-auto pr-2 flex items-end">
        <div className="w-full relative">
          <select
            className="text-center text-[15px] font-light leading-[22px] text-[#000000] block appearance-none w-full border border-[#A2D2FF] py-3 px-2 pr-6 rounded focus:outline-none focus:bg-white"
            id="grid-state"
            value={month}
            onChange={handleChangeMonth}
          >
            {MonthNames.map((name, idx) => {
              const monthIdx = idx + 1
              return (
                <option key={name} value={idx}>
                  {showMonthName ? name.slice(0, 3) : monthIdx < 10 ? `0${monthIdx}` : monthIdx}
                </option>
              )
            })}
          </select>
          <div className="cursor-pointer rounded-r bg-[#A1D2FF] pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 border-l-[1px] border-[#A2D2FF] text-gray-700">
            <img src={arrowDown} />
          </div>
        </div>
        <label
          className="font-light block uppercase tracking-wide text-[##333333] text-xs pl-[4px]"
          htmlFor="grid-state"
        >
          月
        </label>
      </div>
      {showDate === true && (
        <div className="auto-cols-auto   pl-2 flex items-end">
          <div className="w-full relative">
            <select
              className="text-center text-[15px] font-light leading-[22px] text-[#000000] block appearance-none w-full border border-[#A2D2FF] py-3 px-2 pr-6 rounded focus:outline-none focus:bg-white"
              id="grid-state"
              value={date}
              onChange={handleChangeDate}
            >
              {dateList &&
                dateList.map((d) => {
                  return (
                    <option key={d} value={d}>
                      {d < 10 ? `0${d}` : d}
                    </option>
                  )
                })}
            </select>

            <div className="cursor-pointer rounded-r bg-[#A1D2FF] pointer-events-none absolute inset-y-0 right-0 flex items-center px-1 border-l-[1px] border-[#A2D2FF] text-gray-700">
              <img src={arrowDown} />
            </div>
          </div>
          <label
            className="font-light block uppercase tracking-wide text-[##333333] text-xs pl-[4px]"
            htmlFor="grid-state"
          >
            日
          </label>
        </div>
      )}
    </div>
  )
}

DatePicker.propTypes = {
  initDate: PropTypes.string,
  handleChange: PropTypes.func,
  showMonthName: PropTypes.bool,
  showDate: PropTypes.bool
}
