import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LiveExtraTime } from '../../components/LiveExtraTime'
import { LiveTime } from '../../components/LiveTime'
import StartExtraTime from '../../components/StartExtraTime'
import _ from 'lodash'
import { HourglassModal } from './_HourglassModal'
import { LiveComments } from './_LiveComments'
import { ExtraTimeHelper } from './_ExtraTimeHelper'
import { LiveItemModal } from './_LiveItemModal'
import { useDuringContext } from './_Provider'
import { withDuringLayout } from './_withDuringLayout'
import { useSubcriber } from '../../hooks/useSubcriber'
import { postCountinueExtraTime } from 'src/services/post/postContinueExtraTime'
import { useAuthState } from '../../../../providers/AuthProviders'
import MultiLoveEffectModal from 'src/components/Effect/MultiLoveEffectModal'

const DuringDelivery = withDuringLayout(
  () => {
    const { liveInfoQuery, liveItemsSocket } = useDuringContext()
    const { data: liveInfos } = liveInfoQuery
    const navigate = useNavigate()
    const startedExtraTime = useRef({ showStartedExtra: () => {} })
    const isExtraTime = _.get(liveInfos, 'is_extra_time')
    const { user } = useAuthState()
    const isHasPassport = Boolean(_.get(user, 'platinum_passport'))
    const isHasDreamCompass = Boolean(_.get(user, 'dream_compass'))
    const isRequireDreamCompass = _.get(liveInfos, 'is_dream_compass')
    const isRequirePassport = _.get(liveInfos, 'is_platinum_passport')
    const isFreePassport = _.get(liveInfos, 'is_free_platium_passport')
    const canWorkWithFreePassport = _.get(liveInfos, 'can_work_with_free_passport')
    const [isMultiLoveModalOpen, setIsMultiLoveModalOpen] = useState(false)
    const [multiLevel, setMultiLevel] = useState({})

    const normalPassportModal = useMemo(() => {
      if (isRequirePassport) {
        if (isFreePassport) {
          return isHasPassport
        } else {
          return true
        }
      } else {
        return true
      }
    }, [isHasPassport, isRequirePassport, isFreePassport])

    const { channelName, appId, token } = useMemo(() => {
      const memoizedValue = {
        channelName: _.get(liveInfos, 'agora_infos.channel'),
        appId: _.get(liveInfos, 'agora_infos.app_id'),
        token: _.get(liveInfos, 'agora_infos.token')
      }
      return memoizedValue
    }, [liveInfos])

    const closeMultiLoveModal = useCallback(() => {
      setIsMultiLoveModalOpen(false)
      localStorage.removeItem('item')
      localStorage.removeItem('currentLevel')
      localStorage.removeItem('nextLevel')
    }, [setIsMultiLoveModalOpen])

    const handleComplete = async () => {
      if (liveItemsSocket.enableExtraTime) {
        await liveInfoQuery.refetch()
        const iconHelp = document.getElementById('icon-help')
        if (iconHelp) {
          iconHelp.click()
        }
        liveItemsSocket.setLimittedHourglass(false)
        startedExtraTime.current?.showStartedExtra()
      } else {
        navigate(-1)
      }
    }

    useEffect(() => {
      if (!liveInfos) return

      if (!_.get(liveInfos, 'can_join_live_stream')) {
        navigate(-1)
        return
      }

      if (_.get(liveInfos, 'white_list').length > 0 && !_.get(liveInfos, 'white_list').includes(_.get(user, 'id'))) {
        navigate(-1)
        return
      }

      if (_.get(liveInfos, 'black_list').length > 0 && _.get(liveInfos, 'black_list').includes(_.get(user, 'id'))) {
        navigate(-1)
        return
      }

      if (isRequireDreamCompass && !isHasDreamCompass) {
        navigate(-1)
        return
      }

      if (isRequirePassport) {
        if (isFreePassport && !isHasPassport && (_.get(liveInfos, 're_joined') || isExtraTime)) {
          navigate(-1)
          return
        }

        if (!isFreePassport && !isHasPassport) {
          navigate(-1)
          return
        }
      }

      if (_.get(liveInfos, 'live_stream.status') !== 'incomming') {
        navigate(-1)
      } else if (isExtraTime && !_.get(liveInfos, 'can_join_extra_time') && _.get(liveInfos, 'can_continue_extra')) {
        startedExtraTime.current?.showStartedExtra()
        navigate(-1)
      } else {
        liveItemsSocket.setEnableExtraTime(_.get(liveInfos, 'enable_extra_time'))
        liveItemsSocket.setExtraWatchableTime(_.get(liveInfos, 'extra_watchable_time'))
        liveItemsSocket.setLimittedHourglass(_.get(liveInfos, 'can_watch_till_normal_time'))
        if (isExtraTime) {
          liveItemsSocket.setLimittedHourglass(_.get(liveInfos, 'can_watch_till_end_live'))
          startedExtraTime.current?.showStartedExtra()
        }
        if (!_.get(liveInfos, 're_joined')) {
          document.getElementById('icon-help').click()
        }
      }
    }, [liveInfos])

    useEffect(() => {
      if (!liveItemsSocket.disableUser) return

      navigate(-1)
    }, [liveItemsSocket.disableUser])

    useSubcriber({
      channelName,
      appId,
      token,
      settingId: _.get(liveInfos, 'live_stream.livestream_setting_id')
    })

    const handleCompleteContinue = () => {
      postCountinueExtraTime({ livestream_id: _.get(liveInfos, 'live_stream.id') }).then(() => {
        navigate(-1)
      })
    }
    return (
      <>
        { _.get(liveInfos, 'live_stream.watchable_time') && (
          <div className="relative h-[calc(100vh-56px)]">
            <div className="absolute top-0 right-0 space-y-[8px] text-right">
              <LiveTime
                time={_.get(liveInfos, 'live_stream.watchable_time')}
                className="relative"
                onComplete={handleComplete}
              />

              <LiveExtraTime
                time={_.get(liveInfos, 'count_down_extra_time')}
                className="relative"
              />

              <StartExtraTime
                time={!_.get(liveInfos, 'can_continue_extra') && new Date(_.get(liveInfos, 'extra_continue_time')) > new Date() ? _.get(liveInfos, 'extra_continue_time') : _.get(liveInfos, 'extra_watchable_time')}
                ref={startedExtraTime}
                onComplete={handleCompleteContinue}
                className="relative"
              />
              <ExtraTimeHelper isExtraTime={isExtraTime} normalPassportModal={normalPassportModal} freeMinutes={_.get(liveInfos, 'passport_free_time')} />
            </div>

            <div className="absolute bottom-0 right-0 space-y-[8px] text-right">
              <HourglassModal
                isExtraTime={isExtraTime}
                setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                setMultiLevel={setMultiLevel}
                normalPassportModal={normalPassportModal}
              />
              <LiveItemModal
                setIsMultiLoveModalOpen={setIsMultiLoveModalOpen}
                setMultiLevel={setMultiLevel}
                normalPassportModal={normalPassportModal}
                canWorkWithFreePassport={canWorkWithFreePassport}
              />
            </div>

            <MultiLoveEffectModal
              character={_.get(liveInfos, 'character')}
              isMultiLoveModalOpen={isMultiLoveModalOpen}
              multiLevel={multiLevel}
              onClose={closeMultiLoveModal}
            />

            <div className="flex flex-col h-full w-[75%] justify-between">
              <LiveComments normalPassportModal={normalPassportModal} canWorkWithFreePassport={canWorkWithFreePassport} />
            </div>
          </div>
        )}
      </>
    )
  }
)
export { DuringDelivery }
