export default class GachaInfo {
  constructor(
    id,
    name,
    content,
    amount,
    startAt,
    endAt,
    gachaCover,
    gachaType,
    highPriorityOrder,
    lotteryCount,
    paymentLimit,
    salesType,
    status,
    useCompass,
    useSSGachaTicket,
    eventId,
    paymentItemId,
    image,
    gachaModes
  ) {
    this.id = id
    this.name = name
    this.content = content
    this.amount = amount
    this.startAt = startAt
    this.endAt = endAt
    this.gachaCover = gachaCover
    this.gachaType = gachaType
    this.highPriorityOrder = highPriorityOrder
    this.lotteryCount = lotteryCount
    this.paymentLimit = paymentLimit
    this.salesType = salesType
    this.status = status
    this.useCompass = useCompass
    this.useSSGachaTicket = useSSGachaTicket
    this.eventId = eventId
    this.paymentItemId = paymentItemId
    this.image = image
    this.gachaModes = gachaModes
  }
}

export const GACHA_TYPES = {
  Clothes: 'avatar',
  Items: 'item',
  Still: 'still',
  Mixer: 'mixer'
}

export const DRAW_TYPE = {
  Free: 0,
  One: 1,
  Eleven: 2
}

export const CURRENCY_TYPE = {
  Diamond: 0,
  Ticket: 1,
  SSRTicket: 2
}
