import React from 'react'
import styled from 'styled-components'
import { useFirstStory } from 'src/pages/newUser/hooks/useFirstStory'
import StoryWithVoice from 'src/pages/newUser/StoryWithVoice'

import Nameplate from 'src/assets/img/opening-story/nameplate.png'
import BGHistoryBtn from 'src/assets/img/opening-story/btn-history.png'
import BGAutoBtn from 'src/assets/img/opening-story/btn-auto.png'
import BGSkipBtn from 'src/assets/img/opening-story/btn-skip.png'
import BGVoicePlay from 'src/assets/img/opening-story/bg-voice-play.png'
import ArrowDownIcon from 'src/assets/img/opening-story/arrow-down.png'
import IconClose from 'src/assets/img/opening-story/icon-close.png'
import BtnAutoCancel from 'src/assets/img/opening-story/btn_auto_cancel.png'
import { postReadOpeningStory } from 'src/services/post/postSkipOpeningStory'
import BGCommon from 'src/assets/img/register/bg-common.png'
import BtnModalOpen from 'src/assets/img/common/btn_music-setting.png'
import { MusicModal } from 'src/components/common/MusicModal'
import Loading from 'src/components/layouts/Loading'
import { ModalWithBg } from 'src/components/common/ModalWithBg'
import StandardizedButton from 'src/components/common/StandardizedButton'
import BtnConfirm from 'src/assets/img/opening/btn-skip.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'

export const OpeningStoryPage = () => {
  const {
    stories,
    currentStory,
    blackoutBg,
    isRepeatBgm,
    openHistory,
    nextStory,
    audioSettings,
    handleHistoryAction,
    handleAutoAction,
    handleAudioClick,
    handleMusicModalToggle,
    redirectTo,
    ref,
    messagesEndRef,
    isLastStory,
    isAuto,
    isMusicModalOpen,
    setOpenHistory,
    setStoryIndex,
    storyIndex,
    audioRef,
    isReady,
    audioSeRef,
    audioCharacterRef,
    wrapperRef,
    containerRef,
    blackoutColorAnimation,
    setBlackoutColorAnimation,
    openModal,
    setOpenModal
  } = useFirstStory()

  if (!isReady) {
    return (
      <Wrapper backgroundImage={BGCommon} blackout={blackoutBg}>
        <Loading />
      </Wrapper>
    )
  }

  return (
    <div
      style={{ backgroundColor: `${blackoutColorAnimation}` }}
      className="sm:w-[400px] w-screen h-screen mx-auto relative bg-no-repeat bg-center bg-cover"
      ref={containerRef}
    >
      <Wrapper
        backgroundImage={currentStory?.backgroundImage}
        blackout={blackoutBg}
        timeTrasition={
          currentStory?.type === 'CancelBlackout' ? `${currentStory.timeToLeave / 1000}s` : '2s'
        }
        ref={wrapperRef}
      >
        <MusicModalOpen onClick={handleMusicModalToggle}>
          <img src={BtnModalOpen} alt="" />
        </MusicModalOpen>
        {currentStory && (
          <>
            <audio
              ref={audioCharacterRef}
              src={currentStory.character_voice_url ? currentStory.character_voice_url : ''}
              loop={currentStory.isCharacterVoiceRepeat}
              {...(audioSettings.voice && { muted: true })}
            />
            {currentStory.bgmUrl && (
              <div style={{ visibility: 'hidden' }} >
                <audio
                  ref={audioRef}
                  autoPlay={true}
                  src={currentStory?.bgmUrl ? currentStory.bgmUrl : ''}
                  loop={isRepeatBgm}
                  {...(audioSettings.bgm && { muted: true })}
                />
                <iframe src={currentStory?.bgmUrl ? currentStory.bgmUrl : ''} type="audio/mp3" allow="autoplay" id="audio" />
                <audio autoPlay>
                  <source src={currentStory?.bgmUrl ? currentStory.bgmUrl : ''} type="audio/mp3" />
                </audio>
              </div>
            )}
            {currentStory?.characterImageThumbnail && (
              <CharacterThumbContainer>
                <CharacterThumb CharacterThumb={currentStory?.characterImageThumbnail} />
              </CharacterThumbContainer>
            )}
            {currentStory?.seUrl && (
              <div style={{ visibility: 'hidden' }}>
                <audio
                  ref={audioSeRef}
                  autoPlay={true}
                  src={currentStory.seUrl ? currentStory.seUrl : ''}
                  loop={currentStory.isSeAudioRepeat}
                  {...(audioSettings.se && { muted: true })}
                />
                <iframe src={currentStory?.seUrl ? currentStory.seUrl : ''} type="audio/mp3" allow="autoplay" id="audio" />
                <audio autoPlay>
                  <source src={currentStory?.seUrl ? currentStory.seUrl : ''} type="audio/mp3" />
                </audio>
              </div>
            )}

            {currentStory?.address && (
              <Address length={currentStory.address.length}>{currentStory.address}</Address>
            )}
          </>
        )}
        {!openHistory ? (
          <>
            {nextStory?.character_voice_url && (
              <VoicePlay>
                <div className="w-[110px] h-[30px] mt-2.5 ml-4 font-hiragino text-[9px] text-white font-semibold leading-[118%] text-start px-2.5">
                  <p>次のページは</p>
                  <p>ボイスが流れます</p>
                </div>
              </VoicePlay>
            )}
            <div className="absolute z-50 bottom-10 left-4 right-4">
              <UserContainer>
                <UserName>
                  <div className="relative">
                    <img
                      src={Nameplate}
                      alt=""
                      className="w-[180px]"
                      style={{ transform: 'rotate(1.24deg)' }}
                    />
                    <span className="text-[white] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] font-bold rotate-[-4.24deg] whitespace-nowrap">
                      {currentStory?.characterName}
                    </span>
                  </div>
                </UserName>
                <UserActions>
                  <HistoryBtn onClick={handleHistoryAction}>履歴</HistoryBtn>
                  <AutoBtn isAuto={isAuto} onClick={handleAutoAction}>
                    {isAuto ? 'Stop' : 'Auto'}
                  </AutoBtn>
                  <SkipBtn
                    onClick={() => {
                      setOpenModal(true)
                    }}
                  >
                    Skip
                  </SkipBtn>
                </UserActions>
              </UserContainer>
              <HistoryBottom
                onClick={() => {
                  if (isLastStory) {
                    postReadOpeningStory()
                    return redirectTo()
                  }
                  if (isAuto) return
                  if (nextStory.isBlackout === undefined) {
                    setStoryIndex((prev) => prev + 1)
                    return
                  }
                  let opacity = 1
                  const timeChange = nextStory.timeToChange
                  setBlackoutColorAnimation(nextStory.blackoutColor)
                  const intervalId = setInterval(() => {
                    opacity -= 0.1
                    wrapperRef.current.style.opacity = opacity
                    if (opacity <= 0) {
                      clearInterval(intervalId)
                      setStoryIndex((prev) => prev + 1)
                    }
                  }, timeChange / 10)
                }}
              >
                {currentStory && (
                  <div className="focus:outline-0 w-full h-full bg-transparent text-[13px] font-light font-hiragino leading-[21px] overflow-y-auto break-words">
                    <p ref={ref} />
                  </div>
                )}
                {(isLastStory && isAuto) || isAuto || (
                  <TriangleDown>
                    <img src={ArrowDownIcon} className="h-8" alt="arrow down" />
                  </TriangleDown>
                )}
              </HistoryBottom>
            </div>{' '}
          </>
        ) : (
          <div className="absolute top-32 left-4 right-4">
            <div
              className="absolute cursor-pointer -top-6 -right-4"
              onClick={() => setOpenHistory(false)}
            >
              <img
                className="drop-shadow-[0px_2px_6px_rgba(0,0,0,0.25)] w-12 h-11"
                src={IconClose}
                alt="close"
              />
            </div>

            {stories && (
              <div className="pl-6 pr-4 py-6  bg-[rgba(255,255,255,0.95)]">
                <div className="pr-2 max-h-[65vh] overflow-y-auto styled-scroll font-hiragino text-[13px] font-light leading-[21px] text-[#000000] text-justify">
                  {stories.map((story, i) => {
                    if (storyIndex < i) return false
                    return <StoryWithVoice story={story} key={story.id} />
                  })}
                  <div ref={messagesEndRef} />
                </div>
              </div>
            )}
          </div>
        )}
        {isMusicModalOpen && (
          <MusicModal
            muteSettings={audioSettings}
            handleAudioClick={handleAudioClick}
            handleMusicModalToggle={handleMusicModalToggle}
          />
        )}
      </Wrapper>
      <ModalWithBg open={openModal} onClose={() => setOpenModal(false)} title={'確認'}>
        <p className="text-center pb-10 font-bold">ストーリーをスキップしますか?</p>
        <div className="flex w-[100%] justify-between">
          <StandardizedButton
            className="w-28"
            img={BtnCancel}
            alt="キャンセル"
            onClick={() => setOpenModal(false)}
          />
          <StandardizedButton
            className="w-28"
            img={BtnConfirm}
            alt="スキップ"
            onClick={redirectTo}
          />
        </div>
      </ModalWithBg>
    </div>
  )
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${(props) => props.backgroundImage});
  z-index: 0;
  background-color: ${(props) => props.blackout};
  transition: background-color ${(props) => props.timeTrasition}, opacity 2s;
`

const MusicModalOpen = styled.div.attrs({
  className: 'absolute top-4 right-4 w-10'
})``

const CharacterThumbContainer = styled.div.attrs({
  className: 'w-[100%] h-[100%] absolute left-[0px] bottom-[0px]'
})`
  height: calc(100vh - 100px);
  z-index: 10;
`

const CharacterThumb = styled.div.attrs({
  className: 'w-full h-full absolute'
})`
  background-image: url(${(props) => props.CharacterThumb});
  background-size: 150% 150%;
  background-repeat: no-repeat;
  background-position: 50% -25%;
`

const Address = styled.div.attrs({
  className: 'absolute top-6 left-0 w-32 h-7 text-white text-sm whitespace-nowrap'
})`
  background-color: #6c74b4;
  line-height: 28px;
  font-size: ${(props) => (props.length > 7 ? '12px' : '14px')};
`

const UserContainer = styled.div.attrs({
  className: 'flex absolute -top-6 -left-3 w-full z-10 justify-between'
})`
  width: 100%;
  font-size: 14px;
  border: 1px;
`

const UserName = styled.div.attrs({
  className: ''
})`
  height: 63px;
  border-radius: 4px;
`

const UserActions = styled.div.attrs({
  className: 'flex'
})`
  margin-bottom: 2px;
`

const HistoryBottom = styled.div.attrs({
  className: 'relative bg-white border-orange-200 border-[1px] text-left px-10 pt-7 pb-8'
})`
  height: 150px;
  background: rgba(255, 255, 255, 0.9);
  border: 3px solid #ffffff;
  border-radius: 6px;
`

const TriangleDown = styled.span.attrs({
  className: 'absolute right-1 bottom-1 cursor-pointer'
})``

const HistoryBtn = styled.div.attrs({
  className:
    'cursor-pointer w-[46px] h-[37px] text-[10px] font-medium font-hiragino text-white leading-4 flex items-end justify-center pb-1 mr-2'
})`
  background-image: url('${BGHistoryBtn}');
  background-size: 100% 100%;
  filter: drop-shadow(0px -1px 6px rgba(0, 0, 0, 0.25));
`

const AutoBtn = styled.div.attrs({
  className:
    'cursor-pointer w-[46px] h-[37px] text-[10px] font-medium font-hiragino text-white leading-4 flex items-end justify-center pb-1 mr-2'
})`
  background-image: url(${(props) => (props.isAuto ? BtnAutoCancel : BGAutoBtn)});
  background-size: 100% 100%;
`

const SkipBtn = styled.div.attrs({
  className:
    'cursor-pointer w-[46px] h-[37px] text-[10px] font-medium font-hiragino text-white leading-4 flex items-end justify-center pb-1'
})`
  background-image: url('${BGSkipBtn}');
  background-size: 100% 100%;
`

const VoicePlay = styled.div.attrs({
  className: 'flex absolute bottom-56 right-6 w-[110px] h-[40px]'
})`
  transform: rotate(0.8deg);
  background-image: url('${BGVoicePlay}');
  background-size: 100% 100%;
`
