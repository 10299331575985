import Heart from 'src/assets/img/common/heart.png'

export const getItemImage = (present) => {
  if (present.image && present.presentType !== 'heart') return present.image
  return Heart
}

export const isPresentTypeHeart = (presentType) => presentType === 'heart'

export const isPresentTypeDiamond = (presentType) => presentType === 'diamond'

export const isPresentTypeDiamondRed = (presentType) => presentType === 'diamond_red'

const presentTotalAmount = (obj) => obj.reduce((acc, present) => acc + present.amount, 0)

export const updateMultipleItemAmount = (presents, updateUserItem) => {
  const HeartType = 'heart'
  const DiamondType = 'diamond'
  const DiamondRedType = 'diamond_red'

  const presentTypeHeartList = presents.filter((present) => present.presentType === HeartType)
  if (presentTypeHeartList.length > 0) {
    const totalAmount = presentTotalAmount(presentTypeHeartList)
    updateUserItem(HeartType, totalAmount)
  }

  const presentTypeDiamondList = presents.filter((present) => present.presentType === DiamondType)
  if (presentTypeDiamondList.length > 0) {
    const totalAmount = presentTotalAmount(presentTypeDiamondList)
    updateUserItem(DiamondType, totalAmount)
  }

  const presentTypeDiamondRedList = presents.filter((present) => present.presentType === DiamondRedType)
  if (presentTypeDiamondRedList.length > 0) {
    const totalAmount = presentTotalAmount(presentTypeDiamondRedList)
    updateUserItem(DiamondRedType, totalAmount)
  }
}

export const updateSingleItemAmount = ({ presents, updateUserItem, presentId }) => {
  const findItem = presents.find((present) => present.id === presentId)
  const { presentType, amount } = findItem

  if (isPresentTypeHeart(presentType)) updateUserItem(presentType, amount)
  if (isPresentTypeDiamond(presentType)) updateUserItem(presentType, amount)
  if (isPresentTypeDiamondRed(presentType)) updateUserItem(presentType, amount)
}
