import PropTypes from 'prop-types'
import { useState } from 'react'
import clsx from 'clsx'
import { MIXER_ITEM_AMOUNTS } from 'src/pages/main/capsule/CapsuleToyContainer'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import StandardizedButton from 'src/components/common/StandardizedButton'
import { genGachaTimeString } from 'src/lib/date'
import { Modal } from 'src/components/common/Modal'
import CapsuleMixerExplanationModal from 'src/pages/main/capsule/CapsuleMixerExplanationModal'
import CapsuleDetailsModal from 'src/pages/main/capsule/CapsuleDetailsModal'
import CapsuleMixerDrawModal from 'src/pages/main/capsule/CapsuleMixerDrawModal'
import CapsuleMixerConfirmModal from 'src/pages/main/capsule/CapsuleMixerConfirmModal'
import CapsuleResultScreen from 'src/pages/main/capsule/CapsuleResultScreen'
import Item from 'src/models/Item'

import BtnAboutReward from 'src/assets/img/gacha/btn_about_reward.png'
import BtnAboutMixer from 'src/assets/img/gacha/btn_about_mixer.png'
import BtnR20SSR1 from 'src/assets/img/gacha/btn_R20SSR1.png'
import BtnSR5SSR1 from 'src/assets/img/gacha/btn_SR5SSR1.png'
import BtnSR10SSR1 from 'src/assets/img/gacha/btn_SR10SSR1.png'

const CapsuleMixerTab = ({
  image,
  startDate,
  endDate,
  isShown,
  items,
  drawMixerGacha,
  resultItem
}) => {
  const MODAL_TYPES = { Details: 0, About: 1, Draw: 2, Confirm: 3, Result: 4 }
  const [showModal, setShowModal] = useState(false)
  const [modalContent, setModalContent] = useState()
  const [requiredItemAmount, setRequiredItemAmount] = useState(MIXER_ITEM_AMOUNTS.R20)
  const [selectedItems, setSelectedItems] = useState([])

  const openModal = (type, amount) => {
    setModalContent(type)
    setShowModal(true)
    if (amount) setRequiredItemAmount(amount)
  }

  const closeModal = () => setShowModal(false)

  const drawMixer = (items) => {
    drawMixerGacha(requiredItemAmount, items)
    setModalContent(MODAL_TYPES.Result)
  }

  return (
    <div className={clsx('relative h-full', !isShown && 'pointer-events-none')}>
      <FadeTransition show={isShown} className="absolute h-full top-0 left-0 right-0">
        <div className="p-2 h-full">
          <div
            className="w-full h-full bg-light-pink rounded-lg relative text-hiragino drop-shadow-md"
            style={{ backgroundImage: `url('${image}')`, backgroundSize: '100% 100%' }}
          >
            <button
              type="button"
              className="absolute top-3 left-2 w-36"
              onClick={() => openModal(MODAL_TYPES.About)}
            >
              <img src={BtnAboutMixer} alt="ミキサーガチャとは？" />
            </button>
            <div className="absolute bottom-6 p-2">
              <div className="flex gap-2">
                <StandardizedButton
                  onClick={() => {
                    openModal(MODAL_TYPES.Draw, MIXER_ITEM_AMOUNTS.R20)
                  }}
                  img={BtnR20SSR1}
                  alt="Rアイテム20個でSSRを1個獲得"
                  className=""
                />
                <StandardizedButton
                  onClick={() => {
                    openModal(MODAL_TYPES.Draw, MIXER_ITEM_AMOUNTS.SR10)
                  }}
                  img={BtnSR10SSR1}
                  alt="SRアイテム10個でSSRを1個獲得"
                  className=""
                />
                <StandardizedButton
                  onClick={() => {
                    openModal(MODAL_TYPES.Draw, MIXER_ITEM_AMOUNTS.SSR5)
                  }}
                  img={BtnSR5SSR1}
                  alt="SRアイテム5個でSSRを1個獲得"
                  className=""
                />
              </div>
              <div className="flex mt-2 items-center justify-between">
                <div className="w-full h-[30px] mx-auto bg-white/70 rounded text-sm truncate flex items-center justify-center px-6">
                  {genGachaTimeString(startDate, endDate)}
                </div>

                <button
                  type="button"
                  className="h-[30px] ml-2"
                  onClick={() => openModal(MODAL_TYPES.Details)}
                >
                  <img src={BtnAboutReward} alt="提供割合について" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>
      <Modal
        open={showModal}
        onClose={closeModal}
        className="sm:w-[360px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
      >
        {modalContent === MODAL_TYPES.About ? (
          <CapsuleMixerExplanationModal handleClose={closeModal} />
        ) : modalContent === MODAL_TYPES.Details ? (
          <CapsuleDetailsModal handleClose={closeModal} />
        ) : modalContent === MODAL_TYPES.Draw ? (
          <CapsuleMixerDrawModal
            handleClose={closeModal}
            handleConfirm={() => {
              setModalContent(MODAL_TYPES.Confirm)
            }}
            items={items}
            requiredItemAmount={requiredItemAmount}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        ) : modalContent === MODAL_TYPES.Confirm ? (
          <CapsuleMixerConfirmModal
            handleClose={closeModal}
            handleConfirm={drawMixer}
            handleRechoose={() => {
              setModalContent(MODAL_TYPES.Draw)
            }}
            selectedItems={selectedItems}
          />
        ) : (
          <CapsuleResultScreen
            handleClose={closeModal}
            handleAgain={() => setModalContent(MODAL_TYPES.Draw)}
            items={[resultItem]}
          />
        )}
      </Modal>
    </div>
  )
}

CapsuleMixerTab.propTypes = {
  isShown: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  startDate: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired,
  drawMixerGacha: PropTypes.func.isRequired,
  resultItem: PropTypes.instanceOf(Item)
}

export default CapsuleMixerTab
