import styled from 'styled-components'
import PropTypes from 'prop-types'
import { rarities } from 'src/constants/items'

const Item = ({ onClick, item = {} }) => {
  const { rarity, thumbnail } = item
  const rarityImg = rarities[rarity] || rarities.r
  const handleClick = () => {
    onClick && onClick(item)
  }
  return (<div>
    <ItemWrapper onClick={handleClick} background={rarityImg} >
      { thumbnail
        ? <ThumbnailWrapper background={thumbnail} />
        : null }

    </ItemWrapper>
    <p className='w-full pl-5 pr-3 text-[10px] font-hiragino text-[#000000]'>{item?.name}</p>
  </div>)
}

Item.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

const ItemWrapper = styled.div.attrs({
  className: 'h-[100px] w-full relative pt-[22px] pb-2 pl-3.5 pr-3'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const ThumbnailWrapper = styled.div.attrs({
  className: 'h-full w-full relative py-[18px] px-2.5'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const GridDressup = ({ items = [], gap, col }) => {
  return (
    <div className={`p-2.5 ${'grid grid-cols-' + col + ' ' + gap}`}>
      {items.map((item, index) => {
        return (
          <Item key={index} item={item} />
        )
      }
      )}
    </div>
  )
}

export default GridDressup

GridDressup.propTypes = {
  items: PropTypes.array,
  gap: PropTypes.string,
  col: PropTypes.number
}
