import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const postPaymentOrder = async (payload) => {
  const res = await axiosInstance.post(API_ROUTES.Payments.Order, payload)
  return res.data
}

const postPaymentOrderWebMoney = async (payload) => {
  const res = await axiosInstance.post(API_ROUTES.Payments.OrderWebMoney, payload)
  return res.data
}

const postPaymentOrderAmazon = async (payload) => {
  const res = await axiosInstance.post(API_ROUTES.Payments.AmazonPaymentsVerify, payload)
  return res.data
}

const postPaymentOrderBitcash = async (payload) => {
  const res = await axiosInstance.post(API_ROUTES.Payments.OrderBitcash, payload)
  return res.data
}

export { postPaymentOrder, postPaymentOrderWebMoney, postPaymentOrderAmazon, postPaymentOrderBitcash }
