import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getFaqs = async () => {
  try {
    const { data } = await axiosInstance.get(API_ROUTES.faqs)
    if (!data) throw new Error()
    return data || []
  } catch {
    return []
  }
}
