import moment from 'moment'
export const MonthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

export const getListYear = (minYear, maxYear) => {
  const listYear = []
  for (let i = minYear; i <= maxYear; i++) {
    listYear.push(i)
  }
  return listYear
}

export const MIN_YEAR = 1950

export const genDateString = (dateString, andTime, separator = '.') => {
  if (!dateString) return '20xx.xx.xx'

  const date = new Date(
    new Date(dateString).toLocaleString('ja-JP', {
      timeZone: 'Asia/Tokyo'
    })
  )
  dateString = `${date.getFullYear()}${separator}${String(
    date.getMonth() + 1
  )}${separator}${date.getDate()}`

  if (andTime === true) {
    dateString =
      dateString +
      ` ${date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()}:${
        date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
      }`
  }

  return dateString
}

export const genGachaTimeString = (startDate, endDate) => {
  const dateStartObject = moment(startDate).utcOffset(9).format('MM/DD')
  const dateEndObject = moment(endDate).utcOffset(9).format('MM/DD HH:mm')

  return `開催期間：${dateStartObject}~${dateEndObject}まで`
}

export const getDateWaiting = (item) => {
  if (!item) return null
  const start = moment(item.created_at)
  const now = moment()
  const end = moment(item.created_at)
    .add(item.day_waiting, 'days')
    .add(item.hour_waiting, 'hours')
    .add(item?.minute_waiting, 'minutes')
  const duration = moment.duration(end.diff(start))
  const days = duration.days()
  const hours = duration.hours()
  const minutes = duration.minutes()
  const percent = (now.diff(start) / end.diff(start)) * 100
  return {
    days,
    hours,
    minutes,
    percent,
    end,
    endformat: formatDate(end)
  }
}

export const formatDate = (date) => {
  const _date = moment(date)
  return `${_date.format('M/D H:mm')}`
}

export const beforeThePresentTime = (date) => {
  const now = new Date()
  const targetTime = new Date(date)
  return now.getTime() <= targetTime.getTime()
}

export const isNextDayAfter5AM = (targetDate) => {
  const date = new Date()
  const currentTime = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 5, 0, 0)
  const targetTime = new Date(
    targetDate.getFullYear(),
    targetDate.getMonth(),
    targetDate.getDate() + 1,
    5,
    0,
    0
  )
  return currentTime > targetTime
}

export const calculateDifference = (date) => {
  const currentDate = new Date()
  const argumentDate = new Date(date)
  const difference = argumentDate - currentDate
  const oneDay = 24 * 60 * 60 * 1000
  const oneHour = 60 * 60 * 1000
  const oneMinute = 60 * 1000

  if (difference >= 2 * oneDay) {
    return `受け取り期限あと${Math.floor(difference / oneDay)}日`
  } else if (difference >= oneDay && difference < 2 * oneDay) {
    return '受け取り期限あと1日'
  } else if (difference >= oneHour) {
    return `受け取り期限あと${Math.floor(difference / oneHour)}時間`
  } else if (difference >= oneMinute) {
    return `受け取り期限あと${Math.floor(difference / oneMinute)}分`
  } else if (difference <= oneMinute && difference > 0) {
    return `受け取り期限あと${Math.floor(oneMinute / 1000 / 60)}分`
  } else if (difference <= 0) {
    return '受け取り期限が切れました'
  }
}

export const formattedCountDownTime = (hours, minutes, seconds) => {
  return [
    (hours || 0).toString().padStart(2, '0'),
    (minutes || 0).toString().padStart(2, '0'),
    (seconds || 0).toString().padStart(2, '0')
  ].join(':')
}

export const formattedTimeToSecond = (hours, minutes, seconds) => {
  const totalSeconds = hours * 3600 + minutes * 60 + seconds
  return [totalSeconds]
}

export const TOKYO_TIME = new Date().toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
