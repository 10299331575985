const ChevronDoubleDown = () => {
  return (
    <svg width="19" height="24" viewBox="0 0 19 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4906 12.0176L0.415603 4.04883C-0.142522 3.49805 -0.142522 2.60742 0.415603 2.0625L1.75748 0.738281C2.3156 0.1875 3.2181 0.1875 3.77029 0.738281L9.49404 6.38672L15.2178 0.738281C15.7759 0.1875 16.6784 0.1875 17.2306 0.738281L18.5844 2.05664C19.1425 2.60742 19.1425 3.49805 18.5844 4.04297L10.5094 12.0117C9.95123 12.5684 9.04873 12.5684 8.4906 12.0176ZM10.5094 23.2676L18.5844 15.2988C19.1425 14.748 19.1425 13.8574 18.5844 13.3125L17.2425 11.9883C16.6844 11.4375 15.7819 11.4375 15.2297 11.9883L9.49998 17.6309L3.77623 11.9824C3.2181 11.4316 2.3156 11.4316 1.76342 11.9824L0.415603 13.3066C-0.142522 13.8574 -0.142522 14.748 0.415603 15.293L8.4906 23.2617C9.04873 23.8184 9.95123 23.8184 10.5094 23.2676Z"
        fill="#A1D2FF"
      />
    </svg>
  )
}

export default ChevronDoubleDown
