import React from 'react'
import { useMutation, useQuery } from 'react-query'
import AccountPage from 'src/pages/menu/Account'
import getNotificationSettings from 'src/services/get/getNotificationSetting'
import {
  putUpdateGlobalNotification,
  putUpdateNotification
} from 'src/services/put/putUpdateNotification'

const AccountPageContainer = () => {
  const { data, isFetching, isFetched, refetch } = useQuery({
    queryFn: getNotificationSettings,
    queryKey: 'get-notification-settings'
  })

  const { mutate: toggleAccept } = useMutation({
    // @ts-ignore
    mutationFn: async ({ id, isNotification }) => {
      await putUpdateNotification(id, isNotification)
    },
    onSuccess: () => refetch()
  })

  const { mutate: toggleGlobalSetting } = useMutation({
    // @ts-ignore
    mutationFn: async (isNotification) => {
      await putUpdateGlobalNotification(isNotification)
    },
    onSuccess: () => refetch()
  })

  return (
    <AccountPage
      isLoading={!isFetched && isFetching}
      toggleAccept={toggleAccept}
      characterNotifications={data?.characterSetting || []}
      globalSetting={data?.globalSetting || {}}
      toggleGlobalSetting={toggleGlobalSetting}
    />
  )
}

export default AccountPageContainer
