import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import PropTypes from 'prop-types'
import News from 'src/models/News'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import PinkBtn from 'src/assets/img/menu/btn_pink/btn_pink.png'
import Vector from 'src/assets/img/menu/btn_pink/vector.png'
import { useNavigate } from 'react-router-dom'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { genDateString } from 'src/lib/date'
import TipsModal from 'src/components/common/TipsModal'
import NotificationIcon from 'src/components/common/NotificationIcon'
import styled from 'styled-components'
import BGPage from 'src/assets/img/register/bg-common.png'
import Pagination from 'src/components/common/Pagination'

const NewsListPage = ({ newsList, isLoading, userId, totalPage = 0, currPage, setCurrPage }) => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <Header>
        <MenuBar
          title="news"
          onBack={() => {
            localStorage.removeItem('current_page')
            navigate(-1)
          }}
        />
      </Header>
      <MainLayout
        active="menu"
        showHeader={false}
        showFooter={false}
        className="h-fit mana-wrapper"
      >
        <div className="w-full p-4">
          {isLoading ? (
            <Loading />
          ) : !isLoading && newsList?.length === 0 ? (
            <NoContent message="お知らせがありません" />
          ) : (
            <Content>
              <div className="h-full hidden-scroll overflow-y-auto flex flex-col items-center gap-2">
                {newsList?.map((news) => (
                  <div
                    key={news.id}
                    onClick={() => {
                      localStorage.setItem('current_page', currPage)
                      navigate(`${MYPAGE_PATHS.NewsPage}/${news.id}`)
                    }}
                    className="relative p-4 bg-menu-off-blue rounded-md w-full text-left text-sm border-4 border-menu-dark-blue drop-shadow-lg font-hiragino flex justify-between items-center"
                  >
                    <div className="pr-2">
                      <span className="block">{genDateString(news.startDisplayDate)}</span>
                      <span className="block">{news.title}</span>
                    </div>
                    <div className="relative">
                      <PinkButton src={PinkBtn} />
                      <img
                        src={Vector}
                        className="absolute top-[28%] left-[38%]"
                        style={{ width: 'initial' }}
                      />
                    </div>
                    {news.unreadUserIds?.find((d) => d === userId) && (
                      <NotificationIcon className="top-0 right-0" style={{ width: '25px' }} />
                    )}
                  </div>
                ))}
              </div>
              <Pagination totalPage={totalPage} currPage={currPage} setCurrPage={setCurrPage} />
            </Content>
          )}
        </div>
        <TipsModal type="news" />
      </MainLayout>
    </Wrapper>
  )
}

NewsListPage.propTypes = {
  newsList: PropTypes.arrayOf(PropTypes.instanceOf(News)),
  isLoading: PropTypes.bool.isRequired,
  userId: PropTypes.number,
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func
}

export default NewsListPage

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`

const Content = styled.div.attrs({
  className: 'w-full mx-auto relative'
})`
  height: calc(100vh - 170px);
`

const PinkButton = styled.img.attrs({
  className: 'h-[26px]'
})`
  width: 26px !important;
`
