import React from 'react'
import PropTypes from 'prop-types'

import ModalCloseButton from 'src/components/common/ModalCloseButton'
import StandardizedButton from 'src/components/common/StandardizedButton'
import TitleHeader from 'src/components/common/TitleHeader'
import BtnChoose from 'src/assets/img/gacha/btn_choose.png'

const CapsuleModalSoldOut = ({ currencyType, handleClose }) => {
  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[368px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title="アイテム不足" className="mb-4" />
      <div className="font-bold text-center whitespace-pre-line text-lg">
        {/* {genInsufficientText()} */}
        ダイヤ(またはガチャチケット)が足りません
      </div>

      <div className="flex gap-5">
        <StandardizedButton className="w-28" onClick={handleClose} img={BtnChoose} alt="選び直す" />
      </div>
    </div>
  )
}

CapsuleModalSoldOut.propTypes = {
  currencyType: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired
}

export default CapsuleModalSoldOut
