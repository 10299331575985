import PropTypes from 'prop-types'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'

const CapsuleMixerExplanationModal = ({ handleClose }) => {
  return (
    <div className="w-full min-h-[300px] m-4 py-8 px-4 bg-popup-sm bg-fill bg-no-repeat rounded-md relative">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title="ミキサーガチャとは" />
      <div className="w-full font-hiragino whitespace-pre-line text-center text-sm mb-2">
        {'ミキサーガチャとは\n被りの着せ替えアイテムを交流会で使える\nSSRアイテムに交換できるガチャダヨ！'}
      </div>
      <div className="w-full font-hiragino whitespace-pre-line text-sm text-center">
        {'被ったアイテムを選択して\nSSRのアイテムをGETだコケッ！'}
      </div>
    </div>
  )
}

CapsuleMixerExplanationModal.propTypes = { handleClose: PropTypes.func.isRequired }

export default CapsuleMixerExplanationModal
