import { useNavigate } from 'react-router-dom'
import VoiceLiveImage from 'src/assets/img/mypage/side-menu/nav_voice_live.png'
import StartLive from 'src/pages/voiceLive/assets/bg_start_live.png'
import React, { useEffect, useState } from 'react'
import Countdown from 'react-countdown'
import { useQuery } from 'react-query'
import { getLiveSettingInfos } from 'src/services/get/getLiveSettingInfos'
import _ from 'lodash'
import clsx from 'clsx'

const VoiceLiveBanner = () => {
  const navigate = useNavigate()
  const FIVE_MINS = 5 * 60 * 1000
  const [isLive, setIsLive] = useState(false)
  const [textColor, setTextColor] = useState('text-glitter-lake')

  const { data, isFetched, remove } = useQuery({
    queryFn: getLiveSettingInfos,
    queryKey: 'get-live-setting-infos'
  })

  useEffect(() => {
    const startTime = _.get(data, 'start_time')
    if (new Date(startTime) < new Date()) {
      setIsLive(true)
      return
    }

    if (startTime && new Date(startTime) - new Date() < FIVE_MINS) {
      setTextColor('text-menu-red')
    }
  }, [data])

  useEffect(() => () => remove(), [])

  const changeTextColor = (total) => {
    if (total >= FIVE_MINS) return
    setTextColor('text-menu-red')
  }
  return (
    <>
      <div
        onClick={() => navigate('/voice-live/top-countdown')}
        style={{ transform: 'skew(0, -5deg)' }}
        className="relative shadow-[0_35px_35px_rgba(0,0,0,0.25)] z-30"
      >
        <div className="absolute border-b-2 border-[#fff] shadow-[2px_2px_#003289] bottom-0 top-[22px] right-0 left-0" />
        <img src={!isLive ? VoiceLiveImage : StartLive} alt="actor" className="mt-2" />
        {!isLive && (
          <div>
            {isFetched && data && (
              <span
                className={clsx(
                  'absolute text-[11px] font-fotKurokane font-black bottom-[5%] bg-clip-text drop-shadow-2xl block left-[7%]',
                  textColor
                )}
                style={{
                  textShadow:
                    '#fff 0px 0px 0px, #fff 0px 0px 0px, #fff 0px 0px 2px, #fff 0px 0px 3px, #fff 0px 0px 10px'
                }}
              >
                <Countdown
                  date={_.get(data, 'start_time')}
                  onComplete={() => setIsLive(true)}
                  onTick={({ total }) => changeTextColor(total)}
                  renderer={({ days, hours, minutes, seconds }) => {
                    return (
                      <>
                        {days.toString().padStart(2, '0')}日{hours.toString().padStart(2, '0')}時間
                        {minutes.toString().padStart(2, '0')}分{seconds.toString().padStart(2, '0')}
                        秒
                      </>
                    )
                  }}
                />
              </span>
            )}
          </div>
        )}
      </div>
    </>
  )
}

export default VoiceLiveBanner
