import clsx from 'clsx'
import PropTypes from 'prop-types'

import BGCommon from 'src/assets/img/register/bg-common.png'

const StandardPageWrapper = ({
  children,
  className = 'p-4 flex flex-col gap-2',
  overflow = false,
  containerRef
}) => {
  return (
    <div
      className={clsx(
        'w-screen h-full sm:w-desktop-width pb-footer-offset top-0 fixed sm:left-1/2 sm:-translate-x-1/2 bg-center bg-cover bg-no-repeat',
        !overflow && 'pt-standard-header-offset'
      )}
      style={{ backgroundImage: `url('${BGCommon}')` }}
    >
      <div
        className={clsx('w-full', className, overflow && 'overflow-y-scroll hidden-scroll')}
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  )
}

export default StandardPageWrapper

StandardPageWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string,
  overflow: PropTypes.bool,
  containerRef: PropTypes.any
}
