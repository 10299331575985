import { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'
import PresentItem from 'src/components/menu/PresentItem'
import { Tab } from '@headlessui/react'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import Present from 'src/models/Present'
import PresentAcceptModal from 'src/components/menu/PresentAcceptModal'
import styled from 'styled-components'
import Pagination from 'src/components/common/Pagination'

import TabItemSmallOff from 'src/assets/img/menu/tab_itemsmall_off.png'
import TabItemSmallOn from 'src/assets/img/menu/tab_itemsmall_on.png'
import TabDressUpOff from 'src/assets/img/menu/tab_dressup_off.png'
import TabDressUpOn from 'src/assets/img/menu/tab_dressup_on.png'
import TabAllOff from 'src/assets/img/menu/tab_all_off.png'
import TabAllOn from 'src/assets/img/menu/tab_all_on.png'
import BtnUketori from 'src/assets/img/menu/btn_uketori.png'
import TipsModal from 'src/components/common/TipsModal'
import BGPage from 'src/assets/img/register/bg-common.png'

const PresentPage = ({
  presents,
  isLoading,
  handleReceiveAll,
  handleReceiveOne,
  step,
  setStep,
  isLevelUp,
  totalPage = 0,
  currPage,
  setCurrPage,
  activeTab,
  setActiveTab,
  listRef
}) => {
  const [showModal, setShowModal] = useState(false)
  const [modalPresent, setModalPresent] = useState(null)

  const getTabType = useMemo(() => {
    switch (activeTab) {
      case 1:
        return 'item'
      case 2:
        return 'character_cloth'
      default:
        return 'all'
    }
  }, [activeTab])

  const onClickReceiveAll = useCallback(() => {
    handleReceiveAll(getTabType)
    setShowModal(true)
  }, [setShowModal, getTabType, handleReceiveAll])

  const isNormalItem = useCallback(
    (type) => {
      if (!type) return
      return (
        type === 'character_still' ||
        type === 'setting_item' ||
        type === 'character_cloth' ||
        type === 'character_voice'
      )
    },
    [presents]
  )

  return (
    <Wrapper>
      <Header>
        <MenuBar title="present" />
      </Header>
      <MainLayout
        active="menu"
        showHeader={false}
        showFooter={false}
        className="h-fit mana-wrapper"
      >
        <div className="flex flex-col items-center gap-2 w-full px-4">
          <Tab.Group selectedIndex={activeTab} onChange={setActiveTab}>
            <Tab.List className="flex justify-between w-full">
              <Tab>
                {({ selected }) => (
                  <img className="w-28 mr-1" src={selected ? TabAllOn : TabAllOff} alt="すべて" />
                )}
              </Tab>
              <Tab>
                {({ selected }) => (
                  <img
                    className="w-28 mr-1"
                    src={selected ? TabItemSmallOn : TabItemSmallOff}
                    alt="アイテム"
                  />
                )}
              </Tab>
              <Tab>
                {({ selected }) => (
                  <img
                    className="w-28"
                    src={selected ? TabDressUpOn : TabDressUpOff}
                    alt="着せ替え"
                  />
                )}
              </Tab>
            </Tab.List>

            {isLoading ? (
              <Loading />
            ) : !isLoading && presents.length === 0 ? (
              <div className="w-full h-full flex items-center">
                <NoContent message={'受け取り可能な\nプレゼントがありません'} />
              </div>
            ) : (
              <>
                <button type="button" className="my-4 w-52" onClick={onClickReceiveAll}>
                  <img src={BtnUketori} alt="ページ一括受け取り" />
                </button>
                <ul>
                  <Content>
                    <ul className="h-full hidden-scroll overflow-y-auto">
                      <div ref={listRef} />
                      {presents.map(
                        (present) =>
                          new Date(present.expiredAt) > new Date() && (
                            <PresentItem
                              key={present.id}
                              present={present}
                              handleReceive={() => {
                                handleReceiveOne(present.id, present.expiredAt)
                                setModalPresent(present)
                                setShowModal(true)
                              }}
                              isNormalItem={isNormalItem}
                            />
                          )
                      )}
                    </ul>
                    <Pagination
                      totalPage={totalPage}
                      currPage={currPage}
                      setCurrPage={setCurrPage}
                    />
                  </Content>
                </ul>
              </>
            )}
          </Tab.Group>
        </div>
        {step === 2 && (
          <PresentAcceptModal
            show={showModal}
            onClose={() => {
              setShowModal(false)
              setModalPresent(null)
              setStep(0)
              isLevelUp && window.location.reload()
            }}
            present={modalPresent}
            isNormalItem={isNormalItem}
          />
        )}
        <TipsModal type="present" />
      </MainLayout>
    </Wrapper>
  )
}

PresentPage.propTypes = {
  presents: PropTypes.arrayOf(PropTypes.instanceOf(Present)),
  isLoading: PropTypes.bool.isRequired,
  handleReceiveAll: PropTypes.func.isRequired,
  handleReceiveOne: PropTypes.func.isRequired,
  step: PropTypes.number,
  setStep: PropTypes.func,
  isLevelUp: PropTypes.bool,
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  activeTab: PropTypes.number,
  setActiveTab: PropTypes.func,
  listRef: PropTypes.any
}

export default PresentPage

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`

const Content = styled.div.attrs({
  className: 'w-full mx-auto relative'
})`
  height: calc(100vh - 300px);
`
