import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { addRank } from 'src/lib/rank'
import { getRankingDetail, getRankings } from 'src/services/ranking'

import BGTrainingTitle from 'src/assets/img/ranking/detail/detail-top-training-parameter-bg.png'
import BGLoveTitle from 'src/assets/img/ranking/detail/detail-top-love-parameter-bg.png'
import BGLovePage from 'src/assets/img/ranking/detail/detail-love-parameter-bg.png'
import BGTrainingPage from 'src/assets/img/ranking/detail/detail-training-parameter-bg.png'
import BGLoveHeader from 'src/assets/img/ranking/detail/detail-my-love-parameter-bg.png'
import BGTrainingHeader from 'src/assets/img/ranking/detail/detail-my-training-parameter-bg.png'
import { Tabs } from 'src/constants/common'

export const useFetchRankingDetailData = () => {
  const { type } = useParams()

  const [rankingDetail, setRankingDetail] = useState({})
  const [openDetail, setOpenDetail] = useState(false)
  const [activeIndex, setActiveIndex] = useState(1)
  const [activeTab, setActiveTab] = useState('current_day')
  const [ranking, setRanking] = useState({})

  const openDetailModal = useCallback(
    async (id) => {
      if (openDetail) return setOpenDetail(false)
      if (!id) return
      const response = await getRankingDetail(id)
      if (response) setRankingDetail({ ...response, image: 'character.png' })
      setOpenDetail(true)
    },
    [openDetail, setRankingDetail]
  )

  const theme = useMemo(() => {
    const period = Object.values(Tabs).find((tab) => tab.key === activeTab)
    switch (type) {
      case 'grow':
        return {
          title: '育て度 - 詳細',
          rankingHeader: (
            <div className="text-white text-xs text-left ml-1.5">
              私の{period.name}の育て度ランキング <strong>{ranking?.userRank || '--'}</strong>位
            </div>
          ),
          bgTitle: BGTrainingTitle,
          bgHeader: BGTrainingHeader,
          bgPage: BGTrainingPage
        }
      case 'love':
        return {
          title: 'LOVE度 - 詳細',
          rankingHeader: (
            <div className="text-white text-xs text-left ml-1.5">
              私の{period.name}のLOVE度ランキング <strong>{ranking?.userRank || '--'}</strong>位
            </div>
          ),
          bgTitle: BGLoveTitle,
          bgHeader: BGLoveHeader,
          bgPage: BGLovePage
        }
    }
  }, [type, activeTab, activeIndex, ranking])

  const fetchData = useCallback(async () => {
    const response = await getRankings(type, activeTab, 0, activeIndex)
    if (response && response[activeTab]) {
      const addRankingResponse = addRank(response[activeTab])
      const items = addRankingResponse.map((item, idx) => {
        const _idx = idx + 1
        return {
          ...item,
          places: _idx,
          background: `no${_idx <= 5 ? _idx : 5}.png`,
          character: `ranking_character_${activeIndex}.png`,
          backgroundPlaces: _idx <= 5 ? `character1-no${item.rank}.png` : 'character1-other.png'
        }
      })
      setRanking({ ...ranking, [activeTab]: items, userRank: response.userRank })
    }
  }, [activeTab, activeIndex])

  useEffect(() => {
    fetchData()
  }, [activeTab, activeIndex])

  const onChangeTab = useCallback(
    (index) => {
      setActiveTab(Tabs[index].key)
    },
    [activeTab]
  )

  return {
    theme,
    activeIndex,
    setActiveIndex,
    onChangeTab,
    Tabs,
    ranking,
    openDetailModal,
    openDetail,
    activeTab,
    rankingDetail
  }
}
