import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { useAuthState } from 'src/providers/AuthProviders'
import NewsPage from 'src/pages/myPage/News/News'
import getNewsDetail from 'src/services/get/getNewDetail'

const NewsPageContainer = () => {
  const [news, setNews] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const { id } = useParams()
  const { user } = useAuthState()
  // TODO: API integration

  useEffect(() => {
    if (!user) return
    const req = new CancelableAPIRequest(getNewsDetail, user.id, id)
    req.invoke((res) => {
      const findNews = res.find((news) => news.id === Number(id))
      setNews(findNews)
      setIsLoading(false)
    })

    return () => {
      req.cancel()
    }
  }, [user])

  return <NewsPage news={news} isLoading={isLoading} />
}

export default NewsPageContainer
