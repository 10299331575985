import React, { useState } from 'react'
import iconHelp from '../../assets/btn_help.png'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import mascotChara from '../../assets/mascot_chara.png'
import btnClose from '../../assets/btn_close.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const ExtraTimeHelper = ({ isExtraTime, normalPassportModal, freeMinutes }) => {
  const [infoModal, setInfosModal] = useState(false)
  const title = isExtraTime ? 'Extraタイム突入' : '残り時間・Extraタイム'
  const firstPara = isExtraTime ? 'Extraタイム突入♪最初の3分間のみ、\n' +
                                   '無料で視聴できるコケッ。\n' +
                                   '3分経過後は、時間延長の砂を使って視聴時間を\n' +
                                   '延長したユーザー様のみが、視聴可能だョ！'
    : '『時間延長の砂』を彼に送ることで、\n' +
      '配信時間を延長してあげる事が出来るコケッ！\n' +
      '誰かが『時間延長の砂』を使えば、\n' +
      '延長時間は全視聴者に平等に与えられるョ！'
  const secondPara = isExtraTime ? '・Extraタイム中は、時間延長の砂を使ったユーザー様\n' +
                                   'だけ継続視聴が可能(ノーマルタイムとの違い)\n' +
                                   '・残り視聴時間が0になると、強制的に視聴が終了'
    : '但し、Extraタイムがスタートしてからは、\n' +
      '『時間延長の砂』を使用した視聴者様にのみ、\n' +
      '時間延長が与えられるコケッ！'
  const thirdPara = isExtraTime ? '※残り視聴時間が0になった瞬間からLIVE配信は\n' +
                                  '見れなくなってしまうので、残り視聴時間が0に\n' +
                                  'なる前に時間延長の砂を使う必要があるコケ～ッ！'
    : '残りの配信時間が０秒になってからは\n' +
      '再入場出来ないから、赤ダイヤの補充は\n' +
      'お早めにだコケェッ！'

  return (
    <>
      <img src={iconHelp} id="icon-help" className="inline" onClick={() => setInfosModal(true)} />

      <ModalWithBg
        title={
          <span className="text-[15px]">{normalPassportModal ? title : '残り時間について'}</span>
        }
        open={infoModal}
        onClose={() => setInfosModal(false)}
      >
        <div className={clsx('space-y-4', isExtraTime ? 'text-[10.5px]' : 'text-[12px]')}>
          {normalPassportModal ? (
            <>
              <p className={clsx('whitespace-pre-line', isExtraTime ? '' : 'text-center')}>
                {firstPara}
              </p>
              <p
                className={clsx(
                  'whitespace-pre-line',
                  isExtraTime ? 'text-[#ff0000]' : 'text-center'
                )}
              >
                {secondPara}
              </p>
              <p
                className={clsx(
                  'whitespace-pre-line',
                  isExtraTime ? 'text-[#ff0000]' : 'text-center'
                )}
              >
                {thirdPara}
              </p>
            </>
          ) : (
            <>
              <p className={clsx('whitespace-pre-line', 'text-center', 'text-[11px]')}>
                右上に表示されている｢配信時間残り｣は、プラチナパスポート所有者専用の視聴可能時間だコケッ！
              </p>
              <p className={clsx('whitespace-pre-line', 'text-center', 'text-[11px]')}>
                ｢配信時間残り｣に表示されている時間に関わらず、
                <br/>
                滞在時間が<span className='text-[#FF1D34] text-[11px]'>{freeMinutes}分</span>を過ぎると強制退出となるコケッ！
              </p>
            </>
          )}

          <p className="text-right">
            <img className="inline w-[80px] h-[80px]" src={mascotChara} />
          </p>

          <div className="flex items-center justify-center">
            <img
              src={btnClose}
              className="inline w-[119px] h-[49px]"
              onClick={() => setInfosModal(false)}
            />
          </div>
        </div>
      </ModalWithBg>
    </>
  )
}

ExtraTimeHelper.propTypes = {
  isExtraTime: PropTypes.bool,
  normalPassportModal: PropTypes.bool,
  freeMinutes: PropTypes.number
}

export { ExtraTimeHelper }
