const { useEffect, useState } = require('react')
const { useParams } = require('react-router-dom')
const { default: CancelableAPIRequest } = require('src/models/CancelableAPIRequest')
const { useAuthState } = require('src/providers/AuthProviders')
const { default: getUserInquiries } = require('src/services/get/getUserInquiries')

const useInquiriesHasNotifications = () => {
  const [hasNotifications, setHasNotifications] = useState(false)
  const { id } = useParams()
  const { user } = useAuthState()

  useEffect(() => {
    const req = new CancelableAPIRequest(getUserInquiries, user?.id)
    req.invoke((resp) => {
      let i = 0
      for (let index = 0; index < resp.length; index++) {
        if (!resp[index].isReaded && resp[index].adminResponse && resp[index].id !== Number(id)) {
          i++
        }
      }
      if (i > 0) {
        setHasNotifications(true)
      } else {
        setHasNotifications(false)
      }
    })

    return () => {
      req.cancel()
    }
  }, [])

  return {
    hasNotifications
  }
}

export default useInquiriesHasNotifications
