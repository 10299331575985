import { useContext } from 'react'
import styled from 'styled-components'
// import MenuWrapper from 'src/components/menu/MenuWrapper'
import PropTypes from 'prop-types'
import { useParams, useNavigate } from 'react-router-dom'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { StillAlbumContext } from 'src/contexts'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'

import BGStillFolder from 'src/assets/img/stillalbum/still_folder.png'
import NavigatorSorry from 'src/assets/img/character/mascot/sorry.png'
import BGPage from 'src/assets/img/stillalbum/bg_common.png'

const characterNames = ['朝日空', '風早翔', '立花駿', '若槻蓮', '横浜湊']
const StillFolder = ({ item = {} }) => {
  const { paramater, image } = item
  const { characterId, name } = useParams()
  const navigate = useNavigate()
  return (
    <div>
      <StillFolderWrapper
        onClick={() => {
          navigate(
            MYPAGE_PATHS.RecommendStillAlbumDetailPage.replace(':characterId', characterId)
              .replace(':id', item.id)
              .replace(':name', name || 'noname')
          )
        }}
      >
        {image?.url ? (
          <img
            className="w-full h-full object-cover object-center"
            src={image?.url}
            alt="Still Folder"
          />
        ) : null}
      </StillFolderWrapper>
      <div className="h-[23px] w-full">
        <img
          className="w-full h-full"
          src={require(`src/assets/img/stillalbum/paramater_${
            paramater >= 9 ? 'max' : paramater
          }.png`)}
          alt=""
        />
      </div>
    </div>
  )
}

StillFolder.propTypes = {
  item: PropTypes.shape({
    paramater: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string
    })
  })
}

const StillFolderWrapper = styled.div.attrs({
  className: 'h-[119px] w-full'
})`
  background-image: url(${BGStillFolder});
  background-size: 100% 100%;
`

export const RecommendStillAlbumPage = () => {
  const { stillAlbums, isLoading } = useContext(StillAlbumContext)

  const { name } = useParams()
  const nameTitle = characterNames.includes(name) ? name : 'noname'

  return (
    <Wrapper>
      <Header>
        <MenuBar title={`still-album-${nameTitle}`} />
      </Header>
      <MainLayout className="h-fit" active="mypage" showHeader={false}>
        <div className="pl-5 pr-[18px] py-5 mb-[120px] h-fit">
          {isLoading ? (
            <Loading />
          ) : stillAlbums.length === 0 ? (
            <NoContent>
              <div className="w-[115px] h-[115px] mx-auto mb-5">
                <img className="w-full h-full" src={NavigatorSorry} alt="No content" />
              </div>
              <p className="font-vldLineGR text-[#333333] text-xl px-11 leading-[30px]">
                表示できるアルバムがありません <br />
                イベントが開催されると自動で <br />
                フォルダが作成されます
              </p>
            </NoContent>
          ) : (
            <div className="grid grid-cols-3 gap-2">
              {stillAlbums.map((el, i) => (
                <StillFolder item={el} key={i} />
              ))}
            </div>
          )}
        </div>
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto relative bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`

const NoContent = styled.div.attrs({
  className: 'absolute w-full pb-20'
})`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`
