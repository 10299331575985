import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

import BtnPurchase from 'src/assets/img/shop/btn_fee_bk.png'

const PurchaseButton = ({ cost, onClick, disabled }) => {
  return (
    <Button
      disabled={disabled}
      bgImage={BtnPurchase}
      isDisabled={disabled}
      onClick={onClick}
    >
      <span className="drop-shadow-md">¥{cost}</span>
    </Button>
  )
}

PurchaseButton.propTypes = {
  cost: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired
}

export default PurchaseButton

const Button = styled.button.attrs({
  className: 'w-[136px] font-hiragino-mincho text-white drop-shadow text-xl flex items-center justify-center rounded-2xl text-white'
})`
  background-image: url(${(props) => props.isDisabled ? '' : props.bgImage});
  background-size: 100% 100%;
  background-color: ${(props) => props.isDisabled ? '#CCCCCC' : ''};
`
