import { NotificationArea } from 'src/components/myPage/NotificationArea'
import styled from 'styled-components'
import { useState, useCallback, useMemo, useEffect } from 'react'
import SearchButton from 'src/assets/img/mypage/search_button.png'
import ProgressSlideBar from 'src/components/common/ProgreeSlideBar'
import RightSideBanner from 'src/components/myPage/RightSideBanner'
import BackGroundEffect from 'src/components/myPage/BackGroundEffect'
import CoverEffect from 'src/components/myPage/CoverEffect'
import TipsModal from 'src/components/common/TipsModal'
import { useAuthState } from 'src/providers/AuthProviders'
import { useQuery } from 'react-query'
import getNews from 'src/services/get/getNews'
import getEvents from 'src/services/get/getEvents'
import Loading from 'src/components/layouts/Loading'
import { beforeThePresentTime } from 'src/lib/date'
import { MainLayout } from 'src/components/layouts/MainLayout'
import { MYPAGE_CHARACTER_IMAGES } from 'src/constants/characters'
import { useGetRecommendsData } from 'src/services/hooks/useFetchRecommends'
import getUserPresents from 'src/services/get/getUserPresents'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'

const MyPageContainer = () => {
  const user = useAuthState()
  const news = useQuery('news', () => getNews(user.id, 'all'))
  const events = useQuery('events', () => getEvents())
  const recommends = useGetRecommendsData()
  const [isDisplayCharacter, setIsDisplayCharacter] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [presents, setPresents] = useState([])

  localStorage.removeItem('idTokenRegister')
  localStorage.removeItem('accessTokenRegister')

  useEffect(() => {
    const req = new CancelableAPIRequest(getUserPresents, user?.user.id)
    req.invoke((resp) => {
      setPresents(resp)
    })

    return () => req.cancel()
  }, [])

  const findRecommendMan = useMemo(
    () => recommends.data?.find((man) => man.recommend),
    [recommends.data]
  )

  const mypageImage = useMemo(() => {
    if (!findRecommendMan) return
    return MYPAGE_CHARACTER_IMAGES[findRecommendMan.id]
  }, [findRecommendMan])

  const handleDisplayAll = useCallback(() => {
    if (isDisplayCharacter) setIsDisplayCharacter(false)
  }, [isDisplayCharacter])

  const filterEvents = useMemo(() => {
    return events.data?.filter(
      (event) => beforeThePresentTime(event.endTime) && event.displayOnTheMyPage
    )
  }, [events.data])

  if (news.isLoading || events.isLoading || recommends.isLoading) {
    return (
      <Wrapper>
        <Loading />
      </Wrapper>
    )
  }

  if (news.data && events.data && recommends.data && mypageImage) {
    return (
      <MainLayout
        className="bg-no-repeat bg-[length:100%_100%] bg-top overflow-hidden"
        classNameHeader="px-0 py-8 xs:px-4"
        style={{ backgroundImage: `url('${mypageImage.room}')` }}
        showHeader={!isDisplayCharacter}
        showFooter={!isDisplayCharacter}
      >
        <div onClick={() => handleDisplayAll()}>
          <Wrapper>
            {!isDisplayCharacter && <CoverEffect />}
            <CharacterImage>
              <img className="w-[327px] h-[860px]" src={mypageImage.image} alt="キャラクター画像" />
            </CharacterImage>
            {!isDisplayCharacter && <BackGroundEffect />}
            {!isDisplayCharacter && (
              <>
                <SearchButtonArea onClick={() => setIsDisplayCharacter(true)}>
                  <img src={SearchButton} alt="searchButton" />
                </SearchButtonArea>
                <NotificationArea presents={presents} />
                <RightSideBanner events={filterEvents} />
                <ProgressSlideBar
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  recommendedMan={findRecommendMan}
                />
              </>
            )}
          </Wrapper>
        </div>
        <TipsModal type="mypage" />
      </MainLayout>
    )
  }
}

export default MyPageContainer

const Wrapper = styled.div.attrs({
  className: 'relative px-2 py-4 pt-[106px] flex flex-col items-end'
})`
  height: calc(100vh - 100px);
`

const SearchButtonArea = styled.div.attrs({
  className: 'absolute cursor-pointer z-30'
})`
  width: 40px;
  height: 50px;
  top: 100px;
  left: 10px;
  transform: skew(0, 10deg);
`

const CharacterImage = styled.div.attrs({
  className: 'absolute right-[10%] -bottom-[15%] h-full w-full z-30'
})`
  @media (max-width: 350px) {
    left: -15%;
  }
`
