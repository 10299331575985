import Item from 'src/models/Item'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getDrawGacha = async (userId, gachaId, drawType, currencyType) => {
  if (!userId || !gachaId || !drawType || !currencyType) return false

  try {
    const res = await axiosInstance.get(`${API_ROUTES.Gachas}/${gachaId}/roll_gacha`, {
      params: { user_id: userId, draw_type: drawType, currency_type: currencyType }
    })
    if (res.data.success === false) return false
    else {
      const items = []
      for (const i of res.data.items) {
        const item = new Item(
          i.id,
          i.name,
          i.image,
          i.description,
          i.expired_at,
          i.item_kind,
          i.item_value,
          i.rarity,
          i.love_amount,
          i.max_amount,
          i.created_at,
          i.updated_at
        )
        items.push(item)
      }

      return items
    }
  } catch (error) {
    return false
  }
}

export default getDrawGacha
