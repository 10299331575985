import { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { raritiesHorizontal } from 'src/constants/items'
import ButtonCreate from 'src/components/dressup/ButtonCreate'
import moment from 'moment'
import { getDateWaiting } from 'src/lib/date'

import NotCreated from 'src/assets/img/dressup/not_created.png'
import RemoveTheFitting from 'src/assets/img/dressup/remove_the_fitting.png'
import BtnDetail from 'src/assets/img/dressup/detail.png'
import BGLevel from 'src/assets/img/dressup/level.png'
import BGRaisingDegree from 'src/assets/img/dressup/bg_raising_degree.png'
import BtnCreate from 'src/assets/img/dressup/btn_create.png'
import Line from 'src/assets/img/dressup/line.png'
import IconInfo from 'src/assets/img/dressup/icon_info.png'
import BGMaking from 'src/assets/img/dressup/making.png'

const DateProcess = ({ dateWaiting }) => {
  return (
    <div className="border border-[#FCA5D6] rounded-[3.5px] w-[96px] relative">
      <div className="text-[5px] text-[#FCA5D6] font-medium text-left pl-1 pt-[3px] pb-[2px]">
        {`${dateWaiting?.days}日${dateWaiting?.hours}時間${dateWaiting?.minutes}分`}
      </div>
      <div
        className="rounded-sm text-[5px] text-white font-medium text-left pl-1 pt-[3px] pb-[2px] whitespace-nowrap overflow-hidden absolute top-0 left-0"
        style={{
          background: 'linear-gradient(90deg, #FF73C2 0%, #FCA5D6 100%)',
          width: `${dateWaiting?.percent}%`
        }}
      >
        {`${dateWaiting?.days}日${dateWaiting?.hours}時間${dateWaiting?.minutes}分`}
      </div>
    </div>
  )
}

DateProcess.propTypes = {
  dateWaiting: PropTypes.object
}

const Item = ({ onClick, item = {}, openDetail }) => {
  const [dateWaiting, setDateWaiting] = useState({})
  useEffect(() => {
    const _dateWaiting = getDateWaiting(item)
    setDateWaiting(_dateWaiting)
  }, [item])

  const formatDate = (date) => {
    const _date = moment(date)
    return `${_date.format('YYYY年MM月DD日')}`
  }

  const { rarity } = item
  const rarityImg = raritiesHorizontal[rarity] || raritiesHorizontal.r
  const handleClick = () => {
    onClick && onClick(item)
  }
  const handleOpenDetail = () => {
    openDetail && openDetail(item)
  }
  return (
    <ItemWrapper className={`${rarity === 'ssr' ? 'ml-1' : ''}`} background={rarityImg}>
      <div className="pr-1">
        <ThumbnailWrapper background={item?.cloth_image?.url} />
      </div>
      <div>
        <div className="flex items-center gap-2">
          <ButtonWrapper className="pl-1.5 pr-2" background={BGLevel}>
            LV.{item?.level < 10 ? '0' + item?.level : item?.level}
          </ButtonWrapper>
          <ButtonWrapper className="px-2" background={BGRaisingDegree}>
            育て度＋500
          </ButtonWrapper>
        </div>
        <div className="w-[80%]">
          <div className="text-left text-[10px] text-[#333333] leading-[15px] font-hiragino font-medium mt-1">
            {item?.name}
          </div>
          <div
            className="h-[1px] w-full mb-[2px]"
            style={{
              backgroundImage: `url(${Line})`
            }}
          />
          <p className="font-hiragino font-light text-[8px] text-[#8E8E8E] text-justify leading-[9px] mb-1 min-h-[20px]">
            {item?.explanatory_text}
          </p>
        </div>
        <div className="flex items-center gap-2">
          <ButtonWrapper
            className="pl-1.5 pr-2 h-[25px] w-[94px]"
            background={item?.not_created ? RemoveTheFitting : BtnCreate}
          />
          <ButtonCreate
            onClick={handleClick}
            notCreated={item?.not_created}
            diamond={item?.red_diamond}
            className={`w-[92px] h-[24px] ${item?.not_created ? 'pl-[2px]' : 'pl-4'}`}
          />
        </div>
      </div>
      <div className="relative">
        <div onClick={handleOpenDetail} className="absolute bottom-0 right-0 h-[25px] w-[56px]">
          <img className="w-full h-full" src={BtnDetail} alt="detail" />
        </div>
      </div>
      {item?.not_created ? (
        <div className="absolute top-3 -right-2 w-fit pt-2 text-right">
          <img src={NotCreated} alt="not_created" className="w-[112px] h-[18px]" />
        </div>
      ) : Math.round(dateWaiting?.percent) < 100 ? (
        <div className="absolute top-2 -right-2 w-fit pt-2 text-right">
          <div className="flex justify-end items-center pr-5">
            <img className="w-[5px] h-[5px] mr-[2px]" src={IconInfo} />
            <span className="text-[5px] font-light">
              {/* 2022年12月31日まで */} {formatDate(dateWaiting?.end)}
            </span>
          </div>
          <div
            style={{
              backgroundImage: `url(${BGMaking})`,
              backgroundSize: '100% 100%'
            }}
            className="flex items-center pl-10 pr-[42px] text-left text-white text-[10px] font-semibold mt-[2px] mb-1 w-[112px] h-[18px]"
          >
            LV.{item?.nurturing_level < 10 ? '0' + item?.nurturing_level : item?.nurturing_level}
          </div>
          <DateProcess dateWaiting={dateWaiting} />
        </div>
      ) : null}
    </ItemWrapper>
  )
}

Item.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
  openDetail: PropTypes.func
}

const ItemWrapper = styled.div.attrs({
  className:
    'h-fit w-full relative pt-[20px] pb-3 pl-3.5 pr-3 grid grid-cols-[auto_200px_auto] font-hiragino'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const ThumbnailWrapper = styled.div.attrs({
  className: 'h-full w-full relative py-[18px] px-2.5'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const ButtonWrapper = styled.div.attrs({
  className: 'w-fit text-[#fff] text-[8px] font-Inter font-light font-hiragino leading-[12px]'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const ListDressup = ({ items = [], onClick, openDetail }) => {
  return (
    <div className="py-[5px] pr-3 pl-2.5">
      {items.map((item, index) => {
        return (
          <Item key={index} item={item} onClick={(item) => onClick(item)} openDetail={openDetail} />
        )
      })}
    </div>
  )
}

export default ListDressup

ListDressup.propTypes = {
  items: PropTypes.array,
  gap: PropTypes.string,
  col: PropTypes.number,
  onClick: PropTypes.func,
  openDetail: PropTypes.func
}
