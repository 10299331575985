import React from 'react'
import PropTypes from 'prop-types'

export const SlideButton = ({ visible, className, classNameVisible, onClick, ...props }) => {
  let classNames = 'btn-slide ease-in-out duration-500 inline-block ' + className
  if (visible) {
    classNames += ' opacity-1 mr-0 ' + classNameVisible
  } else {
    classNames += ' opacity-0 -mr-[180px]'
  }

  return (
    <div className={classNames} onClick={() => onClick && onClick()}>
      {props.children}
    </div>
  )
}

SlideButton.propTypes = {
  visible: PropTypes.bool,
  className: PropTypes.string,
  classNameVisible: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node
}
