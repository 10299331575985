import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getRankingEventDetails = async (id) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Ranking.EventDetails.replace(':id', id))
    const hasError = res?.data?.status === 404
    if (hasError) return {}

    return res?.data
  } catch (error) {
    return {}
  }
}

export default getRankingEventDetails
