import { useNavigate } from 'react-router-dom'
import MenuEvent from 'src/assets/img/mypage/side-menu/menu-event.png'
import { MYPAGE_PATHS } from 'src/routes/myPage'

const EventBanner = () => {
  const navigate = useNavigate()

  return (
    <div
      onClick={() => navigate(MYPAGE_PATHS.EventListPage)}
      className="absolute left-0 top-0 w-[5.9rem]"
    >
      <img
        src={MenuEvent}
        alt=""
        className="xs:w-[100%] w-[85%] drop-shadow-[0_35px_35px_rgba(0,0,0,0.25)]"
      />
    </div>
  )
}

export default EventBanner
