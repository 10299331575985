export const renderButtonAmazonPay = (user, selectedItem, method) => {
  const script = document.createElement('script')
  script.src = 'https://static-fe.payments-amazon.com/checkout.js'
  script.async = true
  localStorage.setItem(
    'item-pay',
    JSON.stringify({
      user_id: user?.id || 1,
      product_id: selectedItem?.id,
      payment_amount: selectedItem?.listed_price,
      payment_method: method
    })
  )

  script.onload = () => {
    // eslint-disable-next-line no-undef
    amazon.Pay.renderButton('#btn-amazon', {
      merchantId: process.env.REACT_APP_MERCHANT_ID,
      publicKeyId: process.env.REACT_APP_PUBLICKEY_ID,
      ledgerCurrency: 'JPY',
      checkoutLanguage: 'ja_JP',
      productType: 'PayOnly',
      placement: 'Checkout',
      buttonColor: 'Gold',
      sandbox: process.env.REACT_APP_SANDBOX === 'true',
      createCheckoutSessionConfig: {
        payloadJSON: JSON.stringify({
          webCheckoutDetails: {
            checkoutReviewReturnUrl: location.origin + '/loading-payment',
            checkoutCancelUrl: location.origin + '/shop'
          },
          storeId: process.env.REACT_APP_STORE_ID
        }),
        signature: process.env.REACT_APP_SIGNATURE,
        algorithm: 'AMZN-PAY-RSASSA-PSS-V2'
      }
    })
  }

  document.body.appendChild(script)
}
