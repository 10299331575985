// @ts-nocheck
import PlayVoice from './PlayVoice'
import React, { useRef } from 'react'

/* eslint-disable react/prop-types */
const StoryWithVoice = ({ story }) => {
  const audio = useRef()

  return (
    <div key={story.id} className="mb-5">
      {story.character_voice_url && (
        <label
          onClick={(e) => {
            audio.current?.play?.()
          }}
        >
          <PlayVoice />
          <audio ref={audio} className="preview-CharacterVoice" src={story.character_voice_url} />
        </label>
      )}
      {(story.description || '').split('\n').map((p, index) => (
        <p className="mb-1" key={index}>
          {p}
        </p>
      ))}
    </div>
  )
}

export default StoryWithVoice
