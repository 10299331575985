import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const postLiveTime = async (payload) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.Live.LiveTime, payload)
    const data = res.data
    return {
      item: data.item,
      flag: data.flag,
      currentLevel: data.current_level,
      nextLevel: data.next_level
    }
  } catch (error) {
    console.error(error)
    return { item: false, level: false }
  }
}
