import styled from 'styled-components'
import BackGround from 'src/assets/img/mini_game/background.png'
import { Header } from 'src/components/message/chat/Header'
import ArrowTarget from 'src/assets/img/mini_game/target_arrow.png'
import ArrowTargetStock from 'src/assets/img/mini_game/target_arrow_stock.png'
import ScoreBoard from 'src/assets/img/mini_game/score_board.png'
import { Modal } from 'src/components/common/Modal'
import HeartGet from 'src/assets/img/mini_game/title/title_geart_get.png'
import GameOver from 'src/assets/img/mini_game/title/title_gameover.png'
import GameOver2 from 'src/assets/img/mini_game/title/title_gameover2.png'
import Close from 'src/assets/img/mini_game/btn/btn_close.png'
import BtnBackTalk from 'src/assets/img/mini_game/btn/btn_talk_back.png'
import { useMiniGamePlay } from 'src/pages/MiniGame/hooks/useMiniGamePlay'

const MiniGamePlay = () => {
  const {
    containerRef,
    targetRef,
    targetCanvasRef,
    arrowRef,
    speed,
    characterId,
    stage,
    hitArrows,
    score,
    arrows,
    arrowStockCount,
    modalOpen,
    step,
    rotation,
    navigateToPage
  } = useMiniGamePlay()

  const currentURL = localStorage.getItem('partName')

  return (
    <Wrapper>
      <Container ref={containerRef}>
        <Header />
        <TargetContainer ref={targetRef} rotation={rotation} speed={speed}>
          <img
            src={require(`../../assets/img/mini_game/target/character${characterId}/target_lv_${stage}.png`)}
            ref={targetCanvasRef}
            className="block w-52 h-52  xs:w-56 xs:h-56 mx-auto object-contain"
          />
        </TargetContainer>
        <Score>
          <ScoreContent>
            <img src={ScoreBoard} alt="score-board" className="w-36" />
            <p className="absolute top-[41%] left-1">ステージ</p>
            <p className="absolute top-[61%] left-1">刺さった弓矢</p>
            <p className="absolute top-[81%] left-1">スコア</p>
            <p className="absolute top-[41%] right-2">{stage}</p>
            <p className="absolute top-[61%] right-2">{hitArrows}本</p>
            <p className="absolute top-[81%] right-2">{score}点</p>
          </ScoreContent>
        </Score>
        {arrows.map((arrow) => {
          return (
            <Arrow key={arrow.id} ref={arrow.id === arrows.length - 1 ? arrowRef : null}>
              <img src={ArrowTarget} alt="arrow" className="w-5 xs:w-6" />
            </Arrow>
          )
        })}
        <ArrowStock>
          {[...Array(Math.max(arrowStockCount - 1, 0))].map((_, index) => (
            <img key={index} src={ArrowTargetStock} alt="arrow-target-stock" className="w-16" />
          ))}
        </ArrowStock>
        <Modal
          open={modalOpen}
          onClose={() => null}
          className="md:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg sm:w-full font-hiragino flex items-center justify-center"
        >
          <Content>
            {step === 1 ? (
              <>
                <img src={HeartGet} alt="heart-get" />
                <img
                  src={Close}
                  onClick={() => navigateToPage(-1)}
                  alt="close-btn"
                  className="w-32 mt-2 cursor-pointer"
                />
              </>
            ) : step === 2 ? (
              <>
                <img src={GameOver} alt="game-over" className="cursor-pointer" />
                <img
                  src={Close}
                  onClick={() => navigateToPage(-1)}
                  alt="close-btn"
                  className="w-32 mt-2 cursor-pointer"
                />
              </>
            ) : (
              <>
                <img src={GameOver2} alt="game-over" />
                <img
                  src={BtnBackTalk}
                  onClick={() => currentURL.includes('realtalk') ? navigateToPage(`/realtalk/detail/${characterId}`) : navigateToPage(`/message/detail/${characterId}`)}
                  alt="close-btn"
                  className="w-32 mt-2 cursor-pointer"
                />
              </>
            )}
          </Content>
        </Modal>
      </Container>
    </Wrapper>
  )
}

export default MiniGamePlay

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto bg-no-repeat bg-center bg-cover text-[#333333] relative overflow-hidden'
})`
  background-image: url(${BackGround});
`

const Container = styled.div.attrs({
  className: 'w-full max-w-[400px]'
})``

const TargetContainer = styled.div.attrs({
  className: 'relative mt-10 w-56 mx-auto z-20'
})`
  img {
    animation: ${(props) => props.rotation} ${(props) => props.speed}s infinite linear;
  }
  .stopped {
    animation-play-state: paused;
  }
`

const Score = styled.div.attrs({
  className: 'absolute bottom-10 left-2'
})``

const Arrow = styled.div.attrs({
  className: 'absolute bottom-[12%] left-[47%] z-10'
})`
  .hit {
    animation-name: none;
    pointer-events: none;
  }
`

const ArrowStock = styled.div.attrs({
  className: 'absolute right-3 bottom-8'
})``

const ScoreContent = styled.div.attrs({
  className: 'relative text-xs font-light'
})``

const Content = styled.div.attrs({
  className: 'flex flex-col items-center'
})``
