import PopUpProgress from 'src/components/common/PopUpProgress'
import ReturnButton from 'src/components/common/ReturnButton'
import { MainLayout } from 'src/components/layouts/MainLayout'

import BackroundInProgress from 'src/assets/img/in-progress/bg_common.jpeg'

const InProgress = () => {
  return (
    <MainLayout showHeader={false} active="" classNameHeader="pt-menu-header-offset px-4">
      <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed sm:left-1/2 sm:-translate-x-1/2 z-[51]">
        <ReturnButton to="/mypage" className="absolute left-0 top-2" />
      </div>
      <div className="absolute top-0 bottom-0 w-screen pb-[100px] sm:w-desktop-width ">
        <img src={BackroundInProgress} alt="" className="w-full h-full" />
      </div>
      <PopUpProgress />
    </MainLayout>
  )
}

export default InProgress
