import React, { useState } from 'react'
import { ErrorMessage } from 'src/components/ErrorMessage'
import { authActionTypes, useAuthDispatch } from 'src/providers/AuthProviders'
import loginByEmail from 'src/services/post/loginByEmail'
import Loading from '../../components/layouts/Loading'
import loginLogo from 'src/assets/img/common/login_logo.png'
import { validateEmail, validatePassword } from 'src/lib/validation'
import StandardizedButton from 'src/components/common/StandardizedButton'

export const LoginPage = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const authDispatch = useAuthDispatch()

  const handleSubmit = async () => {
    if (!email || !password) {
      return setErrors({ all: 'メルアドレスまたはパスワードを入力してください。' })
    }

    const error = {
      ...validatePassword(password),
      ...validateEmail(email)
    }

    setErrors(error)

    if (Object.keys(error).length) return

    setIsLoading(true)
    try {
      const res = await loginByEmail(email, password)

      if (!res || typeof res.session_token === 'undefined') {
        setErrors({ all: 'メールアドレスまたはパスワードが正しくありません。' })
      } else {
        authDispatch({ type: authActionTypes.LOGIN_USER, payload: res })
      }
    } catch (e) {
      console.log('[[LOGIN ERROR]]: ', e)
      setErrors({ all: 'システムエラーです。もう一度お試しください' })
    }
    setIsLoading(false)
  }

  return (
    <div className='sm:w-desktop-width w-screen h-screen m-h-screen mx-auto bg-no-repeat bg-[length:100%_100%] bg-top overflow-hidden'>
      <div className="w-full h-full flex flex-col justify-center items-center">
        <div className="w-full h-full flex flex-col justify-center items-center">
          <img className="w-1/2 mb-4" src={loginLogo} />
          <ErrorMessage errors={errors} field="all" />
          <div className="mt-4 w-5/6  flex flex-col items-start">
            <span className='flex items-center w-full font-bold'>ログインID</span>
            <input
              type="text"
              name="email"
              className="mt-2 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
              value={email || ''}
              onChange={(e) => setEmail(e.target.value)}
            />
            <ErrorMessage errors={errors} field="email" />
          </div>
          <div className="mt-4 w-5/6 flex flex-col items-start font-bold">
            <span className='flex items-center w-full'>パスワード</span>
            <input
              type="password"
              name="password"
              className="mt-2 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
              value={password || ''}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ErrorMessage errors={errors} field="password" />
          </div>

          <StandardizedButton
            className="w-[120px] h-[60px] mt-4"
            onClick={handleSubmit}
            title="ログイン"
          />
        </div>
        <div className="w-screen max-h-footer-offset sm:w-desktop-width fixed bottom-6 mr-8 z-50 flex justify-end font-bold">
          <a href={process.env.REACT_APP_IDOL_PRINCE_URL}>新規登録はコチラ</a>
        </div>
      </div>
      {isLoading && <Loading />}
    </div>
  )
}
