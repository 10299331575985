import React from 'react'
import './App.css'

import { BrowserRouter as Router } from 'react-router-dom'
import QueryProvider from 'src/providers/QueryProvider'
import ScrollToTop from 'src/components/common/ScrollToTop'
import { IndexRouter } from 'src/routes'
import { MaintenanceProvider } from 'src/providers/MaintenanceProviders'

import BGCommon from 'src/assets/img/register/bg-common.png'
import styled from 'styled-components'
import ActionCableProvider from './providers/ActionCableProvider'
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID
}

TagManager.initialize(tagManagerArgs)

function App() {
  return (
    <AppWrapper>
      <QueryProvider>
        <MaintenanceProvider>
          <ActionCableProvider>
            <Router>
              <ScrollToTop />
              <IndexRouter />
            </Router>
          </ActionCableProvider>
        </MaintenanceProvider>
      </QueryProvider>
    </AppWrapper>
  )
}

export default App

const AppWrapper = styled.div.attrs({
  className: 'App h-screen w-screen bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${BGCommon});
`
