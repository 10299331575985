import PropTypes from 'prop-types'
import StandardizedButton from 'src/components/common/StandardizedButton'
import TitleHeader from 'src/components/common/TitleHeader'
import Item from 'src/models/Item'
import BtnConfirm from 'src/assets/img/realtalk/btn_send_item_setting.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import BtnPlus from 'src/assets/img/realtalk/btn_plus.png'
import BtnMinus from 'src/assets/img/realtalk/btn_minus.png'
import BtnPlusGray from 'src/assets/img/realtalk/btn_plus_gray.png'
import BgLove from 'src/assets/img/gacha/bg_love.png'

import BtnAllCountItem from 'src/assets/img/realtalk/button_count_item.png'
import { useEffect, useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import clsx from 'clsx'

const ModalItemDetail = ({ item, handleCloseModalDetail, handleSendMessageItem, handleClose }) => {
  const { user } = useAuthState()

  const [countItem, setCountItem] = useState(0)
  const [totalItemLove, setTotalItemLove] = useState(item.increase_love)
  const handleCountPlusItem = () => {
    setCountItem(countItem + 1)
  }
  const handleCountMinusItem = () => {
    setCountItem(countItem - 1)
  }
  useEffect(() => {
    const times = user.dream_compass ? item.increase_love_dream_compass : 1
    setTotalItemLove(item.increase_love * countItem * times)
  }, [countItem])

  return (
    <div className="w-full m-4 px-4 pt-4 pb-8 bg-popup-l bg-fill bg-no-repeat rounded-md flex flex-col items-center">
      <TitleHeader title="アイテム送信" className="my-6" />
      <h1 className="mb-5 font-hiragino">アイテムを何個送りますか？</h1>
      <div>
        <div className="flex gap-5 items-center justify-center">
          <div>
            {countItem > 0 && (
              <button onClick={handleCountMinusItem}>
                <img className="w-[30px]" src={BtnMinus} />
              </button>
            )}
          </div>
          <ItemThumbnail
            image={item.image}
            imgClassName="w-[100px]"
            className=""
            rarity
            loveAmount={item.increase_love}
          />
          <div>
            <button disabled={item.amount === countItem} onClick={handleCountPlusItem}>
              <img className="w-[30px]" src={item.amount === countItem ? BtnPlusGray : BtnPlus} />
            </button>
          </div>
        </div>
        <div className="text-center mt-2 font-hiragino">贈るアイテム数 x{countItem} </div>
        <button className=" flex m-auto" onClick={() => setCountItem(item.amount)}>
          <img className="w-[100px] mt-3" src={BtnAllCountItem} />
        </button>
        <p className="m-auto bg-[#D9D9D9] font-hiragino w-[90px] p-[5px] rounded text-[10px] text-center mt-2">
          所持アイテム {item.amount}
        </p>
      </div>
      <table className="mt-5">
        <tr>
          <td>
            <ValueIndicator label="贈るアイテム数" />
          </td>
          <td>
            <div className="ml-5">x{countItem}</div>
          </td>
        </tr>
        <tr>
          <td>
            <ValueIndicator label="LOVE度上昇" />
          </td>
          <td>
            <div className="text-center ml-5">+{totalItemLove}</div>
          </td>
        </tr>
      </table>
      <div className="mt-1">
        <p className="text-[10px]">
          夢のコンパス有効期限内は更に{item.increase_love_dream_compass}倍LOVE度加算
        </p>
      </div>
      <div
        className={
          countItem > 0
            ? 'flex w-4/5 justify-between  gap-4 mt-5'
            : 'flex w-4/5 justify-center mt-5'
        }
      >
        <StandardizedButton
          className="w-28"
          img={BtnCancel}
          alt="キャンセル"
          onClick={handleCloseModalDetail}
        />
        {countItem > 0 && (
          <StandardizedButton
            className="w-28"
            img={BtnConfirm}
            alt="贈る"
            onClick={() => {
              handleCloseModalDetail()
              handleClose()
              handleSendMessageItem(countItem, item.item_id)
            }}
          />
        )}
      </div>
    </div>
  )
}

ModalItemDetail.propTypes = {
  item: PropTypes.instanceOf(Item).isRequired,
  handleCloseModalDetail: PropTypes.func.isRequired,
  handleSendMessageItem: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount !== 0 && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${BgLove}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">LOVE度</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

const ValueIndicator = ({ label, value, bgStyle, marginRight }) => {
  return (
    <div
      className={`rounded border h-8 flex text-xs font-hiragino bg-[#DAEBFF] w-[150px] ${marginRight} mb-1`}
    >
      <span className=" w-full flex items-center justify-center h-full align-middle px-2 text-center">
        {label}
      </span>
    </div>
  )
}

ValueIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  marginRight: PropTypes.string.isRequired,
  bgStyle: PropTypes.string.isRequired
}

export default ModalItemDetail
