/* eslint-disable no-irregular-whitespace */
import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { MainLayout } from 'src/components/layouts/MainLayout'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import TitleHeader from 'src/components/common/TitleHeader'
import InfoBox from 'src/components/common/InfoBox'
import ShopPaymentItem from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentItem'
import ShopPaymentButtons from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentButtons'
import ShopPaymentConditions from 'src/pages/main/shop/components/ShopDiamondTab/ShopPaymentConditions'

import BtnReturn from 'src/assets/img/common/btn_return.png'
import { Link } from 'react-router-dom'
import { MENU_PATHS } from 'src/routes/menu'
import { useQuery } from 'react-query'
import getCharacters from 'src/services/get/getCharacters'

const paymentTitle = {
  career: 'キャリア決済',
  credit: 'クレジット決済',
  paypay: 'Paypay',
  web_money: 'WebMoney',
  amazon_pay: 'Amazon Pay',
  bitcash: 'ビットキャッシュ'
}

const ShopPaymentMethodPicker = ({ method, description }) => {
  const { closeModal, selectedItem } = useShopContext()
  const characters = useQuery('characters', getCharacters)

  const navigatorCharacter = useMemo(() => {
    if (!characters.data) return
    return characters.data.find((character) => character.navigator)
  }, [characters.data])

  useEffect(() => {
    const shopModal = document.querySelector('.shop-modal')
    const className = shopModal?.getAttribute('class')
    shopModal?.removeAttribute('class')

    return () => {
      shopModal?.setAttribute('class', className || '')
    }
  }, [])

  return (
    <>
      <MainLayout active="shop" classNameHeader="p-4" showHeader={false}>
        <StandardPageWrapper className="relative h-full flex flex-col px-4 pt-16 pb-4" overflow={true}>
          <button type="button" onClick={closeModal} className="absolute left-0 top-4">
            <img src={BtnReturn} className="w-[64px]" alt="return button" />
          </button>

          <div className="w-full p-4 overflow-y-scroll text-center bg-white/80 hidden-scroll">
            <TitleHeader title={paymentTitle[method]} />
            <InfoBox title="購入アイテム">
              <div className="flex flex-col items-center space-y-1">
                <ShopPaymentItem shopDetails={selectedItem} />
                <span>価格 {selectedItem?.listed_price}円</span>
              </div>
            </InfoBox>
            <InfoBox title="商品詳細" className="mt-4">
              {description}
            </InfoBox>
            <ShopPaymentButtons method={method} />
            <InfoBox title="資金決済法に基づく表記">
              商品の品質上、購入後の返品、キャンセルは出来ませんので予めご了承下さい。
            </InfoBox>
            <ShopPaymentConditions method={method} />
            <InfoBox title="ヘルプ＆サポート" className="mt-4">
              {navigatorCharacter && (
                <>
                  <div>
                    購入に関する質問は、
                    <Link
                      to={`/message/detail/${navigatorCharacter.id}`}
                      className="cursor-pointer text-blue-600 font-semibold underline"
                    >
                      ナビゲーターのニワ丸
                    </Link>
                    までご連絡頂けますとスムーズです。
                  </div>
                  <div className="h-[1px] bg-black w-full my-2" />
                </>
              )}
              <div>
                <p>
                  メールでの問い合わせ窓口：
                  <Link
                    to={MENU_PATHS.InquiryPage}
                    className="cursor-pointer text-blue-600 font-semibold underline"
                  >
                    info@idolprince.games
                  </Link>
                </p>
                <p>メール対応時間：9時〜22時</p>
              </div>
            </InfoBox>
          </div>
        </StandardPageWrapper>
      </MainLayout>
    </>
  )
}

ShopPaymentMethodPicker.propTypes = {
  method: PropTypes.string.isRequired,
  description: PropTypes.string
}

export default ShopPaymentMethodPicker
