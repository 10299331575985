import React from 'react'
import styled from 'styled-components'
import Loading from 'src/components/layouts/Loading'

import BGCommon from 'src/assets/img/register/bg-common.png'

export const LoginLinePage = () => {
  return (
    <Wrapper>
      <Loading />
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-screen h-screen mx-auto relative bg-center bg-cover bg-no-repeat'
})`
  background-image: url('${BGCommon}');
`
