import React from 'react'
import { Navigate } from 'react-router-dom'
import CapsuleToyContainer from 'src/pages/main/capsule/CapsuleToyContainer'
import InProgress from 'src/pages/main/inProgress/InProgress'
import { RealTalkPage } from 'src/pages/main/realtime/Realtalk'
import RealTalkDetail from 'src/pages/main/realtime/RealTalkDetail'
import { MessageDetailPage } from 'src/pages/main/Message/Detail'
import { MessagePage } from 'src/pages/main/Message/Message'
import { MyPage } from 'src/pages/main/MyPage'
import ShopWebMoney from 'src/pages/main/shop/components/ShopDiamondTab/ShopWebMoney'
import ShopPage from 'src/pages/main/shop/ShopPage'
import MiniGame from 'src/pages/MiniGame'
import MiniGamePlay from 'src/pages/MiniGame/play'
import NoContent from 'src/pages/NoContent'
import { LoadingPayment } from 'src/pages/main/LoadingPayment'

const MAIN_PATHS = {
  MessagePage: '/message',
  MessageDetailPage: '/message/detail/:characterId',
  CapsuleToyPage: '/capsule',
  RealTalkPage: '/realtalk',
  RealTalkDetailPage: '/realtalk/detail/:characterId',
  MyPage: '/mypage',
  ShopPage: '/shop',
  InProgress: '/in-progress',
  NoContent: '/no-content',
  MiniGame: '/mini-game/:characterId',
  MiniGamePlay: '/mini-game/:characterId/play',
  ShopWebMoney: '/payments/paymentOrderWebmoney',
  LoadingPayment: '/loading-payment'
}

const MainRouter = [
  { path: MAIN_PATHS.MessagePage, element: <MessagePage /> },
  { path: MAIN_PATHS.MessageDetailPage, element: <MessageDetailPage /> },
  { path: MAIN_PATHS.CapsuleToyPage, element: <CapsuleToyContainer /> },
  { path: MAIN_PATHS.RealTalkPage, element: <RealTalkPage /> },
  { path: MAIN_PATHS.RealTalkDetailPage, element: <RealTalkDetail /> },
  { path: MAIN_PATHS.MyPage, element: <MyPage /> },
  { path: MAIN_PATHS.ShopPage, element: <ShopPage /> },
  { path: MAIN_PATHS.ShopWebMoney, element: <ShopWebMoney /> },
  { path: MAIN_PATHS.InProgress, element: <InProgress /> },
  { path: MAIN_PATHS.NoContent, element: <NoContent /> },
  { path: MAIN_PATHS.MiniGame, element: <MiniGame /> },
  { path: MAIN_PATHS.MiniGamePlay, element: <MiniGamePlay /> },
  { path: MAIN_PATHS.LoadingPayment, element: <LoadingPayment /> }
]

const NavigateToMyPage = [{ path: '*', element: <Navigate to={MAIN_PATHS.MyPage} /> }]

export { MainRouter, NavigateToMyPage, MAIN_PATHS }
