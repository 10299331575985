import PropTypes from 'prop-types'
import React, { useContext } from 'react'

const ShopContext = React.createContext({
  modalContent: null,
  selectedItem: {},
  selectedDiamonds: 0,
  selectedHearts: 0,
  selectedHeartExchange: {},
  setModalContent: () => {},
  closeModal: () => {},
  setSelectedItem: () => {},
  setSelectedHeartExchange: (id) => {}
})

const ShopContextProvider = ({ value, children }) => (
  <ShopContext.Provider value={value}>{children}</ShopContext.Provider>
)

const useShopContext = () => {
  return useContext(ShopContext)
}

ShopContextProvider.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node
}

export { ShopContextProvider, useShopContext }
