import { REGEX } from 'src/constants/regex'

export const countCharacters = (str) => {
  let count = 0
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i)
    // 0x0から0x7fの範囲はASCII文字のため半角
    if (charCode >= 0x0 && charCode <= 0x7f) {
      count += 1
      // 0xff61と0xff9fの範囲内の文字は半角カタカナ
    } else if (charCode >= 0xff61 && charCode <= 0xff9f) {
      count += 1
    } else {
      // その他は全角
      count += 2
    }
  }
  return count
}

export const validatePassword = (password, isRequired = false) => {
  const errors = {}
  if (!password) {
    if (isRequired) {
      errors.password = 'パスワードを入力してください。'
    }
    return errors
  }
  if (!REGEX.PASSWORD.test(password)) {
    errors.password = 'パスワードは半角英数字で入力してください。'
  }
  if (password.length < 6) {
    errors.password = 'パスワードは6文字以上で入力してください。'
  }
  if (password.length > 32) {
    errors.password = 'パスワードは32文字以内で入力してください。'
  }
  return errors
}

export const validatePasswordConfirm = (password, passwordConfirm) => {
  const errors = {}

  if (password && passwordConfirm && password !== passwordConfirm) {
    errors.passwordConfirm = 'パスワードの確認が正しくありません。'
  }
  return errors
}

export const validateEmail = (email, isRequired = false) => {
  const errors = {}
  if (!email) {
    if (isRequired) {
      errors.email = 'メールアドレスを入力してください。'
    }
    return errors
  }

  if (!REGEX.EMAIL.test(email)) {
    errors.email = 'メールアドレスは不正な値です。再度入力してください。'
  }

  return errors
}
