import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getItemRollUser = async (idGacha, timesroll) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.HistoryItems.replace(':id', idGacha))
    return res.data
  } catch (error) {
    console.error('Error fetching rolled items:', error)
    throw error
  }
}

export default getItemRollUser
