/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { countCharacters } from 'src/lib/validation'
import styled from 'styled-components'

export const RankingItem = ({ ranking, onClick }) => {
  const location = useLocation()
  const stringClass = useMemo(() => {
    if (!ranking.name) return
    const countName = countCharacters(ranking.name)
    if (countName < 13) return ''
    if (countName < 15) return 'scale-90 absolute -top-[90%] -left-[1%]'
    return 'scale-[80%] absolute -top-[90%] -left-[8%]'
  }, [ranking])

  return (
    <Wrapper background={ranking.background} onClick={onClick} location={location.pathname}>
      {ranking.character && (
        <div className="ranking-item__image px-[2px] pb-4 p-2.5 h-[90%]">
          <img
            className="h-full w-full object-cover object-top"
            src={require(`../../assets/img/ranking/characters/${ranking.character}`)}
            alt={ranking.name}
          />
        </div>
      )}
      <Places background={ranking.backgroundPlaces}>
        {ranking.places > 5 && (
          <div
            className="absolute -top-2 right-2 font-[1000] text-3xl -tracking-[0.15rem] font-AlibabaSansBlack"
            style={{
              WebkitTextStroke: '1px #928dcf'
            }}
          >
            {ranking.rank}
          </div>
        )}
        <div className="absolute bottom-0 left-[1px] right-[1px]">
          {ranking.name && (
            <div className={`text-[10px] whitespace-nowrap font-bold ${stringClass}`}>
              {ranking.name}
            </div>
          )}
          {ranking.totalReact && (
            <div className="text-[8px] font-semibold">{ranking.totalReact + ' pt'}</div>
          )}
        </div>
      </Places>
    </Wrapper>
  )
}

RankingItem.propTypes = {
  ranking: PropTypes.shape({
    places: PropTypes.number.isRequired,
    totalReact: PropTypes.number,
    name: PropTypes.string,
    background: PropTypes.string.isRequired,
    character: PropTypes.string.isRequired,
    backgroundPlaces: PropTypes.string.isRequired,
    rank: PropTypes.number
  }).isRequired,
  onClick: PropTypes.func
}

const Wrapper = styled.div.attrs({
  className: 'overflow-hidden ranking-item relative min-h-[17vh] h-[17vh] max-h-[204px]'
})`
  background-image: url(${(props) => require(`../../assets/img/ranking/${props.background}`)});
  background-size: 100% 100%;
  cursor: ${(props) => (props.location === '/ranking' ? 'default' : 'pointer')};
`

const Places = styled.div.attrs({
  className: 'absolute bottom-[1px] left-[1px] right-[1px] text-white font-hiragino h-14'
})`
  background-image: url(${(props) => require(`../../assets/img/ranking/${props.background}`)});
  background-size: 100% 100%;
`
