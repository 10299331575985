import PropTypes from 'prop-types'

const Tabs = ({ tabs, activeTab, setTab, children, classNameActive }) => {
  return (
    <>
      <div
        className={`grid grid-flow-col grid-cols-${tabs.length} gap-x-1 flex-row`}
      >
        {tabs.map((t, i) => (
          <button
            type="button"
            className={`${activeTab === t.key ? classNameActive : ''}`}
            key={i}
            onClick={() => setTab(t.key)}
          >
            {t.label}
          </button>
        ))}
      </div>
      <div className="w-full h-full">{children}</div>
    </>
  )
}

export default Tabs

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired
  })).isRequired,
  activeTab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  classNameActive: PropTypes.string
}
