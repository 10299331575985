import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { validateEmail, validatePassword, validatePasswordConfirm } from 'src/lib/validation'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import { ErrorMessage } from 'src/components/ErrorMessage'
import StandardizedButton from 'src/components/common/StandardizedButton'

const EmailEditBox = ({ emailAddress, handleSave, handleClose, apiErrors }) => {
  const [email, setEmail] = useState(emailAddress)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [errors, setErrors] = useState({})

  useEffect(() => {
    setErrors(apiErrors)
  }, [apiErrors])

  const handleChangeEmail = (value) => {
    setEmail(value)
    delete errors.email
    setErrors({ ...errors, ...validateEmail(value, true) })
  }

  const handleChangePassword = (value) => {
    setPassword(value)
    delete errors.password
    delete errors.passwordConfirm
    setErrors({
      ...errors,
      ...validatePassword(value, true),
      ...validatePasswordConfirm(value, passwordConfirm)
    })
  }

  const handleChangePasswordConfirm = (value) => {
    setPasswordConfirm(value)
    delete errors.passwordConfirm
    setErrors({ ...errors, ...validatePasswordConfirm(password, value) })
  }

  return (
    <div className="bg-popup-sm bg-fill bg-no-repeat rounded-md">
      <ModalCloseButton handleClose={handleClose} />
      <div className="py-8 px-4 w-full flex flex-col justify-center items-center z-10">
        <TitleHeader title="メールアドレス登録・変更" />
        <span className="text-sm text-left w-full">メールアドレス</span>
        <span className="text-xs text-left w-full">（お間違えの無いようご入力下さい）</span>
        <div className="w-full  flex flex-col items-start">
          <input
            type="text"
            name="email"
            id="email"
            aria-label="email"
            className="mt-2 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
            placeholder="メールアドレス"
            value={email || ''}
            onChange={(e) => handleChangeEmail(e.target.value)}
          />
          <ErrorMessage errors={errors} field="email" />
          <span className="text-xs mt-2 w-full whitespace-pre-line">
            {'注意事項\nドメイン拒否、PCメール拒否をしていると登録確認メールを受け取れません。\nidolprince.games\nからのメールを受信できるよう設定の確認をお願いします。'}
          </span>
        </div>
        <span className="mt-4 text-sm text-left w-full">ログインパスワード<span className="text-[10px]">(6文字~32文字の半角英数字)</span></span>
        <div className="w-full  flex flex-col items-start">
          <input
            type="password"
            name="password"
            id="password"
            aria-label="password"
            className="mt-2 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
            placeholder="ログインパスワード"
            value={password || ''}
            onChange={(e) => handleChangePassword(e.target.value)}
          />
          <ErrorMessage errors={errors} field="password" />
        </div>
        <span className="mt-2 text-sm text-left w-full">パスワード<span className="text-[10px]">（もう一度ご入力ください）</span></span>
        <div className="w-full  flex flex-col items-start">
          <input
            type="password"
            name="passwordConfirm"
            id="passwordConfirm"
            aria-label="passwordConfirm"
            className="mt-2 p-2 bg-white border border-menu-dark-blue focus:outline-none rounded-md w-full drop-shadow-md"
            placeholder="パスワード"
            value={passwordConfirm || ''}
            onChange={(e) => handleChangePasswordConfirm(e.target.value)}
          />
          <ErrorMessage errors={errors} field="passwordConfirm" />
        </div>
        <StandardizedButton
          title="登録"
          className="w-[120px] h-[60px] mt-4"
          disabled={!email || !password || !passwordConfirm || !!Object.keys(errors).length}
          onClick={() => handleSave({
            email,
            password,
            password_confirmation: passwordConfirm
          })}
        />
      </div>
    </div>
  )
}

EmailEditBox.propTypes = {
  emailAddress: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  apiErrors: PropTypes.object
}

export default EmailEditBox
