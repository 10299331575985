import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const postBackGround = async (params) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.RealTalk.PostBackGround, params)
    if (res.data) {
      return res.data
    }
    return null
  } catch (error) {
    return false
  }
}

export const postIsCheckStory = async (params) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.StoryMessage.PostNoticeMessage, params)
    if (res.data) {
      return res.data
    }
    return null
  } catch (error) {
    return false
  }
}
