import React, { useEffect, useState } from 'react'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import { useAuthState } from 'src/providers/AuthProviders'
import getGachaInfo from 'src/services/get/getGachaInfo'
import getUserItems from 'src/services/get/getUserItems'
import postDrawGacha from 'src/services/get/getDrawGacha'
import Item, { ITEM_RARITY } from 'src/models/Item'
import CapsuleToyPage from 'src/pages/main/capsule/CapsuleToy'

export const MIXER_ITEM_AMOUNTS = { R20: 20, SR10: 10, SSR5: 5 }

/**
 * TODO:
 * このページで使用されているテキストの多くはプレースホルダーです。
 * API経由でテキストを取得する必要がありますが、モックアップ/デザインが不明です。
 * リリース前にページを見直し、すべての表示テキストが正しいことを確認してください。
 * 特にガチャ倍率などは本文をご覧ください。
 */

const CapsuleToyContainer = () => {
  const [selectedIndex, setSelectedIndex] = useState(1)
  const [gachas, setGachas] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [resultItems, setResultItems] = useState([])
  const [items, setItems] = useState([])

  const { user } = useAuthState()

  // TODO: ブルーダイヤ、AREチケット、ガチャチケットの現在値を取得するAPIです。
  const ticketCount = 10
  const ssrCount = 10
  const gachaId = 1

  useEffect(() => {
    const gachaReq = new CancelableAPIRequest(getGachaInfo)
    Promise.all([
      gachaReq.invoke((resp) => setGachas(resp))
    ]).then(() => {
      setIsLoading(false)
    })

    return () => {
      gachaReq.cancel()
    }
  }, [])

  useEffect(() => {
    const loadItem = async () => {
      try {
        const response = await getUserItems(user.id)
        setItems(response)
      } catch (error) {
        console.error('Error fetching items:', error)
      }
    }
    loadItem()
  }, [])

  const drawGacha = async (draw, currency) => {
    if (resultItems.length > 0) setResultItems([])

    const results = await postDrawGacha(user.id, gachaId, draw, currency)
    setResultItems(results)
  }

  const drawMixerGacha = async (currencyAmount, selectedItems) => {
    if (resultItems.length > 0) setResultItems([])

    // TODO: ミキサー抽選ガチャは、通常のガチャとは異なるデータを送信する必要があります。 おそらく別の API を開発する必要があります。

    setResultItems([
      new Item(
        0,
        'Test 1',
        'https://via.placeholder.com/64',
        'Lorem Ipsum Dolor Lorem Ipsum Dolor Lorem Ipsum Dolor Lorem Ipsum Dolor',
        '2022-06-10T06:03:21.121Z',
        'clothing',
        7,
        ITEM_RARITY.SSR,
        10,
        10,
        '2022-06-03T06:03:21.121Z',
        '2022-06-03T06:03:21.121Z'
      )
    ])
  }

  return (
    <CapsuleToyPage
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      isLoading={isLoading}
      drawGacha={drawGacha}
      drawMixerGacha={drawMixerGacha}
      resultItems={resultItems}
      itemsTicket={items}
      redCount={user.red_amount}
      blueCount={user.blue_amount}
      ticketCount={ticketCount}
      ssrCount={ssrCount}
      gachas={gachas}
      setItemsUpdate={setItems}
      items={items}
    />
  )
}

export default CapsuleToyContainer
