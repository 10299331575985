import React from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import life from 'src/assets/img/common/life.png'
import ibg from 'src/assets/img/common/bar-bg.png'
import add from 'src/assets/img/common/add.png'

export const PossessionHeart = ({ onClick }) => {
  const { user } = useAuthState()
  const navigate = useNavigate()

  return (
    <div className="relative z-0">
      <img src={ibg} className="w-full h-4" />
      <img src={life} className="absolute w-6 -top-1 -left-1" />
      <img
        src={add}
        onClick={() => (onClick ? onClick() : navigate('/shop'))}
        className="absolute right-2 top-[2px] w-3 z-10 cursor-pointer"
      />
      <div className="absolute top-0 bottom-0 left-0 right-0 grid content-center text-sm text-center text-white">
        {user?.possession_heart}
      </div>
    </div>
  )
}

PossessionHeart.propTypes = {
  onClick: PropTypes.func
}
