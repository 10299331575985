import PropTypes from 'prop-types'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import { genDateString } from 'src/lib/date'
import Inquiry from 'src/models/Inquiry'
import HTMLReactParser from 'html-react-parser'

import { useNavigate, useParams } from 'react-router-dom'
import StandardizedButton from 'src/components/common/StandardizedButton'
import SectionHeader from 'src/components/menu/SectionHeader'
import { useEffect, useState } from 'react'
import { CATEGORIES } from 'src/constants/common'
import BtnBack from 'src/assets/img/common/btn_back.png'
import BtnKakunin from 'src/assets/img/menu/btn_kakunin.png'
import BtnOk from 'src/assets/img/common/btn-confirm-feedback.png'
import { postThreadFeedback } from 'src/services/post/postThreadFeedback'
import { useAuthState } from 'src/providers/AuthProviders'
import ReactPlayer from 'react-player'

const InquiryDetail = ({ isLoading, inquiry }) => {
  const navigate = useNavigate()

  const { user } = useAuthState()
  const { id } = useParams()

  const [openFormReplay, setOpenFormReplay] = useState(false)
  const [payloadOption, setPayloadOption] = useState([])
  const [message, setMessage] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)

  const getInquiryText = (item, inquiry) => {
    if (item.contact_type === 'admin_send') {
      if (inquiry === 'サポートからのお知らせ') {
        return '運営からの連絡'
      } else {
        return '運営からの返信'
      }
    } else {
      return 'お問合せ内容'
    }
  }

  useEffect(() => {
    setPayloadOption(CATEGORIES.filter(item => item.name === inquiry?.contactKinds))
  }, [inquiry])

  return (
    <>
      <MenuBar title="inquiry" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex flex-col pt-menu-header-offset" overflow={true}>
          {isLoading ? (
            <Loading />
          ) : !inquiry ? (
            <NoContent />
          ) : isSubmitted ? (
            <>
              <div className="w-full px-10 py-14 bg-white rounded">
                <div className="font-bold text-lg mb-4">お問合せありがとうございます。</div>
                <div className="leading-6 whitespace-pre-line">
                  <button
                    onClick={() => {
                      navigate(-1)
                    }}
                    className="text-menu-dark-blue"
                  >
                    お問合せ履歴と返信
                  </button>
                </div>
              </div>
              <StandardizedButton
                className="w-32 mx-auto mt-4"
                disabled={false}
                onClick={() => {
                  navigate(-1)
                }}
                img={BtnBack}
                alt="戻る"
              />
            </>
          ) : (
            <>
              <div className="bg-white rounded w-full font-hiragino h-[400px] overflow-y-auto">
                <div className="relative px-4 pt-4 pb-4 text-left w-full border-b border-menu-vivid-blue">
                  {inquiry.adminResponse && (
                    <span className="rounded bg-light-pink text-white text-center text-xs p-2 mr-2">
                      返信あり
                    </span>
                  )}
                  <span className="leading-7">{inquiry.contactKinds}</span>
                  <div className="text-[11px] w-full text-end mt-2">
                    {genDateString(inquiry.createdAt, true, '/')}
                  </div>
                </div>
                {inquiry.messageFeedback.map((item, index) => {
                  const regexImageExtensions = /(png|gif|jpg|heic|jpeg)$/
                  const extensionsFile1 = item.attached_file.url?.split('.')
                  const extensionsFile2 = item.attached_file_two.url?.split('.')
                  const extensionsFile3 = item.attached_file_three.url?.split('.')
                  const typeFile1 = extensionsFile1?.pop().toLowerCase()
                  const typeFile2 = extensionsFile2?.pop().toLowerCase()
                  const typeFile3 = extensionsFile3?.pop().toLowerCase()
                  return (
                    <div
                      key={index}
                      className={`${
                        item.contact_type === 'admin_send' ? 'bg-[#FDFAAE]' : ''
                      } px-4 py-4 border-b border-menu-vivid-blue border-dotted flex flex-col items-start`}
                    >
                      <span className="text-menu-vivid-blue">
                        {getInquiryText(item, inquiry.contactKinds)}
                      </span>
                      <div
                        className="text-left whitespace-pre-line style-list"
                        style={{ padding: 8 }}
                      >
                        {HTMLReactParser(item.content)}
                      </div>
                      <>
                        {regexImageExtensions.test(typeFile1) ? (
                          <img
                            src={item.attached_file.url}
                            className="w-full h-[300px] object-contain my-3"
                          />
                        ) : (
                          <ReactPlayer
                            height="100%"
                            width="100%"
                            controls
                            url={item.attached_file.url}
                          />
                        )}
                        {regexImageExtensions.test(typeFile2) ? (
                          <img
                            src={item.attached_file_two.url}
                            className="w-full h-[300px] object-contain my-3"
                          />
                        ) : (
                          <ReactPlayer
                            height="100%"
                            width="100%"
                            style={{ marginTop: item.attached_file_two.url ? '10px' : '0px' }}
                            controls
                            url={item.attached_file_two.url}
                          />
                        )}
                        {regexImageExtensions.test(typeFile3) ? (
                          <img
                            src={item.attached_file_three.url}
                            className="w-full h-[300px] object-contain my-3"
                          />
                        ) : (
                          <ReactPlayer
                            height="100%"
                            width="100%"
                            style={{ marginTop: item.attached_file_three.url ? '10px' : '0px' }}
                            controls
                            url={item.attached_file_three.url}
                          />
                        )}
                      </>
                      <p className="text-[11px] w-full text-end mt-2">
                        {genDateString(item.created_at, true, '/')}
                      </p>
                    </div>
                  )
                })}
                {inquiry.messageFeedback.length === 1 &&
                  inquiry.messageFeedback[0].contact_type === 'user_send' && (<div className="px-4 py-4 border-b border-menu-vivid-blue border-dotted flex flex-col items-start"><span className="text-menu-vivid-blue">運営からの返信</span><p className="text-left whitespace-pre-line">運営からの返信はまだありません。</p></div>)}
              </div>
              <div className="mt-4 mx-auto cursor-pointer flex gap-5">
                <img
                  onClick={() => setOpenFormReplay(true)}
                  className="w-32"
                  src={BtnOk}
                  alt="confirm"
                />
                <img onClick={() => navigate(-1)} className="w-32" src={BtnBack} alt="Return" />
              </div>

              {openFormReplay && (
                <div className="bg-white rounded w-full font-hiragino p-4 mt-4">
                  <SectionHeader title="お問合せ内容" className="w-full" />
                  <textarea
                    className="bg-white w-full p-2 my-2 rounded border border-menu-dark-blue drop-shadow-md truncate flex justify-between items-center"
                    rows={6}
                    onChange={(e) => {
                      setMessage(e.target.value)
                    }}
                    value={message}
                    placeholder="お問合せ内容をご入力ください。"
                  />
                  <StandardizedButton
                    className="w-40 mx-auto"
                    disabled={!message}
                    onClick={() => {
                      postThreadFeedback(user.id, id, inquiry.contentId, message, payloadOption)
                      setIsSubmitted(true)
                    }}
                    img={BtnKakunin}
                    alt="確認する"
                  />
                </div>
              )}
            </>
          )}
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

InquiryDetail.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  inquiry: PropTypes.instanceOf(Inquiry)
}

export default InquiryDetail
