// @ts-nocheck
import { useEffect } from 'react'
import { createClient } from 'agora-rtc-react'
import { useAuthState } from '../../../providers/AuthProviders'

const useClient = createClient({
  mode: 'live',
  codec: 'vp8'
})

const useSubcriber = ({ channelName = '', appId = '', token = '', settingId }) => {
  const { user } = useAuthState()
  const client = useClient()

  useEffect(() => {
    if (!channelName || !appId || !token) return

    client.setClientRole('audience', { level: 1 })
    client.on('user-published', async (user, mediaType) => {
      await client.subscribe(user, mediaType)
      if (mediaType === 'audio') {
        user.audioTrack?.play()
      }
    })

    client.on('user-unpublished', (user, type) => {
      if (type === 'audio') {
        user.audioTrack?.stop()
      }
    })

    client.join(appId, channelName, token, user.id)

    return () => {
      client.leave()
    }
  }, [channelName, client])

  return null
}

export { useSubcriber }
