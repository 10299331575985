import PropTypes from 'prop-types'

const MenuWrapper = ({
  children,
  className = 'grid grid-cols-2 gap-2 items-center',
  overflow = false,
  containerRef
}) => {
  return (
    <div
      className={`w-screen h-full sm:w-desktop-width pb-footer-offset top-0 fixed sm:left-1/2 sm:-translate-x-1/2 ${
        overflow ? null : 'pt-menu-header-offset'
      }`}
    >
      <div
        className={`p-4 h-full w-full ${className} ${
          overflow && 'overflow-y-scroll hidden-scroll'
        }`}
        ref={containerRef}
      >
        {children}
      </div>
    </div>
  )
}

export default MenuWrapper

MenuWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  overflow: PropTypes.bool,
  containerRef: PropTypes.any
}
