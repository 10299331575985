
import { useContext, useEffect, useState } from 'react'
import { ActionCableContext } from 'src/providers/ActionCableProvider'

const useConversationListChannel = () => {
  const cable = useContext(ActionCableContext)
  const [message, setMessage] = useState()

  useEffect(() => {
    if (!cable) return

    const channel = cable.subscriptions.create({ channel: 'ConversationListChannel' }, {
      received: (msg) => setMessage(msg)
    })

    return () => {
      channel.unsubscribe()
    }
  }, [cable])

  return { message }
}

export default useConversationListChannel
