import { useNavigate, useLocation } from 'react-router-dom'
import EventDetail from 'src/components/Event/EventDetail'
import { MainLayout } from 'src/components/layouts/MainLayout'
import BtnReturn from 'src/assets/img/common/btn_return.png'

const EventContainer = () => {
  const navigate = useNavigate()
  window.scrollTo(0, 0)
  const location = useLocation()
  const stateValue = location.state

  const handleClick = () => {
    if (stateValue) {
      navigate(-1)
      return
    }
    navigate('/events')
  }

  return (
    <>
      <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed sm:left-1/2 sm:-translate-x-1/2 z-[51]">
        <button onClick={handleClick} className="absolute left-0 top-2" >
          <img src={BtnReturn} className="w-[64px]" alt="return button" />
        </button>
      </div>
      <MainLayout active="menu" showHeader={false}>
        <EventDetail />
      </MainLayout>
    </>
  )
}

export default EventContainer
