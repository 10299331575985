/* eslint-disable indent */
import ImgRight from 'src/assets/img/gacha/right.png'
import ImgLeft from 'src/assets/img/gacha/left.png'
import Btn from 'src/assets/img/realtalk/realtalk_start_btn.png'
import { animateSlider } from 'src/lib/animationSlider'
import PropTypes from 'prop-types'

import '../components/CardSlide.css'

import BtnNew from 'src/assets/img/realtalk/btn_pink.png'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import iconCharacterLove from 'src/assets/img/realtalk/realtalk_osi_badge.png.png'

function Carroussel({
  mypageImage,
  updateRecommendById,
  currentImage,
  setCurrentImage,
  message,
  messages,
  defaultValue,
  miniRecommendMan
}) {
  const divRef = useRef(null)
  const navigate = useNavigate()
  const [characterId, setCharacterId] = useState(currentImage)
  const [isCheckIconNew, setIsCheckIconNew] = useState()
  const defaultId = defaultValue.find((x) => x.recommend === true).id
  useEffect(() => {
    if (updateRecommendById) {
      updateRecommendById(mypageImage)
    }
  }, [currentImage])

  // function calculateCircularSteps(currentIndex, targetIndex, mypageImage) {
  //   if (currentIndex === targetIndex) {
  //     return 0 // Không cần di chuyển nếu đã ở vị trí mục tiêu
  //   }

  //   const clockwiseSteps = (targetIndex - currentIndex + mypageImage) % mypageImage
  //   const counterClockwiseSteps = (currentIndex - targetIndex + mypageImage) % mypageImage

  //   return Math.min(clockwiseSteps, counterClockwiseSteps)
  // }

  const rotateHandler = (evt) => {
    const carousel = evt.currentTarget.closest('.card-carousel')
    const rotateInt = parseInt(carousel.dataset.rotateInt || 0)
    let direction
    const newArr = mypageImage
    const currentIndex = mypageImage.findIndex((d) => d.recommend === true)
    if (evt.currentTarget.classList.contains('counterclockwise')) {
      direction = -1
      newArr[currentIndex].recommend = false
      currentIndex === newArr.length - 1
        ? (newArr[0].recommend = true)
        : (newArr[currentIndex + 1].recommend = true)
      const getId = newArr.find((item) => item.recommend === true)
      setCharacterId(getId.id)
    } else if (evt.currentTarget.classList.contains('clockwise')) {
      direction = 1
      newArr[currentIndex].recommend = false
      currentIndex === 0
        ? (newArr[newArr.length - 1].recommend = true)
        : (newArr[currentIndex - 1].recommend = true)
      const getId = newArr.find((item) => item.recommend === true)
      setCharacterId(getId.id)
    }
    carousel.dataset.rotateInt = rotateInt + direction
    animateSlider(carousel, rotateHandler)
    updateRecommendById(newArr)
  }

  useEffect(() => {
    if (mypageImage) {
      const carousels = document.querySelectorAll('.card-carousel')
      carousels?.forEach((carousel) => {
        const inner = carousel.querySelector('.inner-carousel')
        const cards = carousel.querySelectorAll('.inner-carousel > div')
        const size = cards.length
        const panelSize = inner.clientWidth
        const translateZ = Math.round(panelSize / 2 / Math.tan(Math.PI / size)) * 1.7
        inner.style.transform = `rotateY(0deg) translateZ(-${translateZ}px)`
        animateSlider(carousel, rotateHandler)
      })
    }
  }, [mypageImage])

  function calculateSteps(currentIndex, targetIndex, arrayLength) {
    if (currentIndex === targetIndex) {
      return { step: 0, isNext: false }
    }

    const prev = (currentIndex - targetIndex + arrayLength) % arrayLength
    const next = (targetIndex - currentIndex + arrayLength) % arrayLength

    return next > prev ? { step: prev, isNext: false } : { step: next, isNext: true }
  }

  const rotateNext = (id) => {
    setCharacterId(id)
    const newArr = mypageImage
    const currentIndex = mypageImage.findIndex((d) => d.recommend === true)
    const targetIndex = mypageImage.findIndex((d) => d.id === id)
    newArr[currentIndex].recommend = false
    newArr[targetIndex].recommend = true
    const carousel = document.querySelector('.card-carousel')
    let rotateInt = parseInt(carousel.dataset.rotateInt || 0)
    const value = calculateSteps(currentIndex, targetIndex, newArr.length)
    if (value.isNext) {
      rotateInt -= value.step
    } else {
      rotateInt += value.step
    }
    carousel.dataset.rotateInt = rotateInt
    animateSlider(carousel, rotateHandler)
    updateRecommendById(newArr)
  }
  useEffect(() => {
    const checkIconNew = mypageImage.find((item) => item.id === characterId)
    const conversation = messages.find((x) => x.conversation_id === message?.conversation_id)
    if (conversation?.character?.id === characterId) {
      checkIconNew.unreadTotal = 1
    }
    setIsCheckIconNew(checkIconNew)
  }, [characterId, message])

  return (
    <>
      {mypageImage.length > 0 && (
        <ResponSiveDisplayScreen className="card-carousel" ref={divRef}>
          <ResponSiveDisplaySlide className="inner-carousel h-[500px]">
            {mypageImage?.map((item, index) => (
              <>
                <ResponSiveDisplay key={index} className={`${item.recommend === true ? 'first-slide' : ''}`}>
                  { (item.id === defaultId && item.recommend === true) &&
                  <img className='icon-love' src={iconCharacterLove} />
                  }
                  <img className='img-slide h-[600px] object-cover' src={(item.id === defaultId && item.recommend === true) ? item.imageActive : (item.recommend === true ? item.imageActive : item.imageGray) } />
                </ResponSiveDisplay>
              </>
            ))}
          </ResponSiveDisplaySlide>
          <ResponSiveDisplayButton className="flex justify-around items-center fixed w-[100%] bottom-[50px]">
            <button onClick={rotateHandler} className=" z-[100] clockwise ">
              <img className="w-[36px] h-[47px]" src={ImgLeft} />
            </button>
            <button
              className="z-[9] h-[50px] w-[200px] relative"
              onClick={() => navigate(`/realtalk/detail/${characterId}`)}
            >
              <img src={Btn} className="" />
              {isCheckIconNew?.unreadTotal > 0 && (
                <img className="absolute top-[-10px] right-[8px] w-[35px]" src={BtnNew} />
              )}
            </button>
            <button onClick={rotateHandler} className=" z-[100] counterclockwise  ">
              <img className="w-[36px] h-[47px]" src={ImgRight} />
            </button>
          </ResponSiveDisplayButton>
          <ResponSiveDisplayIcon className="fixed top-[45%] right-0 bg-white rounded">
            {miniRecommendMan
              .map((item, index) => (
                <div
                  key={index}
                  className={`${item.recommend && 'bg-pink-600'} rounded relative`}
                  onClick={() => rotateNext(item.id)}
                >
                  <ResponSiveDisplayIconImg className="w-[50px]" src={item.imgIcon} />
                  {item.unreadTotal > 0 && (
                    <>
                      <img className="absolute right-[8px] top-[5px] w-[20px]" src={BtnNew} />
                    </>
                  )}
                </div>
              ))}
          </ResponSiveDisplayIcon>
        </ResponSiveDisplayScreen>
      )}
    </>
  )
}

const ResponSiveDisplay = styled.div`
  @media (max-width: 375px) {
    padding: 0 !important;
  }
  @media (min-width: 414px) and (min-height: 736px) {
    padding: 0 !important;
  }
`

const ResponSiveDisplayIcon = styled.div`
`

const ResponSiveDisplayIconImg = styled.img`
  @media (max-width: 375px) {
    width: 40px;
  }
`

const ResponSiveDisplaySlide = styled.div``
const ResponSiveDisplayButton = styled.div`
  @media (max-width: 375px) {
    width: 375px;
  }
  @media (max-height: 768px) {
    bottom: 20px
  }
`

const ResponSiveDisplayScreen = styled.div`
  @media (max-width: 375px) {
  }
`

Carroussel.propTypes = {
  mypageImage: PropTypes.array,
  defaultValue: PropTypes.array,
  currentImage: PropTypes.number,
  updateRecommendById: PropTypes.func,
  setCurrentImage: PropTypes.func,
  messages: PropTypes.array,
  setRecommendMan: PropTypes.func,
  miniRecommendMan: PropTypes.array,
  message: PropTypes.any
}

export default Carroussel
