import styled from 'styled-components'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'
import { CharacterBoxContent } from 'src/components/myPage/recommend/CharacterBox'

import BgCommon from 'src/assets/img/common/bg_common.png'
import { useGetRecommendsData, useUpdateRecommendsMan } from 'src/services/hooks/useFetchRecommends'
import TipsModal from 'src/components/common/TipsModal'

export const RecommendPage = () => {
  const { data: recommends, isLoading } = useGetRecommendsData()
  const mutation = useUpdateRecommendsMan()

  return (
    <Wrapper>
      <Header>
        <MenuBar title="recommend" />
      </Header>
      <MainLayout className="relative h-fit" active="mypage" showHeader={false} showFooter={true}>
        <div className="h-full pb-[112px] px-[14px]">
          {recommends?.map((item, idx) => (
            <div className="mt-[12px]" key={idx}>
              <CharacterBoxContent character={item} handleUpdate={mutation.mutate} />
            </div>
          ))}
        </div>
        {(isLoading || mutation.isLoading) && <Loading />}
      </MainLayout>
      <TipsModal type="favorite_character" />
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${BgCommon});
`
