/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { withVoiceFullLayout } from '../../hocs/withVoiceLayout'
import LiveScheduleBg from '../../assets/live_schedule.png'
import LiveDelivery from '../../assets/live_delivery_bg.png'
import LiveCommingSoon from '../../assets/live_started_bg.png'
import BgLiveStart from '../../assets/live_start_bg.png'
import BgInLive from '../../assets/bg_in_live.png'
import BackroundInProgress from 'src/assets/img/in-progress/bg_common.jpeg'
import HeaderAddition from '../../assets/header_addition.png'
import ButtonOk from '../../../../assets/img/common/btn_ok.png'
import Countdown from 'react-countdown'
import BGModalTitle from '../../../../assets/img/bg-modal-title.png'
import BGModal from '../../../../assets/img/bg-modal-confirm.png'
import IconPassport from '../../assets/stand_by_passport.png'
import { useNavigate } from 'react-router-dom'
import { VOICE_LIVE_PATH } from '../../../../routes/live'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { MAIN_PATHS } from 'src/routes/main'
import { useQuery } from 'react-query'
import { getLiveSettingInfos } from '../../../../services/get/getLiveSettingInfos'
import { getLiveActiveLink } from '../../../../services/get/getLiveActiveLink'
import _ from 'lodash'
import clsx from 'clsx'
import TipsModal from 'src/components/common/TipsModal'
import { FIVE_MINS } from 'src/constants/livestream'
import styled from 'styled-components'

const TopCountdown = withVoiceFullLayout(() => {
  const [isLive, setIsLive] = useState(false)
  const [open, setOpen] = useState(false)
  const [canLive, setCanLive] = useState(false)
  const [textColor, setTextColor] = useState('text-glitter-lake')
  const [liveScheduleId, setLiveScheduleId] = useState()
  const navigate = useNavigate()

  const { data, refetch, isFetched, remove } = useQuery({
    queryFn: getLiveSettingInfos,
    queryKey: 'get-live-setting-infos'
  })

  useEffect(() => {
    getLiveActiveLink().then((data) => setLiveScheduleId(data.event.id))
  }, [])

  useEffect(() => {
    const startTime = _.get(data, 'start_time')
    if (new Date(startTime) < new Date()) {
      setIsLive(true)
      return
    }

    if (startTime && (new Date(startTime) - new Date() < FIVE_MINS)) {
      setCanLive(true)
      setTextColor('text-menu-red')
    }
  }, [data])

  useEffect(() => () => remove(), [])

  const textModal = 'LIVE配信で臨場感溢れるトークを楽しもう!\n' +
                    '配信5分前になると、このぺージをタップして\n' +
                    'LIVE配信視聴ぺージへ進めるようになるコケッ!\n' +
                    '次のLIVE配信もお楽しみにッ!'

  const deliveryLive = () => {
    if (canLive) {
      navigate({ pathname: VOICE_LIVE_PATH.CharacterSelection.replace(':setting_id', _.get(data, 'id')) })
    } else {
      setOpen(true)
    }
  }

  const changeTextColor = (total) => {
    if (total >= FIVE_MINS) return

    setCanLive(true)
    setTextColor('text-menu-red')
  }

  return (
    <>
      <div className="top-[20px] flex flex-col justify-between">
        {open &&
          <Container className="fixed top-[24%] flex justify-center w-[100%]" style={{ zIndex: 99 }}>
            <DivBg className="max-h-[380px] relative bg-modal h-[436px] pt-[25px]" style={{ backgroundImage: `url(${BGModal})`, backgroundSize: '100% 100%' }}>
              <div className="flex items-center flex-col w-full">
                <div className='w-[70%] h-[40px] text-[24px] text-[#FFFF] mb-[20px]' style={{ backgroundImage: `url(${BGModalTitle})`, backgroundSize: '100% 100%', textAlign: 'center' }}>
                  <span>配信準備中</span>
                </div>
                <ImagePassport className='w-[240px] h-[80px] ml-[50px]' style={{ backgroundImage: `url(${IconPassport})`, backgroundSize: '100% 100%' }}/>
                <TextModal className="whitespace-pre-line text-left w-[100%] px-5 mt-2 flex justify-center">{textModal}</TextModal>
                <div className="w-[117px] h-[48px] mt-[29px] cursor-pointer">
                  <button onClick={() => setOpen(false)}>
                    <img src={ButtonOk} alt="" />
                  </button>
                </div>
              </div>
            </DivBg>
          </Container>
        }
        {!isLive ? (
          <div className="relative">
            {isFetched && data
              ? <>
                  <img src={BgLiveStart} className="cursor-pointer" onClick={() => deliveryLive()} /><span className={clsx('absolute text-[13px] font-fotKurokane font-black right-[60px] bottom-[12px] bg-clip-text drop-shadow-2xl', textColor)}
                    style={{ textShadow: '#fff 0px 0px 0px, #fff 0px 0px 0px, #fff 0px 0px 2px, #fff 0px 0px 3px, #fff 0px 0px 10px' }}>
                    <Countdown date={_.get(data, 'start_time')} onComplete={() => setIsLive(true)} onTick={({ total }) => changeTextColor(total)}
                      renderer={({ days, hours, minutes, seconds }) => {
                        return <>
                        あと
                          {days.toString().padStart(2, '0')}日
                          {hours.toString().padStart(2, '0')}時間
                          {minutes.toString().padStart(2, '0')}分
                          {seconds.toString().padStart(2, '0')}秒
                        </>
                      } } />
                  </span>
                </> : <img src={LiveCommingSoon} className="cursor-pointer" onClick={() => deliveryLive()} />
            }
          </div>
        ) : (
          <div className="relative">
            <img src={BgInLive} className="cursor-pointer" onClick={() => navigate({ pathname: VOICE_LIVE_PATH.CharacterSelection.replace(':setting_id', _.get(data, 'id')) })} />
          </div>
        )}
        <div className='flex justify-center'>
          <img
            src={LiveScheduleBg}
            className="w-[80%] mt-5 mb-5 cursor-pointer"
            onClick={() => navigate(`${MYPAGE_PATHS.EventListPage}/${liveScheduleId}`, { state: true })} />
        </div>
      </div>
      <TipsModal type="countdown" />
    </>
  )
}, { bg: BackroundInProgress, headerBg: HeaderAddition })

const DivBg = styled.div`
  @media (max-width: 375px) {
    width: 346px;
  }
`

const ImagePassport = styled.image`
  @media (max-width: 320px) {
    width: 200px;
    height: 60px;
    background-image: url(${IconPassport});
    background-repeat: no-repeat;
    background-size: contain !important;
  }
`

const TextModal = styled.div`
  @media (max-width: 375px) {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    font-size: 13px;
  }
`

const Container = styled.div`
  @media (max-width: 375px) {
    position: fixed;
    top: 150px;
  }

  @media (max-width: 320px) {

  }

  @media (min-width: 768px) {
    width: 400px;
  }
`

export { TopCountdown }
