import { Listbox } from '@headlessui/react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { MIXER_ITEM_AMOUNTS } from 'src/pages/main/capsule/CapsuleToyContainer'
import Item, { ITEM_RARITY } from 'src/models/Item'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import CheckmarkCheckbox from 'src/components/common/CheckmarkCheckbox'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'

const CapsuleMixerDrawModal = ({
  handleClose,
  handleConfirm,
  items,
  requiredItemAmount,
  selectedItems,
  setSelectedItems
}) => {
  const sortOptions = [
    { id: 0, name: 'LOVE度が高い順' },
    { id: 1, name: 'アイテム名順' }
  ]

  const [sortedItems, setSortedItems] = useState([])
  const [sort, setSort] = useState(sortOptions[0])

  const selectItem = (item) => {
    const list = [...selectedItems]
    const index = list.findIndex((el) => item.id === el.id)
    if (index > -1) {
      list.splice(index, 1)
    } else {
      if (list.length >= requiredItemAmount) return
      list.push(item)
    }
    setSelectedItems(list)
  }

  useEffect(() => {
    const filteredItems = items.filter((el) => {
      switch (requiredItemAmount) {
        case MIXER_ITEM_AMOUNTS.R20:
          return el.rarity === ITEM_RARITY.R
        case MIXER_ITEM_AMOUNTS.SR10:
          return el.rarity === ITEM_RARITY.SR
        case MIXER_ITEM_AMOUNTS.SSR5:
        default:
          return el.rarity === ITEM_RARITY.SSR
      }
    })

    if (sort.id === sortOptions[0].id) filteredItems.sort((a, b) => b.loveAmount - a.loveAmount)
    else filteredItems.sort((a, b) => a.name.localeCompare(b.name))

    setSortedItems(filteredItems)
  }, [items, sort])

  return (
    <div className="w-full min-h-[300px] m-4 py-8 px-4 bg-popup-l bg-fill bg-no-repeat rounded-md relative">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title="アイテム選択" className="mb-2" />
      <div className="w-full flex justify-between items-center font-vldLineGR text-xs">
        <span>
          {selectedItems.length}/{requiredItemAmount} 選択中
        </span>
        <Listbox value={sort} onChange={setSort}>
          <div className="relative">
            <Listbox.Button className="w-32 py-1 rounded-md border border-menu-dark-blue truncate">
              {sort.name}
            </Listbox.Button>
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-xs z-50">
              {sortOptions.map((opt) => (
                <Listbox.Option
                  key={opt.id}
                  value={opt}
                  className="relative cursor-default select-none p-2"
                >
                  <span className={clsx('block truncate')}>{opt.name}</span>
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </Listbox>
      </div>
      <ul className="grid grid-cols-4 gap-1 my-4">
        {sortedItems.map((item) => (
          <li className="relative" key={item.id}>
            <CheckmarkCheckbox
              id={`item${item.id}`}
              isChecked={selectedItems.findIndex((el) => el.id === item.id) > -1}
              onChange={() => selectItem(item)}
            />
            <label className="flex flex-col" htmlFor={`item${item.id}`}>
              <ItemThumbnail image={item.image} />
              <span className="font-vldLineGR text-[9px] text-center line-clamp-2">
                {item.name}
              </span>
            </label>
          </li>
        ))}
      </ul>
      <div className="mx-auto w-4/5 flex justify-between">
        <StandardizedButton
          className="w-28"
          img={BtnCancel}
          alt="キャンセル"
          onClick={handleClose}
        />
        <StandardizedButton
          className="w-28"
          img={BtnConfirm}
          alt="決定"
          onClick={() => handleConfirm(selectedItems)}
          disabled={selectedItems.length < requiredItemAmount}
        />
      </div>
    </div>
  )
}

CapsuleMixerDrawModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired,
  requiredItemAmount: PropTypes.number.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired,
  setSelectedItems: PropTypes.func.isRequired
}

export default CapsuleMixerDrawModal
