import EventsList from 'src/components/Event/EventsList'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'

const EventListContainer = () => {
  window.scrollTo(0, 0)

  return (
    <>
      <MenuBar title="event" back="/mypage" />
      <MainLayout active="menu" showHeader={false}>
        <EventsList />
      </MainLayout>
    </>
  )
}

export default EventListContainer
