import { HeaderHeart } from 'src/components/common/HeaderHeart'
import { PossessionHeart } from 'src/components/common/PossessionHeart'
import PropTypes from 'prop-types'
import ReturnButton from 'src/components/common/ReturnButton'

export const Header = ({ back }) => {
  return (
    <div className="flex items-center justify-between font-hiragino relative">
      <div className="w-[59px] h-[33px] cursor-pointer">
        <ReturnButton back={back} />
      </div>
      <div className="grid grid-cols-2 mr-6 w-[50%] absolute top-5 right-0">
        <HeaderHeart />
        <PossessionHeart />
      </div>
    </div>
  )
}

Header.propTypes = {
  back: PropTypes.string
}
