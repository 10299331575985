import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const postThreadFeedback = async (userId, inquiryId, contentId, message, optionFeedback) => {
  const formData = {
    user_contacts: {
      content_id_user: contentId,
      content: message,
      contact_kind: optionFeedback[0].id
    }
  }
  try {
    const res = await axiosInstance.post(
      `${API_ROUTES.Users}/${userId}/${API_ROUTES.Inquiries}/${inquiryId}/${API_ROUTES.SendContentFeedback}`,
      formData
    )
    return res.data
  } catch (error) {
    console.log(error)
  }
}

export { postThreadFeedback }
