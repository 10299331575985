import clsx from 'clsx'
import PropTypes from 'prop-types'
import { formatDate } from 'src/lib/date'
import styled from 'styled-components'
import BgLove from 'src/assets/img/gacha/bg_love.png'
import ReactPlayer from 'react-player'
import BtnbackFile from 'src/assets/img/ranking/icon-back.png'

import { useMemo, useState } from 'react'

export const ItemChatClient = ({ data }) => {
  const [openModal, setOpenModal] = useState(false)

  const attachmentUrl = data?.attachment_url

  const [checkFileVideo, checkFile] = useMemo(() => {
    if (!attachmentUrl) {
      return [false, false]
    }

    let newCheckFileVideo = true
    let newCheckFile = true

    if (attachmentUrl.includes('data:')) {
      if (attachmentUrl.includes('data:video')) {
        newCheckFile = false
      } else {
        newCheckFileVideo = false
      }
    } else {
      const regexImageExtensions = /(png|gif|jpg|heic|jpeg)$/
      const regexVideoExtensions = /(mp4|mov|wav)$/
      const extensionsData = attachmentUrl.split('.')
      const typeFile = extensionsData.pop().toLowerCase()
      if (regexImageExtensions.test(typeFile)) {
        newCheckFileVideo = false
      }
      if (regexVideoExtensions.test(typeFile)) {
        newCheckFile = false
      }
    }

    return [newCheckFileVideo, newCheckFile]
  }, [attachmentUrl])

  const handleOpenModal = () => {
    setOpenModal(true)
  }
  const handleClose = () => {
    setOpenModal(false)
  }

  return (
    <>
      <DivRespon>
        <DivResponseChat
          className={
            `${data?.item ? 'h-[110px]' : data?.description || data?.content ? '' : 'h-[200px]'}`
          }
        >
          <ChatClient />
          <div className="text-justify text-[#325282] text-xs font-normal whitespace-pre-line">
            {data?.description || data?.content}
          </div>
          {data?.item && (
            <div className="font-vldLineGR flex flex-col items-center">
              <ItemThumbnail
                image={data?.item.image}
                imgClassName="w-[70px] object-contain"
                className=""
                rarity
                loveAmount={data?.item.increase_love}
              />
              <div className="font-vldLineGR text-[10px]">{data?.item.name}</div>
              <div className="font-vldLineGR text-[10px]">x{data?.item.count || data?.item.amount}個</div>
            </div>
          )}
          {checkFile && (<img className="w-full h-full object-contain" src={data?.attachment_url} onClick={handleOpenModal} />)}
          {checkFileVideo && (<ReactPlayer controls width="100%" height="100%" url={data?.attachment_url} className="object-contain" />)}
        </DivResponseChat>
        <div className="pl-2 mr-2 text-[8px] font-light text-black flex flex-col justify-end z-10">
          <span>{formatDate(new Date(data?.created_at || data?.sent_datetime))}</span>
        </div>
      </DivRespon>

      {openModal && (
        <div className="">
          <div className="fixed top-[10%] left-[5%] z-[1001]" onClick={handleClose}>
            <img className="w-[30px]" src={BtnbackFile} />
          </div>
          <div className="sm:w-[360px] fixed top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center bg-[black] h-screen w-screen z-[1000]">
            <div className="block">
              {checkFile && (<img className="w-full h-full" src={data?.attachment_url} onClick={handleOpenModal}/>)}
              {checkFileVideo && (<div onClick={handleOpenModal}><ReactPlayer playsinline controls width="100%" height="100%" url={data?.attachment_url}/></div>)}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ItemChatClient.propTypes = {
  data: PropTypes.object.isRequired,
  isMe: PropTypes.bool
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount !== 0 && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${BgLove}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">LOVE度</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

const ChatClient = styled.div`
   {
    position: absolute;
    top: 10px;
    right: -18px;
    clip-path: polygon(100% 0, 0 49%, 100% 100%);
    width: 18px;
    height: 14px;
    z-index: 0;
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: 'FlipH';
  }

  ::before {
    position: absolute;
    content: '';
    top: 0.5px;
    left: -0.5px;
    width: 20px;
    height: 13px;
    background: #ff9bca;
    clip-path: polygon(100% 0, 0 49%, 100% 100%);
  }
`
const DivRespon = styled.div.attrs({
  className:
    'flex justify-end pr-5 w-full font-hiragino'
})`

  @media (max-width: 360px) {
    width: 90%;
  }
  @media (max-width: 320px) {
    width: 80%;
  }
`

const DivResponseChat = styled.div.attrs({
  className:
    'order-last rounded-lg relative bg-[#ff9bca] max-w-[270px] w-[200px] p-2 pl-[9px] pr-[10px] pt-[5px] z-10 break-words'
})`

  @media (max-width: 320px) {
    width : 150px
  }
`
