import { useInfiniteQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { getChannelInfo } from 'src/services/get/getChat'

const useConversation = (type = 'single_chat') => {
  const { characterId } = useParams()
  const queryClient = useQueryClient()
  const queryKey = ['useConversation', type, characterId]

  const fetchMessages = ({ pageParam }) => {
    if (!characterId) return

    const params = {
      character_id: characterId,
      room_type: type,
      cursor: pageParam
    }

    return getChannelInfo(params)
  }

  const addNewMessageToCache = (newMessage) => {
    queryClient.setQueryData(queryKey, data => {
      const newPages = [...data.pages]
      const firstPageIndex = newPages.findIndex(page => page.first_page)
      if (firstPageIndex >= 0) {
        newPages[firstPageIndex].messages.push(newMessage)
      }

      return {
        pages: newPages,
        pageParams: data.pageParams
      }
    })
  }
  const updateMessageToCache = (updatedMessage) => {
    queryClient.setQueryData(queryKey, data => {
      const newPages = [...data.pages]
      newPages.forEach(page => {
        const messageIndex = page.messages.findIndex(message => message.key === updatedMessage.key)
        if (messageIndex >= 0) {
          page.messages[messageIndex] = updatedMessage
        }
      })
      return {
        pages: newPages,
        pageParams: data.pageParams
      }
    })
  }

  return {
    ...useInfiniteQuery(
      queryKey,
      fetchMessages,
      {
        select: data => ({
          pages: [...data.pages].reverse(),
          pageParams: [...data.pageParams].reverse()
        }),
        getNextPageParam: (lastPage) => lastPage.cursor
      }
    ),
    addNewMessageToCache,
    updateMessageToCache
  }
}

export { useConversation }
