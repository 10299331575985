import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { StillAlbumContext } from 'src/contexts'
import { RecommendStillAlbumPage } from 'src/pages/myPage/Recommend/StillAlbum'
import { RecommendStillAlbumDetailPage } from 'src/pages/myPage/Recommend/StillAlbumDetail'
import { getStillAlbum } from 'src/services/get/getRecommend'

export const StillAlbumContainer = () => {
  const [stillAlbums, setStillAlbums] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const { characterId } = useParams()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getStillAlbum(characterId)
      if (res && res.length) {
        setStillAlbums(res.sort((a, b) => a.paramater - b.paramater))
      }
      setIsLoading(false)
    }
    fetchData()
  }, [])

  const location = useLocation()
  const { pathname } = location
  return (<StillAlbumContext.Provider value={{ stillAlbums, isLoading }}>
    {pathname.includes('detail') ? <RecommendStillAlbumDetailPage /> : <RecommendStillAlbumPage />}
  </StillAlbumContext.Provider>)
}
