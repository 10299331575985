// TODO:このAPIは、リリース前に作り直す必要があります。 現在、モックアップは、どのユーザーまたはどのキャラクターの設定が更新されているかを指定する方法を示していません。

import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Update a user's notification settings
 *
 * @param {number} character The ID of the user whose settings are being updated
 * @param {boolean} accept Whether or not to accept notifications
 * @return Returns true or false based on whether or not the request was successfull
 */
const putUpdateNotification = async (character, accept) =>
  axiosInstance.put(`${API_ROUTES.Notification.Update}/${character}`, {
    notification_setting: {
      line_notifi: accept
    }
  })

const putUpdateGlobalNotification = async (accept) =>
  axiosInstance.put(API_ROUTES.Notification.UpdateGlobal, {
    notification_setting: {
      line_notifi: accept
    }
  })

export { putUpdateNotification, putUpdateGlobalNotification }
