
import React from 'react'
import ShopBlock from 'src/pages/main/shop/components/ShopBlock'
import ShopExchangeHeart from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeHeart'
import { useHeartExchangeList } from 'src/pages/main/shop/hooks/useHeartExchangeQuery'

const ShopHeartTab = () => {
  const { data = [] } = useHeartExchangeList()

  return (
    <>
      <div className="grid items-center justify-center w-full grid-cols-2 gap-4">
        {data.map((heartExchangeConfig, index) => {
          const isValidConfig =
            Number.isInteger(heartExchangeConfig.number_of_red_diamonds) &&
            Number.isInteger(heartExchangeConfig.number_of_hearts_exchanged)

          if (!isValidConfig) return <React.Fragment key={index} />

          return (
            <ShopBlock isPink key={index}>
              <ShopExchangeHeart heartExchangeConfig={heartExchangeConfig} />
            </ShopBlock>
          )
        })}
      </div>
    </>
  )
}

export default ShopHeartTab
