import styled from 'styled-components'
import Loading from 'src/components/layouts/Loading'
import BGContent from 'src/assets/img/message/bg-content.png'
import { MessageItem } from 'src/components/message/Item'
import { useEffect, useState } from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useQuery } from 'react-query'
import getNews from 'src/services/get/getNews'
import { getChatList } from 'src/services/get/getChat'
import getCharacters from 'src/services/get/getCharacters'
import useConversationListChannel from 'src/services/common/useConversationListChannel'
import TipsModal from '../common/TipsModal'

const MessageContainer = () => {
  const news = useQuery('news', () => getNews(user.id, 'tips'))
  const { user } = useAuthState()
  const { message } = useConversationListChannel()

  const [messages, setMessages] = useState([])

  useEffect(() => {
    ;(async () => {
      const [channelList, characters] = await Promise.all([getChatList(user.id), getCharacters()])
      if (channelList) {
        const isPlayableCharacter = characters.filter((character) => character.playable)
        const filterChannelList = channelList.filter((channel) =>
          isPlayableCharacter.some((character) => character.id === channel.character.id)
        )
        const _channels = filterChannelList
          .filter((item) => {
            return item.character || (item.characters && item.characters.length)
          })
          .map((item) => {
            return {
              ...item,
              character: item.character || (item.characters || [])[0] || {},
              user: item.user || (item.users || [])[0] || {}
            }
          })

        let _characters = []
        if (isPlayableCharacter.length > 0) {
          _characters = isPlayableCharacter.map((item) => {
            return {
              channel_id: Date.now().toString(36) + Math.random().toString(36).substring(2),
              character: item
            }
          })
        }
        let channelsUniq = _characters.reduce(
          (acc, eachArr2Elem) => {
            if (
              _channels.findIndex(
                (eachArr1Elem) =>
                  eachArr1Elem?.character?.id === eachArr2Elem?.character?.id ||
                  eachArr1Elem?.character?.name === eachArr2Elem?.character?.name
              ) === -1
            ) {
              acc.push(eachArr2Elem)
            }
            return acc
          },
          [..._channels]
        )
        channelsUniq = channelsUniq.map((item) => {
          return {
            ...item,
            level: isPlayableCharacter.find((char) => char.id === item.character.id)?.level || {}
          }
        })
        setMessages(channelsUniq)
      }
    })()
  }, [])

  useEffect(() => {
    if (!message?.conversation_id) return

    const copyMessages = [...messages]
    const index = copyMessages.findIndex((msg) => msg.conversation_id === message.conversation_id)
    if (index >= 0) {
      copyMessages[index] = { ...copyMessages[index], ...message }
      setMessages(copyMessages)
    }
  }, [message])

  if (news.isLoading || messages.length === 0) return <Loading />

  if (news.data) {
    return (
      <Wrapper>
        {(messages || []).map((item, idx) => (
          <MessageItem idx={idx} key={idx} data={item} />
        ))}
        <TipsModal type="message" />
      </Wrapper>
    )
  }
}

export default MessageContainer

const Wrapper = styled.div.attrs({
  className: 'pl-5 pr-[18px] py-5 mb-[120px] h-fit'
})`
  background-image: url(${BGContent});
  background-size: 100% 100%;
`
