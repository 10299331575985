import PropTypes from 'prop-types'
import clsx from 'clsx'
import React from 'react'
import { useAuthState } from 'src/providers/AuthProviders'
import { useNavigate } from 'react-router-dom'
import { HeaderHeart } from 'src/components/common/HeaderHeart'
import { PossessionHeart } from 'src/components/common/PossessionHeart'

import diamonRed from '../../assets/img/common/diamond-red.png'
import diamonBlue from '../../assets/img/common/diamond-blue.png'
import ibg from '../../assets/img/common/bar-bg.png'
import add from '../../assets/img/common/add.png'

export const Header = ({ className = 'px-4 py-8' }) => {
  const { user } = useAuthState()
  const navigate = useNavigate()

  return (
    <div
      className={clsx(
        'w-screen sm:w-desktop-width fixed sm:left-1/2 sm:-translate-x-1/2 z-50 grid grid-cols-4 gap-2 content-center mx-auto',
        className
      )}
    >
      <HeaderHeart />
      <PossessionHeart />
      <div className="relative">
        <img src={ibg} className="w-full h-4" />
        <img src={diamonBlue} className="absolute w-6 -top-1 -left-1" />
        <div className="absolute top-0 bottom-0 left-0 right-0 grid content-center text-sm text-center text-white">
          {user?.blue_amount}
        </div>
      </div>
      <div className="relative z-0">
        <img src={ibg} className="w-full h-4" />
        <img src={diamonRed} className="absolute w-6 -top-1 -left-1" />
        <img
          src={add}
          onClick={() => navigate('/shop')}
          className="absolute right-2 top-[2px] w-3 cursor-pointer z-10"
        />
        <div className="absolute top-0 bottom-0 left-0 right-0 grid content-center text-sm text-center text-white">
          {user?.red_amount}
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string
}
