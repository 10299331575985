import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import mascotChara from '../assets/mascot_chara.png'
import btnClose from '../assets/btn_close.png'
import { ModalWithBg } from '../../../components/common/ModalWithBg'

const ModalPassport = ({ open, modalType, onClose }) => {
  const contentText = useMemo(() => {
    if (modalType === 'live-comment') {
      return ['コメントの送信', 'すぐにコメントの送信が出来るようになるョ！']
    } else if (modalType === 'live-extra-item') {
      return ['時間延長の砂の送信', '今日の配信を長く楽しめるョ！']
    } else {
      return ['アイテムをプレゼントする事', '彼にアイテムをプレゼント出来るようになるョ！']
    }
  }, [modalType])

  if (!open) return <></>

  return (
    <>
      <ModalWithBg
        title="機能セーブ中"
        open={open}
        onClose={onClose}
      >
        <div className="space-y-4 pb-[20px]">
          <p className="text-center text-[11px] whitespace-pre-line font-hiragino">
            プラチナパスポートをお持ちでない為、
            <p>{contentText[0]}が出来ないコケッ！</p>
          </p>
          <p className="text-center text-[11px] whitespace-pre-line font-hiragino">
            ショップから｢プラチナパスポート｣を
            <p>ご購入頂くと、｢LIVE配信｣で</p>
            <p>・コメントの送信(永久無料)</p>
            <p>・アイテムの送信</p>
            <p>が可能となり、視聴時間の制限も無くなるコケッ！</p>
          </p>
          <p className="text-center text-[11px] whitespace-pre-line font-hiragino">
            今からショップで購入した場合も、
            <p>{contentText[1]}</p>
          </p>
          <p className="text-right absolute right-10 z-0">
            <img className="inline w-[80px] h-[80px]" src={mascotChara} />
          </p>

          <div className="flex items-center justify-center relative">
            <img className="inline cursor-pointer w-[119px] h-[49px]" src={btnClose} onClick={onClose}/>
          </div>
        </div>
      </ModalWithBg>
    </>
  )
}

ModalPassport.propTypes = {
  open: PropTypes.bool,
  modalType: PropTypes.string.isRequired,
  onClose: PropTypes.func
}

export { ModalPassport }
