import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getRecommendMans = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Recommend.Man)
    if (res && res.data) return res.data
    return []
  } catch (error) {
    return []
  }
}

export const getStillAlbum = async (characterId, id) => {
  const URL = id
    ? `${API_ROUTES.Recommend.StillAlbum.replace('id', characterId)}/${id}`
    : API_ROUTES.Recommend.StillAlbum.replace('id', characterId)
  try {
    const res = await axiosInstance.get(URL)
    if (res && res.data) return res.data
    return []
  } catch (error) {
    return []
  }
}

export const getVoiceAlbum = async (id, type) => {
  const URL = API_ROUTES.Recommend.VoiceAlbum.replace('character_id', id)
  try {
    const res = await axiosInstance.get(URL, {
      params: {
        voice_type: type
      }
    })
    if (res && res.data.success) return res.data.items
    return []
  } catch (error) {
    return []
  }
}
