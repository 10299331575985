import PropTypes from 'prop-types'
import TitleHeader from 'src/components/common/TitleHeader'
import FadeTransition from 'src/components/common/transitions/FadeTransition'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import { Modal } from 'src/components/common/Modal'
import StandardizedButton from 'src/components/common/StandardizedButton'
import styled from 'styled-components'
import { useEffect, useState } from 'react'
import ModalItemDetail from './ModalItemDetail'
import { getListItemCharacter } from 'src/services/get/getListItemCharacter'
import BgLove from 'src/assets/img/gacha/bg_love.png'

import clsx from 'clsx'

const ModalListItem = ({ show, handleClose, characterId, handleSendMessageItem, setDataItem }) => {
  const [modalItemDetail, setModalItemDetail] = useState(null)
  const [modalItemOpen, setModalItemOpen] = useState(false)
  const [itemCharacter, setItemCharacter] = useState([])

  const TRANSITION_CLASS_NAME =
    'fixed w-full top-1/2 left-[42%] -translate-x-2/4 -translate-y-2/4 p-2 z-50'

  const handleModalItem = (item) => {
    setDataItem(item)
    setModalItemDetail(item)
    setModalItemOpen(true)
  }
  const [selectedOption, setSelectedOption] = useState('rarity_desc')

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handleCloseModalDetail = () => {
    setModalItemOpen(false)
  }

  useEffect(() => {
    getListItemCharacter(characterId, selectedOption).then((res) => setItemCharacter(res.items))
  }, [selectedOption])

  return (
    <>
      <Modal
        focusOutside={false}
        open={show}
        onClose={handleClose}
        className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
      >
        <FadeTransition show={show} className={TRANSITION_CLASS_NAME}>
          <div className="w-[95%] mx-10 px-4 pt-8 pb-10 bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center reletive">
            <div className="reletive">
              <TitleHeader title="アイテム選択" className="mb-4" />
              <SelectOption
                className="absolute right-[20px] top-[14%] bg-white border-indigo-500/50 text-center font-vldLineGR text-xs mt-1"
                value={selectedOption}
                onChange={handleSelectChange}
              >
                <option className="text-center" value="rarity_desc">
                  レア度が高い順
                </option>
                <option className="text-center" value="rarity_asc">
                  レア度が低い順
                </option>
                <option value="date_newest" className="text-center">
                  入手日新しい順
                </option>
                <option className="text-center" value="date_oldest">
                  入手日古い順
                </option>
              </SelectOption>
            </div>
            <div className="overflow-y-scroll w-full h-[300px] mt-6">
              <div className="grid grid-cols-3 gap-2 w-full">
                {itemCharacter.map((item) => {
                  return (
                    <>
                      <ItemDetails
                        image={item.image}
                        className=""
                        imgClassName="w-[70px]"
                        loveAmount={item.increase_love}
                        name={item.name}
                        rarity
                        onClick={() => {
                          handleModalItem(item)
                        }}
                      />
                    </>
                  )
                })}
              </div>
            </div>
            <div className="flex justify-between mt-5">
              <StandardizedButton
                className="w-28"
                img={BtnCancel}
                alt="キャンセル"
                onClick={handleClose}
              />
            </div>
          </div>
        </FadeTransition>
      </Modal>
      <Modal
        open={modalItemOpen}
        onClose={handleCloseModalDetail}
        className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left transform transition-all sm:max-w-lg font-hiragino flex items-center justify-center"
      >
        {modalItemOpen ? (
          <ModalItemDetail
            item={modalItemDetail}
            handleCloseModalDetail={handleCloseModalDetail}
            handleSendMessageItem={handleSendMessageItem}
            handleClose={handleClose}
          />
        ) : null}
      </Modal>
    </>
  )
}

ModalListItem.propTypes = {
  show: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  setOpenModal: PropTypes.func,
  characterId: PropTypes.number,
  handleSendMessageItem: PropTypes.func,
  setDataItem: PropTypes.func
}

const ItemDetails = ({ image, name, rate, loveAmount, onClick }) => {
  return (
    <div className="font-vldLineGR flex flex-col items-center" onClick={onClick}>
      <ItemThumbnail
        image={image}
        className=""
        imgClassName="w-[70px]"
        loveAmount={loveAmount}
        rarity
      />
      <div className="font-vldLineGR pt-1 text-[9px] text-center line-clamp-2 text-ellipsis">
        {name}
      </div>
    </div>
  )
}

const ItemThumbnail = ({
  image,
  rarity,
  loveAmount,
  className = 'w-[72px] h-[80px] border-menu-dark-blue rounded-sm',
  imgClassName = 'w-[60px]'
}) => {
  return (
    <div
      className={clsx(
        'flex justify-center items-center relative',
        className,
        !rarity && 'border-2 drop-shadow-md bg-white'
      )}
    >
      <img className={clsx(imgClassName)} src={image} />
      {loveAmount !== 0 && (
        <div
          className="text-white truncate absolute right-0 bottom-1 w-[56px] h-[18px] flex justify-center items-center"
          style={{ backgroundImage: `url('${BgLove}')`, backgroundSize: '100% 100%' }}
        >
          <div className="leading-3">
            <span className="text-[6px] pl-1">LOVE度</span>
            <span className="text-[9px]">{loveAmount}</span>
          </div>
        </div>
      )}
    </div>
  )
}

ItemThumbnail.propTypes = {
  image: PropTypes.string.isRequired,
  rarity: PropTypes.bool,
  loveAmount: PropTypes.number,
  className: PropTypes.string,
  imgClassName: PropTypes.string
}

ItemDetails.propTypes = {
  image: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  rate: PropTypes.number,
  loveAmount: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ModalListItem

const SelectOption = styled.select`
  border: 1px solid #c1e2ff;
  padding: 5px;
  border-radius: 5px;
`
