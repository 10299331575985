/* eslint-disable camelcase */
import React from 'react'
import { useAuthState } from 'src/providers/AuthProviders'

import life from 'src/assets/img/header-heart/life.png'
import lifeLock from 'src/assets/img/header-heart/life-lock.png'
import life_0 from 'src/assets/img/header-heart/heart_all_gray.png'
import life_1 from 'src/assets/img/header-heart/heart_quarter_gray.png'
import life_2 from 'src/assets/img/header-heart/heart_half_gray.png'
import life_3 from 'src/assets/img/header-heart/heart_three_gray.png'

export const HeaderHeart = () => {
  const { user } = useAuthState()
  const {
    dream_compass: dreamCompass,
    platinum_passport: platinumPassport,
    possession_heart_recovery_or_maximum: possessionHeartRecoveryOrMaximum
  } = user
  const imgArray = [life_0, life_1, life_2, life_3, life, lifeLock]
  const normalLife = true
  const certArray = [normalLife, platinumPassport, dreamCompass]
  const mod = possessionHeartRecoveryOrMaximum % 4
  let heartRecovery = possessionHeartRecoveryOrMaximum
  const lifeHeartArray = [{ lifeHeart: life }, { lifeHeart: lifeLock }, { lifeHeart: lifeLock }]

  const setUpLifeHeart = (index, mod) => {
    lifeHeartArray[index].lifeHeart = imgArray[mod]
  }

  const setLife = () => {
    for (let index = lifeHeartArray.length - 1; index >= 0; index--) {
      if (heartRecovery >= 4 && certArray[index]) {
        setUpLifeHeart(index, 4)
        heartRecovery -= 4
      } else if (heartRecovery < 4 && heartRecovery >= 0 && certArray[index]) {
        setUpLifeHeart(index, mod)
        heartRecovery -= 4
      } else {
        setUpLifeHeart(index, 0)
      }
      if (!certArray[index]) {
        setUpLifeHeart(index, 5)
      }
    }
  }

  setLife()

  return (
    <div className="flex h-4 w-20 bg-[rgba(0,0,0,0.1)] justify-between rounded-md">
      <img src={lifeHeartArray[0].lifeHeart} className="w-6 -my-1" />
      <img src={lifeHeartArray[2].lifeHeart} className="w-6 -my-1" />
      <img src={lifeHeartArray[1].lifeHeart} className="w-6 -my-1" />
    </div>
  )
}
