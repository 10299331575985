export const addRank = (items) => {
  let beforeRank
  const addRanking = (i) => i + 1
  return items.map((item, i) => {
    const before = items[i - 1]
    if (before) {
      if (before.totalReact === item.totalReact) {
        item.rank = beforeRank
      } else {
        item.rank = addRanking(i)
        beforeRank = addRanking(i)
      }
    } else {
      item.rank = addRanking(i)
      beforeRank = addRanking(i)
    }
    return item
  })
}
