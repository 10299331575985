export default class Present {
  constructor(
    id,
    boxMessage,
    amount,
    image,
    expiredAt,
    isReceived,
    presentType,
    receivedAt,
    title
  ) {
    this.id = id
    this.boxMessage = boxMessage
    this.amount = amount
    this.image = image
    this.expiredAt = expiredAt
    this.isReceived = isReceived
    this.presentType = presentType
    this.receivedAt = receivedAt
    this.title = title
  }
}
