import styled from 'styled-components'
import PropTypes from 'prop-types'

import BGBtnLeft from 'src/assets/img/dressup/bg_btn_left.png'
import BGBtnRight from 'src/assets/img/dressup/bg_btn_right.png'

const ButtonAbsolute = ({ position, onClick, children, className }) => {
  return (
    <ButtonAbsoluteWrapper background={position === 'left' ? BGBtnLeft : BGBtnRight} className={`${className + ' '} ${position === 'left' ? 'left-0' : 'right-0'}`} onClick={onClick}>
      {children}
    </ButtonAbsoluteWrapper>
  )
}

export default ButtonAbsolute

ButtonAbsolute.propTypes = {
  position: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string
}

const ButtonAbsoluteWrapper = styled.div.attrs({
  className: 'absolute'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`
