import React from 'react'
import PropTypes from 'prop-types'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import ToggleSwitch from 'src/components/common/ToggleSwitch'
import { CHARACTER_LINE_LINKS, CIRCLE_IMAGE_OF_CHARACTER } from 'src/constants/characters'
import NotificationSetting from 'src/models/NotificationSetting'
import IconManagerNew from 'src/assets/img/menu/icon_manager_new.png'

const AccountPage = ({
  toggleAccept,
  isLoading,
  characterNotifications,
  globalSetting,
  toggleGlobalSetting
}) => {
  return (
    <>
      <MenuBar title="account" />
      <MainLayout active="menu" showHeader={false}>
        <MenuWrapper className="flex flex-col items-center justify-center gap-2">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {' '}
              <NotifcationSettingBar
                name="お知らせ"
                img={IconManagerNew}
                isLineFollow
                accept={globalSetting?.isNotification}
                setAccept={() => toggleGlobalSetting(!globalSetting?.isNotification)}
              />
              {characterNotifications?.map((character, index) => {
                if (character.navigator) return false
                return (
                  <NotifcationSettingBar
                    name={character.name}
                    img={CIRCLE_IMAGE_OF_CHARACTER[character.id]}
                    isLineFollow={character.isLineFollow}
                    lineUrl={CHARACTER_LINE_LINKS[character.id]}
                    accept={character.isNotification}
                    setAccept={() => {
                      toggleAccept({ id: character.id, isNotification: !character.isNotification })
                    }}
                    key={index}
                  />
                )
              })}
            </>
          )}
        </MenuWrapper>
      </MainLayout>
    </>
  )
}

AccountPage.propTypes = {
  toggleAccept: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  characterNotifications: PropTypes.arrayOf(PropTypes.instanceOf(NotificationSetting)),
  toggleGlobalSetting: PropTypes.func,
  globalSetting: PropTypes.object
}

export default AccountPage

const NotifcationSettingBar = ({ name, img, lineUrl, isLineFollow, accept, setAccept }) => {
  return (
    <div className="flex items-center justify-between w-full h-16 px-4 py-4 overflow-hidden text-xs bg-white rounded font-hiragino whitespace-nowrap cursor-pointer">
      <img className="w-12 h-12 rounded-full" src={img} alt={name} />
      <span className="w-16">{name}</span>
      {
        isLineFollow ? <span
          className='w-32 bg-dark-600 p-2 drop-shadow-md rounded text-white text-ellipsis overflow-hidden'
        >
          LINE友だち追加済
        </span> : <a
          href={lineUrl}
          target="_blank"
          rel="noreferrer"
          className='w-32 bg-line-green p-2 drop-shadow-md rounded text-white text-ellipsis overflow-hidden'
        >
          LINE友だち追加
        </a>
      }
      <ToggleSwitch checked={accept} onChange={setAccept} srLabel={name} />
    </div>
  )
}

NotifcationSettingBar.propTypes = {
  name: PropTypes.string.isRequired,
  img: PropTypes.any.isRequired,
  setAccept: PropTypes.func.isRequired,
  lineUrl: PropTypes.string.isRequired,
  isLineFollow: PropTypes.bool,
  accept: PropTypes.bool
}
