import { Carousel } from 'react-responsive-carousel'
import PropTypes from 'prop-types'
import useMultiAudio from 'src/components/common/UseMultiAudio'
import { useCallback, useEffect } from 'react'

import BtnBefore from 'src/assets/img/history/btn_wing_before.png'
import BtnNext from 'src/assets/img/history/btn_wing_next.png'
import styled from 'styled-components'

const MangaContents = ({
  data = [],
  allPageOfManga = 0,
  currPage,
  setCurrPage,
  limitPageNumber,
  isOpen,
  toggleModal
}) => {
  const [players, toggle] = useMultiAudio(data.map((item) => item?.audio?.url || ''))

  useEffect(() => {
    players.forEach((player, idx) => {
      if (player.playing) {
        const togglePlayer = toggle(idx)
        togglePlayer()
      }
    })
  }, [currPage])

  const handleTap = useCallback((e, callback) => {
    e.preventDefault()
    callback && callback()
  }, [])

  const renderArrowNext = useCallback(
    (clickHandler, hasPrev) => {
      const handleClick = () => {
        if (limitPageNumber === currPage) return toggleModal()
        if (currPage < data.length) setCurrPage(currPage + 1)
        setTimeout(() => {
          clickHandler()
        }, 100)
      }
      return (
        <>
          {hasPrev ? (
            <button
              onClick={(e) => handleTap(e, handleClick)}
              className="cursor-pointer w-10 h-9 absolute bottom-0 right-0 z-10"
              style={{
                bottom: '-60px',
                left: 'calc(50% + 106px)'
              }}
            >
              <img className="w-full h-full" src={BtnNext} alt="next" />
            </button>
          ) : null}
        </>
      )
    },
    [currPage, isOpen]
  )

  const renderArrowPrev = useCallback(
    (clickHandler, hasNext) => {
      const handleClick = () => {
        if (currPage !== 0) setCurrPage(currPage - 1)
        setTimeout(() => {
          clickHandler()
        }, 100)
      }
      return (
        <>
          {hasNext ? (
            <button
              onClick={(e) => handleTap(e, handleClick)}
              className="cursor-pointer w-10 h-9 absolute bottom-0 z-10"
              style={{
                bottom: '-60px',
                right: 'calc(50% + 106px)'
              }}
            >
              <img className="w-full h-full" src={BtnBefore} alt="before" />
            </button>
          ) : null}
        </>
      )
    },
    [currPage]
  )

  return (
    <>
      <div className="font-vldLineGR text-[9px] text-[#000000] text-left leading-[11px] ml-[3px] mb-[3px]">
        この漫画はBGMが流れます。音量にご注意ください。
      </div>
      <Content>
        <div className="absolute top-0 left-0 h-full w-full z-50" />
        <Carousel
          autoPlay={false}
          infiniteLoop={false}
          showThumbs={false}
          showStatus={false}
          showArrows={true}
          showIndicators={false}
          renderArrowPrev={renderArrowPrev}
          renderArrowNext={renderArrowNext}
          className="w-full h-full"
        >
          {data.map((item, idx) => {
            if (currPage + 1 < item.page) return false
            return (
              <div key={idx} className="w-full h-full">
                <img src={item.manga_url} className="w-full h-full" />
              </div>
            )
          })}
        </Carousel>
      </Content>
      <div className="font-vldLineGR text-[9px] text-[#000000] text-right leading-[11px] mt-[2px] ml-[3px] mb-[3px] mr-[5px]">
        {allPageOfManga ? currPage : 0}/{allPageOfManga} page
      </div>
    </>
  )
}

MangaContents.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  allPageOfManga: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func,
  limitPageNumber: PropTypes.number,
  isOpen: PropTypes.bool,
  toggleModal: PropTypes.func
}

export default MangaContents

const Content = styled.div.attrs({
  className: 'md:w-desktop-width w-screen mx-auto relative'
})`
  height: calc(100vh - 170px);
`
