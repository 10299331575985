import React, { useMemo, useState } from 'react'
import { authActionTypes, useAuthDispatch } from '../../../../providers/AuthProviders'
import { useAuthState } from 'src/providers/AuthProviders'
import { LiveModal } from '../../components/LiveModal'
import { LoveItem } from './_LoveItem'
import LiveItemAddition from '../../assets/live_item_addition.png'
import { useDuringContext } from './_Provider'
import _ from 'lodash'
import { postLiveTime } from '../../../../services/post/postLiveTime'
import { InsufficientDiamondModal } from './_InsufficientDiamondModal'
import PropTypes from 'prop-types'
import { ModalPassport } from '../../components/ModalPassport'

const LiveItemModal = ({ setIsMultiLoveModalOpen, setMultiLevel, normalPassportModal, canWorkWithFreePassport }) => {
  const authDispatch = useAuthDispatch()
  const { user } = useAuthState()
  const [open, setOpen] = useState(false)
  const [insufficientDiamond, setInsufficientDiamond] = useState(false)
  const [openModalPassport, setOpenModalPassport] = useState(false)

  const onClose = () => {
    document.getElementById('hourglass-icon').className = 'relative z-[9]'
    setOpen(false)
  }

  const { liveItemsSocket, liveInfoQuery, channelId } = useDuringContext()
  const liveItems = useMemo(() => {
    if (!liveItemsSocket.activeItemData.changed) {
      return _.get(liveInfoQuery, 'data.live_items') || []
    }

    return liveItemsSocket.activeItemData.items
  }, [liveItemsSocket.activeItemData, liveInfoQuery])

  const openLiveModal = () => {
    document.getElementById('hourglass-icon').className = 'relative z-[8]'
    setOpen(true)
  }
  const clickLiveItem = (liveItem) => {
    if (!normalPassportModal && !canWorkWithFreePassport) {
      setOpenModalPassport(true)
    } else {
      sendItem(liveItem)
    }
  }
  const sendItem = async (liveItem) => {
    if (user.red_amount < _.get(liveItem, 'number_diamond')) {
      onClose()
      setInsufficientDiamond(true)
    } else {
      try {
        onClose()
        const { item, currentLevel, nextLevel } = await postLiveTime({
          livestream_id: channelId,
          live_item_id: _.get(liveItem, 'id'),
          amount: 1
        })
        authDispatch({
          type: authActionTypes.SPEND_DIAMOND_RED,
          payload: _.get(liveItem, 'number_diamond')
        })
        if (!item && (!currentLevel || !nextLevel)) return
        setMultiLevel({
          currentLevel: currentLevel,
          nextLevel: nextLevel
        })
        setIsMultiLoveModalOpen(true)
      } catch {}
    }
  }

  return (
    <div className="relative z-[9]" id="chicken-icon">
      <img src={LiveItemAddition} className="w-[54px] h-[57px]" onClick={() => openLiveModal()} />
      <LiveModal open={open} onClose={onClose}>
        <p className="text-white text-[12px] text-left ml-4 whitespace-pre-line">
          {
            'アイテムを送ると、名前が表示されてカレに覚えてもらいやすくなるョ！また、大花火とプリンスキャッスルは、その他のアイテムより強調表示されるので、より一層覚えて貰えるコケ♪'
          }
        </p>

        <div className="grid grid-cols-2">
          {liveItems.map((liveItem, index) => (
            <LoveItem item={liveItem} onClick={() => clickLiveItem(liveItem)} key={index} />
          ))}
        </div>
      </LiveModal>

      <ModalPassport open={openModalPassport} modalType="live-item" onClose={() => setOpenModalPassport(false)} />
      <InsufficientDiamondModal open={insufficientDiamond} onClose={() => setInsufficientDiamond(false)} />
    </div>
  )
}

LiveItemModal.propTypes = {
  setIsMultiLoveModalOpen: PropTypes.func,
  setMultiLevel: PropTypes.func,
  normalPassportModal: PropTypes.bool,
  canWorkWithFreePassport: PropTypes.bool
}

export { LiveItemModal }
