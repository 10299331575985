import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useAuthState } from 'src/providers/AuthProviders'
import getFirstStories from 'src/services/get/getFirstStories'

const useFetchFirstStory = () => {
  const { user } = useAuthState()
  const { characterId } = useParams()

  return useQuery({
    queryKey: ['useFirstStory', user?.id, characterId],
    queryFn: getFirstStories
  })
}

export { useFetchFirstStory }
