import React from 'react'
import { ModalWithBg } from '../../../components/common/ModalWithBg'
import PropTypes from 'prop-types'
import ButtonOk from '../../../assets/img/common/btn_ok.png'
import MascotChara from 'src/assets/img/mini_game/mascot_chara.png'
import styled from 'styled-components'

const ModalSpecialEdition = ({ open, onClose }) => {
  if (!open) return <></>

  return (
    <Container>
      <ModalWithBg title="Special Edition" open={open} onClose={onClose}>
        <div className='font-vldLineGR text-[14px]'>
          <p>この配信枠では、『D×6』全員での配信や </p>
          <p>『アイドルプリンスプロダクション』に所属しているタレントが登場するコケッ！</p>
          <p>
            この配信枠に入場して『時間延長の砂』や
            <br />『アイテム』を使用した場合、
            <br />『使用時の上昇LOVE度』は、
            <br />『推しメン』に設定しているD×6のメンバーに付与されるコケッ！
          </p>
        </div>
        <p className="text-right absolute right-[12px] z-0">
          <img className="inline w-[80px] h-[68px]" src={MascotChara} />
        </p>
        <div className="flex items-center justify-center relative mt-[40px]">
          <img
            className="inline cursor-pointer w-[119px] h-[49px]"
            src={ButtonOk}
            onClick={onClose}
          />
        </div>
      </ModalWithBg>
    </Container>
  )
}

ModalSpecialEdition.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

const Container = styled.div`
  @media (max-width: 375px) {
    font-size: 12px;
  }
`

export { ModalSpecialEdition }
