import PropTypes from 'prop-types'

export function ErrorMessage({ errors, field }) {
  const list = errors[field]

  return (
    <div className='mt-2'>
      { list
        ? list.map ? (
          <ul>
            {list.map((v, i) => {
              return (
                <li key={i} className="text-orange-700 font-bold text-[11px]">
                  {v}
                </li>
              )
            })}
          </ul>
        )
          : <p className="text-left text-orange-700 font-bold text-[11px]">{list === 'TypeError' ? 'エラー： "何か問題がありました。後でもう一度やり直してください」' : list}</p>
        : null}
    </div>

  )
}

ErrorMessage.propTypes = {
  field: PropTypes.string,
  errors: PropTypes.object
}
