import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

const EventSlideBanner = (props) => {
  const { events } = props
  const navigate = useNavigate()

  const getLinkTo = useMemo(
    () => (event) => event.displayLink ? `/${event.link}` : `/events/${event.id}`
  )

  return (
    <CarouselContainer>
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        showStatus={false}
        showArrows={false}
        selectedItem={events.slice(0, 5).length}
      >
        {events.slice(0, 5).map((event) => (
          <div
            onClick={() => navigate(getLinkTo(event))}
            key={event.id}
            className="h-[5rem] overflow-hidden"
          >
            <img className="xs:h-[100%] h-[85%] xs:object-center" src={event.image} />
          </div>
        ))}
      </Carousel>
    </CarouselContainer>
  )
}

EventSlideBanner.propTypes = {
  events: PropTypes.array.isRequired
}

export default EventSlideBanner

const CarouselContainer = styled.div.attrs({
  className: 'carousel-wrapper cursor-pointer xs:w-[180px] w-160px'
})`
  transform: skew(0, 5deg);
`
