import axios from 'axios'
import { getAccessToken } from 'src/services/common/storage'
import { searchUserAgent } from 'src/constants/userAgent'

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
})

axiosInstance.interceptors.request.use(
  ($config) => {
    const token = getAccessToken()

    if (token && token.length && token !== 'undefined') {
      $config.headers.Authorization = `Bearer ${token}`
    }
    $config.headers['Content-Type'] = 'application/json'
    $config.headers.Accept = 'application/json'
    $config.headers.device = searchUserAgent()
    return $config
  },
  (error) => {
    Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error.response?.data?.error)
  }
)

export default axiosInstance
