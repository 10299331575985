import PropTypes from 'prop-types'
import ReturnButton from 'src/components/common/ReturnButton'

import HeaderMenu from 'src/assets/img/common/header_menu.png'
import HeaderNews from 'src/assets/img/common/header_news.png'
import HeaderSetting from 'src/assets/img/common/header_setting.png'
import HeaderHelp from 'src/assets/img/common/header_help.png'
import HeaderItem from 'src/assets/img/menu/header_item.png'
import HeaderEvent from 'src/assets/img/menu/header_event.png'
import HeaderContact from 'src/assets/img/menu/header_contact.png'
import HeaderVoice from 'src/assets/img/voicealbum/header_voicealbum.png'
import HeaderVoiceAlbumAsahi from 'src/assets/img/voicealbum/header_voicealbum_asahi.png'
import HeaderVoiceAlbumKazehaya from 'src/assets/img/voicealbum/header_voicealbum_kazehaya.png'
import HeaderVoiceAlbumTachibana from 'src/assets/img/voicealbum/header_voicealbum_tachibana.png'
import HeaderVoiceAlbumWakatsuki from 'src/assets/img/voicealbum/header_voicealbum_wakatsuki.png'
import HeaderVoiceAlbumYokohama from 'src/assets/img/voicealbum/header_voicealbum_yokohama.png'
import HeaderStillAlbumAsahi from 'src/assets/img/common/header_album_asahi.png'
import HeaderStillAlbumKazehaya from 'src/assets/img/common/header_album_kazehaya.png'
import HeaderStillAlbumTachibana from 'src/assets/img/common/header_album_tachibana.png'
import HeaderStillAlbumWakatsuki from 'src/assets/img/common/header_album_wakatsuki.png'
import HeaderStillAlbumYokohama from 'src/assets/img/common/header_album_yokohama.png'
import HeaderGacha from 'src/assets/img/gacha/header_gacha.png'
import HeaderPresent from 'src/assets/img/menu/header_presentbox.png'
import HeaderUserProfile from 'src/assets/img/menu/header_id_name.png'
import HeaderKiyaku from 'src/assets/img/menu/header_kiyaku.png'
import HeaderPrivacy from 'src/assets/img/menu/header_privacy.png'
import HeaderTokusho from 'src/assets/img/menu/header_tokusho.png'
import HeaderHistoryAsahi from 'src/assets/img/history/header_history_asahi.png'
import HeaderHistoryKazehaya from 'src/assets/img/history/header_history_kazehaya.png'
import HeaderHistoryTachibana from 'src/assets/img/history/header_history_tachibana.png'
import HeaderHistoryWakatsuki from 'src/assets/img/history/header_history_wakatsuki.png'
import HeaderHistoryYokohama from 'src/assets/img/history/header_history_yokohama.png'
import HeaderDressup from 'src/assets/img/dressup/header.png'
import HeaderMyFavorite from 'src/assets/img/menu/header_myfavotite.png'
import HeaderProfile from 'src/assets/img/mypage/recommend/profile/header_profile.png'
import HeaderShop from 'src/assets/img/shop/header_shop.png'
import HeaderMessage from 'src/assets/img/message/bg-header-right.png'
import HeaderRealTalk from 'src/assets/img/menu/header_realtalk.png'
import BgSetting from 'src/assets/img/realtalk/bg_setting_back.png'
import BgSettingPreview from 'src/assets/img/realtalk/bg_setting.png'

const MenuBar = ({ title, back, showReturnButton = true, onBack }) => {
  const determineHeaderImage = () => {
    switch (title) {
      case 'inquiry':
        return HeaderContact
      case 'event':
        return HeaderEvent
      case 'news':
        return HeaderNews
      case 'account':
        return HeaderSetting
      case 'help':
        return HeaderHelp
      case 'voice-album-':
        return HeaderVoice
      case 'voice-album-朝日空':
        return HeaderVoiceAlbumAsahi
      case 'voice-album-風早翔':
        return HeaderVoiceAlbumKazehaya
      case 'voice-album-立花駿':
        return HeaderVoiceAlbumTachibana
      case 'voice-album-若槻蓮':
        return HeaderVoiceAlbumWakatsuki
      case 'voice-album-横浜湊':
        return HeaderVoiceAlbumYokohama
      case 'item':
        return HeaderItem
      case 'still-album-noname':
      case 'still-album-朝日空':
        return HeaderStillAlbumAsahi
      case 'still-album-風早翔':
        return HeaderStillAlbumKazehaya
      case 'still-album-立花駿':
        return HeaderStillAlbumTachibana
      case 'still-album-若槻蓮':
        return HeaderStillAlbumWakatsuki
      case 'still-album-横浜湊':
        return HeaderStillAlbumYokohama
      case 'history-noname':
      case 'history-朝日空':
        return HeaderHistoryAsahi
      case 'history-風早翔':
        return HeaderHistoryKazehaya
      case 'history-立花駿':
        return HeaderHistoryTachibana
      case 'history-若槻蓮':
        return HeaderHistoryWakatsuki
      case 'history-横浜湊':
        return HeaderHistoryYokohama
      case 'gacha':
        return HeaderGacha
      case 'present':
        return HeaderPresent
      case 'user_profile':
        return HeaderUserProfile
      case 'terms_of_service':
        return HeaderKiyaku
      case 'privacy_policy':
        return HeaderPrivacy
      case 'specified_commercial':
        return HeaderTokusho
      case 'dressup':
        return HeaderDressup
      case 'recommend':
        return HeaderMyFavorite
      case 'profile':
        return HeaderProfile
      case 'shop':
        return HeaderShop
      case 'message':
        return HeaderMessage
      case 'realtalk':
        return HeaderRealTalk
      case 'menu':
        return HeaderMenu
      case 'background_setting':
        return BgSetting
      case 'background_setting_preview':
        return BgSettingPreview
      default:
        return HeaderMenu
    }
  }

  return (
    <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed left-1/2 -translate-x-1/2 z-[51]">
      {showReturnButton ? <ReturnButton back={back} onBack={onBack} /> : null}
      <img src={determineHeaderImage()} className="absolute right-0 w-2/5" alt={title} />
    </div>
  )
}

MenuBar.propTypes = {
  title: PropTypes.string.isRequired,
  back: PropTypes.string,
  showReturnButton: PropTypes.bool,
  onBack: PropTypes.func
}

export default MenuBar
