import PropTypes from 'prop-types'
import { Link, useNavigate } from 'react-router-dom'
import NotificationIcon from 'src/components/common/NotificationIcon'

const MenuItem = ({
  label,
  src,
  to,
  from,
  isExternal = false,
  showNotice = false,
  isOpeningStory = false
}) => {
  const navigate = useNavigate()

  if (isExternal) {
    return (
      <a href={to} target="_blank" rel="noreferrer">
        <img src={src} alt={label} />
      </a>
    )
  } else if (isOpeningStory) {
    return (
      <div className="relative cursor-pointer" onClick={() => navigate(to, { state: { from } })}>
        <img src={src} alt={label} />
      </div>
    )
  } else {
    return (
      <Link to={to} className="relative">
        <img src={src} alt={label} />
        {showNotice && <NotificationIcon className="-top-2 -right-2" />}
      </Link>
    )
  }
}

MenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  from: PropTypes.string,
  isExternal: PropTypes.bool,
  showNotice: PropTypes.bool,
  isOpeningStory: PropTypes.bool
}

export default MenuItem
