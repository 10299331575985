import React, { useCallback, useEffect, useState } from 'react'
import { withVoiceFullLayout } from '../../hocs/withVoiceLayout'
import btnLiveAbsence from '../../assets/btn_live_absence.png'
import btnLiveEnd from '../../assets/btn_live_end.png'
import btnLiveEnter from '../../assets/btn_live_enter.png'
import flagLive from '../../assets/flag_live.png'
import HeaderStream from '../../assets/header_addition.png'
import mascotChara from '../../assets/mascot_chara.png'
import btnCancel from '../../assets/btn_cancel.png'
import btnClose from '../../assets/btn_close.png'
import btnContinueExtra from '../../assets/btn_continue_extra.png'
import btnCancelContinue from '../../assets/btn_cancel_continue.png'
import soraDefaultBg from '../../assets/sora_default.png'
import minatoDefaultBg from '../../assets/minato_default.png'
import renDefaultBg from '../../assets/ren_default.png'
import shoDefaultBg from '../../assets/sho_default.png'
import shunDefaultBg from '../../assets/shun_default.png'
import guest1DefaultBg from '../../assets/guest1_default.png'
import guest2DefaultBg from '../../assets/guest2_default.png'
import exTraTimeBlocking from 'src/pages/voiceLive/assets/extra_time_blocking.png'
import extratimeContinue from '../../assets/extratime_continue.png'
import CommonBg from '../../assets/common_bg.png'
import _ from 'lodash'
import clsx from 'clsx'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { VOICE_LIVE_PATH } from '../../../../routes/live'
import { LiveTime } from '../../components/LiveTime'
import StartExtraTime from '../../components/StartExtraTime'
import { useMutation, useQuery } from 'react-query'
import { getLiveListChannel } from 'src/services/get/getLiveListChannel'
import { useCharacterLiveContext } from './_Provider'
import { postUserLivestream } from '../../../../services/post/postUserLivestream'
import LiveLovePoint from './_LiveLovePoint'
import { postCountinueExtraTime } from 'src/services/post/postContinueExtraTime'
import { ModalPassportRejoin } from '../../components/ModalPassportRejoin'
import { ModalSpecialEdition } from '../../components/ModalSpecialEdition'
import Countdown from 'react-countdown'
import { formattedTimeToSecond } from 'src/lib/date'
import TitleHourglassRed from '../../assets/title_hourglass_red.png'
import { postLiveTime } from '../../../../services/post/postLiveTime'
import { WaitingTime } from '../../components/WaitingTime'
import btnWaiting from '../../assets/btn_waiting.png'
import BtnShop from 'src/assets/img/gacha/btn_shop.png'
import { MAIN_PATHS } from 'src/routes/main'
import { authActionTypes, useAuthState, useAuthDispatch } from 'src/providers/AuthProviders'
import TipsModal from 'src/components/common/TipsModal'
import ConditionModal from './_ConditionModal'
import { FIVE_MINS, TIME_CONTINUE_MODAL } from 'src/constants/livestream'
import iconHelp from '../../assets/btn_help.png'
import moment from 'moment'

const liveActions = {
  incomming: btnLiveEnter,
  completion: btnLiveEnd
}

const liveBgAbsence = {
  sora: soraDefaultBg,
  minato: minatoDefaultBg,
  ren: renDefaultBg,
  sho: shoDefaultBg,
  shun: shunDefaultBg,
  guest_1: guest1DefaultBg,
  guest_2: guest2DefaultBg
}

const CharacterSelection = withVoiceFullLayout(
  () => {
    const authDispatch = useAuthDispatch()
    const [open, setOpen] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [openModalContinue, setOpenModalContinue] = useState(false)
    const [openWaitingModal, setOpenWaitingModal] = useState(false)
    const [openModalPassportRejoin, setOpenModalPassportRejoin] = useState(false)
    const [modalType, setModalType] = useState('PLATINUM_PASSPORT')
    const [livestreamId, setLivestreamId] = useState('')
    const [freeMinutes, setFreeMinutes] = useState('')
    const [channelCurrent, setChannelCurrent] = useState()
    const navigate = useNavigate()
    const { setting_id: settingId } = useParams()
    const { user } = useAuthState()
    const isHasPassport = Boolean(_.get(user, 'platinum_passport'))
    const isHasDreamcompass = Boolean(_.get(user, 'dream_compass'))
    const { characterLiveSocket } = useCharacterLiveContext()
    const [sendModal, setSendModal] = useState(false)
    const { mutate: createUserLivestream } = useMutation({
      mutationFn: async (livestreamSettingId) => {
        await postUserLivestream(livestreamSettingId)
      },
      onSuccess: () => refetch()
    })
    const { data, remove, isFetched, refetch } = useQuery({
      queryFn: () => getLiveListChannel(settingId),
      queryKey: 'get-live-list-channel',
      enabled: Boolean(settingId)
    })
    const [modalSpecialEdition, setModalSpecialEdition] = useState(false)

    useEffect(() => {
      createUserLivestream(settingId)
    }, [settingId])

    useEffect(() => {
      if (characterLiveSocket.reFetch) {
        refetch()
        characterLiveSocket.setReFetch(false)
      }

      return () => {
        remove()
      }
    }, [characterLiveSocket.reFetch])

    const handleContinueExtra = (channelId) => {
      setSendModal(true)
    }

    const joinLivestream = (livestreamId) => {
      navigate(
        VOICE_LIVE_PATH.DuringDelivery.replace(':channel_id', livestreamId)
      )
    }

    const sendExtraContinueTime = async () => {
      if (user.red_amount >= 5) {
        const { item, flag, currentLevel, nextLevel } = await postLiveTime({
          livestream_id: channelCurrent.live_stream.id
        })
        if (item && currentLevel && nextLevel) {
          localStorage.setItem('item', JSON.stringify(item))
          localStorage.setItem('currentLevel', currentLevel)
          localStorage.setItem('nextLevel', nextLevel)
        } else {
          localStorage.setItem('item', '')
          localStorage.setItem('currentLevel', '')
          localStorage.setItem('nextLevel', '')
        }
        if (flag) {
          setSendModal(false)
          return
        }
        authDispatch({
          type: authActionTypes.SPEND_DIAMOND_RED,
          payload: 5
        })
        setSendModal(false)
        joinLivestream(_.get(channelCurrent, 'live_stream.id'))
      } else {
        setSendModal(false)
        setOpenModalContinue(true)
      }
    }

    const textModalOk =
      'この枠は限定配信だョ!\n' +
      'その為、条件を満たしてないユーザー様は\n' +
      '参加することが出来ないコケ･･･。\n\n' +
      '限定配信の参加条件や詳しい内容については\n' +
      'お知らせやイベントページを確認するコケ!\n' +
      '参加できるはずなのに参加できない場合や\n' +
      '参加条件が分からない場合は、ニワ丸こと\n' +
      'ぼくの所まで聞きに来て欲しいコケ!'
    const textModalNg =
      'あなたはLIVE配信に参加できないコケ・・・。\n' +
      '詳しくは、お知らせ内の \n' +
      '<a style="color:red" href="/news/detail/53">【重要】LIVE配信視聴時の注意事項について </a> \n' +
      'をご確認くださいだコケ。\n\n' +
      'LIVE配信視聴時の注意事項にも記載がある \n' +
      '通り、参加できない理由についてユーザー様 \n' +
      'からお問い合わせが合った際にも、\n' +
      'お答えすることはできないコケ。'

    const [textModal, setTextModal] = useState('')
    const onSelectItem = useCallback(
      (channel) => {
        if (_.get(channel, 'white_list').includes(_.get(user, 'id'))) {
          joinLivestream(_.get(channel, 'live_stream.id'))
          return
        } else if (_.get(channel, 'white_list').length > 0) {
          setOpenModal(true)
          setTextModal(textModalOk)
          return
        }

        if (_.get(channel, 'black_list').includes(_.get(user, 'id'))) {
          setOpenModal(true)
          setTextModal(textModalNg)
          return
        } else if (_.get(channel, 'black_list').length > 0) {
          joinLivestream(_.get(channel, 'live_stream.id'))
          return
        }

        const isDreamCompass = (() => {
          const requireDreamCompass = _.get(channel, 'live_stream.is_dream_compass')
          if (!requireDreamCompass) return true

          return isHasDreamcompass
        })()

        const passportFreeTime = _.get(channel, 'live_stream.passport_free_time')
        if (!isDreamCompass) {
          setOpen(true)
          setModalType('DREAM_COMPASS')
          setLivestreamId(_.get(channel, 'live_stream.id'))
          setFreeMinutes(passportFreeTime)
          return
        }

        const handleOpenModal = () => {
          setOpen(true)
          setModalType('FREE_PLATINUM_PASSPORT')
          setLivestreamId(_.get(channel, 'live_stream.id'))
          setFreeMinutes(passportFreeTime)
        }
        const isRequirePassport = _.get(channel, 'live_stream.is_platinum_passport')
        const isFreePassport = _.get(channel, 'live_stream.is_free_platium_passport')
        if (isRequirePassport && !isHasPassport) {
          if (!isFreePassport) {
            setOpen(true)
            setModalType('PLATINUM_PASSPORT')
          } else if (_.get(channel, 'live_stream.first_joined_at')) {
            const firstJoinAt = new Date(_.get(channel, 'live_stream.first_joined_at'))
            const checkTimeJoin = moment(firstJoinAt).add(passportFreeTime, 'minutes').format('YYYY-MM-DD HH:mm:ss')
            if (new Date(checkTimeJoin) < new Date()) {
              setOpenModalPassportRejoin(true)
              return
            }
            handleOpenModal()
          } else {
            handleOpenModal()
          }
          return
        }

        if (
          _.get(channel, 'live_stream.is_extra_time') &&
          !_.get(channel, 'live_stream.can_join_extra_time')
        ) {
          joinLivestream(_.get(channel, 'live_stream.id'))
          return
        }
        joinLivestream(_.get(channel, 'live_stream.id'))
      },
      [user]
    )

    if (!isFetched) return <></>

    const handleClose = () => {
      setOpenModal(false)
    }

    const hiddenNormalTime = () => {
      refetch()
    }

    return (
      <>
        {data &&
          data.map((channel, index) => {
            const currentTime = new Date()
            const isAbsence = !_.get(channel, 'live_stream')
            const flag = _.get(channel, 'live_stream.can_continue_extra')
            const incomming = !isAbsence && _.get(channel, 'live_stream.status') === 'incomming'
            const completion = !isAbsence && _.get(channel, 'live_stream.status') === 'completion'
            const isStartLive = new Date(_.get(channel, 'live_stream.start_time')) <= currentTime
            const isDisplayBlockingExtraTime =
              new Date(_.get(channel, 'live_stream.extra_watchable_time')) > currentTime
            const isDisplayBlockingWatchableTime =
              new Date(_.get(channel, 'live_stream.watchable_time')) > currentTime
            const isContinueExtra =
              new Date(_.get(channel, 'live_stream.extra_continue_time')) > currentTime
            const isStillContinueExtra = new Date(_.get(channel, 'live_stream.end_time')) - currentTime > TIME_CONTINUE_MODAL
            const waitingTime = new Date(_.get(channel, 'live_stream.start_time')).getTime() - new Date().getTime()
            const startLiveFlag = _.get(channel, 'live_stream.can_join_live_stream')
            const isExtraTime = _.get(channel, 'live_stream.is_extra_time')
            const isRequirePassport = _.get(channel, 'live_stream.is_platinum_passport')
            const canJoinExtra = isRequirePassport ? isHasPassport : true
            const canJoinNormal = isStartLive && (isDisplayBlockingWatchableTime || (canJoinExtra && isDisplayBlockingExtraTime))
            const isNotInWhiteList = _.get(channel, 'white_list').length > 0 && !_.get(channel, 'white_list').includes(_.get(user, 'id'))
            const isInBlackList = _.get(channel, 'black_list').length > 0 && _.get(channel, 'black_list').includes(_.get(user, 'id'))
            const canJoinWithDreamcompass = _.get(channel, 'live_stream.is_dream_compass') ? isHasDreamcompass : true

            const typeVideo = _.get(channel, 'live_stream.entrance_image') && _.get(channel, 'live_stream.entrance_image').includes('.mp4')
            return (
              <div
                key={index}
                className={clsx('h-[120px] border-[1px] relative', index && 'mt-[16px]')}
              >
                {typeVideo ? (
                  <video src={_.get(channel, 'live_stream.entrance_image')} poster={CommonBg} className='w-[100%] h-[100%]' autoPlay loop muted playsInline={true}/>
                ) : (
                  <>
                    <img
                      src={
                        isAbsence
                          ? liveBgAbsence[_.get(channel, 'character_sub_name')]
                          : _.get(channel, 'live_stream.entrance_image')
                      }
                      className="absolute z-[0] top-0 left-0 w-full h-full"
                    />
                  </>
                )}
                {
                  index > 4 && (
                    <img src={iconHelp} className='absolute bottom-[12px] left-[10px]' onClick={() => setModalSpecialEdition(true)} />
                  )
                }

                {!isAbsence && incomming && isStartLive && (
                  <div className="absolute top-0 right-0">
                    <img src={flagLive} className="w-[89px] h-[24px]" />
                  </div>
                )}

                {isAbsence || (
                  <>
                    {index <= 4 && (
                      <LiveLovePoint
                        livestreamId={_.get(channel, 'live_stream.id')}
                        lovePoint={_.get(channel, 'live_stream.love_point')}
                      />
                    )}

                    {incomming && isStartLive && !isExtraTime && (
                      <LiveTime
                        time={
                          completion ? new Date() : _.get(channel, 'live_stream.watchable_time')
                        }
                        livestreamId={_.get(channel, 'live_stream.id')}
                        onComplete={hiddenNormalTime}
                        className="absolute bottom-[48px] right-[6px]"
                      />
                    )}
                    {canJoinExtra && incomming && isExtraTime && isDisplayBlockingExtraTime && (
                      <StartExtraTime
                        isShow={true}
                        time={_.get(channel, 'live_stream.extra_watchable_time')}
                        livestreamId={_.get(channel, 'live_stream.id')}
                        className="absolute bottom-[39px] right-[0]"
                        onComplete={() => {
                          refetch()
                        }}
                      />
                    )}

                    {canJoinExtra && incomming && !flag && isContinueExtra && (
                      <StartExtraTime
                        isShow={true}
                        time={_.get(channel, 'live_stream.extra_continue_time')}
                        livestreamId={_.get(channel, 'live_stream.id')}
                        className="absolute bottom-[39px] right-[0]"
                        onComplete={() => {
                          postCountinueExtraTime({ livestream_id: _.get(channel, 'live_stream.id') }).then(() => {
                            setOpenWaitingModal(true)
                            refetch()
                          })
                        }}
                      />
                    )}

                    {incomming && waitingTime <= FIVE_MINS && waitingTime >= 0 && !startLiveFlag &&
                      <>
                        <WaitingTime
                          time={_.get(channel, 'live_stream.start_time')}
                          className="absolute bottom-[50px] right-[0]"
                        />
                      </>
                    }
                  </>
                )}

                {canJoinNormal && (
                  <div
                    className="absolute bottom-[6px] right-[6px]"
                    onClick={incomming ? () => {
                      localStorage.setItem('item', '')
                      localStorage.setItem('currentLevel', '')
                      localStorage.setItem('nextLevel', '')
                      onSelectItem(channel)
                    } : {}}
                  >
                    <img
                      src={liveActions[_.get(channel, 'live_stream.status')] || btnLiveAbsence}
                      className="w-[128px] h-[36px]"
                    />
                  </div>
                )}

                {isStartLive && canJoinExtra && isContinueExtra && !flag && (
                  <div
                    className="absolute bottom-[6px] right-[6px]"
                    onClick={incomming ? () => onSelectItem(channel) : {}}
                  >
                    <img
                      src={liveActions[_.get(channel, 'live_stream.status')] || btnLiveAbsence}
                      className="w-[128px] h-[36px]"
                    />
                  </div>
                )}

                { waitingTime <= FIVE_MINS && waitingTime >= 0 && <div className="absolute bottom-[6px] right-[6px]">
                  <img src={btnWaiting} className="w-[128px] h-[36px]" />
                </div>}

                {canJoinExtra && !isDisplayBlockingExtraTime &&
                  isExtraTime && incomming && flag && (
                  <div className="flex absolute top-8 right-[6px]">
                    {(openWaitingModal || (!isNotInWhiteList && !isInBlackList && canJoinWithDreamcompass && isStillContinueExtra && isContinueExtra)) && (
                      <div
                        onClick={() => {
                          setChannelCurrent(channel)
                        }}
                      >
                        <img
                          src={extratimeContinue}
                          className="w-[120px] h-auto"
                          onClick={() => handleContinueExtra(_.get(channel, 'live_stream.id'))}
                        />
                        <span className="font-vldLineGR text-white text-center font-normal absolute bottom-[10px] text-[15px] right-[169px] w-[22px]">
                          <Countdown
                            date={_.get(channel, 'live_stream.extra_continue_time')}
                            onComplete={() => {
                              refetch()
                            }}
                            renderer={({ hours, minutes, seconds }) => {
                              return <>{formattedTimeToSecond(hours, minutes, seconds)}</>
                            }}
                          />
                        </span>
                      </div>
                    )}
                    <img src={exTraTimeBlocking} className="w-[120px] h-full" />
                  </div>
                )}

                {!canJoinExtra && isExtraTime && incomming && (
                  <div className="flex absolute top-8 right-[6px]">
                    <img src={exTraTimeBlocking} className='w-[120px] h-full' />
                  </div>
                )}
              </div>
            )
          })}

        <ModalWithBg
          title="ダイヤ不足"
          open={openModalContinue}
          onClose={() => setOpenModalContinue(false)}
        >
          <div className="text-center h-[75px]">赤ダイヤが不足しています</div>
          <div className="flex items-center justify-between">
            <img
              src={btnCancelContinue}
              onClick={() => setOpenModalContinue(false)}
              className="w-[119px] h-[49px]"
            />
            <Link to={MAIN_PATHS.ShopPage}>
              <img className="w-[119px] h-[49px]" src={BtnShop} />
            </Link>
          </div>
        </ModalWithBg>

        <ModalWithBg title="コンテニュー?" open={sendModal} onClose={() => setSendModal(false)}>
          <div className="space-y-[24px]">
            <div className="text-center font-hiragino text-[13px]">
              <span>
                <Countdown
                  date={_.get(channelCurrent, 'live_stream.extra_continue_time')}
                  onComplete={() => {
                    setSendModal(false)
                  }}
                  renderer={({ hours, minutes, seconds }) => {
                    return <>{`あと「${formattedTimeToSecond(hours, minutes, seconds)}秒」以内なら再入場可能だコケッ！`}</>
                  }}
                />
              </span>
              <p>コンテニューしますか？</p>
            </div>

            <div className="flex w-7/12 text-center mx-auto">
              <span className="w-1/2 flex-shrink-0 px-2 py-1 bg-black rounded-l-[12px] font-hiragino text-[10px] text-white font-[500]">
                必要赤ダイヤ
              </span>
              <span
                className={clsx(
                  'w-1/2 flex-shrink-0 px-2 py-1 bg-white rounded-r-[12px] font-hiragino text-[10px] font-[500] border-[1px] border-[#000000]',
                  'text-[#FF1D34]'
                )}
              >
                x{5}
              </span>
            </div>

            <img src={TitleHourglassRed} className="mx-auto w-[112px] h-[68px]" />

            <div className="flex whitespace-nowrap justify-around">
              <div className="flex w-5/12">
                <span className="w-1/2 flex-shrink-0 px-2 py-1 bg-black rounded-l-[12px] font-hiragino text-[10px] text-white font-[500]">
                  追加時間
                </span>
                <span
                  className={clsx(
                    'w-1/2 flex-shrink-0 px-2 py-1 bg-white rounded-r-[12px] font-hiragino text-[10px] font-[500] border-[1px] border-[#000000]',
                    'text-[#FF1D34]'
                  )}
                >
                  ＋{70}秒
                </span>
              </div>
              <div className="flex w-5/12">
                <span className="w-1/2 flex-shrink-0 px-2 py-1 bg-black rounded-l-[12px] font-hiragino text-[10px] text-white font-[500]">
                  LOVE度
                </span>
                <span
                  className={clsx(
                    'w-1/2 flex-shrink-0 px-2 py-1 bg-white rounded-r-[12px] font-hiragino text-[10px] font-[500] border-[1px] border-[#000000]',
                    'text-[#FF1D34]'
                  )}
                >
                  ＋{18}
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between">
              <img
                src={btnCancel}
                onClick={() => setSendModal(false)}
                className="w-[119px] h-[49px]"
              />
              <img
                src={btnContinueExtra}
                onClick={sendExtraContinueTime}
                className="w-[119px] h-[49px]"
              />
            </div>

            <div className="text-[13px] text-center font-hiragino">
              <p>※コンテニュー時間が過ぎると</p>
              <p>本日のLIVE配信は再入場できません</p>
            </div>
          </div>
        </ModalWithBg>

        <ConditionModal open={open} modalType={modalType} livestreamId={livestreamId}
          freeMinutes={freeMinutes} setOpenModal={setOpen} />

        <ModalWithBg
          title={textModalOk === textModal ? '限定配信中' : '重要なお知らせ'}
          open={openModal}
          onClose={handleClose}
        >
          <div className="space-y-4">
            {textModalOk === textModal ? (
              <div className="mb-[70px] whitespace-pre-line leading-6 text-[14px]">{textModal}</div>
            ) : (
              <p
                className="mb-[70px] whitespace-pre-line leading-6 text-[13px]"
                dangerouslySetInnerHTML={{ __html: textModal }}
              />
            )}
            <p className="text-right absolute bottom-[5%] right-[15%]">
              <img className="inline w-[80px] h-[80px]" src={mascotChara} />
            </p>

            <div className="flex items-center justify-center absolute bottom-[5%] left-[32%]">
              <img
                className="inline cursor-pointer w-[119px] h-[49px]"
                src={btnClose}
                onClick={handleClose}
              />
            </div>
          </div>
        </ModalWithBg>

        <ModalPassportRejoin open={openModalPassportRejoin} onClose={() => setOpenModalPassportRejoin(false)} />
        <ModalSpecialEdition open={modalSpecialEdition} onClose={() => setModalSpecialEdition(false)} />
        <TipsModal type="selection" isDetail={true} />
      </>
    )
  },
  { headerBg: HeaderStream }
)

export { CharacterSelection }
