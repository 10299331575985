import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import LevelProgress from 'src/components/common/LevelProgress'
import { Modal } from 'src/components/common/Modal'
import styled from 'styled-components'

import StillBtn from 'src/assets/img/mypage/recommend/btn_still.png'
import VoiceBtn from 'src/assets/img/mypage/recommend/btn_voice.png'
import MyFavoriteChoicheBtn from 'src/assets/img/mypage/recommend/btn_myfavorite_choice.png'
import MyFavoriteBtn from 'src/assets/img/mypage/recommend/btn_myfavorite.png'
import DressUpBtn from 'src/assets//img/mypage/recommend/btn_dressup.png'
import BnrAsahi from 'src/assets/img/mypage/recommend/bnr_asahi.png'
import BnrKazehaya from 'src/assets/img/mypage/recommend/bnr_kazehaya.png'
import BnrTachibana from 'src/assets/img/mypage/recommend/bnr_tachibana.png'
import BnrWakatsuki from 'src/assets/img/mypage/recommend/bnr_wakatsuki.png'
import BnrYokohama from 'src/assets/img/mypage/recommend/bnr_yokohama.png'
import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BtnClose from 'src/assets/img/common/btn-close.png'
import CancelBtn from 'src/assets/img/common/btn-cancel.png'
import OKBtn from 'src/assets/img/mypage/recommend/btn_accept.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'

export const characterNameList = {
  asahi: 0,
  kazehaya: 1,
  tachibana: 2,
  wakatsuki: 3,
  yokohama: 4
}

export const CharacterBoxContent = ({ character = {}, handleUpdate }) => {
  const { id, name, recommend } = character
  const navigate = useNavigate()
  const [characterImg, setCharacterImg] = useState()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleOk = useCallback(() => {
    handleUpdate(id)
    setIsOpenModal(!isOpenModal)
  }, [isOpenModal])

  useEffect(() => {
    let img = ''
    switch (id) {
      case 1: // 朝日空
        img = BnrAsahi
        break
      case 2: // 横浜湊
        img = BnrYokohama
        break
      case 3: // 若槻蓮
        img = BnrWakatsuki
        break
      case 4: // Kazehaya
        img = BnrKazehaya
        break
      case 5: // 立花駿
        img = BnrTachibana
        break
      default:
        break
    }
    setCharacterImg(img)
    // }
  }, [])

  return (
    <>
      {characterImg !== '' ? (
        <div className="bg-[url('/src/assets/img/common/bg_myfavorite.png')] w-full h-[225px] m-auto pl-[13px] bg-no-repeat bg-[length:100%_100%]">
          <div className="grid grid-cols-[135px_auto] w-full">
            <div className="pt-[2px]">
              <img
                className="h-full w-full"
                src={characterImg}
                alt=""
                onClick={() => {
                  navigate(
                    MYPAGE_PATHS.RecommendProfilePage.replace(':id', id).replace(
                      ':name',
                      name || 'noname'
                    )
                  )
                }}
              />
            </div>
            <div>
              <div className="pt-2.5 pl-3 pr-2.5 w-full">
                <div
                  className="bg-[url('/src/assets/img/mypage/recommend/bg_myfavorite_gauge.png')] bg-no-repeat h-[80px] w-full pt-[21px] pb-4 pl-[33px] pr-[35px]"
                  style={{
                    backgroundSize: '100% 100%'
                  }}
                >
                  {/* <div>Love度</div>
                  <div>育て度</div> */}
                  <LevelProgress level={character?.level} />
                </div>
              </div>
              <div className="pl-1 flex mt-[12px] relative w-full">
                <img
                  className="h-[54px] w-[52%]"
                  src={StillBtn}
                  alt=""
                  onClick={
                    //   () => {
                    //   navigate(
                    //     MYPAGE_PATHS.RecommendStillAlbumPage.replace(':id', id).replace(
                    //       ':name',
                    //       name || 'noname'
                    //     )
                    //   )
                    // }
                    () => navigate('/in-progress')
                  }
                />
                <img
                  className="h-[54px] w-[52%] absolute right-[9px]"
                  src={VoiceBtn}
                  alt=""
                  onClick={
                    //   () => {
                    //   navigate(
                    //     MYPAGE_PATHS.RecommendVoiceAlbumPage.replace(':id', id).replace(
                    //       ':name',
                    //       name || 'noname'
                    //     )
                    //   )
                    // }
                    () => navigate('/in-progress')
                  }
                />
              </div>
              <div className="pl-1 flex mt-1.5 relative w-full">
                <button className="h-[54px] w-[52%]" onClick={() => setIsOpenModal(!recommend)}>
                  {recommend ? (
                    <img src={MyFavoriteBtn} alt="" />
                  ) : (
                    <img src={MyFavoriteChoicheBtn} alt="" />
                  )}
                </button>
                <img
                  className="h-[54px] w-[52%] absolute right-[9px]"
                  src={DressUpBtn}
                  alt=""
                  onClick={
                    // () => {
                    //   navigate(MYPAGE_PATHS.RecommendDressUpPage.replace(':id', id))
                    // }
                    () => navigate('/in-progress')
                  }
                />
              </div>
            </div>
          </div>
          <Modal
            open={isOpenModal}
            onClose={() => setIsOpenModal(false)}
            className="sm:w-[327px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
          >
            <div
              onClick={() => setIsOpenModal(false)}
              className="w-[50px] h-[44px] absolute -top-4 -right-5 z-50 cursor-pointer"
            >
              <img className="w-full h-full" src={BtnClose} alt="close" />
            </div>
            <ModalBody>
              <ModalTitle>確認</ModalTitle>
              <h6 className="text-center text-base font-bold mb-[12px]">
                {name}を推しメンに設定しますか？
              </h6>
              <div className="pt-[2px] flex justify-center">
                <img className="w-[122px] h-[189px]" src={characterImg} alt="" />
              </div>

              <div className="flex items-center justify-center space-x-6 mt-[18px]">
                <button
                  className="focus:outline-none w-[127px] h-[52px]"
                  onClick={() => setIsOpenModal(false)}
                >
                  <img className="w-full h-full" src={CancelBtn} alt="Cancel" />
                </button>
                <button
                  className="focus:outline-none w-[127px] h-[52px]"
                  onClick={() => handleOk()}
                >
                  <img className="w-full h-full" src={OKBtn} alt="Ok" />
                </button>
              </div>
            </ModalBody>
          </Modal>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

CharacterBoxContent.propTypes = {
  characterName: PropTypes.number,
  character: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    level: PropTypes.shape({
      love: PropTypes.shape({
        level: PropTypes.number.isRequired,
        total_items: PropTypes.number.isRequired,
        current_items: PropTypes.number.isRequired
      }).isRequired,
      grow: PropTypes.shape({
        level: PropTypes.number.isRequired,
        total_items: PropTypes.number.isRequired,
        current_items: PropTypes.number.isRequired
      }).isRequired
    }),
    recommend: PropTypes.bool.isRequired
  }),
  handleUpdate: PropTypes.func.isRequired
}
const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-8 pt-[38px] pb-[36px] relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`
const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-[20px] py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-[21px]'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;
`
