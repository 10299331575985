import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const getLiveSettingInfos = async () => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.SettingInfos)

    return res?.data || null
  } catch {
    return null
  }
}
