import CoverLeft from 'src/assets/img/mypage/effect/cover-left.png'
import CoverCenter from 'src/assets/img/mypage/effect/cover-center.png'
import CoverRight from 'src/assets/img/mypage/effect/cover-right.png'

const CoverEffect = () => {
  return (
    <>
      <div className="absolute left-0 bottom-0">
        <img src={CoverLeft} alt="" />
      </div>
      <div className="absolute right-0 top-0 bottom-0">
        <img src={CoverCenter} alt="" />
      </div>
      <div className="absolute right-0 top-0">
        <img src={CoverRight} alt="" />
      </div>
    </>
  )
}

export default CoverEffect
