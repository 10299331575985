import AsahiSora01 from 'src/assets/bgms/asahisora_01.wav'
import AsahiSora02 from 'src/assets/bgms/asahisora_02.wav'
import AsahiSora03 from 'src/assets/bgms/asahisora_03.wav'
import YokohamaMinato01 from 'src/assets/bgms/yokohamaminato_01.wav'
import YokohamaMinato02 from 'src/assets/bgms/yokohamaminato_02.wav'
import YokohamaMinato03 from 'src/assets/bgms/yokohamaminato_03.wav'
import WakatsukiRen01 from 'src/assets/bgms/wakatukiren_01.wav'
import WakatsukiRen02 from 'src/assets/bgms/wakatukiren_02.wav'
import KazehayaSho01 from 'src/assets/bgms/kazehayasho_01.wav'
import KazehayaSho02 from 'src/assets/bgms/kazehayasho_02.wav'
import KazehayaSho03 from 'src/assets/bgms/kazehayasho_03.wav'
import TatibanaShun01 from 'src/assets/bgms/tatibanashun_01.wav'
import TatibanaShun02 from 'src/assets/bgms/tatibanashun_02.wav'
import TatibanaShun03 from 'src/assets/bgms/tatibanashun_03.wav'

const inRange = (a, b, num) => Math.min(a, b) <= num && num <= Math.max(a, b)

export const birthCartoonBGM = (id, page) => {
  switch (id) {
    case '1':
      if (inRange(1, 3, page)) return AsahiSora01
      if (inRange(6, 18, page)) return AsahiSora02
      if (inRange(20, 100, page)) return AsahiSora03
      return ''
    case '2':
      if (inRange(1, 5, page)) return YokohamaMinato01
      if (inRange(7, 13, page)) return YokohamaMinato02
      if (inRange(15, 100, page)) return YokohamaMinato03
      return ''
    case '3':
      if (inRange(1, 10, page)) return WakatsukiRen01
      if (inRange(12, 100, page)) return WakatsukiRen02
      return ''
    case '4':
      if (inRange(1, 5, page)) return KazehayaSho01
      if (inRange(6, 10, page)) return KazehayaSho02
      if (inRange(13, 100, page)) return KazehayaSho03
      return ''
    case '5':
      if (inRange(1, 8, page)) return TatibanaShun01
      if (inRange(9, 10, page)) return TatibanaShun02
      if (inRange(11, 100, page)) return TatibanaShun03
      return ''
    default:
      return ''
  }
}
