/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useFetchFirstStory } from 'src/pages/newUser/hooks/useFetchFirstStory'
import { MAIN_PATHS } from 'src/routes/main'
import { MENU_PATHS } from 'src/routes/menu'

export const useFirstStory = () => {
  const { data: stories } = useFetchFirstStory()
  const muteSettings = {
    bgm: false,
    se: false,
    voice: false
  }

  const [storyIndex, setStoryIndex] = useState(0)
  const [openHistory, setOpenHistory] = useState(false)
  const [isAuto, setIsAuto] = useState(false)
  const [isAllDisplayed, setIsAllDisplayed] = useState(false)
  const [blackoutColor, setBlackoutColor] = useState('black')
  const [isRepeatBgm, setIsRepeatBgm] = useState(false)
  const [audioSettings, setAudioSettings] = useState(muteSettings)
  const [isMusicModalOpen, setIsMusicModalOpen] = useState(false)
  const messagesEndRef = useRef(null)
  const ref = useRef(null)
  const audioRef = useRef(null)
  const audioSeRef = useRef(null)
  const wrapperRef = useRef(null)
  const containerRef = useRef(null)
  const audioCharacterRef = useRef(null)
  const navigate = useNavigate()
  const storyRef = useRef(storyIndex)
  const timerRef = useRef(null)
  const location = useLocation()
  const from = location.state?.from
  const currentStory = useMemo(() => (stories || [])[storyIndex], [stories, storyIndex])
  const nextStory = useMemo(() => (stories || [])[storyIndex + 1], [stories, storyIndex])
  const isLastStory = useMemo(() => {
    if (!stories) return true

    return storyIndex === stories.length - 1
  }, [stories, storyIndex])
  const [blackoutColorAnimation, setBlackoutColorAnimation] = useState('black')
  const storyDescArray = useMemo(() => {
    if (!stories) return
    return stories.map((story) => story.description)
  }, [stories])
  const isReady = useMemo(() => currentStory || !isLastStory, [currentStory, isLastStory])
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    if (!stories) return
    let startBlackout = false
    let settingBg = ''
    let settingName = ''
    let settingAddress = ''
    stories.forEach((story, i) => {
      const beforeStory = stories[i - 1]

      if (story.type === 'Blackout') {
        startBlackout = true
        story.backgroundImage = beforeStory.backgroundImage
        // setBlackoutColor(story.blackoutColor)
      } else if (story.type === 'CancelBlackout') {
        startBlackout = false
      } else if (beforeStory?.duringBlackout || beforeStory?.isBlackout) {
        story.duringBlackout = true
      } else {
        story.duringBlackout = false
      }

      if (story.type === 'StorySet') {
        if (story.bgmUrl) setIsRepeatBgm(story.isBgmAudioRepeat)
        if (!story.characterName) {
          story.characterName = settingName
        } else {
          settingName = story.characterName
        }
        if (!story.address) {
          story.address = settingAddress
        } else {
          settingAddress = story.address
        }

        if (!story.backgroundImage) {
          if (story.duringBlackout) return
          story.backgroundImage = settingBg
        } else {
          settingBg = story.backgroundImage
        }
      }
    })
  }, [stories])

  const redirectTo = useCallback(() => {
    const path = from !== '/menu' ? MAIN_PATHS.MessagePage : MENU_PATHS.Menu
    navigate(path)
  }, [location])

  const handleHistoryAction = useCallback(() => {
    setOpenHistory(true)
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }, [setOpenHistory])

  useEffect(() => {
    storyRef.current = storyIndex
  }, [storyIndex])

  const handleAutoAction = useCallback(() => {
    if (isLastStory) return
    setIsAuto(!isAuto)
  }, [isAuto, storyIndex, stories, isLastStory])

  useEffect(() => {
    if (!currentStory || isLastStory) return
    if (currentStory?.isBlackout === undefined) {
      wrapperRef.current.style.opacity = 1
      return
    }
    if (currentStory.isBlackout) {
      setTimeout(() => {
        setStoryIndex((prev) => prev + 1)
      }, currentStory.timeToChange)
    } else {
      setTimeout(() => {
        wrapperRef.current.style.opacity = 1
        setStoryIndex((prev) => prev + 1)
      }, currentStory.timeToLeave)
    }
  }, [storyIndex, currentStory])

  useEffect(() => {
    setIsAllDisplayed(false)
    if (!ref.current || !currentStory) return
    ref.current.textContent = ''
    if (!currentStory.description) return
    const speed = 20
    if (isAuto) {
      const string = storyDescArray[storyIndex].split('')
      typewriterAuto(string, speed)
    } else {
      const string = currentStory.description.split('')
      typewriter(string, speed)
      controlSeAudio(string.length * speed)
    }
  }, [currentStory, isAuto, storyDescArray, openHistory])

  const typewriter = useCallback(
    (string, speed) => {
      const soundEffect = audioCharacterRef.current
      if (soundEffect && soundEffect.src && soundEffect.src.includes('mp3')) {
        soundEffect.play()
      }
      string.forEach((char, index) => {
        setTimeout(() => {
          if (ref.current && index !== ref.current.textContent.length) return
          ref.current.textContent += char
        }, speed * index)
      })
    },
    [storyIndex, stories, audioCharacterRef.current]
  )

  const typewriterAuto = useCallback(
    async (string, speed) => {
      if (ref.current) {
        const soundEffect = audioCharacterRef.current
        if (soundEffect && soundEffect.src && soundEffect.src.includes('mp3')) {
          string.forEach((char, index) => {
            setTimeout(() => {
              if (index !== ref.current.textContent.length) return
              ref.current.textContent += char
            }, speed * index)
          })
          soundEffect.play()
          soundEffect.addEventListener('ended', () => {
            if (string.length === ref.current.textContent.length) {
              if (isLastStory) return
              if (nextStory.isBlackout) {
                let opacity = 1
                setBlackoutColorAnimation(nextStory.blackoutColor)
                const intervalId = setInterval(() => {
                  opacity -= 0.1
                  wrapperRef.current.style.opacity = opacity
                  if (opacity <= 0) {
                    clearInterval(intervalId)
                    setStoryIndex((prev) => prev + 1)
                  }
                }, nextStory.timeToChange / 10)
              } else {
                setStoryIndex((prev) => prev + 1)
              }
            }
          })
        } else {
          string.forEach((char, index) => {
            setTimeout(() => {
              if (index !== ref.current.textContent.length) return
              ref.current.textContent += char
              if (string.length === ref.current.textContent.length) {
                if (isLastStory) return
                if (nextStory.isBlackout) {
                  let opacity = 1
                  setBlackoutColorAnimation(nextStory.blackoutColor)
                  const intervalId = setInterval(() => {
                    opacity -= 0.1
                    wrapperRef.current.style.opacity = opacity
                    if (opacity <= 0) {
                      clearInterval(intervalId)
                      setStoryIndex((prev) => prev + 1)
                    }
                  }, nextStory.timeToChange / 10)
                } else {
                  setTimeout(() => setStoryIndex((prev) => prev + 1), 2000)
                }
              }
            }, speed * index)
          })
        }
      }
    },
    [storyIndex, stories, audioCharacterRef.current]
  )

  const controlSeAudio = useCallback((time) => {
    setTimeout(() => {
      setIsAllDisplayed(true)
    }, time)
  }, [])

  const blackoutBg = useMemo(() => {
    if (!currentStory) return
    if (currentStory.type === 'Blackout' || currentStory.type === 'CancelBlackout') {
      return blackoutColor
    }

    if (currentStory.duringBlackout) return 'black'
  }, [currentStory])

  useEffect(() => {
    if (!audioRef.current) return
    audioRef.current.volume = 0.3
  }, [currentStory])

  useEffect(() => {
    if (!audioSeRef.current) return
    audioSeRef.current.volume = 0.3
  }, [audioSeRef.current])

  useEffect(() => {
    if (!audioSeRef.current) return
    audioSeRef.current.volume = 0.3
  }, [audioSeRef.current])

  const handleAudioClick = (type) => {
    setAudioSettings((prev) => ({
      ...prev,
      [type]: !prev[type]
    }))
  }

  const handleMusicModalToggle = () => {
    setIsMusicModalOpen((prev) => !prev)
  }

  return {
    stories,
    currentStory,
    blackoutBg,
    isRepeatBgm,
    isAllDisplayed,
    openHistory,
    nextStory,
    audioSettings,
    handleHistoryAction,
    handleAutoAction,
    handleAudioClick,
    handleMusicModalToggle,
    redirectTo,
    ref,
    messagesEndRef,
    isLastStory,
    isAuto,
    isMusicModalOpen,
    setOpenHistory,
    setStoryIndex,
    setAudioSettings,
    setIsMusicModalOpen,
    storyIndex,
    audioRef,
    isReady,
    audioSeRef,
    audioCharacterRef,
    wrapperRef,
    containerRef,
    blackoutColorAnimation,
    setBlackoutColorAnimation,
    openModal,
    setOpenModal
  }
}
