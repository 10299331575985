import Avata1 from 'src/assets/img/message/avatar-1.png'
import Avata2 from 'src/assets/img/message/avatar-2.png'
import Avata3 from 'src/assets/img/message/avatar-3.png'
import Avata4 from 'src/assets/img/message/avatar-4.png'
import Avata5 from 'src/assets/img/message/avatar-5.png'
import Background from 'src/assets/img/message/detail/bg-page.png'

import character1Rank1 from 'src/assets/img/ranking/dx6/character_1_rank_1.png'
import character2Rank1 from 'src/assets/img/ranking/dx6/character_2_rank_1.png'
import character3Rank1 from 'src/assets/img/ranking/dx6/character_3_rank_1.png'
import character4Rank1 from 'src/assets/img/ranking/dx6/character_4_rank_1.png'
import character5Rank1 from 'src/assets/img/ranking/dx6/character_5_rank_1.png'

import character1Rank2 from 'src/assets/img/ranking/dx6/character_1_rank_2.png'
import character2Rank2 from 'src/assets/img/ranking/dx6/character_2_rank_2.png'
import character3Rank2 from 'src/assets/img/ranking/dx6/character_3_rank_2.png'
import character4Rank2 from 'src/assets/img/ranking/dx6/character_4_rank_2.png'
import character5Rank2 from 'src/assets/img/ranking/dx6/character_5_rank_2.png'

import character1Rank3 from 'src/assets/img/ranking/dx6/character_1_rank_3.png'
import character2Rank3 from 'src/assets/img/ranking/dx6/character_2_rank_3.png'
import character3Rank3 from 'src/assets/img/ranking/dx6/character_3_rank_3.png'
import character4Rank3 from 'src/assets/img/ranking/dx6/character_4_rank_3.png'
import character5Rank3 from 'src/assets/img/ranking/dx6/character_5_rank_3.png'

import character1Rank4 from 'src/assets/img/ranking/dx6/character_1_rank_4.png'
import character2Rank4 from 'src/assets/img/ranking/dx6/character_2_rank_4.png'
import character3Rank4 from 'src/assets/img/ranking/dx6/character_3_rank_4.png'
import character4Rank4 from 'src/assets/img/ranking/dx6/character_4_rank_4.png'
import character5Rank4 from 'src/assets/img/ranking/dx6/character_5_rank_4.png'

import character1Rank5 from 'src/assets/img/ranking/dx6/character_1_rank_5.png'
import character2Rank5 from 'src/assets/img/ranking/dx6/character_2_rank_5.png'
import character3Rank5 from 'src/assets/img/ranking/dx6/character_3_rank_5.png'
import character4Rank5 from 'src/assets/img/ranking/dx6/character_4_rank_5.png'
import character5Rank5 from 'src/assets/img/ranking/dx6/character_5_rank_5.png'

import Character1 from 'src//assets/img/ranking/dx6/character-1.png'
import Character2 from 'src/assets/img/ranking/dx6/character-2.png'
import Character3 from 'src/assets/img/ranking/dx6/character-3.png'
import Character4 from 'src/assets/img/ranking/dx6/character-4.png'
import Character5 from 'src/assets/img/ranking/dx6/character-5.png'

import RankAsahiSora from 'src/assets/img/ranking/modal/asahi_sora.png'
import RankYokohamaMinato from 'src/assets/img/ranking/modal/yokohama_minato.png'
import RankWakatukiRen from 'src/assets/img/ranking/modal/wakatuki_ren_update.png'
import RankKazehayaSho from 'src/assets/img/ranking/modal/kazehaya_sho.png'
import RankTatibanaShun from 'src/assets/img/ranking/modal/tatibana_shun_update.png'

import IconAsahi from 'src/assets/img/menu/icon_asahi.png'
import IconYokohama from 'src/assets/img/menu/icon_yokohama.png'
import IconWakatsuki from 'src/assets/img/menu/icon_wakatsuki.png'
import IconKazehaya from 'src/assets/img/menu/icon_kazehaya.png'
import IconTachibana from 'src/assets/img/menu/icon_tachibana.png'
import IconManager from 'src/assets/img/menu/icon_manager.png'

import ProfileAsahi from 'src/assets/img/character/profile/profile_asahisoa.png'
import ProfileKazehaya from 'src/assets/img/character/profile/profile_kazehayasho.png'
import ProfileTachibana from 'src/assets/img/character/profile/profile_tachibanashun.png'
import ProfileWakatsuki from 'src/assets/img/character/profile/profile_wakatsukiren.png'
import ProfileYokohama from 'src/assets/img/character/profile/profile_yokohamaminato.png'

import RoomAsahi from 'src/assets/img/room/default_asahi_sora.png'
import RoomYokohama from 'src/assets/img/room/default_yokohama_minato.png'
import RoomWakatuki from 'src/assets/img/room/default_wakatuki_ren_update.png'
import RoomKazehaya from 'src/assets/img/room/default_kazehaya_sho.png'
import RoomTatibana from 'src/assets/img/room/default_tatibana_shun_update.png'
import MypageAsahi from 'src/assets/img/character/mypage/asahi_sora.png'
import MypageYokohama from 'src/assets/img/character/mypage/yokohama_minato.png'
import MypageWakatuki from 'src/assets/img/character/mypage/wakatuki_ren_update.png'
import MypageKazehaya from 'src/assets/img/character/mypage/kazehaya_sho.webp'
import MypageTatibana from 'src/assets/img/character/mypage/tatibana_shun_update.png'
import AsahiLove from 'src/assets/img/realtalk/realtalk_set_sora png.png'
import MinatoLove from 'src/assets/img/realtalk/realtalk_set_minato png.png'
import RenLove from 'src/assets/img/realtalk/realtalk_set_ren png.png'
import ShoLove from 'src/assets/img/realtalk/realtalk_set_sho png.png'
import SuynLove from 'src/assets/img/realtalk/realtalk_set_syun png.png'

import SyunSub from 'src/assets/img/realtalk/realtalk_sub_syun.png'
import ShoSub from 'src/assets/img/realtalk/realtalk_sub_sho.png'
import RenSub from 'src/assets/img/realtalk/realtalk_sub_ren.png'
import MinatoSub from 'src/assets/img/realtalk/realtalk_sub_minato.png'
import AsahiSub from 'src/assets/img/realtalk/realtalk_sub_sora.png'

import iconCharacter1 from 'src/assets/img/realtalk/realtalk_sd_minato.png'
import iconCharacter2 from 'src/assets/img/realtalk/realtalk_sd_ren.png'
import iconCharacter3 from 'src/assets/img/realtalk/realtalk_sd_sho.png'
import iconCharacter4 from 'src/assets/img/realtalk/realtalk_sd_sora.png'
import iconCharacter5 from 'src/assets/img/realtalk/realtalk_sd_syun.png'

import ahashi from 'src/assets/img/realtalk/ahashi.png'
import minato from 'src/assets/img/realtalk/minato.png'
import ren from 'src/assets/img/realtalk/ren.png'
import sho from 'src/assets/img/realtalk/sho.png'
import suyn from 'src/assets/img/realtalk/suyn.png'

export const characterNames = ['朝日空', '風早翔', '立花駿', '若槻蓮', '横浜湊']

export const CHARACTERS = [
  {
    name: '朝日空',
    avatar: Avata1,
    background: Background,
    id: 1
  },
  {
    name: '横浜湊',
    avatar: Avata2,
    background: Background,
    id: 2
  },
  {
    name: '若槻蓮',
    avatar: Avata3,
    background: Background,
    id: 3
  },
  {
    name: '風早翔',
    avatar: Avata4,
    background: Background,
    id: 4
  },
  {
    name: '立花駿',
    avatar: Avata5,
    background: Background,
    id: 5
  }
]

export const RANKING_MODAL_CHARACTER_IMAGE = {
  1: RankAsahiSora,
  2: RankYokohamaMinato,
  3: RankWakatukiRen,
  4: RankKazehayaSho,
  5: RankTatibanaShun
}

export const rankingCharacters = [
  {
    id: 1,
    name: '朝日空',
    image: 'character1-character.png',
    background: 'character1-bg.png',
    backgroundName: 'character1.png',
    backgroundNameActive: 'character1-active.png'
  },
  {
    id: 2,
    name: '横浜湊',
    image: 'character2-character.png',
    background: 'character2-bg.png',
    backgroundName: 'character2.png',
    backgroundNameActive: 'character2-active.png'
  },
  {
    id: 3,
    name: '若槻蓮',
    image: 'character3-character.png',
    background: 'character3-bg.png',
    backgroundName: 'character3.png',
    backgroundNameActive: 'character3-active.png'
  },
  {
    id: 4,
    name: '風早翔',
    image: 'character4-character.png',
    background: 'character4-bg.png',
    backgroundName: 'character4.png',
    backgroundNameActive: 'character4-active.png'
  },
  {
    id: 5,
    name: '立花駿',
    image: 'character5-character.png',
    background: 'character5-bg.png',
    backgroundName: 'character5.png',
    backgroundNameActive: 'character5-active.png'
  }
]

export const RANKING_CHARACTER_D6 = {
  1: {
    image: Character1,
    board: {
      1: {
        id: 1,
        img: character1Rank1
      },
      2: {
        id: 2,
        img: character1Rank2
      },
      3: {
        id: 3,
        img: character1Rank3
      },
      4: {
        id: 4,
        img: character1Rank4
      },
      5: {
        id: 5,
        img: character1Rank5
      }
    },
    outerColor: '#e8beeb'
  },
  2: {
    image: Character2,
    board: {
      1: {
        id: 1,
        img: character2Rank1
      },
      2: {
        id: 2,
        img: character2Rank2
      },
      3: {
        id: 3,
        img: character2Rank3
      },
      4: {
        id: 4,
        img: character2Rank4
      },
      5: {
        id: 5,
        img: character2Rank5
      }
    },
    outerColor: '#97b8ea'
  },
  3: {
    image: Character3,
    board: {
      1: {
        id: 1,
        img: character3Rank1
      },
      2: {
        id: 2,
        img: character3Rank2
      },
      3: {
        id: 3,
        img: character3Rank3
      },
      4: {
        id: 4,
        img: character3Rank4
      },
      5: {
        id: 5,
        img: character3Rank5
      }
    },
    outerColor: '#b3d5df'
  },
  4: {
    image: Character4,
    board: {
      1: {
        id: 1,
        img: character4Rank1
      },
      2: {
        id: 2,
        img: character4Rank2
      },
      3: {
        id: 3,
        img: character4Rank3
      },
      4: {
        id: 4,
        img: character4Rank4
      },
      5: {
        id: 5,
        img: character4Rank5
      }
    },
    outerColor: '#c59dcf'
  },
  5: {
    image: Character5,
    board: {
      1: {
        id: 1,
        img: character5Rank1
      },
      2: {
        id: 2,
        img: character5Rank2
      },
      3: {
        id: 3,
        img: character5Rank3
      },
      4: {
        id: 4,
        img: character5Rank4
      },
      5: {
        id: 5,
        img: character5Rank5
      }
    },
    outerColor: '#a398f2'
  }
}

export const CHARACTER_LINE_LINKS = {
  1: process.env.REACT_APP_CHARACTER_1_LINE_LINK,
  2: process.env.REACT_APP_CHARACTER_2_LINE_LINK,
  3: process.env.REACT_APP_CHARACTER_3_LINE_LINK,
  4: process.env.REACT_APP_CHARACTER_4_LINE_LINK,
  5: process.env.REACT_APP_CHARACTER_5_LINE_LINK,
  6: process.env.REACT_APP_CHARACTER_6_LINE_LINK
}

export const CIRCLE_IMAGE_OF_CHARACTER = {
  1: IconAsahi,
  2: IconYokohama,
  3: IconWakatsuki,
  4: IconKazehaya,
  5: IconTachibana,
  6: IconManager
}

export const CHARACTER_PROFILE_IMAGE = {
  1: ProfileAsahi,
  2: ProfileYokohama,
  3: ProfileWakatsuki,
  4: ProfileKazehaya,
  5: ProfileTachibana
}

export const MYPAGE_CHARACTER_IMAGES = {
  1: { image: MypageAsahi, room: RoomAsahi },
  2: { image: MypageYokohama, room: RoomYokohama },
  3: { image: MypageWakatuki, room: RoomWakatuki },
  4: { image: MypageKazehaya, room: RoomKazehaya },
  5: { image: MypageTatibana, room: RoomTatibana }
}

export const MYPAGE_CHARACTER_IMAGES_LOVE = {
  1: { image: AsahiLove, name: '朝日空', id: 1 },
  2: { image: MinatoLove, name: '横浜湊', id: 2 },
  3: { image: RenLove, name: '若槻蓮', id: 3 },
  4: { image: ShoLove, name: '風早翔', id: 4 },
  5: { image: SuynLove, name: '立花駿', id: 5 }
}

export const MYPAGE_CHARACTER_IMAGES_GRAY = [
  { image: AsahiSub, name: '朝日空', image2: ahashi },
  { image: MinatoSub, name: '横浜湊', image2: minato },
  { image: RenSub, name: '若槻蓮', image2: ren },
  { image: ShoSub, name: '風早翔', image2: sho },
  { image: SyunSub, name: '立花駿', image2: suyn }
]

export const MYPAGE_CHARACTER_IMAGES_ICON_MINI = [
  { image: iconCharacter4, name: '朝日空' },
  { image: iconCharacter1, name: '横浜湊' },
  { image: iconCharacter2, name: '若槻蓮' },
  { image: iconCharacter3, name: '風早翔' },
  { image: iconCharacter5, name: '立花駿' }
]
