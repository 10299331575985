import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CHARACTER_PROFILE_IMAGE } from 'src/constants/characters'
import { profileVoice } from 'src/constants/character_voice'
import { MYPAGE_PATHS } from 'src/routes/myPage'

import BtnListeningVoice from 'src/assets/img/common/btn_listeningvoice.png'
import BtnUpBringing from 'src/assets/img/common/btn_upbringing.png'

export const Profile = () => {
  const navigate = useNavigate()
  const { id, name } = useParams()
  const [audioState, setAudioState] = useState(null)

  const voicePlay = useCallback(() => {
    if (audioState && !audioState?.ended) return
    const audio = new Audio(profileVoice(Number(id)))
    audio.play()
    setAudioState(audio)
  }, [id, audioState])

  useEffect(() => {
    return () => {
      if (audioState) {
        audioState.pause()
        audioState.currentTime = 0
      }
    }
  }, [audioState])

  return (
    <div className="w-screen h-screen sm:w-[400px]">
      <img className="w-full" src={CHARACTER_PROFILE_IMAGE[Number(id)]} alt="" />
      <div className="absolute flex bottom-[100px] left-0 w-full justify-center">
        <img
          className="xs:w-[173px] w-[150px] fixed left-[2%] bottom-[18%]"
          src={BtnListeningVoice}
          alt=""
          onClick={voicePlay}
        />
        <img
          className="xs:w-[173px] w-[150px] fixed right-[3%] bottom-[18%]"
          src={BtnUpBringing}
          alt=""
          onClick={() => {
            navigate(
              MYPAGE_PATHS.RecommendMangaPage.replace(':id', id).replace(':name', name || 'noname')
            )
          }}
        />
      </div>
    </div>
  )
}
