import { useEffect } from 'react'
import styled from 'styled-components'
import BackGround from 'src/assets/img/mini_game/background.png'
import ScoreBoard from 'src/assets/img/mini_game/score.png'
import Title from 'src/assets/img/mini_game/title/title_how_to_play.png'
import BackBtn from 'src/assets/img/mini_game/btn/btn_back.png'
import StartBtn from 'src/assets/img/mini_game/btn/btn_start.png'
import { useNavigate, useParams } from 'react-router-dom'
import Mascot from 'src/assets/img/character/mascot/bye_bye.png'
import { getUserMiniGame } from 'src/services/hooks/useUserMiniGames'
import { useQuery } from 'react-query'
import { useAuthState } from 'src/providers/AuthProviders'

const MiniGame = () => {
  const navigate = useNavigate()
  const { characterId } = useParams()
  const { user } = useAuthState()
  const { data, refetch } = useQuery(['userMiniGameData', user.id], () => getUserMiniGame(user.id))
  const currentUrl = localStorage.getItem('partName')

  useEffect(() => {
    refetch()
  }, [])

  return (
    <Wrapper>
      <Header>
        <img src={ScoreBoard} alt="score-board" />
        <LimitBonus>残り{data?.remaining_number}回</LimitBonus>
        <BestScore>{data?.best_score}点</BestScore>
      </Header>
      <Container>
        <img src={Title} alt="title" className="w-140 mx-auto mb-5" />
        {currentUrl === `/realtalk/detail/${characterId}` ? (
          <>
            <Description>
              <p>的（彼）に矢を投げるコケッ！</p>
              <p>的に矢が刺さると得点GET！</p>
              <p>
                {data?.score}点獲得で{data?.heart}ハートGET！
              </p>
              <p>（1日最大3回まで）</p>
              <p>★赤に当たると高得点</p>
              <p>★黒に当たったり、同じ矢に当たるとGAMEOVER</p>
            </Description>
            <p className="text-xs mb-2">※彼からトークのお返事が届くまでチャレンジ可能！</p>
          </>
        ) : (
          <>
            <Description>
              <p>的（彼）に矢を投げるコケッ！</p>
              <p>的に矢が刺さると得点GET！</p>
              <p>
                {data?.score}点獲得で{data?.heart}ハートGET！
              </p>
              <p>（1日最大3回まで）</p>
            </Description>
            <p className="text-xs mb-6">※彼からトークのお返事が届いた時点で強制終了だョ！</p>
          </>
        )}
        <SelectBtnGroup>
          <img
            src={BackBtn}
            onClick={() => navigate(-1)}
            alt="back-btn"
            className="w-32 mr-6 cursor-pointer"
          />
          <img
            src={StartBtn}
            onClick={() => navigate(`/mini-game/${characterId}/play`)}
            alt="start-btn"
            className="w-32 cursor-pointer"
          />
        </SelectBtnGroup>
      </Container>
      <img src={Mascot} alt="mascot" className="w-32 mt-2 float-right mr-10 min-h-650:hidden" />
    </Wrapper>
  )
}

export default MiniGame

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto bg-no-repeat bg-center bg-cover text-[#333333] relative'
})`
  background-image: url(${BackGround});
`

const Header = styled.div.attrs({
  className: 'relative w-4/5 mx-auto pt-12 z-0 mb-12'
})``

const LimitBonus = styled.div.attrs({
  className: 'absolute z-10 right-[8%] top-[40%] text-xl font-hiragino '
})``

const BestScore = styled.div.attrs({
  className: 'absolute z-10 right-[10%] bottom-[8%] text-xl font-hiragino'
})``

const Container = styled.div.attrs({
  className: 'w-full mx-auto'
})``

const Description = styled.div.attrs({
  className: 'text-base font-bold mb-3'
})``

const SelectBtnGroup = styled.div.attrs({
  className: 'flex justify-center'
})``
