import React from 'react'
import { CharacterSelection } from '../pages/voiceLive/pages/CharacterSelection'
import { DuringDelivery } from '../pages/voiceLive/pages/DuringDelivery'
import { TopCountdown } from '../pages/voiceLive/pages/TopCountdown'

const VOICE_LIVE_PATH = {
  TopCountdown: '/voice-live/top-countdown',
  CharacterSelection: '/voice-live/character-selection/:setting_id',
  DuringDelivery: '/voice-live/during-delivery/:channel_id'
}

const VoiceLivePath = [
  { path: VOICE_LIVE_PATH.TopCountdown, element: <TopCountdown /> },
  { path: VOICE_LIVE_PATH.CharacterSelection, element: <CharacterSelection /> },
  { path: VOICE_LIVE_PATH.DuringDelivery, element: <DuringDelivery /> }
]

export { VoiceLivePath, VOICE_LIVE_PATH }
