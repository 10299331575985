import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const getLiveListChannel = async (settingId) => {
  try {
    const res = await axiosInstance.get(API_ROUTES.Live.ListChannel.replace(':setting_id', settingId))

    return res?.data || null
  } catch {
    return null
  }
}
