import PropTypes from 'prop-types'
import TitleHeader from 'src/components/common/TitleHeader'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import Item from 'src/models/Item'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import { useCallback } from 'react'

const ItemDetailsModal = ({ item, handleClose }) => {
  const isSettingItem = useCallback(
    (type) => {
      if (!type) return
      return type === 'SettingItem'
    },
    [item]
  )

  return (
    <div className="w-full m-4 px-4 pt-4 pb-8 bg-popup-l bg-fill bg-no-repeat rounded-md flex flex-col items-center">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader
        title={['r', 'sr', 'ssr'].includes(item.rarity) ? 'アイテム' : '特殊アイテム'}
        className="my-6"
      />
      <ItemThumbnail
        image={item.image.url}
        className="w-[115px] h-[115px] border-menu-dark-blue rounded-sm"
        imgClassName={isSettingItem(item.type) ? 'w-full h-full' : 'w-[90%]'}
        rarity={isSettingItem(item.type)}
      />
      <div className="mt-6 w-3/4 flex justify-around">
        <ValueIndicator
          label="LOVE度"
          value={item.increase_love || item.increaseLove}
          borderStyle="border-love-pink"
          bgStyle="bg-love-pink text-white"
        />
        <ValueIndicator
          label="所持数"
          value={item.count || item.amount}
          borderStyle="border-menu-dark-blue"
          bgStyle="bg-menu-light-blue"
        />
      </div>
      <div className="mt-6 mb-2 text-xl text-center font-vldLineGR">{item.name}</div>
      <div className="text-sm">{item.description}</div>
    </div>
  )
}

ItemDetailsModal.propTypes = {
  item: PropTypes.instanceOf(Item).isRequired,
  handleClose: PropTypes.func.isRequired
}

const ValueIndicator = ({ label, value, borderStyle, bgStyle }) => {
  return (
    <div
      className={`rounded border ${borderStyle} h-8 flex justify-center items-center text-xs font-hiragino`}
    >
      <span className={`flex items-center h-full align-middle px-2 ${bgStyle}`}>{label}</span>
      <span className="px-2">{value}</span>
    </div>
  )
}

ValueIndicator.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  borderStyle: PropTypes.string.isRequired,
  bgStyle: PropTypes.string.isRequired
}

export default ItemDetailsModal
