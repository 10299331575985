import { useParams } from 'react-router-dom'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import NoContent from 'src/components/layouts/NoContent'
import { removeNewlineCode } from 'src/lib/string'
import { useQuery } from 'react-query'
import getEvents from 'src/services/get/getEvents'
import { useEffect, useState } from 'react'
import InnerHTML from 'dangerously-set-html-content'

const EventDetail = () => {
  const { data: events, isLoading } = useQuery('events', () => getEvents())
  const [currentEvent, setCurrentEvent] = useState({})
  const params = useParams()

  useEffect(() => {
    if (!events) return
    const findEventFromParams = events?.find((event) => event.id === Number(params.id))
    setCurrentEvent(findEventFromParams)
  }, [params, events])

  return (
    <MenuWrapper className="flex flex-col items-center px-2 pb-4 pt-0">
      {isLoading ? (
        <Loading />
      ) : !currentEvent ? (
        <NoContent />
      ) : (
        <div className="bg-white w-full h-full overflow-y-auto rounded">
          {currentEvent.articleContentHtml && <InnerHTML html={removeNewlineCode(currentEvent.articleContentHtml)} />}
        </div>
      )}
    </MenuWrapper>
  )
}

export default EventDetail
