import PropTypes from 'prop-types'
import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

export const Modal = ({ open, onClose, className, title, children, footer }) => {
  const classHeightScreen = () => {
    if (className.includes('m-h-100')) {
      return className
    }
    return `max-h-80 ${className}`
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="sm:w-[400px] relative z-[100]" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="relative inset-0 h-screen w-screen">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={classHeightScreen()}>
                {title && <Dialog.Title as="div">{title}</Dialog.Title>}
                {children}
                {footer}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.node,
  footer: PropTypes.node
}
