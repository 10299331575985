import React from 'react'
import PropTypes from 'prop-types'
import BtnBefore from 'src/assets/img/history/btn_wing_before.png'
import BtnNext from 'src/assets/img/history/btn_wing_next.png'

const Pagination = ({ totalPage, currPage, setCurrPage }) => {
  return (
    <>
      <div className="font-vldLineGR text-[9px] text-[#000000] text-right leading-[11px] mt-[5px] ml-[3px] mb-0 mr-[5px]">
        {totalPage ? currPage : 0}/{totalPage} page
      </div>
      <div className="relative mt-2">
        {currPage !== 1 ? (
          <button
            onClick={(e) => setCurrPage(currPage - 1)}
            className="cursor-pointer w-10 h-9 float-left ml-[40px]"
          >
            <img className="w-full h-full" src={BtnBefore} alt="before" />
          </button>
        ) : null}
        {currPage < totalPage ? (
          <button
            onClick={() => setCurrPage(currPage + 1)}
            className="cursor-pointer w-10 h-9 float-right mr-[40px]"
          >
            <img className="w-full h-full" src={BtnNext} alt="next" />
          </button>
        ) : null}
      </div>
    </>
  )
}

export default Pagination

Pagination.propTypes = {
  totalPage: PropTypes.number,
  currPage: PropTypes.number,
  setCurrPage: PropTypes.func
}
