import styled, { keyframes } from 'styled-components'

const LoadingIcon = () => {
  return (
    <Spinner>
      <div>
        {Array(12)
          .fill(null)
          .map((_, i) => (
            <Circle key={i} total={12} />
          ))}
      </div>
    </Spinner>
  )
}

export default LoadingIcon

const pulse = keyframes`
  0% {
    background: #fff8f9;
  }
  9% {
    background: #fff0f3;
  }
  18% {
    background: #ffe9ed;
  }
  27% {
    background: #ffe1e7;
  }
  36% {
    background: #ffd2db;
  }
  45% {
    background: #ffcbd5;
  }
  54% {
    background: #ffc4cf;
  }
  63% {
    background: #ffbcc9;
  }
  72% {
    background: #ffb4c3;
  }
  81% {
    background: #ffadbd;
  }
  90% {
    background: #ffa6b7;
  }
  100% {
    background: #ff9eb1;
  }
`

const Spinner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  width: 100%;
  position: relative;
`

const Circle = styled.div`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: ${pulse} 1s ease-in-out infinite;
  animation-duration: 1.2s;
  position: absolute;
  &:nth-child(1) {
    animation-delay: 0s;
    transform: rotate(30deg) translate(-220%, -50%);
  }
  &:nth-child(2) {
    animation-delay: 0.1s;
    transform: rotate(60deg) translate(-220%, -50%);
  }
  &:nth-child(3) {
    animation-delay: 0.2s;
    transform: rotate(90deg) translate(-220%, -50%);
  }
  &:nth-child(4) {
    animation-delay: 0.3s;
    transform: rotate(120deg) translate(-220%, -50%);
  }
  &:nth-child(5) {
    animation-delay: 0.4s;
    transform: rotate(150deg) translate(-220%, -50%);
  }
  &:nth-child(6) {
    animation-delay: 0.5s;
    transform: rotate(180deg) translate(-220%, -50%);
  }
  &:nth-child(7) {
    animation-delay: 0.6s;
    transform: rotate(210deg) translate(-220%, -50%);
  }
  &:nth-child(8) {
    animation-delay: 0.7s;
    transform: rotate(240deg) translate(-220%, -50%);
  }
  &:nth-child(9) {
    animation-delay: 0.8s;
    transform: rotate(270deg) translate(-220%, -50%);
  }
  &:nth-child(10) {
    animation-delay: 0.9s;
    transform: rotate(300deg) translate(-220%, -50%);
  }
  &:nth-child(11) {
    animation-delay: 1s;
    transform: rotate(330deg) translate(-220%, -50%);
  }
  &:nth-child(12) {
    animation-delay: 1.1s;
    transform: rotate(360deg) translate(-220%, -50%);
  }
`
