import PropTypes from 'prop-types'
import { Modal } from 'src/components/common/Modal'
import styled from 'styled-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import { isNextDayAfter5AM, TOKYO_TIME } from 'src/lib/date'
import { removeNewlineCode } from 'src/lib/string'
import InnerHTML from 'dangerously-set-html-content'

import BGModal from 'src/assets/img/common/bg-modal-confirm.png'
import BGModalTitle from 'src/assets/img/common/bg-modal-title.png'
import BtnClose from 'src/assets/img/common/btn-close.png'
import { useLocation } from 'react-router-dom'
import { useAuthState } from 'src/providers/AuthProviders'
import getNews from 'src/services/get/getNews'
import { useQuery } from 'react-query'
import { filterDisplayTips } from 'src/lib/news'

const TipsModal = (props) => {
  const { type, isDetail } = props
  const [isTipsModalOpen, setIsTipsModalOpen] = useState(false)
  const [isTipsDisplaySetting, setIsTipsDisplaySetting] = useState(false)
  const contentRef = useRef(null)
  const location = useLocation()
  const user = useAuthState()
  const news = useQuery('news', () => getNews(user.id, 'all'))
  const [tips, setTips] = useState([])
  const [countTips, setCountTips] = useState(0)

  useEffect(() => {
    const data = news?.data
    if (!data) return

    let pathname = ''
    if (isDetail) {
      const pagePath = location.pathname.split('/')
      pagePath.pop()
      pathname = pagePath.join('/') + '/'
    } else {
      pathname = location.pathname
    }
    setTips(filterDisplayTips(data, 'tips', pathname))
  }, [news.data, setTips])

  useEffect(() => {
    if (tips.length > 0) {
      const scrollToTop = () => {
        if (!contentRef.current) return
        contentRef.current.scrollTop = 0
      }
      setTimeout(() => {
        scrollToTop()
      }, 100)
    }
  }, [contentRef, tips])

  useEffect(() => {
    if (tips.length > 0) {
      const storage = JSON.parse(localStorage.getItem('tips'))
      if (!storage || !Object.keys(storage).includes(type)) return setIsTipsModalOpen(true)
      if (!isNextDayAfter5AM(new Date(storage[type].time))) return setIsTipsModalOpen(false)
      setIsTipsModalOpen(true)
      const saveStorageData = JSON.stringify({ ...storage })
      localStorage.setItem('tips', saveStorageData)
    }
  }, [setIsTipsModalOpen, tips])

  const handleTipsDisplaySetting = useCallback(() => {
    setCountTips(countTips + 1)
    if (!isTipsDisplaySetting) return
    const storage = JSON.parse(localStorage.getItem('tips'))
    const saveStorageData = JSON.stringify({
      ...storage,
      [type]: { time: TOKYO_TIME, isSetting: true }
    })
    localStorage.setItem('tips', saveStorageData)
  }, [setIsTipsDisplaySetting, isTipsDisplaySetting, countTips])

  return (
    <>
      {countTips < tips.length && (
        <Modal
          open={isTipsModalOpen}
          onClose={() => null}
          className="sm:w-[327px] w-10/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg"
        >
          <ModalBody>
            <img
              className="w-[50px] h-[43px] absolute -top-5 right-0 cursor-pointer"
              src={BtnClose}
              alt=""
              onClick={handleTipsDisplaySetting}
            />
            <div className="py-4 w-full max-h-full flex flex-col items-center justify-center rounded-md">
              <ModalTitle>{tips[countTips]?.name}</ModalTitle>
              <div
                ref={contentRef}
                className="h-[400px] mb-4 styled-scroll px-1 font-hiragino font-light text-xs text-[#000000] text-justify leading-6 overflow-y-auto whitespace-pre-line"
              >
                {tips.map((item, index) => (
                  <>{countTips === index && <InnerHTML html={removeNewlineCode(item.content)} />}</>
                ))}
              </div>
            </div>
            {tips[countTips]?.displaySetting && (
              <div className="w-full h-12 flex justify-center bg-[#DAEBFF] items-center">
                <input
                  className="mr-4"
                  type="checkbox"
                  onChange={() => setIsTipsDisplaySetting(!isTipsDisplaySetting)}
                  checked={isTipsDisplaySetting}
                />
                <p className="text-xs">次の午前5時まで表示しない</p>
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
    </>
  )
}

TipsModal.propTypes = {
  type: PropTypes.string.isRequired,
  isDetail: PropTypes.bool
}

export default TipsModal

const ModalBody = styled.div.attrs({
  className: 'w-full h-fit px-3 py-5 relative'
})`
  background-image: url(${BGModal});
  background-size: 100% 100%;
`

const ModalTitle = styled.div.attrs({
  className:
    'h-fit px-8 py-[7px] relative text-center text-white text-xl font-vldLineGR leading-[25px] mx-4 mb-4'
})`
  background-image: url(${BGModalTitle});
  background-size: 100% 100%;
`
