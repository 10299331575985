import { useEffect, useState } from 'react'
import StandardPageWrapper from 'src/components/common/StandardPageWrapper'
import { MainLayout } from 'src/components/layouts/MainLayout'
import MenuBar from 'src/components/menu/MenuBar'
import { useGetRecommendsData } from 'src/services/hooks/useFetchRecommends'
import Carroussel from './components/Carousel'

import Sora from 'src/assets/img/realtalk/realtalk_sub_sora.png'
import SyunSub from 'src/assets/img/realtalk/realtalk_sub_syun.png'
import ShoSub from 'src/assets/img/realtalk/realtalk_sub_sho.png'
import RenSub from 'src/assets/img/realtalk/realtalk_sub_ren.png'
import MinatoSub from 'src/assets/img/realtalk/realtalk_sub_minato.png'

import iconCharacterMina from 'src/assets/img/realtalk/realtalk_sd_minato.png'
import iconCharacterRen from 'src/assets/img/realtalk/realtalk_sd_ren.png'
import iconCharacterSho from 'src/assets/img/realtalk/realtalk_sd_sho.png'
import iconCharacterSora from 'src/assets/img/realtalk/realtalk_sd_sora.png'
import iconCharacterSuyn from 'src/assets/img/realtalk/realtalk_sd_syun.png'

import AsahiLove from 'src/assets/img/realtalk/realtalk_set_sora png.png'
import MinatoLove from 'src/assets/img/realtalk/realtalk_set_minato png.png'
import RenLove from 'src/assets/img/realtalk/realtalk_set_ren png.png'
import ShoLove from 'src/assets/img/realtalk/realtalk_set_sho png.png'
import SuynLove from 'src/assets/img/realtalk/realtalk_set_syun png.png'

import { getChatList } from 'src/services/get/getChat'
import getCharacters from 'src/services/get/getCharacters'
import { useAuthState } from 'src/providers/AuthProviders'
import Loading from 'src/components/layouts/Loading'
import useConversationListChannel from 'src/services/common/useConversationListChannel'
import TipsModal from 'src/components/common/TipsModal'

const ASHA = '朝日空'
const MINA = '横浜湊'
const REN = '若槻蓮'
const SHO = '風早翔'
const SUYN = '立花駿'
export const RealTalkPage = () => {
  const [currentImage, setCurrentImage] = useState()
  const [recommendMan, setRecommendMan] = useState([])
  const [miniRecommendMan, setMiniRecommendMan] = useState([])
  const [messages, setMessages] = useState([])
  const recommends = useGetRecommendsData()
  const GETID = recommends?.data?.find((item) => item.recommend)
  const { user } = useAuthState()
  const { message } = useConversationListChannel()

  useEffect(() => {
    ;(async () => {
      const [channelList, characters] = await Promise.all([
        getChatList(user.id, 'realtime_chat'),
        getCharacters()
      ])
      if (channelList) {
        const isPlayableCharacter = characters.filter((character) => character.playable)
        const filterChannelList = channelList.filter((channel) =>
          isPlayableCharacter.some((character) => character.id === channel.character.id)
        )
        const _channels = filterChannelList
          .filter((item) => {
            return item.character || (item.characters && item.characters.length)
          })
          .map((item) => {
            return {
              ...item,
              character: item.character || (item.characters || [])[0] || {},
              user: item.user || (item.users || [])[0] || {}
            }
          })
        setMessages(_channels)
      }
    })()
  }, [])

  useEffect(() => {
    if (!recommends.data) return
    const dataSort = recommends?.data
      .filter((x) => !x.recommend)
      .sort((a, b) => {
        if (b.level.love.level - a.level.love.level !== 0) {
          return b.level.love.level - a.level.love.level
        } else if (b.level.love.current_items - a.level.love.current_items !== 0) {
          return b.level.love.current_items - a.level.love.current_items
        } else {
          return a.id - b.id
        }
      })
    const secondValue = dataSort[1]
    dataSort.splice(1, 1)
    dataSort.push(secondValue)
    dataSort.unshift(recommends?.data.find((x) => x.recommend))
    const updatedRecommendMan = dataSort.map((item) => {
      switch (item.name) {
        case SHO:
          return {
            ...item,
            imageActive: ShoLove,
            imageGray: ShoSub,
            imgIcon: iconCharacterSho,
            imageLove: ShoLove
          }
        case ASHA:
          return {
            ...item,
            imageActive: AsahiLove,
            imageGray: Sora,
            imgIcon: iconCharacterSora,
            imageLove: AsahiLove
          }
        case MINA:
          return {
            ...item,
            imageActive: MinatoLove,
            imageGray: MinatoSub,
            imageLove: MinatoLove,
            imgIcon: iconCharacterMina
          }
        case REN:
          return {
            ...item,
            imageActive: RenLove,
            imageGray: RenSub,
            imgIcon: iconCharacterRen,
            imageLove: RenLove
          }
        case SUYN:
          return {
            ...item,
            imageActive: SuynLove,
            imageGray: SyunSub,
            imgIcon: iconCharacterSuyn,
            imageLove: SuynLove
          }
        default:
          return item
      }
    })
    const index = updatedRecommendMan.findIndex((d) => d.recommend === true)

    if (index !== -1) {
      const first = updatedRecommendMan.slice(index)
      const second = updatedRecommendMan.slice(0, index)
      const dataResult = [...first, ...second]
      setRecommendMan(dataResult)
    }

    const miniResult = updatedRecommendMan.sort((a, b) => {
      if (a.recommend && !b.recommend) {
        return -1
      } else if (!a.recommend && b.recommend) {
        return 1
      } else if (b.level.love.level - a.level.love.level !== 0) {
        return b.level.love.level - a.level.love.level
      } else if (b.level.love.current_items - a.level.love.current_items !== 0) {
        return b.level.love.current_items - a.level.love.current_items
      } else {
        return a.id - b.id
      }
    })

    setMiniRecommendMan(miniResult)

    setCurrentImage(GETID?.id)
  }, [recommends?.data])

  const updateRecommendById = (newArr) => {
    setRecommendMan(newArr)
    const copyMini = [...miniRecommendMan]
    newArr.forEach((data) => {
      const index = copyMini.findIndex((d) => d.id === data.id)
      if (index > -1) {
        copyMini[index].recommend = data.recommend
      }
    })
    setMiniRecommendMan(copyMini)
  }

  useEffect(() => {
    if (!message?.conversation_id) return
    const copyMessages = [...messages]
    const index = copyMessages.findIndex(msg => msg.conversation_id === message.conversation_id)
    if (index >= 0) {
      copyMessages[index] = { ...copyMessages[index], ...message }
      setMessages(copyMessages)
    }
  }, [message])

  return (
    <>
      <MenuBar title="realtalk" />
      <MainLayout
        active="event"
        classNameHeader="pt-menu-header-offset xs:px-4 px-0"
        showFooter={false}
      >
        <StandardPageWrapper
          className={
            'carousel-standard-wrapper flex flex-col overflow-x-clip h-[calc(100%-96px)] fixed bottom-[calc((100%-760px)*(-1))]'
          }
        >
          {recommendMan.length > 0 && messages.length > 0 ? (
            <Carroussel
              defaultValue={recommends.data}
              mypageImage={recommendMan.map((itemA) => {
                const matchingItemB = messages.find((itemB) => itemB.character.id === itemA.id)
                if (matchingItemB) {
                  return {
                    ...itemA,
                    unreadTotal: matchingItemB.unreadTotal
                  }
                }
                return itemA
              })}
              message={message}
              updateRecommendById={updateRecommendById}
              currentImage={currentImage}
              setCurrentImage={setCurrentImage}
              messages={messages}
              miniRecommendMan={miniRecommendMan.map((itemA) => {
                const matchingItemB = messages.find((itemB) => itemB.character.id === itemA.id)
                if (matchingItemB) {
                  return {
                    ...itemA,
                    unreadTotal: matchingItemB.unreadTotal
                  }
                }
                return itemA
              })}
            />
          ) : (
            <Loading />
          )}
          <TipsModal type="realtalk" />
        </StandardPageWrapper>
      </MainLayout>
    </>
  )
}
