import PropTypes from 'prop-types'
import HTMLReactParser from 'html-react-parser'
import TitleHeader from 'src/components/common/TitleHeader'
import { removeNewlineCode } from 'src/lib/string'

const LegaleseWrapper = ({ title, content, children }) => {
  return (
    <div className="py-4 bg-menu-off-blue w-full max-h-full flex flex-col items-center justify-center rounded-md">
      {title ? <TitleHeader title={title} /> : null}
      <div className="styled-scroll pl-[1.85rem] pr-3.5 mr-4 font-hiragino font-light text-xs text-[#000000] text-justify leading-6 overflow-y-auto whitespace-pre-line">
        {HTMLReactParser(removeNewlineCode(content))}
      </div>
      {children}
    </div>
  )
}

LegaleseWrapper.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}

export default LegaleseWrapper
