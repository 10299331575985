import clsx from 'clsx'
import PropTypes from 'prop-types'

const InfoBox = ({ title, children, className }) => {
  return (
    <div
      className={clsx(
        'w-full rounded border border-menu-dark-blue text-xs font-hiragino',
        null || className
      )}
    >
      <div className="w-full text-left text-white p-2 bg-menu-dark-blue truncate">{title}</div>
      <div className="p-4 whitespace-pre-line w-full text-left bg-white">{children}</div>
    </div>
  )
}

InfoBox.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  className: PropTypes.string
}

export default InfoBox
