import PropTypes from 'prop-types'

const NoContent = ({ message = '探していたコンテンツが見つかりませんでした' }) => {
  return (
    <div className="bg-white/50 py-12 px-4 w-full text-2xl rounded-md font-vldLineGR whitespace-pre-line">
      {message}
    </div>
  )
}

NoContent.propTypes = { message: PropTypes.string }

export default NoContent
