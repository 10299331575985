import React, { useState, useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { CharacterItem } from 'src/components/ranking/CharacterItem'
import { RankingItem } from 'src/components/ranking/RankingItem'
import { Link, useNavigate } from 'react-router-dom'
import { rankingCharacters } from 'src/constants/characters'
import { useFetchRankingData } from 'src/pages/myPage/Ranking/hooks/useFetchRankingData'

import BGPage from 'src/assets/img/ranking/bg-page.png'
import Effect1 from 'src/assets/img/ranking/bg-eff-1.png'
import Effect2 from 'src/assets/img/ranking/bg-eff-2.png'
import Effect3 from 'src/assets/img/ranking/bg-eff-3.png'
import BGGrid from 'src/assets/img/ranking/bg-grid.png'
import SeeReward from 'src/assets/img/ranking/see-reward.png'
import BGLoveHeader from 'src/assets/img/ranking/love-parameter-header.png'
import BGTrainingHeader from 'src/assets/img/ranking/training-parameter-header.png'
import BGDX6 from 'src/assets/img/ranking/bg-dx6.png'
import DX6ThisMonth from 'src/assets/img/ranking/d6-thismonth-ranking.png'
import D6Characters from 'src/assets/img/ranking/d6-characters.png'
import LoveSeemoreBtn from 'src/assets/img/ranking/love-parameter-seemore.png'
import TrainSeemoreBtn from 'src/assets/img/ranking/training-parameter-seemore.png'
import LoveIcon from 'src/assets/img/ranking/love-parameter-icon.png'
import TrainIcon from 'src/assets/img/ranking/training-parameter-icon.png'
import BackIcon from 'src/assets/img/ranking/icon-back.png'
import HomeIcon from 'src/assets/img/ranking/icon-home.svg'
import BGMenu from 'src/assets/img/ranking/bg-menu.png'
import BGBackIcon from 'src/assets/img/ranking/bg-back-icon.png'
import BGHomeIcon from 'src/assets/img/ranking/bg-home-icon.png'
import BGD6xBtn from 'src/assets/img/ranking/bg-d6x.png'
import { useRankingRewardInfo } from 'src/services/hooks/useFetchRankingRewardEvents'
import { useQuery } from 'react-query'
import getNews from 'src/services/get/getNews'
import { useAuthState } from 'src/providers/AuthProviders'
import Loading from 'src/components/layouts/Loading'
import TipsModal from 'src/components/common/TipsModal'

export const RankingPage = () => {
  const navigate = useNavigate()
  const { activeIndex, setActiveIndex, ranking } = useFetchRankingData()
  const [selectUser, setSelectUser] = useState(rankingCharacters[0])
  const { data: reward = {}, isLoading: rewardIsLoading } = useRankingRewardInfo()

  const user = useAuthState()
  const news = useQuery('news', () => getNews(user.id, 'tips'))

  const handleSelectUser = useCallback(
    (id) => {
      if (!id) return
      setActiveIndex(id)
      const targetUser = rankingCharacters.find((character) => character.id === id)
      setSelectUser(targetUser)
    },
    [setActiveIndex, setSelectUser]
  )

  const gotoEvent = useMemo(() => {
    if (!reward?.event) return ''
    return `/events/${reward.event.id}`
  }, [reward])

  if (news.isLoading || rewardIsLoading) return <Loading />

  return (
    <Wrapper>
      <div className="sm:w-[400px] w-full fixed top-10 bottom-0">
        <img src={Effect1} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-18 bottom-0">
        <img src={Effect2} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-0 bottom-0">
        <img src={Effect3} alt="" />
      </div>
      <div className="sm:w-[400px] w-full fixed top-0 bottom-0 z-0">
        <img src={BGGrid} alt="" />
      </div>
      <div className="h-[10vh] max-h-[86px]">
        <div className="sm:w-[400px] w-full fixed top-0 left-0 sm:left-1/2 sm:-translate-x-1/2 content-center py-8 z-50 flex items-start justify-between">
          <MenuLeft>
            <div className="relative w-auto h-5 px-5">
              <div
                onClick={() => navigate(-1)}
                className="h-full w-5 pr-[3px] pb-[3px] cursor-pointer"
                style={{
                  backgroundImage: `url(${BGBackIcon})`,
                  backgroundSize: '90% 90%',
                  backgroundPosition: 'right bottom',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <img className="object-contain w-full h-full" src={BackIcon} alt="" />
              </div>
            </div>
            <div className="bg-white w-[1px] h-6" />
            <div className="relative h-5 pl-4 pr-10">
              <div
                className="h-full w-5 pr-[2px] pb-[1px] cursor-pointer"
                style={{
                  backgroundImage: `url(${BGHomeIcon})`,
                  backgroundSize: '80% 90%',
                  backgroundPosition: 'right bottom',
                  backgroundRepeat: 'no-repeat'
                }}
              >
                <Link to="/mypage">
                  <img className="object-cover w-full h-full" src={HomeIcon} alt="" />
                </Link>
              </div>
            </div>
          </MenuLeft>
          <div className="w-44 h-[6vh] max-h-[48px]">
            <Link to={gotoEvent}>
              <img className="w-full h-full" src={SeeReward} alt="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full pt-1 pb-[130px] overflow-x-hidden overflow-y-auto z-50">
        <div className="relative">
          <div className="absolute top-6 bottom-0 w-full bg-[#d0cad7]" />
          <div className="grid grid-cols-5 gap-1 px-2 pb-2">
            {rankingCharacters.map((character) => (
              <CharacterItem
                key={character.id}
                character={character}
                isActive={character.id === activeIndex}
                onClick={() => handleSelectUser(character.id)}
              />
            ))}
          </div>
        </div>
        <div className="px-2 bg-[#d0cad7] relative">
          <RankingHeader background={BGLoveHeader}>
            <div className="text-[#723ba5]">
              <h3 className="text-base font-vldLineGR font-bold leading-[16px]">
                {selectUser.name}
              </h3>
              <p className="text-xs font-medium font-hiragino">今月のLOVE度ランキング</p>
            </div>
            <Link
              to="/ranking/detail/love"
              className="absolute w-20 h-7 xs:w-24 xs:h-7 xs:right-12 right-11 top-4"
            >
              <img className="object-contain w-full h-full" src={LoveSeemoreBtn} alt="See more" />
            </Link>
          </RankingHeader>
          <div className="grid grid-cols-5 gap-1.5 py-2">
            {(ranking.love || []).map((item, index) => (
              <RankingItem key={index} ranking={item} />
            ))}
          </div>
          <div className="pb-2 info font-hiragino">
            <div className="flex mb-1">
              <img className="h-3" src={LoveIcon} alt="love icon" />
              <div className="ml-1 text-[#723ba5] text-[10px]">
                私の今月のLOVE度ランキング
                <strong className="pl-1">{ranking?.love?.userRank || '--'}</strong>位
              </div>
            </div>
            <div className="flex">
              {/* <img className="h-3" src={InfoIcon} alt="info icon" /> */}
              <div className="ml-1 text-[10px]">
                {/* <span className="pt-1 inline-block rounded-[50%] w-3 h-3 bg-[#383838]" /> */}
                {/* 今週のLOVE度ランキング●位以内で握手会のトークに参加可能 */}
              </div>
            </div>
          </div>
        </div>
        <div className="px-2 bg-[#c4cad0] relative">
          <RankingHeader background={BGTrainingHeader}>
            <div className="text-[#1a6784]">
              <h3 className="text-base font-vldLineGR font-bold leading-[16px]">
                {selectUser.name}
              </h3>
              <p className="text-xs font-medium font-hiragino">今月の育て度ランキング</p>
            </div>
            <Link
              to="/ranking/detail/grow"
              className="absolute w-20 h-7 xs:w-24 xs:h-7 xs:right-12 right-11 top-4"
            >
              <img className="object-contain w-full h-full" src={TrainSeemoreBtn} alt="See more" />
            </Link>
          </RankingHeader>
          <div className="grid grid-cols-5 gap-1.5 py-2">
            {(ranking.grow || []).map((item, index) => (
              <RankingItem key={index} ranking={item} />
            ))}
          </div>
          <div className="pb-2 info font-hiragino">
            <div className="flex mb-1">
              <img className="h-3" src={TrainIcon} alt="love icon" />
              <div className="ml-1 text-[10px] text-[#1a6784]">
                私の今月の育て度ランキング
                <strong className="pl-1">{ranking?.grow?.userRank || '--'}</strong>位
              </div>
            </div>
            <div className="flex">
              {/* <img className="h-3" src={InfoIcon} alt="info icon" /> */}
              <div className="ml-1 text-[10px]">
                {/* 今週のLOVE度<span className="pt-1 inline-block rounded-[50%] w-3 h-3 bg-[#383838]" />位以内不握手会の卜一夕仁参加可能 */}
                {/* 今日の育て度ランキング●位以内でプリンスルームに入室可能 */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Link to="/ranking/dx6">
        <RankingDx6>
          <div className="absolute -bottom-1 left-5 h-[14vh] max-h-[134px] w-full">
            <img
              className="w-[60%] object-contain object-bottom"
              src={D6Characters}
              alt="D6Characters"
            />
          </div>
          <D6xBtn>
            <img className="xs:h-full xs:w-full" src={DX6ThisMonth} alt="Dx6 This Month" />
          </D6xBtn>
        </RankingDx6>
      </Link>
      <TipsModal type="ranking" />
    </Wrapper>
  )
}

const Wrapper = styled.div.attrs({
  className: 'sm:w-[400px] w-full h-screen mx-auto relative bg-fixed overflow-hidden'
})`
  background-image: url(${BGPage});
  background-size: 100% 100%;
`

const MenuLeft = styled.div.attrs({
  className: 'flex items-center justify-between h-[4vh] max-h-[36px]'
})`
  background-image: url(${BGMenu});
  background-size: 100% 100%;
`

const RankingHeader = styled.div.attrs({
  className: 'relative h-[8vh] max-h-[56px] flex justify-center items-center -mx-11'
})`
  background-image: url(${(props) => props.background});
  background-size: 100% 100%;
`

const RankingDx6 = styled.div.attrs({
  className: 'w-screen sm:w-[400px] fixed bottom-0 h-[13vh] max-h-[112px]'
})`
  background-image: url(${BGDX6});
  background-size: 100% 100%;
`

const D6xBtn = styled.div.attrs({
  className: 'absolute top-3 right-0 xs:h-16 xs:w-36 h-12 w-28'
})`
  background-image: url(${BGD6xBtn});
  background-size: 100% 74%;
  background-repeat: no-repeat;
  background-position: top;
`
