import Inquiry from 'src/models/Inquiry'
import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Fetches contact inquiries (お問い合わせ)
 *
 * @param {number} userId The ID representing the user whose data you wish to fetch
 * @returns An array of inquiry data.
 */
const getUserInquiries = async (userId) => {
  if (!userId) return []

  try {
    const res = await axiosInstance.get(`${API_ROUTES.Users}/${userId}/${API_ROUTES.Inquiries}`)

    if (res.data.success === false) return []
    else {
      const contacts = []
      for (const c of res.data.user_contacts) {
        const contact = new Inquiry(
          c.id,
          c.attached_file,
          c.contact_kind,
          c.content,
          c.admin_response,
          c.payment_amount,
          c.process_status,
          c.title,
          c.created_at,
          '',
          c.is_readed
        )
        contacts.push(contact)
      }

      return contacts
    }
  } catch (error) {
    return []
  }
}

export default getUserInquiries
