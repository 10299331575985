import React, { useState } from 'react'
import iconExtraTime from '../assets/icon_extra_time.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { formattedCountDownTime } from 'src/lib/date'

const LiveExtraTime = ({ time, className }) => {
  const [isVisible, setIsVisible] = useState(new Date(time) > new Date())

  return (
    <>
      { isVisible && (
        <div className={clsx('absolute w-[fit-content]', className)}>
          <img src={iconExtraTime} className="w-[89px] h-[34px]" />
          <span className="absolute bottom-[-1px] text-[10px] left-[55%] translate-x-[-50%] text-white font-hiragino">
            <Countdown
              date={new Date(time)}
              onComplete={() => setIsVisible(false)}
              renderer={({ hours, minutes, seconds }) => {
                return <>{formattedCountDownTime(hours, minutes, seconds)}</>
              }}
            />
          </span>
        </div>)
      }
    </>
  )
}

LiveExtraTime.propTypes = {
  time: PropTypes.string,
  className: PropTypes.string
}

export { LiveExtraTime }
