import styled from 'styled-components'
import PropTypes from 'prop-types'

import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import BtnComplete from 'src/assets/img/dressup/completion_1.png'
import ArrowDoubleTop from 'src/assets/img/dressup/arrow_double_top.png'
import ArrowDoubleBottom from 'src/assets/img/dressup/arrow_double_bottom.png'
import ArrowDoubleLeft from 'src/assets/img/dressup/arrow_double_left.png'
import ArrowDoubleRight from 'src/assets/img/dressup/arrow_double_right.png'
import ArrowSingleTop from 'src/assets/img/dressup/arrow_single_top.png'
import ArrowSingleBottom from 'src/assets/img/dressup/arrow_single_bottom.png'
import ArrowSingleLeft from 'src/assets/img/dressup/arrow_single_left.png'
import ArrowSingleRight from 'src/assets/img/dressup/arrow_single_right.png'
import BGMenu from 'src/assets/img/dressup/bg-remote.png'

const MenuCross = ({ onClose, onClick }) => {
  return (
    <div className='relative h-full w-full' style={{
      backgroundImage: `url(${BGMenu})`
    }}>
      <div className='absolute left-3 top-3 text-white text-[10px] font-Inter'>
        設置する場所を十字キーで調整
      </div>
      <div className='absolute' style={{
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
      }}>
        <div className='mx-auto w-[45px] h-[45px] mb-3'>
          <img className='w-full h-full' src={ArrowDoubleTop} alt='arrow double top' />
        </div>
        <div className='mx-auto w-[45px] h-[18px] mb-2'>
          <img className='w-full h-full' src={ArrowSingleTop} alt='arrow double left' />
        </div>
        <div className='flex justify-center items-center'>
          <div className='w-[45px] h-[45px] mr-[34px]'>
            <img className='w-full h-full' src={ArrowDoubleLeft} alt='arrow double left' />
          </div>
          <div className='w-[18px] h-[45px] mr-[39px]'>
            <img className='w-full h-full' src={ArrowSingleLeft} alt='arrow double left' />
          </div>
          <div className='w-[18px] h-[45px] ml-[39px]'>
            <img className='w-full h-full' src={ArrowSingleRight} alt='arrow double left' />
          </div>
          <div className='w-[45px] h-[45px] ml-[34px]'>
            <img className='w-full h-full' src={ArrowDoubleRight} alt='arrow double right' />
          </div>
        </div>
        <div className='mx-auto w-[45px] h-[18px] mt-2'>
          <img className='w-full h-full' src={ArrowSingleBottom} alt='arrow double left' />
        </div>
        <div className='mx-auto w-[45px] h-[45px] mt-3'>
          <img className='w-full h-full' src={ArrowDoubleBottom} alt='arrow double top' />
        </div>
      </div>
      <div className='absolute w-full flex justify-between bottom-4 px-3'>
        <ButtonWrapper onClick={onClose} background={BtnCancel} />
        <ButtonWrapper background={BtnComplete} />
      </div>
    </div>
  )
}

const ButtonWrapper = styled.div.attrs({
  className: 'w-[91px] h-[37px]'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

MenuCross.propTypes = {
  onClick: PropTypes.func,
  onClose: PropTypes.func
}

export default MenuCross
