import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Create a new inquiry
 *
 * @param {string} userId The ID of the user who is making the request
 * @param {string} content The content of the request body
 * @param {string} category The category ID for the inquiry type
 * @returns True on succesful request, false on unsuccessful request
 */

const postUserInquiry = async (userId, content, category, files) => {
  if (!userId || !content || !category) return false

  // TODO: 執筆時点では、モックアップは content または contact_kinds フィールドをサポートしていませんでした。 統合を更新する必要がある場合があります。
  const formData = new FormData()
  formData.append('user_contacts[contact_kind]', category.id)
  formData.append('user_contacts[content]', content)

  if (files.file1) {
    formData.append('user_contacts[attached_file]', files.file1)
  }

  if (files.file2) {
    formData.append('user_contacts[attached_file_two]', files.file2)
  }

  if (files.file3) {
    formData.append('user_contacts[attached_file_three]', files.file3)
  }

  try {
    const res = await axiosInstance.post(`${API_ROUTES.Users}/${userId}/${API_ROUTES.Inquiries}`, formData)
    if (!res.data.success) return false
    return true
  } catch (error) {
    return false
  }
}

export default postUserInquiry
