import React, { useState, useEffect } from 'react'
import { useCharacterLiveContext } from './_Provider'
import iconLiveLove from '../../assets/icon_live_love.png'
import PropTypes from 'prop-types'
import _ from 'lodash'

const LiveLovePoint = ({ livestreamId, lovePoint }) => {
  const [displayPoint, setDisplayPoint] = useState(lovePoint)
  const { characterLiveSocket } = useCharacterLiveContext()

  useEffect(() => {
    if (_.isEmpty(characterLiveSocket.loveData)) return

    if (livestreamId === characterLiveSocket.loveData.livestream_id) setDisplayPoint(characterLiveSocket.loveData.love_point)
  }, [characterLiveSocket.loveData])

  return (
    <div className="absolute bottom-[6px] left-[6px]">
      <img src={iconLiveLove} className="w-[100px] h-full" />
      <span className="absolute bottom-[1px] text-[10px] left-[45%] translate-x-[-50%] font-hiragino">{displayPoint}</span>
    </div>
  )
}

LiveLovePoint.propTypes = {
  livestreamId: PropTypes.any,
  lovePoint: PropTypes.any
}

export default LiveLovePoint
