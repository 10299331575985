import { useState, useEffect } from 'react'

const useAudio = url => {
  const [audio, setAudio] = useState(null)
  const [playing, setPlaying] = useState(false)

  const playAudio = () => {
    if (!audio) {
      const audioObj = new Audio(url)
      setAudio(audioObj)
    }
    setPlaying(true)
  }

  const pauseAudio = () => {
    if (audio) {
      audio.pause()
    }
    setPlaying(false)
  }

  useEffect(() => {
    if (audio) {
      playing ? audio.play().catch((err) => alert(err)) : audio.pause()
    }
  }, [playing, audio]
  )

  useEffect(() => {
    if (audio) {
      audio.addEventListener('ended', () => setPlaying(false))
      return () => {
        audio.pause()
        audio.removeEventListener('ended', () => setPlaying(false))
        setAudio(null)
      }
    }
  }, [audio])

  return [playing, playAudio, pauseAudio]
}

export default useAudio
