import PropTypes from 'prop-types'
import clsx from 'clsx'

import DiamondRed from 'src/assets/img/common/diamond-red.png'
import DiamondBlue from 'src/assets/img/common/diamond-blue.png'
import SSRTicket from 'src/assets/img/gacha/ssr-ticket.png'
import GachaTicket from 'src/assets/img/gacha/gacha-ticket.png'
import Life from 'src/assets/img/common/life.png'

const CurrencyChangeIndicator = ({ icon, from, to, counter = '枚', hasChange = false }) => {
  const getIcon = () => {
    switch (icon) {
      case 'life':
        return Life
      case 'blue':
        return DiamondBlue
      case 'ssr':
        return SSRTicket
      case 'ticket':
        return GachaTicket
      case 'red':
      default:
        return DiamondRed
    }
  }
  return (
    <div className="flex text-sm w-full justify-between items-center mt-2 rounded-md bg-white">
      <span className="w-28 py-2 px-4 bg-menu-light-blue rounded-md text-center flex items-center justify-around">
        <img className="w-4" src={getIcon()} />
        <span>所有数</span>
      </span>
      <span className="w-22 p-1 text-right">{`${from}${counter}`}</span>
      <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.52589 4.1934L1.45446 0.128952C0.879911 -0.201127 0 0.119186 0 0.935592L0 9.06255C0 9.79497 0.817634 10.2364 1.45446 9.86919L8.52589 5.80669C9.1567 5.44536 9.1587 4.55473 8.52589 4.1934Z"
          fill="#333333"
        />
      </svg>
      <span className={clsx('w-22 p-2', hasChange && 'text-warn-red')}>{`${to}${counter}`}</span>
    </div>
  )
}

CurrencyChangeIndicator.propTypes = {
  icon: PropTypes.string.isRequired,
  from: PropTypes.number.isRequired,
  to: PropTypes.number.isRequired,
  counter: PropTypes.string,
  hasChange: PropTypes.bool
}

export default CurrencyChangeIndicator
