import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const registerCloth = async (characterId, characterClothId, userId) => {
  try {
    const URL = API_ROUTES.Character.RegisterCloth.replace(':id', characterId).replace(
      ':character_cloth_id',
      characterClothId
    )
    const res = await axiosInstance.post(URL, { user_id: userId })
    if (res.data.success === false) return false
    return true
  } catch (error) {
    return false
  }
}

export default registerCloth
