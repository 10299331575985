import Proptypes from 'prop-types'
import styled from 'styled-components'

import BGMusictSetting from 'src/assets/img/common/bg_music-setting.png'
import BGBarBgm from 'src/assets/img/common/bar-bg-bgm.png'
import BGBarSe from 'src/assets/img/common/bar-bg-se.png'
import BGBarVoice from 'src/assets/img/common/bar-bg-voice.png'
import BtnToggleOff from 'src/assets/img/common/btn_toggle-off.png'
import BtnToggleOn from 'src/assets/img/common/btn_toggle-on.png'
import BtnModalClose from 'src/assets/img/common/btn-close02.png'

export const MusicModal = ({ muteSettings, handleAudioClick, handleMusicModalToggle }) => {
  return (
    <>
      <Wrapper>
        <Overlay onClick={() => handleMusicModalToggle()}>
          <Modal onClick={(e) => e.stopPropagation()}>
            <CloseBtn
              onClick={(e) => {
                e.stopPropagation()
                handleMusicModalToggle()
              }}
            />
            <MusicSettingBar>
              <img src={BGBarBgm} alt="" />
              <ToggleBtn onClick={() => handleAudioClick('bgm')} isMuted={muteSettings.bgm} />
            </MusicSettingBar>
            <MusicSettingBar>
              <img src={BGBarSe} alt="" />
              <ToggleBtn onClick={() => handleAudioClick('se')} isMuted={muteSettings.se} />
            </MusicSettingBar>
            <MusicSettingBar>
              <img src={BGBarVoice} alt="" />
              <ToggleBtn onClick={() => handleAudioClick('voice')} isMuted={muteSettings.voice} />
            </MusicSettingBar>
          </Modal>
        </Overlay>
      </Wrapper>
    </>
  )
}

MusicModal.propTypes = {
  muteSettings: Proptypes.object,
  handleAudioClick: Proptypes.func,
  handleMusicModalToggle: Proptypes.func
}

const Wrapper = styled.div.attrs({
  className: 'z-50'
})`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Overlay = styled.div.attrs({})`
  width: 100%;
  height: 100%;
  background-color: rgb(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
`

const Modal = styled.div.attrs({})`
  position: relative;
  background: url(${BGMusictSetting}) no-repeat center center / cover;
  width: 280px;
  height: 317px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 16px 24px;
`

const CloseBtn = styled.button.attrs({})`
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(35%, -42%);
  width: 80px;
  height: 67px;
  background: url(${BtnModalClose}) no-repeat center center / cover;
`

const MusicSettingBar = styled.div.attrs({})`
  width: 100%;
  position: relative;
`
const ToggleBtn = styled.button.attrs({})`
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  width: 44px;
  height: 24px;
  background-image: url(${(props) => (props.isMuted ? BtnToggleOff : BtnToggleOn)});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
`
