import { useState, useEffect, useMemo, useRef, useCallback } from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { getHistoryManga } from 'src/services/get/getHistoryManga'
import { characterNames } from 'src/constants/characters'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
import Loading from 'src/components/layouts/Loading'

import BGPage from 'src/assets/img/stillalbum/bg_common.png'
import { birthCartoonBGM } from 'src/constants/character_bgm'
import MangaInfoModal from 'src/components/Manga/MangaInfoModal'
import MangaContents from 'src/components/Manga/MangaContents'

export const RecommendMangaPage = () => {
  const { id, name } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [isOpen, setIsOpen] = useState(false)
  const [currPage, setCurrPage] = useState(1)

  const ref = useRef(null)
  const fetchData = useCallback(async () => {
    const result = await getHistoryManga(id)
    if (result) setData(result)
    setLoading(false)
  }, [id])

  useEffect(() => {
    fetchData()
  }, [])

  const limitPageNumber = useMemo(() => data.limit_page_number, [data])

  const nameTitle = useMemo(() => (characterNames.includes(name) ? name : 'noname'), [name])

  const bgm = useMemo(() => birthCartoonBGM(id, currPage), [id, currPage])

  useEffect(() => {
    if (!ref.current) return
    ref.current.volume = 0.3
  }, [ref.current])

  const toggleModal = useCallback(() => setIsOpen(!isOpen), [isOpen])

  return (
    <Wrapper>
      {bgm && <audio ref={ref} src={bgm} autoPlay loop preload="auto" />}
      <Header>
        <MenuBar title={`history-${nameTitle}`} />
      </Header>
      <MainLayout
        className="h-fit mana-wrapper"
        active="mypage"
        showHeader={false}
        showFooter={false}
      >
        {loading ? (
          <Loading />
        ) : (
          <MangaContents
            data={data.items}
            allPageOfManga={data.all_page_of_manga}
            currPage={currPage}
            setCurrPage={setCurrPage}
            limitPageNumber={limitPageNumber}
            isOpen={isOpen}
            toggleModal={toggleModal}
          />
        )}
        <MangaInfoModal isOpen={isOpen} toggleModal={toggleModal} />
      </MainLayout>
    </Wrapper>
  )
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})``

const Wrapper = styled.div.attrs({
  className:
    'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-hidden bg-center bg-cover bg-no-repeat'
})`
  background-image: url(${BGPage});
`
