import { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import MenuBar from 'src/components/menu/MenuBar'
import { MainLayout } from 'src/components/layouts/MainLayout'
// import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import PropTypes from 'prop-types'
import { getStillAlbum } from 'src/services/get/getRecommend'
import { useParams, useNavigate } from 'react-router-dom'
import { MYPAGE_PATHS } from 'src/routes/myPage'
import { StillAlbumContext } from 'src/contexts'
import useAudio from 'src/components/common/UseAudio'

import BGStillFolder from 'src/assets/img/stillalbum/still_folder.png'
import NavigatorSorry from 'src/assets/img/character/mascot/sorry.png'
import BGPage from 'src/assets/img/stillalbum/bg_common.png'
import BGStillAlbum from 'src/assets/img/stillalbum/bg_stillalbum.png'
import StillR from 'src/assets/img/stillalbum/still_R.png'
import StillSR from 'src/assets/img/stillalbum/still_SR.png'
import StillSSR from 'src/assets/img/stillalbum/still_SSR.png'
import BtnStillAlbumBefore from 'src/assets/img/stillalbum/btn_album_before.png'
import BtnStillAlbumNext from 'src/assets/img/stillalbum/btn_album_next.png'
import BtnReturn from 'src/assets/img/common/btn_return.png'
import BtnListenVoice from 'src/assets/img/stillalbum/detail/btn_listeningvoice.png'

const rarities = {
  r: StillR,
  sr: StillSR,
  ssr: StillSSR
}

const StillFolder = ({ item = {} }) => {
  const { paramater = 0, image } = item
  return (<div>
    <StillFolderWrapper>
      { image?.url ? <img className="w-full h-full object-cover object-center" src={image?.url} alt='Still Folder' /> : null }
    </StillFolderWrapper>
    <div className='h-[23px] w-full'>
      <img className='w-full h-full' src={require(`src/assets/img/stillalbum/paramater_${paramater >= 9 ? 'max' : paramater}.png`)} alt="" />
    </div>
  </div>)
}

StillFolder.propTypes = {
  item: PropTypes.shape({
    paramater: PropTypes.number,
    image: PropTypes.shape({
      url: PropTypes.string
    })
  })
}

const CharacterStill = ({ onClick, item = {} }) => {
  const { rarity, thumbnail } = item
  const rarityImg = rarities[rarity] || StillR
  const handleClick = () => {
    if (item?.thumbnail) {
      onClick(item)
    }
  }
  return (<div>
    <CharacterStillWrapper onClick={handleClick} background={rarityImg} >
      { thumbnail
        ? <ThumbnailWrapper background={thumbnail}>
            <div className='bg-[rgba(255,255,255,0.5)] pt-1.5 pb-[2px] px-[3px]'>
              <p className='font-Inter font-bold text-[10px] leading-[12px] text-[#FF0000]'>{item?.name}</p>
            </div>
          </ThumbnailWrapper>
        : <p className='w-full pl-5 pr-3 text-[10px] font-vldLineGR text-[#000000]' style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>{item?.name}</p> }

    </CharacterStillWrapper>
  </div>)
}

CharacterStill.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func
}

const CharacterStillWrapper = styled.div.attrs({
  className: 'h-[122px] w-full relative pt-[22px] pb-2 pl-3.5 pr-3'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const ThumbnailWrapper = styled.div.attrs({
  className: 'h-full w-full relative py-[18px] px-2.5'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`

const StillFolderWrapper = styled.div.attrs({
  className: 'h-[119px] w-full'
})`
  background-image: url(${BGStillFolder});
  background-size: 100% 100%;
`

const CharacterStillDetail = ({ item = {}, isPauseAll }) => {
  const [playing, toggle] = useAudio(item?.character_voice?.url)
  useEffect(() => {
    if (isPauseAll && playing) toggle()
  })
  return (<CharacterStillDetailWrapper background={item?.thumbnail}>
    <div className='absolute bottom-0 w-[170px] h-[55px]' style={{
      left: '50%',
      transform: 'translate(-50%, -50%)'
    }}>
      {item?.character_voice?.url ? <div onClick={toggle}><img src={BtnListenVoice} className="w-full h-full" alt="Play voice" /></div> : null}
    </div>
  </CharacterStillDetailWrapper>
  )
}

CharacterStillDetail.propTypes = {
  item: PropTypes.object,
  isPauseAll: PropTypes.bool
}

export const RecommendStillAlbumDetailPage = () => {
  const { stillAlbums } = useContext(StillAlbumContext)
  const [stillAlbumDetail, setStillAlbumDetail] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [characterStillDetail, setCharacterStillDetail] = useState(null)
  const [index, setIndex] = useState(0)
  const [isPauseAll, setIsPauseAll] = useState(false)

  const { characterId, id, name } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const res = await getStillAlbum(characterId, id)
      if (res.success) {
        setStillAlbumDetail(res.items)
      }
      setIsLoading(false)
    }
    fetchData()
  }, [id])

  useEffect(() => {
    if (stillAlbums && stillAlbums.length > 0) {
      const _idx = stillAlbums.findIndex(item => item.id === parseInt(id))
      setIndex(_idx)
    }
  }
  , [stillAlbums, id])

  const handleClick = (idx) => {
    if (idx < 0 || idx >= stillAlbums.length) return
    const _item = stillAlbums[idx]
    navigate(MYPAGE_PATHS.RecommendStillAlbumDetailPage.replace(':characterId', characterId).replace(':id', _item.id).replace(':name', name || 'noname'))
  }

  const onBackFromCharacterStillDetail = () => {
    setIsPauseAll(true)
    setTimeout(() => {
      setCharacterStillDetail(null)
      setIsPauseAll(false)
    }, 100)
  }

  return <Wrapper>
    <Header>
      {
        characterStillDetail ? <div className="w-screen h-menu-header-offset sm:w-desktop-width fixed sm:left-1/2 sm:-translate-x-1/2 z-[51]">
          <div onClick={onBackFromCharacterStillDetail} className="absolute left-0 top-2">
            <img src={BtnReturn} className="w-[64px]" alt="return button" />
          </div>
        </div>
          : <MenuBar title={`still-album-${name}`} />
      }
    </Header>
    <MainLayout className='h-fit' active="mypage" showHeader={false} showFooter={!characterStillDetail}>
      <div
        className='px-3 py-5 mb-[120px] h-fit'
      >
        {
          !characterStillDetail
            ? isLoading ? <Loading /> : stillAlbums.length === 0 ? (
              <NoContent>
                <div className='w-[115px] h-[115px] mx-auto mb-5'>
                  <img className='w-full h-full' src={NavigatorSorry} alt='No content'/>
                </div>
                <p className='font-vldLineGR text-[#333333] text-xl px-11 leading-[30px]'>
                  表示できるアルバムがありません <br/>
                  イベントが開催されると自動で <br/>
                  フォルダが作成されます
                </p>
              </NoContent>
            ) : (
              <>
                <div className='w-[119px] h-[142px] my-0 mx-auto mb-2.5'>
                  <StillFolder item={stillAlbumDetail} />
                </div>
                <StillWrapper>
                  {(stillAlbumDetail?.character_stills || []).map((el, i) => (
                    <CharacterStill onClick={setCharacterStillDetail} item={el} key={i} />))}
                </StillWrapper>
                <div className='w-full h-[31px] flex justify-between gap-x-[18px] mt-2.5'>
                  <div className={`w-[50%] ${index <= 0 && 'opacity-80'}`} onClick={() => handleClick(index - 1)}>
                    <img className='w-full h-full' src={BtnStillAlbumBefore} alt="" />
                  </div>
                  <div className={`w-[50%] ${index >= stillAlbums.length - 1 && 'opacity-80'}`} onClick={() => handleClick(index + 1)}>
                    <img className='w-full h-full' src={BtnStillAlbumNext} alt="" />
                  </div>
                </div>
              </>
            )
            : (<CharacterStillDetail isPauseAll={isPauseAll} key={characterStillDetail?.id} item={characterStillDetail} />)
        }
      </div>
    </MainLayout>
  </Wrapper>
}

const Header = styled.div.attrs({
  className: 'relative w-full h-[80px] z-50'
})`
`

const Wrapper = styled.div.attrs({
  className: 'sm:w-desktop-width w-screen h-screen mx-auto relative overflow-y-auto relative bg-no-repeat bg-center bg-cover'
})`
  background-image: url(${BGPage});
`

const NoContent = styled.div.attrs({
  className: 'absolute w-full pb-20'
})`
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
`

const StillWrapper = styled.div.attrs({
  className: 'grid grid-cols-3 gap-x-2 gap-y-4 py-4 px-3'
})`
  background-image: url(${BGStillAlbum});
  background-size: 100% 100%;
  min-height: 300px;
`

const CharacterStillDetailWrapper = styled.div.attrs({
  className: 'w-full h-full absolute top-0 left-0'
})`
  background-image: url(${props => props.background});
  background-size: 100% 100%;
`
