import React, { useEffect, useState } from 'react'
import iconStartedExtraTime from '../assets/icon_started_extra_time.png'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import Countdown from 'react-countdown'
import { useDuringContext } from '../pages/DuringDelivery/_Provider'
import { useCharacterLiveContext } from '../pages/CharacterSelection/_Provider'
import _ from 'lodash'

const StartExtraTime = ({ isShow, time, livestreamId, className, onComplete }, ref) => {
  const [isVisible, setIsVisible] = useState(isShow || false)
  const [displayTime, setDisplayTime] = useState(time)
  const { liveItemsSocket } = useDuringContext()
  const { characterLiveSocket } = useCharacterLiveContext()

  useEffect(() => {
    if (liveItemsSocket.extraWatchableTime === undefined || liveItemsSocket.extraWatchableTime === '' ||
      new Date(displayTime) > new Date(liveItemsSocket.extraWatchableTime)) {
      return
    }

    setDisplayTime(liveItemsSocket.extraWatchableTime)
  }, [displayTime, liveItemsSocket.extraWatchableTime])

  useEffect(() => {
    if (characterLiveSocket.extraWatchableTime === undefined || characterLiveSocket.extraWatchableTime === '' ||
      characterLiveSocket.livestreamId !== livestreamId || new Date(displayTime) > new Date(characterLiveSocket.extraWatchableTime)) {
      return
    }

    setDisplayTime(characterLiveSocket.extraWatchableTime)
  }, [characterLiveSocket.livestreamId, characterLiveSocket.extraWatchableTime])

  useEffect(() => {
    _.set(ref, 'current.showStartedExtra', () => setIsVisible(true))
  }, [])

  return (
    <>
      { isVisible && (
        <div className={clsx('absolute w-[fit-content]', className)}>
          <img src={iconStartedExtraTime} className="w-[140px] h-auto]" />
          <span className="absolute bottom-[2px] text-[9px] left-[55%] translate-x-[-50%] text-white font-hiragino">
            <Countdown
              date={new Date(displayTime)}
              onComplete={onComplete}
              renderer={({ hours, minutes, seconds }) => {
                const time = [
                  (hours || 0).toString().padStart(2, '0'),
                  (minutes || 0).toString().padStart(2, '0'),
                  (seconds || 0).toString().padStart(2, '0')
                ].join(':')
                return <>{time}</>
              }}
            />
          </span>
        </div>)
      }
    </>
  )
}

StartExtraTime.propTypes = {
  isShow: PropTypes.bool,
  time: PropTypes.string,
  livestreamId: PropTypes.string,
  className: PropTypes.string,
  onComplete: PropTypes.func
}

// @ts-ignore
export default React.forwardRef(StartExtraTime)
