export default class Inquiry {
  constructor(
    id,
    attachedFile,

    contactKinds,
    content,
    adminResponse,
    paymentAmount,
    processStatus,
    title,
    createdAt,
    adminResponseContent,
    isReaded,
    contentId,
    messageFeedback,
    attachedFileTwo,
    attachedFileThree
  ) {
    this.id = id
    this.attachedFile = attachedFile
    this.contactKinds = contactKinds
    this.content = content
    this.adminResponse = adminResponse
    this.paymentAmount = paymentAmount
    this.processStatus = processStatus
    this.title = title
    this.createdAt = createdAt
    this.adminResponseContent = adminResponseContent
    this.isReaded = isReaded
    this.contentId = contentId
    this.messageFeedback = messageFeedback
    this.attachedFileTwo = attachedFileTwo
    this.attachedFileThree = attachedFileThree
  }
}

// TODO: 執筆時点では、モックアップには、経営陣からの適切な対応に必要な変数が欠けていました。 「adminResponse」変数を統合する必要があります。
