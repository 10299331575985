import React from 'react'
import BtnClose from '../../assets/btn_close.png'
import MascotDisableHourglass from '../../assets/mascot_disable_hourglass.png'
import { ModalWithBg } from '../../../../components/common/ModalWithBg'
import PropTypes from 'prop-types'

const CantSendItemModal = ({ open, isExtraTime, onClose }) => {
  const notifyText = isExtraTime ? 'Extra' : 'ノーマル'

  return (
    <ModalWithBg title="お知らせ" open={open} onClose={onClose}>
      <div className="space-y-[30px]">
        <p className="text-center font-hiragino text-sm">
          今日の{notifyText}タイムでは、これ以上
          <br />
          <strong className="text-[#FF1D34]">【時間延長の砂】</strong>
          を
          <br />
          送れないコケッ！
        </p>

        <img src={MascotDisableHourglass} className="mx-auto w-[55%] h-[55%]" />

        <img src={BtnClose} className="mx-auto w-[119px] h-[49px]" onClick={onClose} />
      </div>
    </ModalWithBg>
  )
}

CantSendItemModal.propTypes = {
  open: PropTypes.bool,
  isExtraTime: PropTypes.bool,
  onClose: PropTypes.func
}

export { CantSendItemModal }
