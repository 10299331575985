import clsx from 'clsx'
import PropTypes from 'prop-types'

const CapsuleCarouselControls = ({ length, selectedIndex, onClick }) => {
  const genDots = () => {
    const dots = []

    for (let i = 0; i < length; i++) {
      dots.push(
        <ControlDot
          key={i}
          isSelected={selectedIndex === i}
          onClick={() => {
            onClick(i)
          }}
        />
      )
    }

    return dots
  }

  return <ul>{genDots()}</ul>
}

CapsuleCarouselControls.propTypes = {
  length: PropTypes.number.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired
}

const ControlDot = ({ isSelected, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={clsx(
        'inline-block w-3 h-3 m-2 rounded-full border-2 border-white drop-shadow',
        isSelected ? 'bg-light-pink' : 'bg-white'
      )}
      aria-label="slide"
    />
  )
}

ControlDot.propTypes = { isSelected: PropTypes.bool.isRequired, onClick: PropTypes.func.isRequired }

export default CapsuleCarouselControls
