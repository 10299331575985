import PropTypes from 'prop-types'
import BGBtn from 'src/assets/img/common/btn.png'

const StandardizedButton = ({
  onClick,
  title,
  img = BGBtn,
  alt = '',
  className = 'w-[124px] mt-4',
  disabled = false
}) => {
  return (
    <button
      className={`${className} ${disabled ? 'opacity-50' : ''}`}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {title ? <div
        className={'h-full flex items-center justify-center'}
        style={{ backgroundImage: `url('${BGBtn}')`, backgroundSize: '100% 100%' }}
      >
        <h3 className="text-xl font-vldLineGR text-white font-normal leading-[126%] truncate">
          {title}
        </h3>
      </div> : <img src={img} alt={alt} />}
    </button>
  )
}

StandardizedButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
  img: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

export default StandardizedButton
