import axiosInstance from 'src/services/common/axiosInstance'

export const getUserMiniGame = async (userId) => {
  try {
    const { data } = await axiosInstance.get(`/users/${userId}/user_mini_game`)
    return data
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const updateUserMiniGame = async ({ userId, score }) => {
  try {
    const res = await axiosInstance.patch(`/users/${userId}/user_mini_game`, {
      user_mini_game: { score }
    })
    return res.data
  } catch (error) {
    console.error(error)
    throw error
  }
}
