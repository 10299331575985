import { useEffect } from 'react'

export const useLoadingMore = ({ selector, skip, onLoadMore }) => {
  useEffect(() => {
    const element = document.querySelector(selector)

    if (!element || skip) return

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          onLoadMore()
        }
      })
    }

    const observer = new IntersectionObserver(callback)

    observer.observe(element)

    return () => observer.unobserve(element)
  }, [skip, onLoadMore, selector])
}
