import PropTypes from 'prop-types'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import StandardizedButton from 'src/components/common/StandardizedButton'
import Item from 'src/models/Item'

import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import BtnChoose from 'src/assets/img/gacha/btn_choose.png'

const CapsuleMixerConfirmModal = ({
  handleClose,
  handleConfirm,
  handleRechoose,
  selectedItems
}) => {
  return (
    <div className="w-full min-h-[300px] m-4 py-8 px-4 bg-popup-l bg-fill bg-no-repeat rounded-md relative">
      <ModalCloseButton handleClose={handleClose} />
      <TitleHeader title="アイテム選択" className="mb-2" />
      <div className="font-bold text-center whitespace-pre-line text-lg">
        {'以下のアイテムで\nミキサーガチャをしますか？'}
      </div>
      <ul className="grid grid-cols-4 gap-1 my-4">
        {selectedItems.map((item) => (
          <li className="relative" key={item.id}>
            <ItemThumbnail image={item.image} />
            <span className="font-vldLineGR text-[9px] text-center line-clamp-2">{item.name}</span>
          </li>
        ))}
      </ul>
      <div className="mx-auto w-4/5 flex justify-between">
        <StandardizedButton
          className="w-28"
          onClick={handleRechoose}
          img={BtnChoose}
          alt="選び直す"
        />
        <StandardizedButton className="w-28" img={BtnConfirm} alt="決定" onClick={handleConfirm} />
      </div>
    </div>
  )
}

CapsuleMixerConfirmModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  handleRechoose: PropTypes.func.isRequired,
  selectedItems: PropTypes.arrayOf(PropTypes.instanceOf(Item)).isRequired
}

export default CapsuleMixerConfirmModal
