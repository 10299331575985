import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import CancelableAPIRequest from 'src/models/CancelableAPIRequest'
import InquiryDetail from 'src/pages/menu/InquiryDetail'
import { useAuthState } from 'src/providers/AuthProviders'
import getUserInquiryDetail from 'src/services/get/getUserInquiryDetail'
import putUpdateNotificationInquiry from 'src/services/put/putUpdateNotificationInquiry'

const InquiryDetailContainer = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [inquiry, setInquiry] = useState(null)

  const { user } = useAuthState()
  const { id } = useParams()

  useEffect(() => {
    const req = new CancelableAPIRequest(getUserInquiryDetail, user?.id, id)
    req.invoke((resp) => {
      setInquiry(resp ?? null)
      if (resp.adminResponse && !resp.isReaded) {
        putUpdateNotificationInquiry(user?.id, id)
      }
      setIsLoading(false)
    })

    return () => req.cancel()
  }, [])

  return <InquiryDetail isLoading={isLoading} inquiry={inquiry} />
}

export default InquiryDetailContainer
