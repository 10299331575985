import Present from 'src/models/Present'
import axiosInstance from 'src/services/common/axiosInstance'

/**
 * Fetches a list of presents that the user has not accepted
 *
 * @param {number} id The ID representing the player whose presents you wish to view
 * @returns An array of presents available to the player
 */
const getUserPresents = async (id, currentPage = 0, presentType = 'all') => {
  if (!id) return []

  try {
    const res = await axiosInstance.get(
      `${process.env.REACT_APP_API_BASE_URL}/users/${id}/presents`,
      { params: { current_page: currentPage, present_type: presentType } }
    )

    if (res.data.success === false) return {}
    else {
      const presents = []
      for (const p of res.data.user_presents) {
        const present = new Present(
          p.id,
          p.box_message,
          p.amount,
          p.image,
          p.expired_at,
          p.is_received,
          p.present_type,
          p.received_at,
          p.title
        )

        presents.push(present)
      }

      if (currentPage === 0) {
        return presents
      }

      return {
        ...res.data,
        user_presents: presents
      }
    }
  } catch (error) {
    return []
  }
}

export default getUserPresents
