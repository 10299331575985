import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

/**
 * Receives a present
 *
 * @param {number} userId The ID of the user making the request
 * @param {number} presentId The ID of the present to receive
 * @param {boolean} ReceiveAll Whether or not to receive all presents
 * @return Returns an array of Presents or false if there was an error
 */
const putReceivePresent = async (
  userId,
  presentId = null,
  receiveAll = false,
  presentType,
  currentPage = 1
) => {
  if (!userId || (!presentId && !receiveAll && !presentType)) return false
  try {
    const res = await axiosInstance.put(
      `${API_ROUTES.Users}/${userId}/${API_ROUTES.Present}/${presentId}/${API_ROUTES.ReceivePresent}`,
      {
        receive_all: receiveAll,
        present_type: presentType,
        ...(receiveAll ? { current_page: currentPage } : {})
      }
    )

    if (res.data.success === false) return false
    return res.data.character_levels
  } catch (error) {
    return false
  }
}

export default putReceivePresent
