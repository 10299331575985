import styled from 'styled-components'
import PropTypes from 'prop-types'
import HisTheaterBanner from 'src/components/myPage/HisTheaterBanner'
import RecommendedManBanner from 'src/components/myPage/RecommendedManBanner'
import RankingBanner from 'src/components/myPage/RankingBanner'
import EventSlideBanner from 'src/components/myPage/EventSlideBanner'
import EventBanner from 'src/components/myPage/EventBanner'
import VoiceLiveBanner from 'src/components/myPage/VoiceLiveBanner'

import BGMenu from 'src/assets/img/mypage/bg/bg-menu.png'

const RightSideBanner = (props) => {
  const { events } = props

  return (
    <BannerArea>
      <div className="absolute top-1 -bottom-[26px] -right-[0.5rem] -left-[1rem]">
        <img className="w-[100%] h-[100%] mix-blend-soft-light" src={BGMenu} alt="" />
      </div>
      <VoiceLiveBanner />
      <div className="flex h-[5rem] mt-2 relative z-30" style={{ transform: 'skew(0, -4deg)' }}>
        <EventBanner />
        <RankingBanner />
      </div>
      <div
        className="flex h-[5rem] xs:mt-[1px] relative xs:mb-2 z-30"
        style={{ transform: 'skew(0, 1deg)' }}
      >
        <RecommendedManBanner />
        <HisTheaterBanner />
      </div>
      <div className="absolute top-[93%] right-0 xs:top-[100%] z-30">
        <EventSlideBanner events={events} />
      </div>
    </BannerArea>
  )
}

RightSideBanner.propTypes = {
  events: PropTypes.array.isRequired
}

export default RightSideBanner

const BannerArea = styled.div.attrs({
  className: 'cursor-pointer relative xs:w-[180px] w-[150px]'
})``
