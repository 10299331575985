import axiosInstance from '../common/axiosInstance'
import { API_ROUTES } from '../common/routes'

export const postUserLivestream = async (settingId) => {
  try {
    const res = await axiosInstance.post(API_ROUTES.Live.ListLiveView, { livestream_setting_id: settingId })
    return res
  } catch {
    return null
  }
}
