import PropTypes from 'prop-types'
import CurrencyChangeIndicator from 'src/components/common/CurrencyChangeIndicator'
import { CURRENCY_TYPE } from 'src/models/GachaInfo'

const CapsuleCurrencyChangeDisplay = ({
  currencyType,
  currencyText,
  redCount,
  blueCount,
  ticketCount,
  ssrCount
}) => {
  const genCurrencyChange = () => {
    let change
    let redChange

    const CurrencySsrTicket = () => {
      if (currencyText === 'SSR確定ガチャチケット10枚' && ssrCount >= 10) {
        change = Math.max(ssrCount - 10, 0)
      } else if (currencyText === 'SSR確定ガチャチケット1枚' && ssrCount >= 1) {
        change = Math.max(ssrCount - 1, 0)
      } else {
        change = 0
      }
      return <CurrencyChangeIndicator icon="ssr" from={ssrCount} to={change} hasChange={true} />
    }

    const CurrencyTicket = () => {
      if (currencyText === 'ガチャチケット10枚' && ticketCount >= 10) {
        change = Math.max(ticketCount - 10, 0)
      } else if (currencyText === 'ガチャチケット1枚' && ticketCount >= 1) {
        change = Math.max(ticketCount - 1, 0)
      } else {
        change = 0
      }
      return (
        <CurrencyChangeIndicator icon="ticket" from={ticketCount} to={change} hasChange={true} />
      )
    }

    const CurrencyDiamond = () => {
      if (blueCount >= 50 && currencyText === 'ダイヤ50個') {
        change = Math.max(blueCount - 50, 0)
      } else {
        change = 0
      }
      if (blueCount < 50 && currencyText === 'ダイヤ50個' && redCount > 0) {
        redChange = Math.max(redCount + blueCount - 50, 0)
      } else {
        redChange =
          (redCount && currencyText === 'ダイヤ50個') || currencyText === 'ダイヤ5個' ? redCount : 0
      }
      if (blueCount >= 5 && currencyText === 'ダイヤ5個') {
        change = Math.max(blueCount - 5, 0)
      }
      if (blueCount < 5 && currencyText === 'ダイヤ5個' && redCount > 0) {
        redChange = Math.max(redCount + blueCount - 5, 0)
      }

      return (
        <>
          <CurrencyChangeIndicator
            icon="red"
            from={redCount}
            to={redChange}
            counter="個"
            hasChange={redCount !== redChange}
          />
          <CurrencyChangeIndicator
            icon="blue"
            from={blueCount}
            to={change}
            hasChange={true}
            counter="個"
          />
        </>
      )
    }

    switch (currencyType) {
      case CURRENCY_TYPE.SSRTicket:
        return CurrencySsrTicket()
      case CURRENCY_TYPE.Ticket:
        return CurrencyTicket()
      default:
        return CurrencyDiamond()
    }
  }

  return (
    <div className="my-6 w-4/5">
      <div className="w-full rounded-md bg-white border border-menu-dark-blue text-sm flex p-2 justify-center mb-4">
        <span className="mr-2">必要数</span>
        <span>{currencyText}</span>
      </div>
      {genCurrencyChange()}
    </div>
  )
}

CapsuleCurrencyChangeDisplay.propTypes = {
  currencyType: PropTypes.number.isRequired,
  currencyText: PropTypes.string.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired
}

export default CapsuleCurrencyChangeDisplay
