import PropTypes from 'prop-types'
import { CURRENCY_TYPE, DRAW_TYPE, GACHA_TYPES } from 'src/models/GachaInfo'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import CapsuleCurrencyChangeDisplay from 'src/pages/main/capsule/CapsuleCurrencyChangeDisplay'
import { MAIN_PATHS } from 'src/routes/main'
import { Link } from 'react-router-dom'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnShop from 'src/assets/img/gacha/btn_shop.png'
import BtnChoose from 'src/assets/img/gacha/btn_choose.png'
import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import TitleKakunin from 'src/assets/img/gacha/title_kakunin.png'
import { useEffect } from 'react'
import postUserItemAnalytics from 'src/services/post/postUserItemAnalytics'
import { useAuthState } from 'src/providers/AuthProviders'

const CapsuleInsufficientScreen = ({
  currencyType,
  currencyText,
  handleRechoose,
  handleClose,
  redCount,
  blueCount,
  ticketCount,
  ssrCount,
  handleConfirm,
  drawType,
  typeGacha
}) => {
  const { user } = useAuthState()

  const genConfirmText = () => {
    const checkText = drawType === DRAW_TYPE.Eleven ? '11' : '1'
    const message =
      drawType === DRAW_TYPE.Free
        ? '毎日１回無料のガチャを１回引きますか？'
        : `${currencyText}でガチャを${checkText}回引きますか？`

    return message
  }

  const renderConfirmationOrItemShortage = () => {
    if (
      (ticketCount >= 10 && currencyText === 'ガチャチケット10枚') ||
      (ssrCount >= 10 && currencyText === 'SSR確定ガチャチケット10枚')
    ) {
      return <img src={TitleKakunin} alt="確認" className="w-64" />
    } else {
      return <TitleHeader title="アイテム不足" className="mb-4" />
    }
  }

  useEffect(() => {
    currencyType === 0 && typeGacha === GACHA_TYPES.Items && postUserItemAnalytics(user.id, {
      reason: 'missing_gacha_item_diamond'
    })
  }, [currencyType])

  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[368px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={handleClose} />

      {renderConfirmationOrItemShortage()}
      <div className="font-bold text-center whitespace-pre-line text-lg">{genConfirmText()}</div>
      <CapsuleCurrencyChangeDisplay
        currencyType={currencyType}
        currencyText={currencyText}
        redCount={redCount}
        blueCount={blueCount}
        ticketCount={ticketCount}
        ssrCount={ssrCount}
      />

      {currencyType === CURRENCY_TYPE.Diamond ? (
        <Link className="w-40" to={MAIN_PATHS.ShopPage}>
          <img src={BtnShop} />
        </Link>
      ) : (
        <div className="flex gap-5">
          <StandardizedButton
            className="w-28"
            onClick={handleRechoose}
            img={BtnChoose}
            alt="選び直す"
          />
          {ticketCount >= 10 && currencyText === 'ガチャチケット10枚' && (
            <StandardizedButton
              className="w-28"
              img={BtnConfirm}
              alt="決定"
              onClick={() => {
                handleConfirm(true)
              }}
            />
          )}
          {ssrCount >= 10 && currencyText === 'SSR確定ガチャチケット10枚' && (
            <StandardizedButton
              className="w-28"
              img={BtnConfirm}
              alt="決定"
              onClick={() => {
                handleConfirm(true)
              }}
            />
          )}
        </div>
      )}
    </div>
  )
}

CapsuleInsufficientScreen.propTypes = {
  currencyType: PropTypes.number.isRequired,
  currencyText: PropTypes.string.isRequired,
  handleRechoose: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  redCount: PropTypes.number.isRequired,
  blueCount: PropTypes.number.isRequired,
  ticketCount: PropTypes.number.isRequired,
  ssrCount: PropTypes.number.isRequired,
  handleConfirm: PropTypes.func,
  drawType: PropTypes.number.isRequired,
  typeGacha: PropTypes.string
}

export default CapsuleInsufficientScreen
