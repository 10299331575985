import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { useMutation } from 'react-query'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { useAuthState } from 'src/providers/AuthProviders'
import { postPaymentOrder, postPaymentOrderBitcash } from 'src/services/post/postPaymentOrder'
import ShopPurchaseResultScreen from 'src/pages/main/shop/components/ShopDiamondTab/ShopPurchaseResultScreen'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnPurchase from 'src/assets/img/shop/btn_purchase.png'
import BtnSoftbank from 'src/assets/img/shop/btn_softbank.png'
import BtnDocomo from 'src/assets/img/shop/btn_docomo.png'
import BtnAU from 'src/assets/img/shop/btn_au.png'
import { useNavigate } from 'react-router-dom'
import LoadingIcon from 'src/components/layouts/LoadingIcon'
import { renderButtonAmazonPay } from 'src/services/helper'

const ShopPaymentButtons = ({ method }) => {
  const { selectedItem, setModalContent } = useShopContext()
  const { user } = useAuthState()
  const navigate = useNavigate()

  useEffect(() => {
    renderButtonAmazonPay(user, selectedItem, method)
  }, [])

  const {
    data: orderConfigs,
    mutate: fetchOrderConfigs,
    isLoading
  } = useMutation(postPaymentOrder, {
    onError: () => {
      // @ts-ignore
      setModalContent(<ShopPurchaseResultScreen isError />)
    }
  })

  const {
    data: otherData,
    mutate: fetchOrderBitcashConfigs,
    isLoading: isOtherDataLoading
  } = useMutation(postPaymentOrderBitcash, {
    onSuccess: (data) => {
      // window.open(data.url, '_blank')
    },
    onError: () => {
      setModalContent(<ShopPurchaseResultScreen isError />)
    }
  })

  const onPurchase = useCallback(
    (methodName) => {
      fetchOrderConfigs({
        order: {
          user_id: user?.id || 1,
          product_id: selectedItem?.id,
          payment_amount: selectedItem?.listed_price,
          payment_method: methodName
        }
      })
    },
    [method, selectedItem, user?.id]
  )

  const handleClick = () => {
    const data = {
      selectedItem: selectedItem,
      setModalContent: setModalContent
    }
    localStorage.setItem('dataToSend', JSON.stringify(data))
    navigate('/payments/paymentOrderWebmoney')
  }

  const handleClickBitcash = useCallback(
    (methodName) => {
      fetchOrderBitcashConfigs({
        order: {
          user_id: user?.id || 1,
          product_id: selectedItem?.id,
          payment_amount: selectedItem?.listed_price,
          payment_method: methodName
        }
      })
    },
    [method, selectedItem, user?.id]
  )

  // open GMO payment url when api get order configs success and return url
  useEffect(() => {
    if (otherData?.url) {
      window.location.href = otherData.url
    } else if (orderConfigs?.link_payment) {
      window.location.href = orderConfigs?.link_payment
    }
  }, [orderConfigs?.link_payment, !otherData?.url])

  if (isLoading || isOtherDataLoading) return <></>

  switch (method) {
    case 'career':
      return (
        <>
          <StandardizedButton
            img={BtnSoftbank}
            alt="決済へ進む"
            onClick={() => onPurchase('sb')}
            className="w-64 mt-4"
          />
          <StandardizedButton
            img={BtnDocomo}
            alt="決済へ進む"
            onClick={() => onPurchase('docomo')}
            className="w-64 my-4"
          />
          <StandardizedButton
            img={BtnAU}
            alt="決済へ進む"
            onClick={() => onPurchase('au')}
            className="w-64 mb-4"
          />
        </>
      )
    case 'credit':
      return (
        <StandardizedButton
          img={BtnPurchase}
          alt="決済へ進む"
          onClick={() => onPurchase(method)}
          className="my-4 w-52"
        />
      )
    case 'amazon_pay':
      return (
        <div id="btn-amazon" className="my-3">
          <LoadingIcon />
        </div>
      )
    case 'web_money':
      return (
        <StandardizedButton
          img={BtnPurchase}
          alt="決済へ進む"
          onClick={handleClick}
          className="my-4 w-52"
        />
      )
    case 'bitcash':
      return (
        <StandardizedButton
          img={BtnPurchase}
          alt="決済へ進む"
          onClick={() => handleClickBitcash('bitcash')}
          className="my-4 w-52"
        />
      )
    default:
      return (
        <StandardizedButton
          img={BtnPurchase}
          alt="決済へ進む"
          onClick={() => onPurchase(method)}
          className="my-4 w-52"
        />
      )
  }
}

ShopPaymentButtons.propTypes = {
  method: PropTypes.string.isRequired
}

export default ShopPaymentButtons
