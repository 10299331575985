import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

export const getListItemCharacter = async (idCharacter, selectedOption) => {
  try {
    const res = await axiosInstance.get(
      API_ROUTES.RealTalk.GetListItemChacter.replace(':character_id', idCharacter),
      {
        params: {
          sort_type: selectedOption
        }
      }
    )
    return res.data
  } catch (error) {
    console.log(error)
  }
}
