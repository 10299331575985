import axiosInstance from 'src/services/common/axiosInstance'
import { API_ROUTES } from 'src/services/common/routes'

const getShops = async () => {
  try {
    const { data } = await axiosInstance.get(API_ROUTES.Shops)
    return data || []
  } catch {
    return []
  }
}

export { getShops }
