import PropTypes from 'prop-types'
import { Modal } from 'src/components/common/Modal'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import ItemThumbnail from 'src/components/menu/ItemThumbnail'
import { getItemImage } from 'src/lib/present'
import Present from 'src/models/Present'

const PresentAcceptModal = ({ show, onClose, present, isNormalItem }) => {
  return (
    <Modal
      open={show}
      onClose={onClose}
      className="sm:w-[320px] w-11/12 absolute top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-left shadow-xl transform transition-all sm:max-w-lg font-hiragino"
    >
      <div className="w-full h-[320px] p-4 bg-popup-sm bg-fill bg-no-repeat rounded-md flex flex-col items-center justify-center text-center relative">
        <ModalCloseButton handleClose={onClose} />
        <TitleHeader title="プレゼント受け取り" className="absolute top-8" />
        {present ? (
          <>
            <ItemThumbnail
              image={getItemImage(present)}
              className="w-[100px] h-[100px] border-pale-blue rounded my-4"
              imgClassName={
                isNormalItem(present.presentType)
                  ? 'w-full h-full'
                  : 'w-[90%]'
              }
              rarity={isNormalItem(present.presentType)}
            />
            {new Date(present.expiredAt) > new Date() ? (
              <span className="font-bold">{present.title}を受け取りました</span>) : (
              <span className="font-bold">受取り期限が切れた為、<br />プレゼントを受け取れませんでした</span>)
            }
          </>
        ) : (
          <span className="font-bold">
            プレゼントの受け取りが
            <br />
            完了しました
          </span>
        )}
      </div>
    </Modal>
  )
}

PresentAcceptModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  present: PropTypes.instanceOf(Present),
  isNormalItem: PropTypes.func.isRequired
}

export default PresentAcceptModal
