import { useState } from 'react'
import UserProfilePage from 'src/pages/menu/UserProfile'
import { authActionTypes, useAuthDispatch, useAuthState } from 'src/providers/AuthProviders'
import patchUser from 'src/services/patch/patchUser'

const UserProfilePageContainer = () => {
  const { user } = useAuthState()
  const [apiErrors, setApiErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const authDispatch = useAuthDispatch()

  const handleSave = async (values) => {
    try {
      setIsLoading(true)
      const res = await patchUser(user?.id, values)
      if (res.success) {
        authDispatch({ type: authActionTypes.LOGIN_USER, payload: { ...user, ...res.items } })
      }
      setIsLoading(false)
      return true
    } catch (error) {
      const errors = error?.details || {}
      if (errors.email) {
        errors.email = errors.email.map(item => `メールアドレス${item}`)
      }
      setApiErrors(errors)
      setIsLoading(false)
      return false
    }
  }

  return (
    <UserProfilePage
      user={user}
      isLoading={isLoading}
      handleSave={handleSave}
      apiErrors={apiErrors}
    />
  )
}

export default UserProfilePageContainer
