import { useNavigate } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { Tab } from '@headlessui/react'
import MenuWrapper from 'src/components/menu/MenuWrapper'
import Loading from 'src/components/layouts/Loading'
import Event from 'src/components/Event/Event'
import { useQuery } from 'react-query'
import getEvents from 'src/services/get/getEvents'
import { beforeThePresentTime } from 'src/lib/date'
import { inProgress } from 'src/lib/common'
import TipsModal from 'src/components/common/TipsModal'

import btnOffDaily from 'src/assets/img/event/btn_off_daily.png'
import btnONDaily from 'src/assets/img/event/btn_on_daily.png'
import btnOffEvent from 'src/assets/img/event/btn_off_event.png'
import btnOnEvent from 'src/assets/img/event/btn_on_event.png'
import btnOffLimited from 'src/assets/img/event/btn_off_limited.png'
import btnOnLimited from 'src/assets/img/event/btn_on_limited.png'

const EventsList = () => {
  const { data: events, isLoading } = useQuery('events', () => getEvents())
  const contentRef = useRef(null)
  const [selectedIndex, setSelectedIndex] = useState(2)
  const [currentEvents, setCurrentEvents] = useState([])
  const navigate = useNavigate()

  useEffect(() => {
    const findCurrentEvents = events?.filter(
      (event) => beforeThePresentTime(event.endTime) && event.displayOnTheEventPage
    )
    setCurrentEvents(findCurrentEvents)
  }, [events])

  return (
    <MenuWrapper
      className="flex flex-col items-center pt-menu-header-offset relative"
      overflow={true}
      containerRef={contentRef}
    >
      <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
        <Tab.List className="flex justify-between w-full">
          <Tab className="mr-1">
            {({ selected }) => (
              <img src={selected ? btnONDaily : btnOffDaily} onClick={() => inProgress(navigate)} />
            )}
          </Tab>
          <Tab className="mr-1">
            {({ selected }) => (
              <img
                src={selected ? btnOnLimited : btnOffLimited}
                onClick={() => inProgress(navigate)}
              />
            )}
          </Tab>
          <Tab>{({ selected }) => <img src={selected ? btnOnEvent : btnOffEvent} />}</Tab>
        </Tab.List>
        {isLoading ? (
          <Loading />
        ) : (
          <Tab.Panels className="mt-6 h-full">
            <Tab.Panel>
              <div />
            </Tab.Panel>
            <Tab.Panel>
              <div />
            </Tab.Panel>
            <Tab.Panel>
              <Event events={currentEvents} />
            </Tab.Panel>
          </Tab.Panels>
        )}
      </Tab.Group>
      <TipsModal type="events" />
    </MenuWrapper>
  )
}

export default EventsList
