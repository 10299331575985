import moment from 'moment'
import { useCallback, useContext, useEffect, useState } from 'react'
import { ActionCableContext } from 'src/providers/ActionCableProvider'
import { useAuthState } from 'src/providers/AuthProviders'

const useChatRoom = (conversationId = null) => {
  const { user } = useAuthState()
  const cable = useContext(ActionCableContext)
  const [message, setMessage] = useState()

  const sendMessage = useCallback(
    (messageBody) => {
      const msg = {
        description: messageBody,
        created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        senderId: user.id
      }

      setMessage({
        ...msg,
        is_owner: true
      })
    },
    [user]
  )

  const sendMessageItem = useCallback(
    (messageBody) => {
      const msg = {
        item: messageBody,
        created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        senderId: user.id
      }

      setMessage({
        ...msg,
        is_owner: true
      })
    },
    [user]
  )

  const sendMessageImg = useCallback(
    (messageBody) => {
      const msg = {
        attachment_url: messageBody,
        created_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
        senderId: user.id
      }
      setMessage({
        ...msg,
        is_owner: true
      })
    },
    [user]
  )

  useEffect(() => {
    if (!conversationId || !cable) return

    const channel = cable.subscriptions.create(
      { channel: 'ConversationChannel', conversation_id: conversationId },
      {
        received: (msg) =>
          setMessage({
            ...msg,
            is_owner: false
          })
      }
    )

    return () => {
      channel.unsubscribe()
    }
  }, [conversationId, cable])

  return { message, sendMessage, sendMessageItem, sendMessageImg }
}

export default useChatRoom
