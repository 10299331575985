import { createContext, useContext, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import getMainTenance from 'src/services/get/getMaintenance'
import PropTypes from 'prop-types'

const maintenanceContext = createContext({})
export const useMaintenanceContext = () => useContext(maintenanceContext)

export const MaintenanceProvider = ({ children }) => {
  const queryClient = useQueryClient()

  const { data: maintenance, isLoading } = useQuery('maintenance', getMainTenance)

  useEffect(() => {
    const intervalId = setInterval(() => {
      queryClient.invalidateQueries('maintenance')
    }, 30000)

    return () => clearInterval(intervalId)
  }, [queryClient])

  if (isLoading) return

  const index = { maintenance, isLoading }

  return <maintenanceContext.Provider value={index}>{children}</maintenanceContext.Provider>
}

MaintenanceProvider.propTypes = {
  children: PropTypes.element.isRequired
}
