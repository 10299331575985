const authActionTypes = {
  LOGIN_USER: 'LOGIN_USER',
  LOGOUT_USER: 'LOGOUT_USER',
  ACTIVE_USER: 'ACTIVE_USER',
  EXCHANGE_HEART_BY_RED_DIAMOND: 'EXCHANGE_HEART_BY_RED_DIAMOND',
  SPEND_HEART: 'SPEND_HEART',
  ADD_ITEM: 'ADD_ITEM',
  ADD_HEART: 'ADD_HEART',
  SPEND_DIAMOND_RED: 'SPEND_DIAMOND_RED',
  SPEND_DIAMOND_BLUE: 'SPEND_DIAMOND_BLUE',
  SPEND_DIAMOND_RED_ADD_BLUE: 'SPEND_DIAMOND_RED_BLUE'
}

export { authActionTypes }
