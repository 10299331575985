// @ts-nocheck
import React, { useCallback } from 'react'
import { useMutation } from 'react-query'
import { authActionTypes, useAuthDispatch } from 'src/providers/AuthProviders'
import { useShopContext } from 'src/pages/main/shop/contexts/ShopContext'
import { getExchangeHeartByRedDiamond } from 'src/services/get/getHeartExchangeConfigs'
import ShopExchangeCompleteModal from 'src/pages/main/shop/components/ShopHeartTab/ShopExchangeCompleteModal'
import ModalCloseButton from 'src/components/common/ModalCloseButton'
import TitleHeader from 'src/components/common/TitleHeader'
import ChevronDoubleDown from 'src/components/common/symbols/ChevronDoubleDown'
import StandardizedButton from 'src/components/common/StandardizedButton'

import BtnConfirm from 'src/assets/img/gacha/btn_confirm.png'
import BtnCancel from 'src/assets/img/common/btn-cancel.png'
import DiamondRed from 'src/assets/img/common/diamond-red.png'
import Life from 'src/assets/img/common/life.png'

const ShopExchangeConfirmModal = () => {
  const authDispatch = useAuthDispatch()
  const { setModalContent, closeModal, selectedHeartExchange } = useShopContext()

  const { mutate: handleExchange } = useMutation(getExchangeHeartByRedDiamond, {
    onSuccess: ({ data }) => {
      if (!data) return

      authDispatch({ type: authActionTypes.EXCHANGE_HEART_BY_RED_DIAMOND, payload: data })
      setModalContent(<ShopExchangeCompleteModal />)
    }
  })

  const {
    number_of_red_diamonds: numberOfRedDiamonds,
    number_of_hearts_exchanged: numberOfHeartsExchanged
  } = selectedHeartExchange

  const onConfirm = useCallback(() => {
    handleExchange(selectedHeartExchange.id)
  }, [])

  return (
    <div className="w-full px-4 pt-8 pb-16  min-h-[340px] bg-popup-md bg-fill bg-no-repeat rounded-md relative flex flex-col items-center justify-between font-hiragino">
      <ModalCloseButton handleClose={closeModal} />
      <TitleHeader title="年齢確認" />
      <div className="text-lg font-bold text-center whitespace-pre-line">
        {`ダイヤ${numberOfRedDiamonds}個を\nハート${numberOfHeartsExchanged}個に交換しますか？`}
      </div>
      <div className="flex flex-col items-center justify-center w-3/4 my-7">
        <div className="flex w-full mb-2 border rounded border-menu-dark-blue">
          <div className="flex items-center justify-center w-1/2 py-1 bg-menu-light-blue">
            <img src={DiamondRed} className="w-6 mr-2" />
            <span className="text-sm">ダイヤ</span>
          </div>
          <div className="flex items-center justify-center w-1/2">{numberOfRedDiamonds}個</div>
        </div>
        <ChevronDoubleDown />
        <div className="flex w-full mt-2 border rounded border-menu-dark-blue">
          <div className="flex items-center justify-center w-1/2 py-1 bg-menu-light-blue">
            <img src={Life} className="w-6 mr-2" />
            <span className="text-sm">ハート</span>
          </div>
          <div className="flex items-center justify-center w-1/2">{numberOfHeartsExchanged}個</div>
        </div>
      </div>
      <div className="flex justify-between w-4/5">
        <StandardizedButton
          className="w-28"
          img={BtnCancel}
          alt="キャンセル"
          onClick={closeModal}
        />
        <StandardizedButton className="w-28" img={BtnConfirm} alt="決定" onClick={onConfirm} />
      </div>
    </div>
  )
}

export default ShopExchangeConfirmModal
